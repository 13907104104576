import styled from "styled-components";
import { FormatCurrency } from "../../../../../util";
import { useEffect } from "react";

const ProductItem = ({
  title = "Product Title",
  sub_title = "Product short description. Lorem ipsum dolor sit amet consectetur",
  price = "300000",
  price_discount = "20000",
  product_name = "2000 SMS Broadcast",
  image = "https://images.unsplash.com/photo-1506765515384-028b60a970df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80",
  include = [
    {
      item: {
        item_id: 1,
        channel_id: 2,
        inventory_id: 1,
        placement_id: 1,
        item_name: "Messaging Test SMS LBA MSG placement",
        is_generate_name: 1,
        item_description: "description",
        quantity: 2,
        status: 1,
        created_at: "2023-02-22 16:10:45",
        updated_at: "2023-02-22 16:10:45",
        deleted_date: null,
        image:
          "https://storage.mediacartz.com/files/mediacartz/Fdd4mXhttV.jpeg",
      },
    },
  ],
  quantity,
  discount_type,
  product_id,
  item,
}) => {
  const GenerateMoney = () => {
    if (discount_type === "percentage") {
      return (
        <div className="container-price-item">
          <div className="price-product-item">
            {FormatCurrency.currency(
              parseInt(price_discount) -
                (parseInt(price) / 100) * parseInt(price_discount)
            )}
          </div>
          {price_discount ? (
            <div className="discount-product-item">
              {FormatCurrency.currency(price_discount)}
            </div>
          ) : null}
        </div>
      );
    } else if (discount_type === "amount") {
      return (
        <div className="container-price-item">
          <div className="price-product-item">
            {FormatCurrency.currency(
              parseInt(price_discount) - parseInt(price)
            )}
          </div>
          {price_discount ? (
            <div className="discount-product-item">
              {FormatCurrency.currency(price_discount)}
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="container-price-item">
          <div className="price-product-item">
            {FormatCurrency.currency(price)}
          </div>
        </div>
      );
    }
  };

  const btnAddCart = (item) => {
    const getCart = JSON.parse(window.localStorage.getItem("cart")) || [];

    if (getCart.length > 0) {
      const setStringify = JSON.stringify([
        ...getCart,
        {
          ...item,
          qty: 1,
        },
      ]);
      window.localStorage.setItem("cart", setStringify);
    } else {
      const setStringify = JSON.stringify([
        {
          ...item,
          qty: 1,
        },
      ]);
      window.localStorage.setItem("cart", setStringify);
    }
    window.location.reload();
  };

  const getCheckCartForButton = () => {
    const getCart = JSON.parse(window.localStorage.getItem("cart")) || [];

    const btnSendViewCart = () =>
      (window.location.href = `/home/product/detail?view_cart=true`);

    if (
      getCart.filter((filter) => filter.product_id === item.product_id)
        ?.length > 0
    ) {
      return (
        <div
          className="btn btn-add-to-cart-product-item "
          onClick={btnSendViewCart}
        >
          View to Cart
        </div>
      );
    } else {
      return (
        <div
          className="btn btn-add-to-cart-product-item "
          onClick={() => btnAddCart(item)}
        >
          <i className="bi-cart3" />
          Add to Cart
        </div>
      );
    }
  };

  return (
    <Container>
      <div className="container-img">
        <img src={image} alt={image} />
      </div>

      <div className="container-content">
        <div className="container-content-body">
          <div className="title-product-item">{title}</div>
          <div className="sub-title-product-item">{sub_title}</div>
          <div className="title-product-item">
            {quantity} {product_name}
          </div>

          <GenerateMoney />

          {include.length > 0 ? (
            <div className="container-includes-product-item mt-2">
              <div className="title-includes-product-item">Includes:</div>
              <ul>
                {include?.map((item, idx) => (
                  <li key={idx}>
                    {item?.item?.quantity} {item?.item?.item_name}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>

        {getCheckCartForButton()}
        <a
          className="btn btn-add-to-cart-product-item mt-2"
          href={`/home/product/detail?product_id=${item.product_id}`}
        >
          View Detail
        </a>
      </div>
    </Container>
  );
};

export default ProductItem;

const Container = styled.div`
  .container-img {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  width: 100%;
  max-height: 600px;

  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 14px;
  overflow: hidden;

  .container-content {
    padding: 13.5px;
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    align-items: stretch;
    flex-grow: 1;
    flex: 1;
    height: 386px;
  }

  .container-content-body {
    width: 100%;
    height: 100%;
    max-height: 540px;
    overflow: hidden;
    position: relative;
    flex: 1;
    margin-bottom: auto;
    flex-grow: 1;
  }

  .container-includes-product-item {
    margin-top: 12px;
    .title-includes-product-item {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 24px;
      /* or 185% */

      display: flex;
      align-items: center;

      color: #161c2b;
      margin-bottom: 8px;
    }
    ul {
      list-style-type: disc;
      color: black;
    }
    li {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 18px;
      /* or 180% */

      color: #161c2b;
    }
  }

  .btn-add-to-cart-product-item {
    flex: 1;
    max-height: 35px;

    background: #3b3e98;
    border: 1px solid #3b3e98;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    /* or 192% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    gap: 12px;
    border-radius: 0px;
    margin-top: 12px;
  }

  .discount-product-item {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: line-through;

    color: #6d7588;
  }

  .price-product-item {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: #161c2b;
    margin-top: 10px;
  }
  .sub-title-product-item {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    /* or 180% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #161c2b;
    margin-top: 10px;

    max-height: 50px;
    height: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .container-price-item {
    max-height: 50px;
    height: 100%;
  }

  .title-product-item {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #161c2b;
    text-align: center;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
