import React from 'react'
import { ContainerFooter } from './style'
import packageJson from "../../../package.json"

export const IdxFooter = () => {
  return (
    <ContainerFooter>
      <div>Centennial Tower Lt. 38H, Jl. Jend. Gatot Subroto, RT.2/RW.2, Karet Semanggi, Kec. Setiabudi, Jakarta Selatan, Indonesia 12950</div>
      <div>All Rights Reserved © Mediacartz.com v{packageJson.version} | +62 21 22958145 | <a href="#">cs@mediacartz.com</a></div>
    </ContainerFooter>
  )
}
