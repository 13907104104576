import React, { useState } from "react";
import color from "landingPage/assets/color";
import styled from "styled-components";
import LogoMediacartz from "landingPage/assets/iconAndLogo/logo mediacartz resized-400-02-02 2.png";
import NavbarData from "./Navbar-data";
import { ChevronSmallDown } from "@styled-icons/entypo";
import NavbarDropdown from "./Navbar-dropdown";
import DropdownLeague from "./dropdown-league";
import NavbarMobileVersion from "./Navbar.mobile";
import { Mobile_query_v2 } from "../../util/Query";

const { white, black2, primary } = color;

const Navbar = () => {
  const [activeNav, setActiveNav] = useState("");

  const ActiveNavHandler = (name) => {
    if (activeNav === name) {
      setActiveNav("");
    } else {
      setActiveNav(name);
    }
  };

  const btnLogin = () => (window.location.href = "/login");
  const btnRegister = () => (window.location.href = "/register");

  return (
    <ContainerInti className=" position-relative">
      <Container>
        <header className="d-flex flex-grow-1  justify-content-between align-items-center">
          <div className="logo">
            <a href="/home">
              <img src={LogoMediacartz} alt={LogoMediacartz} />
            </a>
          </div>
          <NavbarWrap className="navbar-wrap">
            <div className="d-flex  gap-3">
              {NavbarData?.map((item, idx) => {
                return (
                  <div
                    onClick={() => ActiveNavHandler(item.name)}
                    className=" justify-content-center align-items-center d-flex flex-column"
                  >
                    <TextNav
                      key={idx}
                      className="text-nav d-flex align-items-center gap-1"
                      active={item.name === activeNav ? true : false}
                    >
                      {item.name} <ChevronSmallDown width={20} />
                    </TextNav>
                    <Ellipse
                      active={item.name === activeNav ? true : false}
                      className="ellipse mt-1"
                    ></Ellipse>
                  </div>
                );
              })}
            </div>
          </NavbarWrap>
          <Account className="account">
            <div className="d-flex align-items-center">
              <div className="laguage">
                <DropdownLeague />
              </div>
              <div className="button-account">
                <button className="my-account" onClick={btnLogin}>
                  My Account
                </button>
                <button className="get-started" onClick={btnRegister}>
                  Get Started
                </button>
              </div>
            </div>
          </Account>
          <NavbarMobileVersion />
        </header>
      </Container>
      <NavbarDropdown
        content={NavbarData?.find((find) => find.name === activeNav)}
      />
    </ContainerInti>
  );
};

export default Navbar;

const Account = styled.div`
  .button-account {
    gap: 20px;
    display: flex;
    align-items: center;
    border-left: thin solid #dddd;
  }
  .button-account > .get-started {
    border: 2px solid #3b3e98;
    border-radius: 24px;
    width: 130px;
    height: 48px;
    text-align: center;
    background-color: #3b3e98;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;

    color: #ffffff;
  }

  .button-account > .my-account {
    margin-left: 20px;
    border: 2px solid #3b3e98;
    border-radius: 24px;
    width: 130px;
    height: 48px;
    text-align: center;
    background-color: transparent;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;

    color: #1a202c;
  }
  ${Mobile_query_v2} {
    display: none;
  }
`;

const TextNav = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  cursor: pointer;
  color: ${({ active }) => (active ? primary : black2)};

  &:hover {
    color: ${primary};
  }
`;

const Ellipse = styled.div`
  width: 7px;
  height: 7px;
  background-color: ${primary};
  border-radius: 100px;
  margin-left: -20%;
  opacity: ${({ active }) => (active ? "1" : "0")};
`;

const NavbarWrap = styled.nav`
  margin-left: 10px;
  margin-top: 10px;

  ${Mobile_query_v2} {
    display: none;
  }
`;

const ContainerInti = styled.div`
  padding: 0 93px;
  padding-top: 16px;

  position: relative;

  ${Mobile_query_v2} {
    padding: 0 25px;
  }
  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;

const Container = styled.div`
  background-color: ${white};
  box-shadow: 0px 10px 40px rgba(59, 62, 152, 0.2);
  height: 105px;
  border-radius: 100px;
  padding: 28px 35px;

  ${Mobile_query_v2} {
    background-color: transparent;
    box-shadow: none;

    header > .logo > a > img {
      width: 148px;
    }
  }
`;
