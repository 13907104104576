import styled from "styled-components";

const OurChannel = () => {
  return (
    <Container>
      <div className="blue-text">OUR CHANNEL</div>
      <div className="title-text-channel">Lorem ipsum dolor sit amet</div>
      <div className="sub-title-text-channel">
        Lorem ipsum dolor sit amet consectetur. Neque at auctor pellentesque id
        purus maecenas odio faucibus. Sagittis dictum molestie turpis dictum
        diam sit placerat.
      </div>
    </Container>
  );
};

export default OurChannel;

const Container = styled.div`
  text-align: center;

  .blue-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;

    /* Brand Colors/Tertiary/100 */

    color: #2fbeee;
  }
`;
