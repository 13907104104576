import React, { useState, useEffect } from "react";
import {
  Modal_Component,
  Loadingfunc,
  AlertError,
  TableData,
  DropDown_More as DropdownMore,
  AlertQuestion,
  AlertSuccess,
} from "component";
import {
  COLOR_SECONDARY,
  ButtonPrimary,
  COLOR_PRIMARY,
} from "component/style/content/default";
import { ApiEvent } from "service/api";
import { Moment, displayStatus } from "../../../../../util";
import ModalFormCreate from "./detail.form.create";
import ModalViewHtml from "./viewHtml"

const header = [
  "#",
  "Pertanyaan",
  "Wajib*",
  "Pilihan Jawaban",
  "Format Jawaban",
];

const dataMore = [
  {
    name: "Ubah",
  },
  {
    name: "Hapus",
  },
];

const api = ApiEvent.Microsite;
const ModalDetail = ({ show, onHide, params }) => {
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [dataForm, setDataForm] = useState([]);
  const [bool, setBool] = useState({
    create: false,
    viewHtml: false
  });
  const [mircosite_form_id, setMicrosite_form_id] = useState(null);
  const [dataDetail, setDataDetail] = useState({});

  const getForm = () => {
    api.getMicrositeForm(id).then((res) => {
      if (res.success) {
        setDataForm(res.data);
      }
    });
  };

  const getData = () => {
    setLoading(true);
    api
      .getMicrositeDetail(id)
      .then((res) => {
        if (res.success) {
          setData(res.data);
        } else {
          AlertError({ title: "ERROR", text: res.error });
        }
      })
      .finally(() => setLoading(false));
  };

  const deleteFormHandler = async (item) => {
    await AlertQuestion({
      title: "Delete",
      text: `Do You Want To Delete '${item.microsite_form_question}' ?`,
    }).then((res) => {
      if (res.isConfirmed) {
        api.deleteFormMicrosite(item.microsite_form_id).then(async (result) => {
          if (result.success) {
            await AlertSuccess({ title: "SUCCESS", text: result.success });
            await getForm();
          } else {
            await AlertSuccess({ title: "ERROR", text: result.error });
          }
        });
      }
    });
  };

  const btnMore = (name, id, item) => {
    switch (name) {
      case "Hapus":
        deleteFormHandler(item);
        break;
      case "Ubah":
        setMicrosite_form_id(item.microsite_form_id);
        setDataDetail(item);
        setBool((state) => ({ ...state, create: true }));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (show) {
      getData();
      getForm();
    }
  }, [show]);

  const btnViewHtml = (type) => {

    switch (type) {
      case 'open':
        setBool(state => ({
          ...state,
          viewHtml: true
        }))

        break;

      default:
        setBool(state => ({
          ...state,
          viewHtml: false
        }))
        break;
    }
  }

  return (
    <Modal_Component show={show} onHide={onHide} title="Detail Microsite">
      {loading ? (
        <Loadingfunc />
      ) : (
        <React.Fragment>
          {/* //info */}
          <div className="card rounded-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <strong>
                    Nama Microsite
                  </strong>
                </div>
                <div className="col-md-8">
                  {data?.microsite_name}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <strong>
                    Konten

                  </strong>
                </div>
                <div className="col-md-8" style={{ overflow: "auto" }}>

                  <ButtonPrimary onClick={() => btnViewHtml("open")}>
                    View
                  </ButtonPrimary>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <strong>
                    Total Diklik
                  </strong>
                </div>
                <div className="col-md-8">
                  {data.microsite_total_clicked}
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <strong>Total Diisi</strong>
                </div>
                <div className="col-md-8">
                  {data.microsite_total_submitted}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <strong>Status Persetujuan</strong>
                </div>
                <div className="col-md-8">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: displayStatus(data.microsite_approve_status_name),
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <strong>URL Microsite</strong>
                </div>
                <div className="col-md-8">
                  <a href={data.microsite_url} target="blank">
                    {data.microsite_url}
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <strong>Tanggal Dibuat</strong>
                </div>
                <div className="col-md-8">
                  {Moment(data.microsite_created_datetime)}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <strong>Dibuat Oleh</strong>
                </div>
                <div className="col-md-8">
                  {data?.microsite_created_by?.name}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <strong>Perusahaan</strong>
                </div>
                <div className="col-md-8">
                  {data?.company?.company_name}
                </div>
              </div>
            </div>
          </div>

          {/* FORM PERTANYAAN ======= */}
          <h3 style={{ color: COLOR_PRIMARY }}>Form Microsite</h3>
          <div className="mt-2 mb-3">
            <ButtonPrimary
              onClick={() => setBool((state) => ({ ...state, create: true }))}
            >
              Buat Pertanyaan
            </ButtonPrimary>
          </div>

          <div className="wrap-table card rounded-0">
            <div className="card-body">
              <TableData header={header}>
                {dataForm?.map((item, idx) => (
                  <tr key={idx}>
                    <td data-label="#">{idx + 1}</td>
                    <td data-label="Pertanyaan">{item.microsite_form_question}</td>
                    <td data-label="Wajib*">
                      {item.microsite_form_question_required_status === 1
                        ? "✔"
                        : ""}
                    </td>
                    <td data-label="Pilihan Jawaban">
                      {" "}
                      {JSON.parse(item.microsite_form_answer_selection)
                        ? JSON.parse(item.microsite_form_answer_selection).join(
                          ", "
                        )
                        : ""}
                    </td>
                    <td data-label="Format Jawaban">{item.microsite_form_answer_format}</td>
                    <td>
                      <DropdownMore
                        title={
                          <button
                            className="button is-primary is-small"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                          >
                            <span className="icon is-small">•••</span>
                          </button>
                        }
                        data_more={dataMore}
                        onClick={({ name, id }) => btnMore(name, id, item)}
                        id={item.microsite_form_id}
                      />
                    </td>
                  </tr>
                ))}
              </TableData>

            </div>
          </div>
        </React.Fragment>
      )}

      <ModalFormCreate
        show={bool.create}
        onHide={() => setBool((state) => ({ ...state, create: false }))}
        params={{
          microsite_id: id,
          microsite_form_id: mircosite_form_id,
          data: dataDetail,
        }}
        getForm={getForm}
        setDataDetail={setDataDetail}
        setMicrosite_form_id={setMicrosite_form_id}
      />
      <ModalViewHtml
        show={bool['viewHtml']}
        onHide={() => btnViewHtml("close")}
        content={
          <div
            dangerouslySetInnerHTML={{ __html: data?.microsite_content }}
          />
        }
      />
    </Modal_Component>
  );
};

export default ModalDetail;
