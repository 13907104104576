import styled from "styled-components";
import { Link } from "react-router-dom";

const ArticleItem = ({
  imgUrl = "https://images.unsplash.com/photo-1585241936939-be4099591252?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  title = "Lorem ipsum dolor sit amet consectetur ",
  subTitle = "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt",
  author = "Admin",
  published = "20/20/2023 12:00",
  slug = "",
  most_viewed = true,
}) => {
  return (
    <Container>
      {most_viewed ? (
        <div className="badge-most-viewed bg-secondary opacity-50 text-white p-2 rounded-1">
          Most Viewed
        </div>
      ) : null}
      <img src={imgUrl} alt={imgUrl} />
      <div className="container-text-article-section">
        <div className="text-author">Author : {author}</div>
        <div className="text-published mb-2">Published : {published}</div>
        <div className="title-article-section">{title}</div>
        <div className="sub-title-article-section">
          <div dangerouslySetInnerHTML={{ __html: subTitle }} />
        </div>
        <a href={`/home/articles/${slug}`}>Read more</a>
      </div>
    </Container>
  );
};

export default ArticleItem;

const Container = styled.div`
  width: 100%;
  min-height: 408.23px;
  overflow: hidden;
  border: 1px solid #dadada;
  position: relative;
  padding-bottom: 17px;

  .badge-most-viewed {
    position: absolute;
    top: 0;
    left: 0;
  }

  .container-text-article-section {
    margin-top: 25px;
    padding: 0 15px;

    .sub-title-article-section {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      text-transform: capitalize;

      color: #95908b;
      margin-top: 11px;

      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      display: -webkit-box;
    }

    .title-article-section {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;

      color: #5f5b57;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 243px;
  }

  @media (max-width: 1024px) {
    height: 300px;
  }
`;
