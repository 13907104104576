import * as Style from "../../../../component/style/content/default";
import { ButtonPrimary } from "../../../../component/style/content/default";
import * as Util from "../../../../util";
import { useState, useEffect, useContext, useRef } from "react";
import * as Services from "../../../../service";
import { Loadingfunc } from "../../../../component";
import Select from "react-select";
import { Spinner, Table } from "react-bootstrap";
import Datepicker from "react-datepicker";
import { Method } from "../../../../service";
import * as Components from "../../../../component";
import moment from "moment";
import { CSVLink } from "react-csv";

const ReportStockPage = () => {
  const { retail_permission } = useContext(Services.Context);
  const elCsvTfIn = useRef(null);
  const elCsvTfOut = useRef(null);
  const [options, setOptions] = useState({
    company: [],
    outlet: [],
  });
  const [selected, setSelected] = useState({
    company: null,
    outlet: null,
    start_date: null,
    end_date: null
  });
  const [loading, setLoading] = useState(false)
  const [tfIn, setTfIn] = useState(null)
  const [tfOut, setTfOut] = useState(null)
  const [csvTfIn, setCsvTfIn] = useState(null);
  const [csvTfOut, setCsvTfOut] = useState(null);

  const getListCompany = async () => {
    const result = await Method.get(`/company?limit=*`).then(
      (res) => res.data?.data
    );

    setOptions((state) => ({
      ...state,
      company: result?.map((item) => ({
        value: item.company_id,
        label: item.company_name,
        slug: item.company_slug
      })),
    }));
  };

  const getStore = async () => {
    await Method.get(
      `company/${selected.company?.value}/store?limit=*&store_active_status=1`
    )
    .then((res) => {
      if (res.data.success) {
        var arrayNew = res.data.data.map((item) => ({
          value: item.store_id,
          label: item.store_name
        }))
        setOptions(state => ({...state, outlet: arrayNew}));
      }
    })
    .catch((err) => {
    });
  };

  const btnHandleCompany = (e) => {
    try {
      setSelected((state) => ({ ...state, company: e }));
    } catch (error) {
      // alert(error);
    }
  };
  
  const loadData = async () => {
    setLoading(true);
    
    await Method.get(`company/${selected.company?.value}/stock/stock_summary`, {
      params: {
        store_id: selected.outlet?selected.outlet.value:null,
        filter_from_datetime: selected.start_date ? moment(selected.start_date).format('YYYY-MM-DD') + " 00:00:00" : null,
        filter_until_datetime: selected.end_date ? moment(selected.end_date).format('YYYY-MM-DD') + " 23:59:59" : null,
      }
    })
    .then(async (res) => {
      if (res.data.success) {
        setTfIn(res.data.data.transfer_in);
        setTfOut(res.data.data.transfer_out);
      } else {
        Components.AlertError({ title: "Error", text: res?.data?.error });
      }
    })
    .catch((err) => {
      Components.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const exportTfIn = () => {
    var csv_data = [['Product Name', 'Item SKU', 'Stock', 'HPP']];
    for (const iterator of tfIn) {
      const newData = [
        iterator.item_name,
        iterator.item_sku,
        iterator.quantity,
        iterator.hpp
      ]

      csv_data.push(newData.map(String));
    }
    setCsvTfIn(csv_data);
  }

  const exportTfOut = () => {
    var csv_data = [['Product Name', 'Item SKU', 'Stock', 'HPP']];
    for (const iterator of tfOut) {
      const newData = [
        iterator.item_name,
        iterator.item_sku,
        iterator.quantity,
        iterator.hpp
      ]

      csv_data.push(newData.map(String));
    }
    setCsvTfOut(csv_data);
  }

  useEffect(() => {
    getListCompany();
  }, []);

  useEffect(() => {
    if(selected.company){
      getStore();
    }
  }, [selected.company]);

  useEffect(() => {
    if (csvTfIn) {
      elCsvTfIn.current.link.click();
      Components.AlertSuccess({ title: "Success", text: "Data transfer in berhasil di download" });
      setCsvTfIn(null)
    }
  }, [csvTfIn])

  useEffect(() => {
    if (csvTfOut) {
      elCsvTfOut.current.link.click();
      Components.AlertSuccess({ title: "Success", text: "Data transfer out berhasil di download" });
      setCsvTfOut(null)
    }
  }, [csvTfOut])

  return (
    <div className="container">
      <div>
        <div><strong>Report</strong></div>
        <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
          Summary Request Stock
        </Style.HeaderPrimary>
      </div>

      {
        retail_permission.data?.type!=='staff' ? (
          <div>
            <div className="card rounded-0 mb-2">
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-lg-3 col-sm-12 p-1">
                      <label htmlFor="" className="form-label">
                        Select Company
                      </label>
                      <Select
                        isClearable
                        placeholder="select company"
                        options={options.company}
                        onChange={(e) => btnHandleCompany(e)}
                      />
                    </div>
                    <div className="col-md-6 col-lg-3 col-sm-12 p-1">
                      <label htmlFor="" className="form-label">
                        Store
                      </label>
                      <Select
                        isClearable
                        placeholder="Select Store"
                        value={selected.outlet}
                        options={options.outlet}
                        onChange={(e) => setSelected(state => ({...state, outlet: e}))}
                        isDisabled={!selected.company}
                      />
                    </div>
                    <div className="col-md-6 col-lg-3 col-sm-12 p-1">
                      <label className="form-label">Tanggal Mulai</label>
                      <Datepicker
                        placeholderText="Tanggal Mulai"
                        className="form-control"
                        selected={selected.start_date}
                        onChange={(e) =>
                          setSelected((state) => ({
                            ...state,
                            start_date: e,
                          }))
                        }
                        selectsStart
                        startDate={selected.start}
                        endDate={selected.end}
                        dateFormat="dd MMMM yyyy"
                        disabled={!selected.company}
                      />
                    </div>
                    <div className="col-md-6 col-lg-3 col-sm-12 p-1">
                      <label className="form-label">Tanggal Berakhir</label>
                      <Datepicker
                        placeholderText="Tanggal Berakhir"
                        className="form-control"
                        selected={selected.end_date}
                        onChange={(e) =>
                          setSelected((state) => ({ ...state, end_date: e }))
                        }
                        selectsEnd
                        startDate={selected.end_date}
                        endDate={selected.end_date}
                        minDate={selected.start_date}
                        dateFormat="dd MMMM yyyy"
                        disabled={!selected.company}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3 p-1 d-flex flex-row gap-2">
                      {loading ? (
                        <Loadingfunc />
                      ) : (
                        <ButtonPrimary onClick={loading ? null : loadData}>
                          {loading ? (
                            <>
                              <Spinner animation="border" variant="light" size="sm" />{" "}
                              &nbsp; Generating Report
                            </>
                          ) : (
                            "Generate Stock"
                          )}
                        </ButtonPrimary>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              tfIn &&
              <div className="card rounded-0 mt-4">
                <div className="card-body">
                  <div className="container">
                    <div className="d-flex flex-row justify-content-between align-items-center gap-2 mb-2">
                      <div><strong>Transfer In</strong></div>
                      <div>
                        <ButtonPrimary onClick={() => !loading ? exportTfIn() : false}>
                          <div>Download Transfer In</div>
                        </ButtonPrimary>
                        <CSVLink data={csvTfIn ? csvTfIn : []} filename={`report_transfer_in_${moment(selected.start_date).format("DD-MM-YYY")}_to_${moment(selected.end_date).format("DD-MM-YYY")}.csv`} ref={elCsvTfIn}></CSVLink>
                      </div>
                    </div>
                    <Components.TableData header={["Product Name", "Product SKU", "Stock", "HPP"]}>
                      {
                        tfIn.map((item, index) => {
                          return(
                            <tr
                              key={index}
                            >
                              <td data-label="Product Name">{item.item_name}</td>
                              <td data-label="Product SKU">{item.item_sku}</td>
                              <td data-label="Stock">{item.quantity}</td>
                              <td data-label="HPP">{Util.FormatCurrency.currency(item.hpp)}</td>
                            </tr>
                          )
                        })
                      }
                    </Components.TableData>
                  </div>
                </div>
              </div>
            }

{
              tfOut &&
              <div className="card rounded-0 mt-4">
                <div className="card-body">
                  <div className="container">
                    <div className="d-flex flex-row justify-content-between align-items-center gap-2 mb-2">
                      <div><strong>Transfer Out</strong></div>
                      <div>
                        <ButtonPrimary onClick={() => !loading ? exportTfOut() : false}>
                          <div>Download Transfer Out</div>
                        </ButtonPrimary>
                        <CSVLink data={csvTfOut ? csvTfOut : []} filename={`report_transfer_out_${moment(selected.start_date).format("DD-MM-YYY")}_to_${moment(selected.end_date).format("DD-MM-YYY")}.csv`} ref={elCsvTfOut}></CSVLink>
                      </div>
                    </div>
                    <Components.TableData header={["Product Name", "Product SKU", "Stock", "HPP"]}>
                      {
                        tfOut.map((item, index) => {
                          return(
                            <tr
                              key={index}
                            >
                              <td data-label="Product Name">{item.item_name}</td>
                              <td data-label="Product SKU">{item.item_sku}</td>
                              <td data-label="Stock">{item.quantity}</td>
                              <td data-label="HPP">{Util.FormatCurrency.currency(item.hpp)}</td>
                            </tr>
                          )
                        })
                      }
                    </Components.TableData>
                  </div>
                </div>
              </div>
            }
          </div>
        ):(
          <div class="alert alert-warning mt-lg-5" role="alert">
            <h5>Akses Ditolak</h5>
            Anda sebagai staff tidak mempunyai akses di perusahaan ini
          </div>
        )
      }
    </div>
  );
};

export default ReportStockPage;