import styled from "styled-components";
import ProductItem from "./product_item";

const ProductSection = ({ data }) => {
  return (
    <Container>
      <div className="title-product-section">
        Select The Products According to Your Needs
      </div>
      <div className="list-product-section justify-content-start ">
        {data?.map((item, idx) => (
          <ProductItem
            key={idx}
            title={item?.product_name}
            sub_title={item?.product_short_description}
            price={item?.discount_amount ? item?.discount_amount : item?.price}
            price_discount={item?.discount_amount ? item?.price : 0}
            product_name={item?.main_item?.item_name}
            include={item?.includes}
            image={item?.image}
            quantity={item.main_item?.quantity}
            discount_type={item.discount_type}
            product_id={item.product_id}
            item={item}
          />
        ))}
      </div>
    </Container>
  );
};

export default ProductSection;

const Container = styled.div`
  .list-product-section {
    background: #ffffff;
    border: 1px solid #dadada;
    /* Shadow 3 */

    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.12);
    margin-top: 40px;
    padding: 26px;
    height: 798px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 210px);
    overflow: auto;
    justify-content: center;
    gap: 12px;
  }
  .title-product-section {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    /* or 129% */
    text-align: center;
    letter-spacing: -0.021em;

    color: #161c2b;
  }
`;
