import React from "react";
import styled from "styled-components";
import Navbar from "landingPage/components/Navbar";
import BannerContact from "./banner";
import Info from "./info";
import Footer from "landingPage/components/footer";
import { Mobile_query_v2 } from "../../../util/Query"

const Contact = () => {
  return (
    <ContainerInti>
      <Navbar />
      <Container>
        <BannerContact />
      </Container>
      <ContainerMaps>
        <Info />
        {/* <Maps /> */}
      </ContainerMaps>
      <Footer />
    </ContainerInti>
  );
};

export default Contact;

const ContainerInti = styled.div`
  background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  );
  position: relative;
`;

const ContainerMaps = styled.div`
  position:relative;
  margin-bottom: -150px;
  /* position: absolute; */
`;

const Container = styled.div`
  /* min-height: 100vh; */
  padding: 0 93px;
  ${Mobile_query_v2} {
    padding: 0 25px;
  }

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;
