import React from "react";
import { Line } from "react-chartjs-2";

const ChartLine = ({
  labels = [],
  label = "default label",
  data_single = [],
  isMulti = false,
  datasets,
  color = "rgb(226,226,226)",
}) => {
  let data;

  if (isMulti) {
    data = {
      labels: labels,
      datasets: datasets,
    };
  } else {
    data = {
      labels: labels,
      datasets: [
        {
          label: label,
          data: data_single,
          fill: false,
          backgroundColor: color,
          borderColor: color,
        },
      ],
    };
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  return <Line data={data} options={options} />;
};

export default ChartLine;
