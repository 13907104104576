import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import * as Components from "../../../component";
import * as Style from "../../../component/style/content/default";
import * as Services from "../../../service";
import styled from "styled-components";
import * as Modal from "./modal";
import { Method } from "../../../service";
import DatePicker from "react-datepicker";
import CardRetailStatistic from '../../../component/RetailTheme/CardRetailStatistic'
import moment from 'moment'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CSVLink } from "react-csv";
import { Warning } from "@styled-icons/fluentui-system-filled/Warning";
import { Link } from "react-router-dom";
import api_permission from "service/api/permission";
import { Download } from "@styled-icons/entypo";
import { exportExcel } from "../../../util";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const itemsPerPage = 10;

export default function Statistic() {
  const { selected_company, dispatch, retail_permission, data_user } = useContext(Services.Context);
  const [modal, setModal] = useState({
    company: false
  });
  const [search, setSearch] = useState({
    from_date: null,
    end_date: null,
  });
  const [store, setStore] = useState([]);
  const [storeSelected, setStoreSelected] = useState([]);
  const [salesType, setSalesType] = useState([]);
  const [salesTypeSelected, setSalesTypeSelected] = useState({ value: '', label: 'Semua Sales Type' });
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState({ value: '', label: 'Semua Payment Method' });
  const [activeCard, setActiveCard] = useState(0)
  const [transaction, setTransaction] = useState(null);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [transactionItem, setTransactionItem] = useState(null);
  const [transactionItemLoading, setTransactionItemLoading] = useState(false);
  const [transactionCustomer, setTransactionCustomer] = useState(null);
  const [transactionCustomerLoading, setTransactionCustomerLoading] = useState(false);
  const [item, setItem] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingExport, setLoadingExport] = useState();
  const [exportDate, setExportDate] = useState({ start: '', end: '' })
  const [modalTransaksi, setModalTransaksi] = useState(false);
  const csvTransactionEl = useRef(null);
  const [csvDataTransaction, setCsvDataTransaction] = useState(null);
  const [modalStock, setModalStock] = useState(false);
  const csvStockEl = useRef(null);
  const [csvDataStock, setCsvDataStock] = useState(null);
  const [storeStock, setStoreStock] = useState('');

  useEffect(() => {
    if(selected_company.value){
      api_permission(dispatch, selected_company.value, data_user.id)
    }
  }, [selected_company])

  const GetCompany = async () => {
    await Method.get(`company`)
      .then(async (res) => {
        if (res?.data?.success) {
          if (!selected_company.value) {
            dispatch({
              type: "SELECTED_COMPANY",
              selected_company: {
                value: res?.data?.data[0]?.company_id,
                label: res?.data?.data[0]?.company_name,
              },
            });
          }
        } else {
          Components.AlertError({ title: "Error", text: res?.data?.error });
        }
      })
      .catch((err) => {
      });
  };

  const GetStore = async () => {
    await Method.get(`company/${selected_company.value}/store?limit=*`)
      .then(async (res) => {
        if (res.data.success) {
          var arrayNew = res.data.data.map((item) => ({
            value: item.store_id,
            label: item.store_name
          }))
          setStore(arrayNew)
        }
      })
      .catch((err) => {
      });
  };

  const GetProduct = async () => {
    await Method.get(`company/${selected_company.value}/item?limit=*`)
      .then(async (res) => {
        if (res.data.success) {
          var arrayNew = res.data.data.map((item) => ({
            value: item.item_id,
            label: item.item_name
          }))
          setItem(arrayNew)
        }
      })
      .catch((err) => {
      });
  };

  const GetPaymentMethod = async () => {
    await Method.get(`list/ms_merchant_payment`)
      .then(async (res) => {
        if (res.data.success) {
          var array = [{ value: '', label: 'Semua Payment Method' }]
          var arrayNew = array.concat(
            res.data.data.map((item) => ({
              value: item.ms_merchant_payment_id,
              label: item.ms_merchant_payment_name
            }))
          )
          setPaymentMethod(arrayNew)
        }
      })
      .catch((err) => {
      });
  };

  const GetSalesType = async () => {
    await Method.get(`list/ms_payment?eligible_for_topup_balance=true&order_by=ms_payment_id&sort_by=asc`)
      .then(async (res) => {
        if (res.data.success) {
          const salesTypeNew = res.data.data.filter(x => x.ms_payment_identifier === "MIDTRANS" || x.ms_payment_identifier === "MERCHANT_PAYMENT");
          var array = [{ value: '', label: 'Semua Sales Type' }]
          var arrayNew = array.concat(
            salesTypeNew.map((item) => ({
              value: item.ms_payment_id,
              label: item.ms_payment_name,
              ms_payment_identifier: item.ms_payment_identifier
            }))
          )
          setSalesType(arrayNew)
        }
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    GetCompany();
    GetSalesType();
    GetPaymentMethod();
  }, []);

  useEffect(() => {
    setSearch((state) => ({
      ...state,
      from_date: null
    }))
    setSearch((state) => ({
      ...state,
      end_date: null
    }))
    setStoreSelected([]);
    setItemSelected([]);
    setSalesTypeSelected({ value: '', label: 'Semua Sales Type' })
    setPaymentMethodSelected({ value: '', label: 'Semua Payment Method' });
    setTransaction(null)
    setTransactionItem(null)
    setTransactionCustomer(null)
    setActiveCard(0);

    GetStore();
    GetProduct();
  }, [selected_company])

  const loadTransaction = async () => {
    setTransactionLoading(true);

    const data_post = {
      filter_from_datetime: search.from_date ? moment(search.from_date).format('YYYY-MM-DD') + " 00:00:00" : null,
      filter_until_datetime: search.end_date ? moment(search.end_date).format('YYYY-MM-DD') + " 23:59:59" : null,
      transaction_approve_status: [3],
      store_id: storeSelected.length > 0 ? storeSelected.map(x => (x.value)) : store.map(x => (x.value)),
      seller_company_id: [selected_company.value],
      ms_payment_id: !salesTypeSelected ? [] : salesTypeSelected.value === '' ? [] : [salesTypeSelected.value],
      ms_merchant_payment_id: !paymentMethodSelected ? [] : paymentMethodSelected.value === '' ? [] : [paymentMethodSelected.value],
      item_id: []
    }

    await Method.post(`report/transaction`, data_post)
      .then(async (res) => {
        if (res.data.success) {
          setTransaction(res.data.data);
        } else {
          Components.AlertError({ title: "Error", text: res?.data?.error });
        }
        setTransactionLoading(false);
      })
      .catch((err) => {
        setTransactionLoading(false);
      });
  }

  const loadTransactionItem = async () => {
    setTransactionItemLoading(true);

    const data_post = {
      filter_from_datetime: search.from_date ? moment(search.from_date).format('YYYY-MM-DD') + " 00:00:00" : null,
      filter_until_datetime: search.end_date ? moment(search.end_date).format('YYYY-MM-DD') + " 23:59:59" : null,
      transaction_approve_status: [3],
      store_id: storeSelected.length > 0 ? storeSelected.map(x => (x.value)) : store.map(x => (x.value)),
      seller_company_id: [selected_company.value],
      ms_payment_id: !salesTypeSelected ? [] : salesTypeSelected.value === '' ? [] : [salesTypeSelected.value],
      ms_merchant_payment_id: !paymentMethodSelected ? [] : paymentMethodSelected.value === '' ? [] : [paymentMethodSelected.value],
      item_id: itemSelected.map(x => (x.value))
    }

    await Method.post(`report/item`, data_post)
      .then(async (res) => {
        if (res.data.success) {
          setTransactionItem(res.data.data);
        } else {
          Components.AlertError({ title: "Error", text: res?.data?.error });
        }
        setTransactionItemLoading(false);
      })
      .catch((err) => {
        setTransactionItemLoading(false);
      });

  }

  const loadTransactionCustomer = async () => {
    setTransactionCustomerLoading(true);

    const data_post = {
      filter_from_datetime: search.from_date ? moment(search.from_date).format('YYYY-MM-DD') + " 00:00:00" : null,
      filter_until_datetime: search.end_date ? moment(search.end_date).format('YYYY-MM-DD') + " 23:59:59" : null,
      transaction_approve_status: [3],
      store_id: storeSelected.length > 0 ? storeSelected.map(x => (x.value)) : store.map(x => (x.value)),
      seller_company_id: [selected_company.value],
      ms_payment_id: !salesTypeSelected ? [] : salesTypeSelected.value === '' ? [] : [salesTypeSelected.value],
      ms_merchant_payment_id: !paymentMethodSelected ? [] : paymentMethodSelected.value === '' ? [] : [paymentMethodSelected.value],
      customer_name: '',
      customer_city: '',
      item_id: []
    }

    await Method.post(`report/customer`, data_post)
      .then(async (res) => {
        if (res.data.success) {
          setTransactionCustomer(res.data.data);
        } else {
          Components.AlertError({ title: "Error", text: res?.data?.error });
        }
        setTransactionCustomerLoading(false);
      })
      .catch((err) => {
        setTransactionCustomerLoading(false);
      });

  }

  const generateData = () => {
    loadTransaction();
    loadTransactionCustomer();
    loadTransactionItem();
    setActiveCard(0);
  }

  const exportTransaction = () => {
    if (exportDate.start === "") {
      Components.AlertError({ title: "Error", text: "Tanggal Mulai harus diisi!" });
      return false;
    }

    if (exportDate.end === "") {
      Components.AlertError({ title: "Error", text: "Tanggal Berakhir harus diisi!" });
      return false;
    }

    setLoadingExport(true)

    var url = 'transaction/report?transaction_from_datetime=' + (moment(exportDate.start).format('YYYY-MM-DD')) + ' 00:00:00';
    if (exportDate.end !== '') {
      url += '&transaction_until_datetime=' + (moment(exportDate.end).format('YYYY-MM-DD')) + ' 23:59:59';
    }
    url+= '&seller_company_id='+selected_company.value
    url+= '&transaction_approve_status=3'

    Method.get(url)
      .then(async (res) => {
        if (res.data.success) {
          var csv_data = [['Outlet', 'Receipt Number', 'Date', 'Time', 'Category', 'Item', 'Variant', 'SKU', 'Modifier Applied', 'Discount Applied', 'Base Price', 'Quantity', 'Gross Sales', 'Discounts', 'Refunds', 'Net Sales', 'Gratuity', 'Tax', 'Sales Type', 'Collected By', 'Served By', 'Cashier', 'Customer', 'Customer Phone', 'Payment Method', 'Receipt Number', 'Event Type', 'Adjustment', 'Admin Fee', 'Net Sales 2', 'Status', 'Voucher Loyalty']];
          const data = res.data.data;

          for (var i = 0; i < data.length; i++) {
            var itemList = []
            const transaction_data = JSON.parse(data[i].transaction_data);
            if(transaction_data.original_order){
              itemList = transaction_data.original_order.map( x => ({ transaction_detail_payload: x }))
            }else{
              itemList = data[i].transaction_detail
            }

            if(itemList.length>0){
              for(var j=0;j<itemList.length;j++){
                const  transaction_detail = itemList[j].transaction_detail_payload
                var sales_type = data[i].ms_payment.ms_payment_name;
                if (data[i].ms_payment.ms_payment_name === 'Merchant') {
                  if(data[i].ms_merchant_payment){
                    if(data[i].ms_merchant_payment?.ms_merchant_payment_type==='ONLINE'){
                      if (data[i].ms_merchant_payment?.ms_merchant_payment_name === 'Tokopedia' || data[i].ms_merchant_payment?.ms_merchant_payment_name === 'Blibli' || data[i].ms_merchant_payment?.ms_merchant_payment_name === 'Shopee') {
                        sales_type = 'E-commerce';
                      } else if (data[i].ms_merchant_payment?.ms_merchant_payment_name === 'BulkSource Bintaro' || data[i].ms_merchant_payment?.ms_merchant_payment_name === 'BulkSource Kemang' || data[i].ms_merchant_payment?.ms_merchant_payment_name === 'JFM Gajah Mada' || data[i].ms_merchant_payment?.ms_merchant_payment_name === 'JFM Pluit' || data[i].ms_merchant_payment?.ms_merchant_payment_name === 'JFM Raden Saleh') {
                        sales_type = 'Modern Trade';
                      }else{
                        sales_type = 'Online';
                      }
                    } else {
                      sales_type = 'Walk In';
                    }
                  }else{
                    sales_type = 'Walk In';
                  }
                }

                //loyalty
                let loyalty = null
                if(data[i].member_voucher_data){
                  loyalty = JSON.parse(data[i].member_voucher_data)
                }

                const newData = [
                  data[i].store?data[i].store.store_name.replace(/,/g,'-'):'',
                  data[i].transaction_id,
                  moment(data[i].transaction_created_datetime).format('DD-MM-YYYY'),
                  moment(data[i].transaction_created_datetime).format('HH:mm:ss'),
                  transaction_detail.category_display_name.replace(/,/g,'-'),
                  transaction_detail.item_name.replace(/,/g,'-'),
                  '',
                  transaction_detail.item_sku,
                  '',
                  data[i].voucher?data[i].voucher.voucher_name.replace(/,/g,'-'):'',
                  transaction_detail.current_price?transaction_detail.current_price:0,
                  transaction_detail.quantity,
                  transaction_detail.current_price * transaction_detail.quantity,
                  j===0?(data[i].transaction_discount>0?data[i].transaction_discount:''):'',
                  '',
                  transaction_detail.current_price * transaction_detail.quantity - (j===0?data[i].transaction_discount:0),
                  '',
                  '',
                  sales_type.replace(/,/g,'-'),
                  '',
                  data[i].transaction_created_by?data[i].transaction_created_by.name.replace(/,/g,'-'):'',
                  data[i].cashier?data[i].cashier.cashier_name.replace(/,/g,'-'):'',
                  data[i].customer_name?data[i].customer_name.replace(/,/g,'-'):'',
                  data[i].customer_msisdn?data[i].customer_msisdn:'',
                  data[i].ms_merchant_payment?data[i].ms_merchant_payment.ms_merchant_payment_name.replace(/,/g,'-'):'',
                  data[i].transaction_receipt_number?data[i].transaction_receipt_number:'',
                  '',
                  '',
                  j===0?(data[i].transaction_administration_fee>0?(data[i].transaction_administration_fee):''):'',
                  transaction_detail.current_price * transaction_detail.quantity - (j===0?data[i].transaction_discount:0) - (j===0?(data[i].transaction_administration_fee>0?(data[i].transaction_administration_fee):0):0),
                  data[i].transaction_approve_status_name.replace(/,/g,'-'),
                  loyalty?loyalty?.voucher?.name:''
                ]
                csv_data.push(newData.map(String));
              }
            }
          }
          if (csv_data.length > 1) {
            setCsvDataTransaction(csv_data);
          }
        } else {
          Components.AlertError({ title: "Error", text: res?.data?.error });
        }
        setLoadingExport(false);
      })
      .catch((err) => {
        Components.AlertError({ title: "Error", text: 'Terjadi kesalahan' });
        setLoadingExport(false);
      });
  }

  useEffect(() => {
    if (csvDataTransaction) {
      csvTransactionEl.current.link.click();
      Components.AlertSuccess({ title: "Success", text: "Data berhasil di download" });
    }
  }, [csvDataTransaction])
  
  const exportStock = () => {
    if (exportDate.start === "") {
      Components.AlertError({ title: "Error", text: "Tanggal Mulai harus diisi!" });
      return false;
    }

    if (exportDate.end === "") {
      Components.AlertError({ title: "Error", text: "Tanggal Berakhir harus diisi!" });
      return false;
    }

    setLoadingExport(true);

    var url = 'company/' + selected_company.value + '/stock/report?filter_from_datetime=' + (moment(exportDate.start).format('YYYY-MM-DD')) + ' 00:00:00';
    if (exportDate.end !== '') {
      url += '&filter_until_datetime=' + (moment(exportDate.end).format('YYYY-MM-DD')) + ' 23:59:59';
    }
    if (storeStock !== '') {
      url += '&store_id=' + storeStock.value;
    }

    Method.get(url)
      .then(response => {
        if (response.data.success) {
          const data = response.data.data;
          var csv_data = [['Item SKU', 'Item Name', 'From', 'To', 'Date', 'Time', 'Stock In', 'Stock Out', 'Remaining Stock', 'Transaction History', 'Created By', 'Approved Date', 'Approved Time', 'Approved By', 'Catatan']];
          for (var i = 0; i < data.length; i++) {
            const from = data[i].ms_reference_name === 'TRANSACTION_CREATE' || data[i].ms_reference_name === 'TRANSACTION_REFUND' ? data[i].store_name : data[i]?.stock_info?.from_store?.store_name;
            const to = data[i]?.stock_info?.to_store?.store_name?.replace(/,/g, '-');
            const approved = data[i]?.stock_info?.stock_updated_by?.name?.replace(/,/g, '-');
            const note = data[i]?.stock_info?.stock_note?.replace(/,/g, '-');
            const newData = [
              data[i].item_sku,
              data[i].item_name.replace(/,/g, '-'),
              from ? from : '',
              to ? to : '',
              moment(data[i].menu_history_datetime).format('DD/MM/YYYY'),
              moment(data[i].menu_history_datetime).format('HH:mm'),
              data[i].menu_history_credit ? data[i].menu_history_credit : '',
              data[i].menu_history_debit ? data[i].menu_history_debit : '',
              data[i].menu_history_balance ? data[i].menu_history_balance : '',
              data[i].ms_reference_name,
              data[i].ms_reference_name === "TRANSACTION_CREATE" || data[i].ms_reference_name === "TRANSACTION_REFUND" ? '' : data[i]?.stock_info?.stock_created_by?.name,
              data[i].ms_reference_name === "TRANSACTION_CREATE" || data[i].ms_reference_name === "TRANSACTION_REFUND" ? '' : moment(data[i].stock_info.stock_updated_datetime).format('DD/MM/YYYY'),
              data[i].ms_reference_name === "TRANSACTION_CREATE" || data[i].ms_reference_name === "TRANSACTION_REFUND" ? '' : moment(data[i].stock_info.stock_updated_datetime).format('HH:mm'),
              approved ? approved : '',
              note ? note : ''
            ]
            csv_data.push(newData.map(String));
          }
          if (csv_data.length > 1) {
            setCsvDataStock(csv_data);
          }
        } else {
          Components.AlertError({ title: "Error", text: response?.data?.error });
        }
        setLoadingExport(false);
      })
      .catch(error => {
        Components.AlertError({ title: "Error", text: 'Terjadi kesalahan' });
        setLoadingExport(false);
      });
  }

  useEffect(() => {
    if (csvDataStock) {
      csvStockEl.current.link.click();
      Components.AlertSuccess({ title: "Success", text: "Data berhasil di download" });
    }
  }, [csvDataStock])

  useEffect(()=>{
    setPaymentMethodSelected({ value: '', label: 'Semua Payment Method' });
  }, [salesTypeSelected])

  return (
    <Style.ContainerContent>
      {/* MODAL =========== */}
      <Modal.ModalSelectCompany
        show={modal.company}
        onHide={() => setModal((state) => ({ ...state, company: false }))}
      />

      <Components.Modal_Component
        size="lg"
        title={"Export Transaksi"}
        onHide={() => {
          setModalTransaksi(false)
          setCsvDataTransaction(null)
          setLoadingExport(false)
          setExportDate({ start: '', end: '' })
        }}
        show={modalTransaksi}
      >
        <div className="row">
          <div className="col-md-4 mb-2">
            <Style.Label color={Style.COLOR_SECONDARY}>Tanggal Mulai</Style.Label>
            <DatePicker
              className="form-control"
              name="from_date"
              selected={exportDate.start}
              onChange={(e) =>
                setExportDate((state) => ({
                  ...state,
                  start: e,
                }))
              }
              selectsStart
              startDate={exportDate.start}
              endDate={exportDate.end}
              dateFormat="dd MMMM yyyy"
              disabled={loadingExport}
            />
          </div>
          <div className="col-md-4 mb-2">
            <Style.Label color={Style.COLOR_SECONDARY}>
              Tanggal Berakhir
            </Style.Label>
            <DatePicker
              className="form-control"
              name="end_date"
              selected={exportDate.end}
              onChange={(e) =>
                setExportDate((state) => ({ ...state, end: e }))
              }
              selectsEnd
              startDate={exportDate.end}
              endDate={exportDate.end}
              minDate={exportDate.start}
              dateFormat="dd MMMM yyyy"
              disabled={loadingExport}
            />
          </div>
          <div className="col-md-4 mb-2 pt-4">
            <Style.ButtonPrimary onClick={() => !loadingExport ? exportTransaction() : false}>
              {loadingExport ? 'Loading...' : 'Export'}
            </Style.ButtonPrimary>
          </div>
        </div>
        <CSVLink data={csvDataTransaction ? csvDataTransaction : []} filename={`report_transaction_${moment().toDate().getTime()}.csv`} ref={csvTransactionEl}></CSVLink>
      </Components.Modal_Component>

      <Components.Modal_Component
        size="lg"
        title={"Export Stock"}
        onHide={() => {
          setModalStock(false)
          setCsvDataStock(null)
          setLoadingExport(false)
          setExportDate({ start: '', end: '' })
          setStoreStock('')
        }}
        show={modalStock}
      >
        <div className="row">
          <div className="col-md-3 mb-2">
            <Style.Label color={Style.COLOR_SECONDARY}>Tanggal Mulai</Style.Label>
            <DatePicker
              className="form-control"
              name="from_date"
              selected={exportDate.start}
              onChange={(e) =>
                setExportDate((state) => ({
                  ...state,
                  start: e,
                }))
              }
              selectsStart
              startDate={exportDate.start}
              endDate={exportDate.end}
              dateFormat="dd MMMM yyyy"
              disabled={loadingExport}
            />
          </div>
          <div className="col-md-3 mb-2">
            <Style.Label color={Style.COLOR_SECONDARY}>
              Tanggal Berakhir
            </Style.Label>
            <DatePicker
              className="form-control"
              name="end_date"
              selected={exportDate.end}
              onChange={(e) =>
                setExportDate((state) => ({ ...state, end: e }))
              }
              selectsEnd
              startDate={exportDate.end}
              endDate={exportDate.end}
              minDate={exportDate.start}
              dateFormat="dd MMMM yyyy"
              disabled={loadingExport}
            />
          </div>
          <div className="col-md-4 mb-2">
            <Style.Label color={Style.COLOR_SECONDARY}>Toko</Style.Label>
            <Select
              placeholder="Semua Toko"
              value={storeStock}
              options={store}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setStoreStock(e)}
              disabled={loadingExport}
            />
          </div>
          <div className="col-md-2 mb-2 pt-4">
            <Style.ButtonPrimary onClick={() => !loadingExport ? exportStock() : false}>
              {loadingExport ? 'Loading...' : 'Export'}
            </Style.ButtonPrimary>
          </div>
        </div>
        <CSVLink data={csvDataStock ? csvDataStock : []} filename={`report_stock_${moment().toDate().getTime()}.csv`} ref={csvStockEl}></CSVLink>
      </Components.Modal_Component>

      <div className="d-flex flex-row">
        <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
          Statistic Report
        </Style.HeaderPrimary>
        <div className="flex-grow-1 ms-2">
          <button
            className="btn border"
            onClick={() => setModal((state) => ({ ...state, company: true }))}
          >
            {selected_company.label ? selected_company.label : 'Select Company'} <i className="demo-icon icon-chevron-down"></i>
          </button>
        </div>

        {
          store.length > 0 && retail_permission.data?.type!=='staff' &&
          <div className="d-flex">
            <Style.ButtonPrimary onClick={() => setModalTransaksi(true)}>
              Export Transaksi
            </Style.ButtonPrimary>
            <div className="ms-2" />
            <Style.ButtonPrimary onClick={() => setModalStock(true)}>
              Export Stock
            </Style.ButtonPrimary>
          </div>
        }
      </div>

      {
        retail_permission.data?.type!=='staff' ? (
          <div>
            {
              store.length === 0 ? (
                <div className="mt-lg-5">
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="row">
                      {
                        store.length === 0 &&
                        <div className="col-md-12 mb-2">
                          <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <Warning width={20} className={'me-2'} />
                            <div>
                              <b>Toko Kosong.</b> Silahkan tambah toko di menu Toko atau klik <Link to="/dashboard/retail/toko"><b>Tambah Toko</b></Link>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {
                    selected_company &&
                    <div>
                      <div className="mt-lg-5">
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="row">
                            <div className="col-md-2 mb-2">
                              <Style.Label color={Style.COLOR_SECONDARY}>Tanggal Mulai</Style.Label>
                              <DatePicker
                                className="form-control"
                                name="from_date"
                                selected={search.from_date}
                                onChange={(e) =>
                                  setSearch((state) => ({
                                    ...state,
                                    from_date: e,
                                  }))
                                }
                                selectsStart
                                startDate={search.from_date}
                                endDate={search.end_date}
                                dateFormat="dd MMMM yyyy"
                              />
                            </div>
                            <div className="col-md-2 mb-2">
                              <Style.Label color={Style.COLOR_SECONDARY}>
                                Tanggal Berakhir
                              </Style.Label>
                              <DatePicker
                                className="form-control"
                                name="end_date"
                                selected={search.end_date}
                                onChange={(e) =>
                                  setSearch((state) => ({ ...state, end_date: e }))
                                }
                                selectsEnd
                                startDate={search.end_date}
                                endDate={search.end_date}
                                minDate={search.from_date}
                                dateFormat="dd MMMM yyyy"
                              />
                            </div>
                            <div className="col-md-4 mb-2">
                              <Style.Label color={Style.COLOR_SECONDARY}>Toko</Style.Label>
                              <Select
                                placeholder="Semua Toko"
                                value={storeSelected}
                                options={store}
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => setStoreSelected(e)}
                              />
                            </div>
                            <div className="col-md-3 mb-2">
                              <Style.Label color={Style.COLOR_SECONDARY}>Sales Type</Style.Label>
                              <Select
                                placeholder="Semua Sales Type"
                                value={salesTypeSelected}
                                options={salesType}
                                onChange={(e) => setSalesTypeSelected(e)}
                              />
                            </div>
                            {
                              salesTypeSelected.ms_payment_identifier === "MERCHANT_PAYMENT" &&
                              <div className="col-md-3 mb-2">
                                <Style.Label color={Style.COLOR_SECONDARY}>Payment Method</Style.Label>
                                <Select
                                  placeholder="Pilih Payment Method"
                                  value={paymentMethodSelected}
                                  options={paymentMethod}
                                  onChange={(e) => setPaymentMethodSelected(e)}
                                />
                              </div>
                            }
                            <div className="col-md-3 mb-2 pt-4">
                              <Style.ButtonPrimary onClick={() => generateData()}>
                                Generate Statistic
                              </Style.ButtonPrimary>
                            </div>
                          </div>
                        </div>
                      </div>

                      {
                        !transactionLoading && !transactionCustomerLoading ?
                          <div>
                            {transaction && transactionCustomer && renderContent()}
                          </div>
                          : renderLoading()
                      }

                      {
                        transactionItem && !transactionItemLoading &&
                        <div className="mt-lg-5">
                          <hr />
                          <div className="row">
                            <div className="col-md-4 mb-2">
                              <Style.Label color={Style.COLOR_SECONDARY}>Produk</Style.Label>
                              <Select
                                value={itemSelected}
                                isMulti
                                options={item}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Semua Produk"
                                onChange={(e) => setItemSelected(e)}
                              />
                            </div>
                            <div className="col-md-3 mb-2 pt-4">
                              <Style.ButtonPrimary onClick={() => loadTransactionItem()}>
                                Filter
                              </Style.ButtonPrimary>
                            </div>
                          </div>

                          {
                            !transactionItemLoading && renderContentItem()
                          }
                        </div>
                      }

                    </div>
                  }
                </div>
              )
            }
          </div>
        ):(
          <div class="alert alert-warning mt-lg-5" role="alert">
            <h5>Akses Ditolak</h5>
            Anda sebagai staff tidak mempunyai akses di perusahaan ini
          </div>
        )
      }

    </Style.ContainerContent>
  );

  function renderLoading() {
    return (
      <div style={{ position: 'fixed', top: 0, bottom: 0, right: 0, left: 0, backgroundColor: '#00000069' }} className="d-flex flex-column justify-content-center align-items-center">
        <div className="spinner-border text-white" style={{ width: 100, height: 100 }} role="status">
        </div>
        <div className="text-white mt-4">Memuat Data</div>
      </div>
    )
  }

  function renderContentItem() {
    return (
      <div className="mt-lg-5">
        <div className="row">
          <div className="col-lg-12 mb-2">
            <div style={{ width: "100%" }}>
              <CardRetailStatistic
                title="Produk"
                active={true}
                hideButton
              >
                <div className="p-3">
                  <div className="row justify-content-md-center">
                    <div className="col-sm-6 col-md-2">
                      <div className="text-center">Produksi</div>
                      <h5 className="text-center">{calculateStockIn()}</h5>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="text-center">Transaksi Diproses</div>
                      <h5 className="text-center">{calculateTerjual()}</h5>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="text-center">Stok Masuk</div>
                      <h5 className="text-center">{calculateTransferIn()}</h5>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="text-center">Expired</div>
                      <h5 className="text-center">{calculateExpired()}</h5>
                    </div>
                  </div>
                </div>
              </CardRetailStatistic>
            </div>
          </div>
          <div className="col-lg-12">
            {renderTransactionItemContent()}
          </div>
        </div>
      </div>
    )
  }

  function renderContent() {
    return (
      <div className="mt-lg-5">
        <div className="row">
          <div className="col-md-7 mb-2">
            <div style={{ width: "100%" }} onClick={() => setActiveCard(0)}>
              <CardRetailStatistic
                title="Transaksi"
                active={activeCard === 0}
              >
                <div className="p-3">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="text-center">Transaksi</div>
                      <h5 className="text-center">{separate(transaction.main_sales.transaction_quantity)}</h5>
                    </div>
                    <div className="col-sm-4">
                      <div className="text-center">Item Terjual(QTY)</div>
                      <h5 className="text-center">{separate(transaction.main_sales.item_quantity)}</h5>
                    </div>
                    <div className="col-sm-4">
                      <div className="text-center">Total Hari</div>
                      <h5 className="text-center">{separate(transaction.main_sales.total_days)}</h5>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-sm-6">
                      <div className="text-center">Transaksi Rata-rata per hari</div>
                      <h5 className="text-center">{transaction.main_sales.average_transaction_quantity_each_day}</h5>
                    </div>
                    <div className="col-sm-6">
                      <div className="text-center">Item Terjual Rata-rata per hari</div>
                      <h5 className="text-center">{transaction.main_sales.average_item_quantity_each_day}</h5>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-sm-3">
                      <div className="text-center">Gross (IDR)</div>
                      <h5 className="text-center">{separate(transaction.main_sales.transaction_amount)}</h5>
                    </div>
                    <div className="col-sm-3">
                      <div className="text-center">Discount (IDR)</div>
                      <h5 className="text-center">{separate(transaction.main_sales.transaction_discount)}</h5>
                    </div>
                    <div className="col-sm-3">
                      <div className="text-center">Admin Fee (IDR)</div>
                      <h5 className="text-center">{separate(transaction.main_sales.transaction_administration_fee)}</h5>
                    </div>
                    <div className="col-sm-3">
                      <div className="text-center">NET SALES (IDR)</div>
                      <h5 className="text-center">{separate(transaction.main_sales.transaction_amount - transaction.main_sales.transaction_discount - transaction.main_sales.transaction_administration_fee)}</h5>
                    </div>
                  </div>
                </div>
              </CardRetailStatistic>
            </div>
          </div>
          <div className="col-md-5 mb-2">
            <div style={{ width: "100%" }} onClick={() => setActiveCard(2)}>
              <CardRetailStatistic
                title="Pelanggan"
                active={activeCard === 2}
              >
                <div className="p-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="text-center">Customer (Unique)</div>
                      <h5 className="text-center">{separate(transactionCustomer.main_sales.unique_customer)}</h5>
                    </div>
                  </div>
                  {/* <div className="row pt-2">
                    <div className="col-sm-12">
                      <div className="text-center">Item (Unique)</div>
                      <h5 className="text-center">{separate(transactionCustomer.main_sales.unique_item_quantity)}</h5>
                    </div>
                  </div> */}
                </div>
              </CardRetailStatistic>
            </div>
          </div>
        </div>

        {activeCard === 0 ? renderTransactionContent() : renderTransactionCustomerContent()}
      </div>
    )
  }

  function calculateStockIn() {
    var total = 0;
    transactionItem.item_report.map((x) => {
      if (x.ms_reference_name === "DIRECT_STOCK") {
        total += x.menu_history_credit
      }
    })

    return total;
  }

  function calculateTerjual() {
    var debit = 0;
    transactionItem.item_report.map((x) => {
      if (x.ms_reference_name === "TRANSACTION_CREATE") {
        debit += x.menu_history_debit
      }
    })

    var credit = 0;
    transactionItem.item_report.map((x) => {
      if (x.ms_reference_name === "TRANSACTION_REFUND") {
        credit += x.menu_history_credit
      }
    })

    return debit - credit;
  }

  function calculateTransferIn() {
    var total = 0;
    transactionItem.item_report.map((x) => {
      if (x.ms_reference_name === "TRANSFER_STOCK") {
        total += x.menu_history_credit
      }
    })

    return total;
  }

  function calculateExpired() {
    var total = 0;
    transactionItem.item_report.map((x) => {
      if (x.ms_reference_name === "DIRECT_STOCK") {
        total += x.menu_history_debit
      }
    })

    return total;
  }

  function separate(number) {
    return number === '' || number === null || number === undefined ? 0 : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  function exportTransaksiProduksi() {
    exportExcel({
      filename: `transaksi vs produk ${moment(search.from_date).format('DD-MM-YYYY')} - ${moment(search.end_date).format('DD-MM-YYYY')}`,
      excelData: transaction.daily_sales.map(x => ({ tanggal: x.interval_datetime, transaksi: x.transaction_quantity, 'produk(qty)': x.item_quantity }))
    })
  }

  function exportNilaiTransaksi() {
    exportExcel({
      filename: `nilai transaksi ${moment(search.from_date).format('DD-MM-YYYY')} - ${moment(search.end_date).format('DD-MM-YYYY')}`,
      excelData: transaction.daily_sales.map(x => ({ tanggal: x.interval_datetime, nilai: x.transaction_amount - x.transaction_discount - x.transaction_administration_fee}))
    })
  }

  function renderTransactionContent() {
    return (
      <div className="mt-5">
        <h3 className="text-center">Statistic Transaksi</h3>
        <div className="row mt-3">
          <div className="col-md-6 mb-2">
            <Containter className="container">
              <ExportBtn onClick={()=>exportTransaksiProduksi()}>
                <Download width={20} height={20} color="gray" />
              </ExportBtn>
              <Bar
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Transaksi VS Produk',
                    },
                  },
                }}
                data={
                  {
                    labels: transaction.daily_sales.map(x => x.interval_datetime),
                    datasets: [
                      {
                        label: 'Transaksi',
                        data: transaction.daily_sales.map(x => x.transaction_quantity),
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      },
                      {
                        label: 'Produk',
                        data: transaction.daily_sales.map(x => x.item_quantity),
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                      },
                    ],
                  }
                }
              />
            </Containter>
          </div>
          <div className="col-md-6 mb-2">
            <Containter className="container">
              <ExportBtn onClick={()=>exportNilaiTransaksi()}>
                <Download width={20} height={20} color="gray" />
              </ExportBtn>
              <Bar
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Nilai Transaksi',
                    },
                  },
                }}
                data={
                  {
                    labels: transaction.daily_sales.map(x => x.interval_datetime),
                    datasets: [
                      {
                        label: 'Nilai',
                        data: transaction.daily_sales.map(x => x.transaction_amount - x.transaction_discount - x.transaction_administration_fee),
                        backgroundColor: '#78ff78',
                      },
                    ],
                  }
                }
              />
            </Containter>
          </div>
        </div>
      </div>
    )
  }

  function exportProduksi() {
    exportExcel({
      filename: `produksi ${moment(search.from_date).format('DD-MM-YYYY')} - ${moment(search.end_date).format('DD-MM-YYYY')}`,
      excelData: produkMergedByItem('produksi').map(x => ({ name: x.item_name, total: x.data.reduce((a, b) => a + b.menu_history_credit, 0) }))
    })
  }

  function exportTransaksi() {
    exportExcel({
      filename: `Terjual ${moment(search.from_date).format('DD-MM-YYYY')} - ${moment(search.end_date).format('DD-MM-YYYY')}`,
      excelData: produkMergedByItem('terjual').map(x => ({ name: x.item_name, total: x.data.reduce((a, b) => a + b.menu_history_debit, 0) }))
    })
  }

  function exportStokMasuk() {
    exportExcel({
      filename: `Stok Masuk ${moment(search.from_date).format('DD-MM-YYYY')} - ${moment(search.end_date).format('DD-MM-YYYY')}`,
      excelData: produkMergedByItem('stock').map(x => ({ name: x.item_name, total: x.data.reduce((a, b) => a + b.menu_history_credit, 0) }))
    })
  }

  function exportExpired() {
    exportExcel({
      filename: `Expired ${moment(search.from_date).format('DD-MM-YYYY')} - ${moment(search.end_date).format('DD-MM-YYYY')}`,
      excelData: produkMergedByItem('expired').map(x => ({ name: x.item_name, total: x.data.reduce((a, b) => a + b.menu_history_debit, 0) }))
    })
  }

  function renderTransactionItemContent() {
    return (
      <div className="row">
        <div className="col-md-12 mb-4">
          <Containter className="container">
            <ExportBtn onClick={()=>exportProduksi()}>
              <Download width={20} height={20} color="gray" />
            </ExportBtn>
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: 'Produksi',
                  },
                },
              }}
              data={
                {
                  labels: produkMergedByItem('produksi').reduce((a, c) => a.concat(c.item_name), []),
                  datasets: [
                    {
                      label: 'Total',
                      data: produkMergedByItem('produksi').reduce((a, c) => a.concat(c.data.reduce((x, y) => x + y.menu_history_credit, 0)), []),
                      backgroundColor: '#f4b183',
                    },
                  ],
                }
              }
            />
          </Containter>
        </div>
        <div className="col-md-12 mb-4">
          <Containter className="container">
            <ExportBtn onClick={()=>exportTransaksi()}>
              <Download width={20} height={20} color="gray" />
            </ExportBtn>
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: 'Transaksi Diproses',
                  },
                },
              }}
              data={
                {
                  labels: produkMergedByItem('terjual').reduce((a, c) => a.concat(c.data.reduce((x, y) => x + y.menu_history_debit, 0) > 0 ? c.item_name : []), []),
                  datasets: [
                    {
                      label: 'Total',
                      data: produkMergedByItem('terjual').reduce((a, c) => a.concat(c.data.reduce((x, y) => x + y.menu_history_debit, 0)), []),
                      backgroundColor: '#a9d18e',
                    },
                  ],
                }
              }
            />
          </Containter>
        </div>
        <div className="col-md-12 mb-4">
          <Containter className="container">
            <ExportBtn onClick={()=>exportStokMasuk()}>
              <Download width={20} height={20} color="gray" />
            </ExportBtn>
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: 'Stok Masuk',
                  },
                },
              }}
              data={
                {
                  labels: produkMergedByItem('stock').reduce((a, c) => a.concat(c.item_name), []),
                  datasets: [
                    {
                      label: 'Total',
                      data: produkMergedByItem('stock').reduce((a, c) => a.concat(c.data.reduce((x, y) => x + y.menu_history_credit, 0)), []),
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                  ],
                }
              }
            />
          </Containter>
        </div>
        <div className="col-md-12 mb-4">
          <Containter className="container">
            <ExportBtn onClick={()=>exportExpired()}>
              <Download width={20} height={20} color="gray" />
            </ExportBtn>
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: 'Expired',
                  },
                },
              }}
              data={
                {
                  labels: produkMergedByItem('expired').reduce((a, c) => a.concat(c.item_name), []),
                  datasets: [
                    {
                      label: 'Total',
                      data: produkMergedByItem('expired').reduce((a, c) => a.concat(c.data.reduce((x, y) => x + y.menu_history_debit, 0)), []),
                      backgroundColor: '#ccc',
                    },
                  ],
                }
              }
            />
          </Containter>
        </div>
        <div className="col-md-12 mb-4">
          <Containter className="container">
            <Components.TableData header={["Product Name", "Store", "Transaksi Diproses", "Produksi", "Transfer In", "Transfer Out", "Expired"]}>
              {renderDataProduk()}
            </Components.TableData>
            {
              transactionItem.item_report.length > 0 &&
              <div className="pb-2 pt-2">
                <Components.Pagination
                  page={page}
                  totalPage={Math.ceil(produkMerged().length / itemsPerPage)}
                  handleOnChange={(e) => setPage(e.selected + 1)}
                />
                <ExportBtnBottom onClick={()=>exportMovement()}>
                  <Download width={20} height={20} color="gray" />
                </ExportBtnBottom>
              </div>
            }
          </Containter>
        </div>
      </div>
    )
  }

  function exportMovement() {
    const data = produkMerged().sort((a, b) => a.item_name.localeCompare(b.item_name))

    var content = [];
    for (var i = (page - 1) * itemsPerPage; i < ((page - 1) * itemsPerPage) + itemsPerPage; i++) {
      if (data[i]) {

        const terjualTemp = data[i].data.filter(x => x.ms_reference_name === "TRANSACTION_CREATE" && x.menu_history_debit).reduce((a, c) => a + c.menu_history_debit, 0);
        const stockin = data[i].data.filter(x => x.ms_reference_name === "DIRECT_STOCK" && x.menu_history_credit).reduce((a, c) => a + c.menu_history_credit, 0);
        const transferin = data[i].data.filter(x => x.ms_reference_name === "TRANSFER_STOCK" && x.menu_history_credit).reduce((a, c) => a + c.menu_history_credit, 0);
        const transferout = data[i].data.filter(x => x.ms_reference_name === "TRANSFER_STOCK" && x.menu_history_debit).reduce((a, c) => a + c.menu_history_debit, 0);
        const expired = data[i].data.filter(x => x.ms_reference_name === "DIRECT_STOCK" && x.menu_history_debit).reduce((a, c) => a + c.menu_history_debit, 0);
        const terjual = terjualTemp - refundByItemStore(data[i].item_name, data[i].store_name);

        content.push({
          "product name": data[i].item_name,
          store: data[i].store_name,
          "transaksi diproses": terjual > 0 ? terjual : '-',
          produksi: stockin > 0 ? stockin : '-',
          "transfer in": transferin > 0 ? transferin : '-',
          "transfer out": transferout > 0 ? transferout : '-',
          expired: expired > 0 ? expired : '-'
        })
      }
    }

    exportExcel({
      filename: `movement ${moment(search.from_date).format('DD-MM-YYYY')} - ${moment(search.end_date).format('DD-MM-YYYY')}`,
      excelData: content
    })
  }

  function produkMerged() {
    const merged = transactionItem.item_report.reduce((r, { item_name, store_name, ...rest }) => {
      const key = `${item_name}-${store_name}`;
      r[key] = r[key] || { item_name, store_name, data: [] };
      r[key]["data"].push(rest)
      return r;
    }, {})

    const data = Object.values(merged)

    return data;
  }

  function refundByItemStore(item_name, store_name) {
    var credit = 0;
    transactionItem.item_report.map((x) => {
      if (x.ms_reference_name === "TRANSACTION_REFUND" && x.item_name === item_name && x.store_name === store_name) {
        credit += x.menu_history_credit
      }
    })
    return credit;
  }

  function produkMergedByItem(type) {
    if (type === 'produksi') {
      var dataType = transactionItem.item_report.reduce((a, c) => a.concat(c.ms_reference_name === "DIRECT_STOCK" && c.menu_history_credit ? c : []), []);
    } else if (type === 'terjual') {
      var buyData = transactionItem.item_report.reduce((a, c) => a.concat(c.ms_reference_name === "TRANSACTION_CREATE" && c.menu_history_debit ? c : []), []);
      var refundData = transactionItem.item_report.reduce((a, c) => a.concat(c.ms_reference_name === "TRANSACTION_REFUND" && c.menu_history_credit ? c : []), []);

      var dataType = buyData;
      for (var i = 0; i < refundData.length; i++) {
        dataType.push({
          ...refundData[i],
          menu_history_debit: refundData[i].menu_history_credit * -1,
          menu_history_credit: 0,
          ms_reference_name: "TRANSACTION_CREATE"
        })
      }
    } else if (type === 'stock') {
      var dataType = transactionItem.item_report.reduce((a, c) => a.concat(c.ms_reference_name === "TRANSFER_STOCK" && c.menu_history_credit ? c : []), []);
    } else if (type === 'expired') {
      var dataType = transactionItem.item_report.reduce((a, c) => a.concat(c.ms_reference_name === "DIRECT_STOCK" && c.menu_history_debit ? c : []), []);
    }

    const merged = dataType.reduce((r, { item_name, ...rest }) => {
      const key = `${item_name}`;
      r[key] = r[key] || { item_name, data: [] };
      r[key]["data"].push(rest)
      return r;
    }, {})

    const data = Object.values(merged)

    return data;
  }

  function renderDataProduk() {
    const data = produkMerged().sort((a, b) => a.item_name.localeCompare(b.item_name))

    var content = [];
    for (var i = (page - 1) * itemsPerPage; i < ((page - 1) * itemsPerPage) + itemsPerPage; i++) {
      if (data[i]) {

        const terjualTemp = data[i].data.filter(x => x.ms_reference_name === "TRANSACTION_CREATE" && x.menu_history_debit).reduce((a, c) => a + c.menu_history_debit, 0);
        const stockin = data[i].data.filter(x => x.ms_reference_name === "DIRECT_STOCK" && x.menu_history_credit).reduce((a, c) => a + c.menu_history_credit, 0);
        const transferin = data[i].data.filter(x => x.ms_reference_name === "TRANSFER_STOCK" && x.menu_history_credit).reduce((a, c) => a + c.menu_history_credit, 0);
        const transferout = data[i].data.filter(x => x.ms_reference_name === "TRANSFER_STOCK" && x.menu_history_debit).reduce((a, c) => a + c.menu_history_debit, 0);
        const expired = data[i].data.filter(x => x.ms_reference_name === "DIRECT_STOCK" && x.menu_history_debit).reduce((a, c) => a + c.menu_history_debit, 0);
        const terjual = terjualTemp - refundByItemStore(data[i].item_name, data[i].store_name);

        content.push(
          <tr
            key={i}
          >
            <td data-label="Product Name">{data[i].item_name}</td>
            <td data-label="Store">{data[i].store_name}</td>
            <td data-label="Terjual">{terjual > 0 ? terjual : '-'}</td>
            <td data-label="Produksi">{stockin > 0 ? stockin : '-'}</td>
            <td data-label="Transfer In">{transferin > 0 ? transferin : '-'}</td>
            <td data-label="Transfer Out">{transferout > 0 ? transferout : '-'}</td>
            <td data-label="Expired">{expired > 0 ? expired : '-'}</td>
          </tr>
        )
      }
    }
    return content
  }

  function exportCustomerUnique() {
    exportExcel({
      filename: `unique customer ${moment(search.from_date).format('DD-MM-YYYY')} - ${moment(search.end_date).format('DD-MM-YYYY')}`,
      excelData: transactionCustomer.detail_sales.map(x => ({tanggal:x.interval_datetime, qty: x.unique_customer}))
    })
  }

  function renderTransactionCustomerContent() {
    return (
      <div className="mt-5">
        <h3 className="text-center">Statistic Pelanggan</h3>
        <div className="row mt-3">
          <div className="col-md-12 mb-2">
            <Containter className="container">
              <ExportBtn onClick={()=>exportCustomerUnique()}>
                <Download width={20} height={20} color="gray" />
              </ExportBtn>
              <Bar
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Customer (Unique)',
                    },
                  },
                }}
                data={
                  {
                    labels: transactionCustomer.detail_sales.map(x => x.interval_datetime),
                    datasets: [
                      {
                        label: 'Customer',
                        data: transactionCustomer.detail_sales.map(x => x.unique_customer),
                        backgroundColor: '#78ff78',
                      }
                    ],
                  }
                }
              />
            </Containter>
          </div>
          {/* <div className="col-md-6 mb-2">
            <Containter className="container">
              <Bar 
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Produk (Unique)',
                    },
                  },
                }}
                data={
                  {
                    labels: transactionCustomer.detail_sales.map(x=>x.interval_datetime),
                    datasets: [
                      {
                        label: 'Nominal',
                        data: transactionCustomer.detail_sales.map(x=>x.unique_item_quantity),
                        backgroundColor: '#78ff78',
                      }
                    ],
                  }
                }
              />
            </Containter>
          </div> */}
        </div>
      </div>
    )
  }
}

const Containter = styled.div`
background:${({ active }) => active ? `linear-gradient(to right, #64b3c1, #7c71d5)` : `#fff`};
border-radius: 6px;
box-shadow: 0px 3px 6px rgb(0 0 0 / 16%) !important;
display:flex;
flex-direction:column;
justify-content: center;
align-items:center;
padding:0;
width:100%;
color: #fff;
position:relative;
`

const ExportBtn = styled.div`
position:absolute;
top:10px;
right:10px;
padding:5px;
border-radius:8px;
z-index:999;
cursor:pointer;
`

const ExportBtnBottom = styled.div`
position:absolute;
bottom:25px;
right:10px;
padding:5px;
border-radius:8px;
z-index:999;
cursor:pointer;
`