import Navbar from "landingPage/components/navbar_v2";
import styled from "styled-components";
import SelfService from "./self_service";
import BannerPublisher from "./banner";
import OurChannel from "./our_channel";

const PublisherPage = () => {
  return (
    <div>
      <Navbar />
      <BannerPublisher />
      <Container>
        <SelfService />
        <OurChannel />
      </Container>
    </div>
  );
};

export default PublisherPage;

const Container = styled.div`
  padding: 0 150px;

  .container-interest {
    margin-top: 80px;
  }

  .container-carousel {
    margin-top: 42px;
  }

  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding: 0 20px;
    overflow-x: hidden;
  }
`;
