import { Modal_Component as ModalComponent } from "../../../../component";
import Select from "react-select"
import { DashboardService as api } from "../../../../service/api"
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form"
import { decrypt } from "../../../../service/encryt"
import Cookies from "js-cookie";

const ModalChangeCompany = ({
  show,
  onHide,
}) => {

  const { control, setValue } = useForm({
    defaultValues: {
      data: [],
      selectedCompany: ''
    }
  })
  const company_id = Cookies.get('company_id')



  const dataCompany = useWatch({
    control,
    name: 'data'
  })
  const selectedCompany = useWatch({
    control,
    name: 'selectedCompany'
  })



  const getDataCompany = async () => {
    const result = await api.list_company()
    const { data, success } = result


    if (success) {
      setValue('data', data.map(item => ({
        value: item.company_id,
        label: item.company_name
      })))
    }
  }

  const getDetailCompany = async () => {
    const result = await api.get_company(company_id)
    const { data: {
      company_id: company_id_result,
      company_name
    }, success } = result

    if (success) {
      setValue('selectedCompany', {
        value: company_id_result,
        label: company_name
      })
    }

  }





  useEffect(() => {
    if (show) {
      getDataCompany()
      getDetailCompany()
    }
  }, [show])


  const handleOnChangeCompany = (e) => {
    setValue('selectedCompany', e)
    Cookies.set('company_id', e.value)
    onHide('close')
    window.location.reload()
  }



  return (
    <ModalComponent show={show} onHide={() => onHide('close')} title="Change Company" size="md" >
      <div className="row mt-lg-5">
        <div className="col-md-12">
          <Select options={dataCompany} placeholder="Select Company" value={selectedCompany} onChange={handleOnChangeCompany} />
        </div>
      </div>
    </ModalComponent>
  )
}

export default ModalChangeCompany;