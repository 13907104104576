import styled from "styled-components";
import Navbar from "landingPage/components/Navbar";
import { Mobile_query_v2 } from "../../../util/Query";
import { BannerComponent, QuestionsComponent } from "./component";
import { FooterV2 } from "../../components";

const FaqPage = () => {
  return (
    <ContainerInti>
      <Navbar />
      <BannerComponent />
      <QuestionsComponent />
      <FooterV2 />
    </ContainerInti>
  );
};

export default FaqPage;

const ContainerInti = styled.div`
  ${Mobile_query_v2} {
    overflow-x: hidden;
  }
  background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  );
  z-index: 1;
`;
