import React from "react";
import styled from "styled-components";
import Navbar from "landingPage/components/Navbar";
import BreadCrumb from "./breadCrumb";

const SearchBudget = () => {
  return (
    <ContainerInti>
      <Container>
        <Navbar />
        <BreadCrumb />
      </Container>
    </ContainerInti>
  );
};

export default SearchBudget;

const ContainerInti = styled.div`
  background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  );
`;

const Container = styled.div`
  min-height: 100vh;
  padding: 0 93px;

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;
