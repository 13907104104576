import styled from "styled-components";
import IcLeft from "../../../asset/version2/ic_left_arrow_landingpage.png";
import IcRight from "../../../asset/version2/ic_right_arrow_landingpage.png";

const Carousel = ({ arrImage = [] }) => {
  return (
    <Container
      id="carouselControls"
      className="carousel slide"
      data-bs-ride="carousel"
      interval={4000}
      touch={true}
    >
      <div className="carousel-inner">
        {arrImage.map((item, idx) => (
          <a
            key={idx}
            target="__blank"
            href={`https://${item?.link}`}
            className={`carousel-item ${idx === 0 ? "active" : ""}`}
          >
            <img
              src={item?.image}
              className="d-block w-100 img_carousel"
              alt={item?.image}
            />
          </a>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselControls"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselControls"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </Container>
  );
};

export default Carousel;

const Container = styled.div`
  .img_carousel {
    width: 1208px;
    height: 400px;
    object-fit: cover;
  }

  .carousel-control-prev-icon {
    width: 40px !important;
    height: 40px !important;
    background: #ffffff !important;
    box-shadow: 0px 1px 6px rgba(49, 53, 59, 0.12) !important;
    border-radius: 20px !important;
    background-image: url(${IcLeft}) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 20% !important;
  }
  .carousel-control-next-icon {
    width: 40px !important;
    height: 40px !important;
    background: #ffffff !important;
    box-shadow: 0px 1px 6px rgba(49, 53, 59, 0.12) !important;
    border-radius: 20px !important;
    background-image: url(${IcRight}) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 20% !important;
  }

  .carousel-control-prev {
    left: 0% !important;
  }
  .carousel-control-next {
    right: 0% !important;
  }
`;
