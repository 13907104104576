import styled from "styled-components";
import entypo_message from "landingPage/assets/iconAndLogo/entypo_message.png";
import pinTarget from "landingPage/assets/iconAndLogo/PinVector.png";
import TargetVector from "landingPage/assets/iconAndLogo/TargetVector.png";
import { useState } from "react";
import materialSymbolsSms from "landingPage/assets/iconAndLogo/material-symbols_sms-rounded.png";
import IconCircleCheck from "landingPage/assets/iconAndLogo/Check Circle.png";
import { Mobile_query_v2 } from "../../../util/Query"

const listHeader = [
  {
    icon: entypo_message,
    title: "Broadcast",
    desc: "Sending mass text messages to a bulk list of recipients from Users.",
  },
  {
    icon: pinTarget,
    title: "LBA",
    desc: "Delivering marketing texts to recipients living in certain geographical areas only.",
  },
  {
    icon: TargetVector,
    title: "Targeted",
    desc: "Delivering marketing texts to recipients based on specific profile: age & sex, time, gadget types, etc",
  },
];

const Header = ({ active, icon, title, desc, onClick }) => {
  return (
    <ContainerHeader active={active} onClick={onClick}>
      <div className="left-header">
        <div className="wrap-icon">
          <img src={icon} alt={icon} />
        </div>
      </div>
      <div className="right-header">
        <div className="title-header">{title}</div>
        <div className="desc-header">{desc}</div>
      </div>
    </ContainerHeader>
  );
};

const CardContent = () => {
  return (
    <ContainerCard>
      <div className="header-card">
        <div className="left-header-card">
          <div className="wrap-img">
            <img src={materialSymbolsSms} />
          </div>
        </div>
        <div className="right-header-card">
          <div className="title-header-card">SMS</div>
          <div className="small-header-card">Lorem ipsum dolor sit </div>
        </div>
      </div>
      <div className="content-card">
        <div className="desc-content-card">
          Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing
          elit.
        </div>
        <div className="wrap-price-content-card">
          <div className="price-content-card">Rp 99.000</div>
          <div className="monthly-text-card">/ monthly</div>
        </div>
        <div className="wrap-include-card">
          <div className="title-include-card">What's included</div>
          <div className="list-icluded-card">
            <ul>
              {[2, 2, 2, 2].map((item, idx) => (
                <li key={idx}>
                  Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="button-card">
        <button className="btn">Get started</button>
      </div>
    </ContainerCard>
  );
};

const Product = () => {
  const [activeCard, setActiveCard] = useState("Broadcast");

  const handleActiveCard = (propsCard) => setActiveCard(propsCard);
  return (
    <div>
      <Container>
        <div id="tab-header">
          <div className="list-header">
            {listHeader.map((item, idx) => (
              <Header
                active={item.title === activeCard ? true : false}
                icon={item.icon}
                title={item.title}
                desc={item.desc}
                onClick={() => handleActiveCard(item.title)}
              />
            ))}
          </div>
        </div>
      </Container>
      <TabContent id="tab-content">
        <div className="title-content">Broadcast</div>
        <div className="desc-content">
          Sending mass text messages to a bulk list of recipients from Users.
          You can create and distribute/promote your products to the customer
          using "SMS Broadcast", just by entering your active numbers, and the
          customer will receive it in the form of SMS (text message)
        </div>
        <div className="list-card">
          {[2, 2, 2].map((item, idx) => (
            <CardContent key={idx} />
          ))}
        </div>
      </TabContent>
    </div>
  );
};

export default Product;

const ContainerCard = styled.div`
${Mobile_query_v2}{
  width:100%;
  .button-card{
    text-align:center;
    button{
      width:100% !important;
    }
  }
}
  .button-card {
    button {
      width: 308px;
      height: 72px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 26px 38px;

      /* Brand Color/Primary */

      background: #3b3e98;
      border-radius: 96px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      /* identical to box height, or 111% */

      text-align: center;

      /* Color/Neutral 10 */

      color: #ffffff;
    }
  }
  .content-card {
    margin-bottom: 48px;
    .wrap-include-card {
      .list-icluded-card ul {
        margin: 0px !important;
        padding: 0px !important;
      }
      .list-icluded-card ul li {
        :before {
          display: block;
          flex-shrink: 0;
          width: 26px;
          height: 26px;

          background: url(${IconCircleCheck}) no-repeat left center;
          background-size: contain;

          content: "";
        }
        gap: 14px;
        display: flex;
        /* align-items: center; */
        justify-content: flex-start;
        text-align: left;

        line-height: 30px;

        list-style: none;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        box-shadow: none;
        margin-bottom: 20px;
        /* or 167% */

        /* Color/Neutral 80 */

        color: #6d7078;
      }
      .title-include-card {
        margin-bottom: 20px;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height, or 111% */

        /* Color/Neutral 100 */

        color: #222833;
      }
    }
    .wrap-price-content-card {
      display: flex;
      gap: 10px;
      align-items: baseline;
      .monthly-text-card {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        /* identical to box height, or 110% */

        /* Color/Neutral 80 */

        color: #6d7078;
      }
      .price-content-card {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 66px;
        /* identical to box height, or 165% */

        /* Color/Neutral 100 */

        color: #222833;
      }
    }
    .desc-content-card {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      /* or 188% */

      /* Color/Neutral 80 */

      color: #6d7078;
      margin-bottom: 13px;
    }
  }

  .header-card {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 28px;
    .right-header-card {
      .small-header-card {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height, or 111% */

        /* Color/Neutral 80 */

        color: #6d7078;
      }
      .title-header-card {
        /* Heading/H5 - SemiBold */

        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;

        /* Color/Neutral 100 */

        color: #222833;
      }
    }
    .left-header-card {
      .wrap-img {
        background: #6899d1;
        opacity: 0.12;
        border-radius: 16px;
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          color: #3b3e98;
        }
      }
    }
  }

  background: #ffffff;
  /* Color/Neutral 30 */

  border: 1px solid #efeff0;
  /* General/Shadow 02 */

  box-shadow: 0px 2px 12px rgba(20, 20, 43, 0.08);
  border-radius: 24px;
  width: 394px;
  min-height: 510px;
  overflow: hidden;
  flex-wrap: wrap;
  padding: 43px 40px;
`;

const ContainerHeader = styled.div`
${Mobile_query_v2}{
  width:100% !important;
}
  .right-header {
    .title-header {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height */

      /* Black 2 */

      color: #1a202c;
      margin-bottom: 8px;
    }
    .desc-header {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      /* or 162% */

      /* Color/Neutral 80 */

      color: #6d7078;
    }
  }
  .left-header {
    display: flex;
    align-items: center;
    .wrap-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      background: linear-gradient(0deg, #f8f9fd, #f8f9fd), #ffffff;
      border-radius: 20px;
    }
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 368px;
  height: 147px;
  border-radius: 24px;
  background: ${({ active }) => (active ? "#ebedf9" : "transparent")};
  padding: 21px 12.5px;
  gap: 25px;
  transition: 250ms;
  :hover {
    background: #ebedf9;
  }
`;

const TabContent = styled.div`
${Mobile_query_v2}{
  .list-card{
    flex-direction:column !important;
    display:flex;
    align-items:center;
    justify-content: center;
  }
  padding:0px 25px;
}
  .list-card {
    display: flex;
    gap: 30px;
  }
  .desc-content {
    /* Body/Normal - Semi Bold */

    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    text-align: center;

    /* Color/Neutral 80 */

    color: #6d7078;
    margin-bottom: 65px;
  }
  .title-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    /* identical to box height, or 123% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.01em;

    /* gray/900 */

    color: #111928;
    margin-bottom: 15px;
  }
  background: #ffff;
  padding: 26px 121px;

  @media only screen and (min-width: 1366px) {
    /* max-width: 1366px; */
    margin: auto;
  }
`;
const Container = styled.div`
${Mobile_query_v2}{
  padding: 0 25px;
  #tab-header{
    .list-header{
      flex-direction: column;
      gap:30px;
    }
  }
}
  #tab-header {
    .list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 24px;
      padding: 26px 17.5px;
    }
  }

  display: flex;
  flex-direction: column;
  padding: 0 93px;

  padding-bottom: 44px !important;

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;
