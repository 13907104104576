import styled from "styled-components";
import ImgBanner from "landingPage/assets/iconAndLogo/Group39682.png";
import { Mobile_query_v2 } from "../../../util/Query"

const Banner = () => {
  return (
    <Container>
      <div className="left">
        <img src={ImgBanner} alt={ImgBanner} />
      </div>
      <div className="right">
        <div className="title">Message Marketing</div>
        <div className="desc">
          Message marketing is more personal than other forms of marketing and
          can help a business gain traction. There are many forms of message
          marketing: SMS (Text Messaging), WhatsApp & Email.
        </div>
      </div>
    </Container>
  );
};

export default Banner;

const Container = styled.div`
${Mobile_query_v2}{
  flex-direction: column;
}
  .right {
    .desc {
      /* Body/Large - SemiBold */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* or 156% */

      /* Color/Neutral 80 */

      color: #6d7078;
    }
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      /* identical to box height */

      /* Brand Color/Primary */

      color: #3b3e98;
      position: relative;
      z-index: 1;
      :before {
        content: "";
        z-index: -1;
        background-color: #fcd45d;
        width: 45px;
        height: 45px;
        border-radius: 9999px;
        top: 5px;
        left: -10px;
        position: absolute;
      }
    }
  }

  .right,
  .left {
    flex: 1;
  }

  margin-bottom: 46px;
  display: flex;
  align-items: center;
  margin-top: 77px;
`;
