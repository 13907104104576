import styled from "styled-components";
import SlideCarousel from "landingPage/components/SliderCarousel";
import OurClient from "landingPage/components/our-client/index";
import ProductAndSolution from "landingPage/components/ProductAndSolution";
import GrowingBusiness from "landingPage/components/growingBusiness";
import Budget from "landingPage/components/budget";
import EgagementNumbers from "landingPage/components/egagementNumbers";
import RespectiveClient from "landingPage/components/respectiveClient";
import Testimonials from "landingPage/components/testimonials";
import imgRoundLogo from "landingPage/assets/iconAndLogo/Asset Round Bubble PS@3x.png";
import { Mobile_query_v2 } from "../../../util/Query";
import { FooterV2 } from "../../components";

import Navbar from "landingPage/components/Navbar";
const Home = () => {
  return (
    <ContainerInti>
      <Navbar />
      <Container>
        <SlideCarousel />
        <OurClient />
        <ProductAndSolution />
        <GrowingBusiness />
        <Budget />
      </Container>
      <EgagementNumbers />
      <Container>
        <RespectiveClient />
      </Container>
      <Testimonials />

      <FooterV2 />
    </ContainerInti>
  );
};

export default Home;

const ContainerInti = styled.div`
  ${Mobile_query_v2} {
    overflow-x: hidden;
  }
  background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  );
  z-index: 1;

  /* &::before {
    width: 137px;
    height: 266px;
    background-image: url(${imgRoundLogo});
    position: absolute;
    right: 0px;
    top: 0px;
  } */
`;

const Container = styled.div`
  padding: 0 93px;

  ${Mobile_query_v2} {
    padding: 0 25px;
  }
  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;
