import React, { useEffect, useState } from "react";
import { Modal_Component, TableData, Pagination } from "component";
import { DonutBar } from "component/chart/donut";
import api from "service/api/Event/microsite";
import getRandomColor from "../../../../../util/generatorColor";
import { Numeric, Moment } from "../../../../../util";

const header = ["No.", "Tanggal Input", "Ip Klien", "Feedback"];

const Feedback = ({ show, onHide, params }) => {
  const { id } = params;
  const [dataFeedback, setDataFeedback] = useState({
    data: [],
    page: 1,
    last_page: 1,
  });
  const [summary, setSummary] = useState({});
  const [pie_data, setPie_data] = useState([]);
  // let summary = {};
  // let pie_data = [];

  const getListFeedback = () => {
    api.getListFeedback(id, dataFeedback.page).then((res) => {
      if (res.success) {
        setDataFeedback({
          data: res.data,
          page: res.page,
          last_page: res.last_page,
        });
      }
    });
  };

  const onChangePaginationHandler = (page) => {
    api.getListFeedback(id, page.selected + 1).then((res) => {
      if (res.success) {
        setDataFeedback({
          data: res.data,
          page: res.page,
          last_page: res.last_page,
        });
      }
    });
  };

  const getSummary = () => {
    api.getSummaryFeedback(id).then((result) => {
      if (result && result.data && result.success) {
        setSummary(result && result.data ? result.data : null);
        setPie_data(
          result.data.body.aggregations.feedback_summary.total_question.buckets
        );
      }
    });
  };

  useEffect(() => {
    if (show) {
      getListFeedback();
      getSummary();
    }
  }, [show]);


  return (
    <Modal_Component show={show} onHide={onHide} title="Microsite Feedback">
      <div className="wrap-summary-feedback">
        <div>
          <strong>Summary feedback</strong>
        </div>

        <div className="mt-3">
          {pie_data.map((item, idx) => (
            <div className="card p-3" key={idx}>
              <div className="card-titel">
                <strong>{item.key}</strong>
              </div>
              <div className="mt-3">
                <div>hasil jawaban (feedback) : </div>
                <div className="d-flex">
                  <div className="left flex-fill">
                    <ul>
                      {item?.total_answer?.buckets?.map((buckets) => (
                        <li>{`${buckets.key}: ${buckets.doc_count}`}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="right">
                    <DonutBar
                      // isMulti
                      title="Answer"
                      // label_multi_1="Answer"
                      labels={item?.total_answer?.buckets?.map(
                        (buckets) => buckets.key
                      )}
                      data_single={item?.total_answer?.buckets?.map(
                        (buckets) => buckets.doc_count
                      )}
                      color={getRandomColor()}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="detail-summary mt-5">
        <div>
          <strong>Summary feedback</strong>
        </div>

        <div className="mt-3 card">
          <TableData header={header}>
            {dataFeedback.data?.map((item, idx) => (
              <tr key={idx}>
                <td data-label={header[idx]}>
                  {Numeric({ idx, page: dataFeedback.page })}
                </td>
                <td>{Moment(item.microsite_feedback_created_datetime)}</td>
                <td>{item.microsite_feedback_ip}</td>
                <td>
                  {JSON.parse(item.microsite_feedback_json).length > 0 &&
                    JSON.parse(item.microsite_feedback_json).map((json) => {
                      return (
                        <div>
                          Q: {json.question}
                          <br />
                          A: {json.answer}
                          <br />
                        </div>
                      );
                    })}
                </td>
              </tr>
            ))}
          </TableData>

          <div className="mt-3">
            <Pagination
              page={dataFeedback.page}
              totalPage={dataFeedback.last_page}
              handleOnChange={onChangePaginationHandler}
            />
          </div>
        </div>
      </div>
    </Modal_Component>
  );
};

export default Feedback;
