import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import * as Component from "../../../component";
import * as Style from "../../../component/style/content/default";
import * as Services from "../../../service";
import * as Modal from "./modal";
import { Method } from "../../../service";
import { Numeric, displayStatus } from "../../../util";
import { Form } from "react-bootstrap";
import api_permission from "service/api/permission";

const statusList = [
  { value: "", label: "Semua" },
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const header = [
  "No",
  "Gambar",
  "Nama Produk",
  "SKU",
  "Tipe",
  "Kategori",
  "Status",
  "Aksi",
];

const header_nobundling = [
  "Gambar",
  "Nama Produk",
  "SKU",
  "Tipe",
  "Kategori",
  "Status",
  "Aksi",
];

const data_more = [
  { name: "Lihat Detail" },
  { name: "Ubah" },
  { name: "Nonaktifkan" },
  { name: "Aktifkan" },
  { name: "Pasang ke Toko" }
];

export default function MasterProduct(props) {
  const { select, nobundling, onSelect, value } = props;
  const { selected_company, dispatch, retail_permission, data_user } = useContext(Services.Context);
  const [modal, setModal] = useState({
    company: false,
  });
  const [category, setCategory] = useState([]);
  const [categorySelected, setCategorySelected] = useState({
    value: "",
    label: "Semua Kategori",
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  });
  const [search, setSearch] = useState('');
  const [statusSelected, setStatusSelected] = useState({ value: '', label: 'Semua' });
  const [modalDetail, setModalDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [modalForm, setModalForm] = useState(false);
  const [modalFormType, setModalFormType] = useState(null);
  const [categoryDisplayName, setCategoryDisplayName] = useState('');
  const [itemSelected, setItemSelected] = useState(null);

  useEffect(() => {
    if(selected_company.value){
      api_permission(dispatch, selected_company.value, data_user.id)
    }
  }, [selected_company])

  const GetCompany = async () => {
    await Method.get(`company`)
      .then(async (res) => {
        if (res?.data?.success) {
          if (!selected_company.value) {
            dispatch({
              type: "SELECTED_COMPANY",
              selected_company: {
                value: res?.data?.data[0]?.company_id,
                label: res?.data?.data[0]?.company_name,
              },
            });
          }
        } else {
          Component.AlertError({ title: "Error", text: res?.data?.error });
        }
      })
      .catch((err) => {
      });
  };

  const GetCategory = async () => {
    await Method.get(
      `company/${selected_company.value}/category_display?category_display_active_status_name=active&limit=*`
    )
      .then(async (res) => {
        if (res.data.success) {
          var array = [{ value: "", label: "Semua Kategori" }];
          var arrayNew = array.concat(
            res.data.data.map((item) => ({
              value: item.category_display_id,
              label: item.category_display_name,
            }))
          );
          setCategory(arrayNew);
        }
      })
      .catch((err) => {
      });
  };

  const getData = async (pageData = 1) => {
    setLoading(true);

    var data_post = {
      item_name: search,
      item_active_status: statusSelected.value,
      category_display_id: categorySelected.value,
    };

    await Method.get(
      `company/${selected_company.value}/item?page=${pageData}`,
      { params: data_post }
    )
      .then(async (res) => {
        console.log('response', res)
        if (res.data.success) {
          setData((state) => ({
            ...state,
            data: res.data.data,
            page: res.data.page,
            lastPage: res.data.last_page,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          Component.AlertError({ title: "Error", text: error.response.data.error });
        } else {
          Component.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
        }
      });
  };

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  const btnMore = async (name, id, category_display_name) => {
    const row = data?.data?.find((find) => find.item_id === id);

    switch (name) {
      case "Lihat Detail":
        getDetailData(row.item_id, 'detail');
        break;

      case "Ubah":
        setCategoryDisplayName(category_display_name);
        getDetailData(row.item_id, 'form');
        break;

      case "Aktifkan":
        await Component.AlertQuestion({
          title: "Warning",
          text: `Do you want to activate ${row.item_name}`,
        }).then((res) => {
          if (res.isConfirmed) {
            //action
            changeStatus(row.item_id, 'activate')
          }
        });
        break;

      case "Nonaktifkan":
        await Component.AlertQuestion({
          title: "Warning",
          text: `Do you want to inactivate ${row.item_name}`,
        }).then((res) => {
          if (res.isConfirmed) {
            //action
            changeStatus(row.item_id, 'inactivate')
          }
        });
        break;

      case "Pasang ke Toko":
        setItemSelected(row)
        break;

      default:
        return name;
    }
  }

  const getDetailData = async (item_id, type) => {
    await Method.get(`item/${item_id}`)
      .then(async (res) => {
        if (res.data.success) {
          setDataDetail(res.data.data);
          setTimeout(() => {
            if (type === 'detail') {
              setModalDetail(true);
            } else {
              setModalForm(true);
              setModalFormType('edit');
            }
          }, 500);
        } else {
          Component.AlertError({ title: "Error", text: res.error });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          Component.AlertError({ title: "Error", text: error.response.data.error });
        } else {
          Component.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
        }
      });
  }

  const changeStatus = async (item_id, status) => {
    await Method.put(`item/${item_id}/${status}`)
      .then(async (res) => {
        if (res.data.success) {
          Component.AlertSuccess({ title: "Success", text: res.success });
          getData();
        } else {
          Component.AlertError({ title: "Error", text: res.error });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          Component.AlertError({ title: "Error", text: error.response.data.error });
        } else {
          Component.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
        }
      });
  }

  const filter = () => {
    getData();
  };

  const resetFilter = () => {
    setSearch("");
    setCategorySelected({ value: "", label: "Semua Kategori" });
    setStatusSelected({ value: "", label: "Semua" });
  };

  const checkSelect = (item) => {
    const check = value.findIndex(x => x.item_id===item.item_id);
    if(check>=0){
      return true
    }else{
      return false
    }
  }

  useEffect(() => {
    if (
      search === "" &&
      categorySelected.value === "" &&
      statusSelected.value === ""
    ) {
      getData();
    }
  }, [search, categorySelected, statusSelected]);

  useEffect(() => {
    GetCompany();
  }, []);

  useEffect(() => {
    GetCategory();
    getData();
  }, [selected_company]);


  return (
    <Style.ContainerContent>
      {/* MODAL =========== */}
      <Modal.ModalSelectCompany
        show={modal.company}
        onHide={() => setModal((state) => ({ ...state, company: false }))}
      />

      <Modal.ModalDetailProduk
        show={modalDetail}
        onHide={() => setModalDetail(false)}
        data={dataDetail}
      />

      <Modal.ModalStoreProduk
        show={itemSelected?true:false}
        onHide={() => setItemSelected(null)}
        data={itemSelected}
      />

      <Modal.ModalFormProduk
        show={modalForm}
        type={modalFormType}
        onHide={() => setModalForm(false)}
        onSubmit={() => {
          getData();
          setModalForm(false);
        }}
        data={dataDetail}
        categoryDisplayName={categoryDisplayName}
      />

      {
        !select &&
        <section>
          <div className="d-flex">
            <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
              Master Produk
            </Style.HeaderPrimary>
            <div className="wrap-select align-items-center ms-5">
              <button
                className="btn border"
                onClick={() => setModal((state) => ({ ...state, company: true }))}
              >
                {selected_company.label ? selected_company.label : "Select Company"}{" "}
                <i className="demo-icon icon-chevron-down"></i>
              </button>
            </div>
          </div>
        </section>
      }

      {
        retail_permission.data?.type!=='staff' ? (

          <section>
            {
              !select &&
              <Style.ButtonPrimary
                onClick={() => {
                  setModalForm(true);
                  setModalFormType('add');
                }}
                className="mb-2"
              >
                Tambah Produk
              </Style.ButtonPrimary>
            }

            <Component.Form>
              <section>
                <div className="row">
                  <div className="col-md-3 mb-2">
                    <Style.Label color={Style.COLOR_SECONDARY}>Keyword</Style.Label>
                    <Form.Control
                      placeholder="Cari produk..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>

                  <div className="col-md-3 mb-2">
                    <Style.Label color={Style.COLOR_SECONDARY}>Kategori</Style.Label>
                    <Select
                      placeholder="Semua Kategori"
                      value={categorySelected}
                      options={category}
                      onChange={(e) => setCategorySelected(e)}
                    />
                  </div>

                  <div className="col-md-3 mb-2">
                    <Style.Label color={Style.COLOR_SECONDARY}>Status</Style.Label>
                    <Select
                      placeholder="Semua Status"
                      value={statusSelected}
                      options={statusList}
                      onChange={(e) => setStatusSelected(e)}
                    />
                  </div>

                  <div className="col-md-3 pt-4 pb-4 d-flex flex-row">
                    <Style.ButtonPrimary onClick={() => filter()}>
                      Cari
                    </Style.ButtonPrimary>
                    <button
                      className="btn bg-light border border-gray ms-1"
                      onClick={() => resetFilter()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </section>

              <section className="mb-5 mb-md-5">
                <Component.TableData header={nobundling?header_nobundling:header}>
                  {loading ? (
                    <tr>
                      <td colSpan={header.length}>
                        <Component.Loadingfunc />
                      </td>
                    </tr>
                  ) : data.data.length === 0 ? (
                    <tr>
                      <td colSpan={header.length} className="text-center">
                        <div>Data Tidak Ditemukan</div>
                      </td>
                    </tr>
                  ) : (
                    data.data.map((item, index) => {
                      if(item.bundling_item && item.bundling_item.length > 0 && nobundling){
                        return null
                      }else{
                        return(
                          <tr
                            key={index}
                          >
                            {
                              !nobundling && <td data-label="No">{Numeric({ page: data.page, idx: index })}</td>
                            }
                            <td data-label="Gambar"><img src={item.item_image[0]} style={{ width: 80, height: 80, resizeMode: 'contain' }} /></td>
                            <td data-label="Nama Produk">{item.item_name}</td>
                            <td data-label="SKU">{item.item_sku}</td>
                            <td data-label="Tipe">{item.bundling_item && item.bundling_item.length > 0 ? 'Bundling':'Ala Carte'}</td>
                            <td data-label="Kategori">{item.category_display.category_display_name}</td>
                            <td data-label="Status">
                              {
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: displayStatus(
                                      item.item_active_status_name
                                    ),
                                  }}
                                />
                              }
                            </td>
                            <td data-label="Aksi">
                              {
                                !select ? (
                                  <Component.DropDown_More
                                    title={
                                      <div className="dropdown-trigger">
                                        <button
                                          className="button is-primary is-small"
                                          aria-haspopup="true"
                                          aria-controls="dropdown-menu"
                                        >
                                          <span className="icon is-small">•••</span>
                                        </button>
                                      </div>
                                    }
                                    data_more={data_more.filter((filter) =>
                                      item.item_active_status_name === "active"
                                        ? filter.name !== "Aktifkan"
                                        : filter.name !== "Nonaktifkan"
                                    )}
                                    id={item.item_id}
                                    onClick={({ name, id }) => btnMore(name, id, item.category_display.category_display_name)}
                                  />
                                ) : (
                                  <>
                                    {
                                      checkSelect(item) ? (
                                        <Style.ButtonDisabled disabled>
                                          Dipilih
                                        </Style.ButtonDisabled>
                                      ) : (
                                        <Style.ButtonPrimary onClick={() => onSelect(item)}>
                                          Pilih
                                        </Style.ButtonPrimary>
                                      )
                                    }
                                  </>
                                )
                              }
                            </td>
                          </tr>
                        )
                      }
                    })
                  )}
                </Component.TableData>
              </section>

              {
                data.data.length > 0 &&
                <section>
                  <Component.Pagination
                    page={data.page}
                    totalPage={data.lastPage}
                    handleOnChange={(e) => btnPagination(e)}
                  />
                </section>
              }
            </Component.Form>
          </section>
        ):(
          <div className="alert alert-warning mt-lg-5" role="alert">
            <h5>Akses Ditolak</h5>
            Anda sebagai staff tidak mempunyai akses di perusahaan ini
          </div>
        )
      }
    </Style.ContainerContent>
  );
}

MasterProduct.defaultProps = {
  select: false,
  nobundling: false,
  onSelect: null,
  value: []
}
