import styled from "styled-components";
import IconSMS from "../../assets/iconAndLogo/sms.svg";
import IconTelephone from "../../assets/iconAndLogo/Vector.svg";
import IconGlobal from "../../assets/iconAndLogo/global.svg";
const NavbarInfo = () => {
  return (
    <Container>
      <div className="wrap_info">
        <div className="row">
          <div className="col-6">
            <div className="wrap-localization d-flex align-items-center">
              <img src={IconGlobal} alt={IconGlobal} className="icon" />
              <select className="form-select form-select-sm w-auto">
                <option value="english">English</option>
                <option value="indonesia">Indonesia</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="wrap-info-general d-flex align-items-center gap-3 justify-content-end">
              <div className="wrap-email d-flex align-items-center gap-2">
                <img src={IconSMS} alt={IconSMS} />
                <div>cs@mediacartz.com</div>
              </div>
              <div className="wrap-tel d-flex align-items-center gap-2">
                <img src={IconTelephone} alt={IconTelephone} />
                <div>(021) 2295 - 8146</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NavbarInfo;

const Container = styled.div`
  background-color: #3b3e98;
  color: #fff;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;

  padding: 0 50px;
  .wrap_info {
    width: 100%;
  }

  .container-interest {
    margin-top: 80px;
  }

  .container-carousel {
    margin-top: 42px;
  }

  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding: 0 20px;
    overflow-x: hidden;
    width: 100%;
  }

  .wrap-localization {
    select {
      word-wrap: normal;
      background: none;
      border: none;
      color: #ffff;
      /* background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      -webkit-appearance: none;
      -moz-appearance: none;
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 3px; */

      :focus {
        box-shadow: none;
        border: none;
      }
    }
  }
`;
