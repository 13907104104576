import styled from "styled-components";

const BreadCumbsArticle = ({ title }) => {
  return (
    <Container>
      <span>article</span>> &nbsp;&nbsp;&nbsp; {title}
    </Container>
  );
};

export default BreadCumbsArticle;

const Container = styled.div`
  border-bottom: 1px solid #e0e0e0;
  padding: 29px 0px;
  /* Text M/Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.006em;
  span {
    color: #3b3e98;
    margin-right: 20px;
  }
`;
