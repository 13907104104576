import styled from "styled-components";
import bgWave from "../../../../assets/iconAndLogo/bg_wave.png";

const WhyChooseUs = () => {
  return (
    <div>
      <Container>
        <div className="blue-text-choose-us">WHY CHOOSE US</div>
        <div className="title-why-choose-us">Lorem ipsum dolor sit amet</div>
        <div className="sub-title-why-choose-us">
          Lorem ipsum dolor sit amet consectetur. Neque at auctor pellentesque
          id <br />
          purus maecenas odio faucibus. Sagittis dictum molestie turpis dictum{" "}
          <br />
          diam sit placerat.
        </div>
      </Container>

      <ContainerListWhyChooseMe>
        <div className="wave_bg" />
        <div className="container-list">
          {[1, 1, 1, 1, 1, 1, 1, 1].map((item, idx) => (
            <div className="card-why-choose-me" key={idx}>
              <div className="number-text">1</div>
              <div className="title-card-why-choose-me">Lorem ipsum dolor</div>
              <div className="sub-title-card-why-choose-me">
                Lorem ipsum dolor sit amet consectetur. Fames ipsum suspendisse
                integer faucibus.
              </div>
            </div>
          ))}
        </div>
      </ContainerListWhyChooseMe>
    </div>
  );
};

export default WhyChooseUs;

const ContainerListWhyChooseMe = styled.div`
  .wave_bg {
    min-height: 200px;
    background-image: url(${bgWave});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 200px;
  }
  .container-list {
    padding-top: 20px !important;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-column-gap: 36px;
    grid-row-gap: 64px;

    padding-bottom: 43px !important;
    .card-why-choose-me {
      position: relative;
      .number-text {
        width: 48px;
        height: 48px;
        background: #3b3e98;
        /* Gray/050 */

        border: 6px solid #f7f8f9;
        border-radius: 100%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height, or 150% */

        text-align: center;
        letter-spacing: -0.02em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-feature-settings: "salt" on;

        /* Neutral/10 */

        color: #ffffff;
        position: absolute;
        top: -24px;
        left: 45%;
      }
      .sub-title-card-why-choose-me {
        /* Text M/Regular */

        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        letter-spacing: -0.006em;

        /* Gray/500 */

        color: #556987;
      }
      .title-card-why-choose-me {
        /* Text L/Semi Bold */

        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        letter-spacing: -0.011em;

        /* Gray/900 */

        color: #2a3342;
        padding-top: 30px;
      }
      display: flex;
      flex-direction: column;
      padding: 28px;
      background: #ffffff;
      /* tailwind/drop-shadow-sm */

      box-shadow: 0px 1px 2px rgba(85, 105, 135, 0.1);
      border-radius: 6px;
    }

    background-color: #f5f6fb;
    padding: 0 150px;

    @media only screen and (min-width: 468px) and (max-width: 1024px) {
      padding: 0 20px;
      overflow-x: hidden;
    }
  }
`;

const Container = styled.div`
  .sub-title-why-choose-us {
    /* Text L/Regular */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    letter-spacing: -0.011em;

    /* Neutral/80 */

    color: #6d7078;
  }

  .title-why-choose-us {
    /* Heading/L */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    /* identical to box height, or 122% */

    text-align: center;
    letter-spacing: -0.022em;

    /* Neutral/100 */

    color: #222833;
  }

  .blue-text-choose-us {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;

    /* Brand Colors/Tertiary/100 */

    color: #2fbeee;
  }

  padding: 0 150px;
  margin-top: 230px;

  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding: 0 20px;
    overflow-x: hidden;
  }
`;
