import styled from "styled-components";
import ImgBgHaveQuestions from "../../../assets/iconAndLogo/img_bg_have_questions.png";

const QuestionsComponent = () => {
  return (
    <Container>
      <div className="container">
        <h4 className="title">Pertanyaan Umum</h4>

        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Lorem ipsum dolor sit amet?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Lorem ipsum dolor sit amet consectetur. Sem dolor dolor volutpat
                ut magna. In vel fermentum non vitae. Augue ut eu nunc nisl
                morbi cras. Ornare tempus nisi tempus viverra suspendisse.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Lorem ipsum dolor sit amet?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Lorem ipsum dolor sit amet consectetur. Sem dolor dolor volutpat
                ut magna. In vel fermentum non vitae. Augue ut eu nunc nisl
                morbi cras. Ornare tempus nisi tempus viverra suspendisse.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading3">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse3"
                aria-expanded="false"
                aria-controls="collapse3"
              >
                Lorem ipsum dolor sit amet?
              </button>
            </h2>
            <div
              id="collapse3"
              className="accordion-collapse collapse"
              aria-labelledby="heading3"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Lorem ipsum dolor sit amet consectetur. Sem dolor dolor volutpat
                ut magna. In vel fermentum non vitae. Augue ut eu nunc nisl
                morbi cras. Ornare tempus nisi tempus viverra suspendisse.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="container-have-question">
        <div className="container">
          <div className="d-flex">
            <div className="container-text">
              <div className="title">Kamu masih memiliki pertanyaan ?</div>
              <div className=" d-flex gap-5">
                <div className="sub-title">
                  <span className="text-title">
                    Hubungi kami dengan mengirimkan pesan berisi pertanyaan atau
                    keluhan kamu di menu contact us
                  </span>
                </div>
                <div className="container-button">
                  <button className="btn btn-hubungi">Hubungi Kami</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default QuestionsComponent;

const Container = styled.div`
  #container-have-question {
    background-image: url(${ImgBgHaveQuestions});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    min-height: 300px;
    padding: 0;
    color: #ffff;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-top: 30px;

    .container-button .btn-hubungi {
      padding: 10px 40px;

      background: #ffffff;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */

      text-align: center;

      color: #3b3e98;
    }

    .sub-title {
      /* Body/Extra Large - Regular */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      justify-content: start;
      text-align: start;
      /* or 150% */

      color: #ffffff;
      margin-top: 20px;

      .text-title {
        width: 692px;
      }
    }

    .container-text .title {
      /* Heading/H3 - Bold */
      text-align: start;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      justify-content: start;

      color: #ffffff;
    }
  }
  .title {
    /* Heading/H3 - SemiBold */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    /* Color/Neutral 100 */

    color: #222833;
  }

  .accordion {
    .accordion-item {
      background-color: transparent !important;
      border: none !important;
    }

    .pbvki .accordion .accordion-item {
      background-color: transparent !important;
      border: none !important;
    }

    .accordion-item {
      background-color: transparent !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    }
    .accordion-button:focus {
      z-index: 3;
      border-color: none !important;
      outline: 0;
      box-shadow: none !important;
    }

    .accordion-button {
      background-color: transparent !important;
    }
    .accordion-button:not(.collapsed) {
      color: #222833 !important;
      background-color: transparent !important;
      box-shadow: none !important;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      /* or 150% */

      display: flex;
      align-items: center;
    }

    .accordion-item:last-of-type .accordion-button.collapsed {
      color: #222833 !important;
      background-color: transparent !important;
      box-shadow: none !important;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      /* or 150% */
    }

    .accordion-button {
      background-color: transparent !important;

      background-color: transparent !important;
      color: #222833 !important;
      background-color: transparent !important;
      box-shadow: none !important;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }
  }
`;
