import styled from "styled-components";

const BannerDetailArticle = ({
  url = "https://images.unsplash.com/photo-1616299281110-0c6920346e7f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
}) => {
  return (
    <Container>
      <img src={url} alt={url} />
    </Container>
  );
};

export default BannerDetailArticle;

const Container = styled.div`
  img {
    height: 900px;
    width: 100%;
    object-fit: cover;
  }
`;
