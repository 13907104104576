import styled from "styled-components";
import NavTabsInterest from "./nav_tabs_interest";
import NavTabsInterestItem from "./nav_tabs_interest_item";
import SelectedFilterItem from "./selected_filter_item";
import ProductOOH from "./placement_ooh";
import ProductSection from "./product";
import PlacementMTR from "./placement_mtr";

const Interest = ({
  data,
  btnHandleChannel,
  btnHandleInventory,
  selected,
  btnDeletedFilter,
  btnSelectCategeory,
  selectedCat,
  btnHandlePlacement,
}) => {
  const { channel, inventory, product, placement, category } = data;

  const ViewFilter = () => {
    console.log("hahaha", selected);
    switch (selected?.channel[0]?.shorten) {
      case "OOH":
      case "TV":
      case "RDO":
        return (
          <div>
            {selected?.placement?.inventory_name ? (
              <div className="container-placement-ooh">
                <ProductOOH
                  data={placement}
                  category={category}
                  btnSelectCategeory={btnSelectCategeory}
                  selected={selectedCat}
                  selected_placement={selected}
                  btnHandlePlacement={btnHandlePlacement}
                />
              </div>
            ) : null}

            {selected?.placement_row2?.length > 0 ? (
              <div className="container-selected-filters">
                <div className="title-selected-filter">Selected Filters</div>
                <div className="list-selected-filter">
                  {selected.placement_row2?.map((item, idx) => (
                    <SelectedFilterItem
                      title={`${selected?.channel[0]?.shorten} - ${item.placement_name}`}
                      onClickProp={() => btnDeletedFilter(item)}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        );

      default:
        if (selected.inventory.length > 0) {
          return (
            <div className="container-selected-filters">
              <div className="title-selected-filter">Selected Filters</div>
              <div className="list-selected-filter">
                {selected.inventory?.map((item, idx) => (
                  <SelectedFilterItem
                    title={`${selected?.channel[0]?.shorten} - ${item.inventory_name}`}
                    onClickProp={() => btnDeletedFilter(item)}
                  />
                ))}
              </div>
            </div>
          );
        } else {
          return <div></div>;
        }
        break;
    }
  };

  return (
    <Container>
      <div className="title-interest">
        Select Your Interests on Our Ad Channels
      </div>

      <div className="container-interest">
        <NavTabsInterest
          data={channel}
          dataInventory={inventory}
          btnHandleChannel={btnHandleChannel}
          btnHandleInventory={btnHandleInventory}
          selected={selected}
        />
      </div>

      <ViewFilter />

      <div className="container-product">
        <ProductSection data={product} />
      </div>
    </Container>
  );
};

export default Interest;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .container-product {
    margin-top: 116px;
    width: 100%;
  }

  .container-placement-ooh {
    margin-top: 40px;
    width: 100%;
  }

  .container-selected-filters {
    margin-top: 86px;
    text-align: left;
    width: 100%;

    .list-selected-filter {
      display: flex;
      gap: 18px;
      align-items: center;
      flex-wrap: wrap;
    }

    .title-selected-filter {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      /* or 140% */
      letter-spacing: -0.017em;
      text-align: left;

      color: #161c2b;
      margin-bottom: 24px;
    }
  }

  .container-interest {
    margin-top: 42px;
    width: 100%;
  }

  .title-interest {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    /* or 130% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.1px;

    color: rgba(22, 28, 43, 0.96);
  }
`;
