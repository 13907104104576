import styled from "styled-components";
import ImgCurve from "../../../../assets/iconAndLogo/img_line_curve_banner_ads.png";

const BannerAds = () => {
  return (
    <Container>
      <div id="overlay-banner-ads" />
      <div className="container-overlay-banner-ads">
        <div className="title-banner-ads">
          Lorem ipsum dolor sit amet <br /> consectetur. Fermentum <br /> fusce
          aliquam.
        </div>
        <div id="line-curve" />
        <div className="sub-title-banner-ads">
          Lorem ipsum dolor sit amet consectetur. A ac velit nulla <br /> neque
          gravida eu mauris integer.
        </div>
        <button className="btn btn-banner-ads">Lorem Ipsum Dolor</button>
      </div>
    </Container>
  );
};

export default BannerAds;

const Container = styled.div`
  width: 100%;
  height: 716px;
  background: url("https://images.unsplash.com/photo-1675432980667-95da207814a5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  /* z-index: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;

  #overlay-banner-ads {
    width: 100%;
    height: 716px;
    background-color: rgba(59, 62, 152, 0.85);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }

  .container-overlay-banner-ads {
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .btn-banner-ads {
      /* Auto layout */

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      gap: 24px;

      width: 220px;
      height: 60px;

      /* Neutral/10

White
*/
      background: #ffffff;
      /* Brand Colors/Primary/100

3B3E98
*/
      border: 1px solid #3b3e98;
      border-radius: 8px;
      /* Text L/Semi Bold */

      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;
      letter-spacing: -0.011em;

      /* Brand Colors/Primary/100 */

      color: #3b3e98;
    }

    .title-banner-ads {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 900;
      font-size: 56px;
      line-height: 64px;
      text-align: center;
      margin-bottom: 28px;
    }

    #line-curve {
      width: 100%;
      height: 9px;
      background-image: url(${ImgCurve});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      justify-content: center;
      margin-bottom: 66px;
    }
    .sub-title-banner-ads {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.017em;
      margin-bottom: 28px;
    }
  }
`;
