import React, { useState, useEffect } from "react";
import { Modal_Component, AlertSuccess, AlertError } from "component";
import {
  COLOR_SECONDARY,
  ButtonPrimary,
} from "component/style/content/default";
import FormatSelection from "component/select/FormatJawaban";
import WajinSelection from "component/select/Wajib";
import api from "service/api/Event/microsite";

const DetailFormCreate = ({
  show,
  onHide,
  params,
  getForm,
  setDataDetail,
  setMicrosite_form_id,
}) => {
  const { microsite_id, microsite_form_id, data } = params;
  const [selected, setSelected] = useState({
    wajib: "",
    format: "",
  });
  const [answer, setAnswer] = useState([{ value: "" }]);
  const [pertanyaan, setPertanyaan] = useState("");
  const [loading, setLoading] = useState(false);

  const btnOnChangeSelected = (e, name) => {
    setSelected((state) => ({
      ...state,
      [name]: e,
    }));
  };

  const clearValueState = () => {
    setAnswer({ value: "" });
    setSelected({
      wajib: "",
      format: "",
    });
    setPertanyaan("");
    setDataDetail({});
    setMicrosite_form_id(null);
  };

  const onChangeAnswer = (e, idxProps) => {
    const value = e.target.value;

    const newState = answer.map((obj, idx) => {
      // 👇️ if id equals 2, update country property
      if (idx === idxProps) {
        return { ...obj, value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setAnswer(newState);
  };

  const submitForm = async () => {
    setLoading(true);
    const body = {
      microsite_form_question: pertanyaan,
      microsite_form_question_required_status: selected["wajib"].value,
      microsite_form_answer_selection: answer.map((item) => item.value),
      microsite_form_answer_format: selected["format"].value,
    };

    if (microsite_form_id) {
      await api
        .putFormMicrosite(body, microsite_form_id)
        .then((res) => {
          if (res.success) {
            AlertSuccess({ title: "SUCCESS", text: res.success });
            onHide();
            clearValueState();
            getForm();
          } else {
            AlertError({ title: "ERROR", text: res.error });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await api
        .postFormMicrosite(body, microsite_id)
        .then((res) => {
          if (res.success) {
            AlertSuccess({ title: "SUCCESS", text: res.success });
            onHide();
            clearValueState();
            getForm();
          } else {
            AlertError({ title: "ERROR", text: res.error });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const checkFormat = (format) => {
    let result;
    if (format === "RADIOBUTTON") {
      result = "radio button";
    } else if (format === "SELECT") {
      result = "select with options";
    } else if (format === "TEXT") {
      result = "text";
    } else if (format === "EMAIL") {
      result = "email";
    } else if (format === "DATE") {
      result = "date (YYYY-MM-DD)";
    } else if (format === "DATETIME") {
      result = "datetime (YYYY-MM-DD HH:ii)";
    } else if (format === "CHECKBOX") {
      result = "checkbox";
    }

    return result;
  };

  const loadData = () => {
    setPertanyaan(data?.microsite_form_question);
    setSelected({
      wajib: {
        value: data?.microsite_form_question_required_status,
        label:
          data?.microsite_form_question_required_status === 0
            ? "Optional"
            : "Wajib",
      },
      format: {
        value: data?.microsite_form_answer_format,
        label: checkFormat(data?.microsite_form_answer_format),
      },
    });
    setAnswer(
      data?.microsite_form_answer_selection
        ? JSON.parse(data?.microsite_form_answer_selection)?.map((item) => ({
          value: item,
        }))
        : ["", "", ""]
    );
  };

  useEffect(() => {
    if (show) {
      loadData();
    }
  }, [show]);

  const onCloseHandler = () => {
    clearValueState();
    onHide();
    setDataDetail({});
    setMicrosite_form_id(null);
  };
  return (
    <Modal_Component
      show={show}
      onHide={onCloseHandler}
      title="Form Microsite"
      btnSubmit
      btnName={loading ? "Loading" : "Submit"}
      onClick={loading ? null : submitForm}
    >
      <div className="card p-3">
        <div className="mb-3">
          <label style={{ color: COLOR_SECONDARY }}>Buat Pertanyaan</label>
          <input
            type="text"
            className="form-control"
            value={pertanyaan}
            onChange={(e) => setPertanyaan(e.target.value)}
          />
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label style={{ color: COLOR_SECONDARY }}>Wajib</label>
              <WajinSelection
                value={selected["wajib"]}
                onChange={(e) => btnOnChangeSelected(e, "wajib")}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label style={{ color: COLOR_SECONDARY }}>Format Jawaban</label>
              <FormatSelection
                value={selected["format"]}
                onChange={(e) => btnOnChangeSelected(e, "format")}
              />
            </div>
          </div>
        </div>

        {["SELECT", "RADIOBUTTON", "CHECKBOX"].includes(
          selected["format"].value
        ) ? (
          <React.Fragment>
            {answer.length > 0 &&
              answer.map((item, idx) => (
                <div className="row">
                  <div className="col-10">
                    <input
                      type="text"
                      placeholder="Masukkan Pilihan Jawaban"
                      className="form-control mb-3"
                      value={item.value}
                      onChange={(e) => onChangeAnswer(e, idx)}
                    />
                  </div>
                  <div className="col-2">
                    <button className="btn border">Hapus</button>
                  </div>
                </div>
              ))}

            <div className="wrap-btn mt-5">
              <ButtonPrimary onClick={() => setAnswer([...answer, ""])}>
                Tambah Pilihan
              </ButtonPrimary>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </Modal_Component>
  );
};

export default DetailFormCreate;
