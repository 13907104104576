import React from "react";
import styled from "styled-components";
import ImageCard from "landingPage/assets/iconAndLogo/BgGroup38295.png";

const CardSolutions = ({
  image = ImageCard,
  title = "Retail",
  desc = "Business Owners can promote their product or brand digitally",
}) => {
  return (
    <Container>
      <div className="wrap-image">
        <img
          src={image}
          alt={image}
          style={{ width: "200px", height: "200px", objectFit: "contain" }}
        />
      </div>

      <div className="wrap-text">
        <div className="title">{title}</div>
        <div className="desc-solutions">{desc}</div>
      </div>

      <div className="wrap-button">
        <a href={"/home/solutions" + `?name=${title}`}>
          <button className="see-detail">See Detail</button>
        </a>
      </div>
    </Container>
  );
};

export default CardSolutions;

const Container = styled.div`
  .wrap-button {
    .see-detail {
      background: #3b3e98;
      border-radius: 20px 0px 20px 20px;
      width: 162px;
      height: 50px;
      overflow: hidden;
      border: thin solid #3b3e98;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      display: flex;
      align-items: center;
      justify-content: center;

      color: #ffffff;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .wrap-text {
    .desc-solutions {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      margin-top: 21px;

      /* Dark Light */

      color: rgba(12, 27, 77, 0.6);
    }
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;

      /* Dark/Default - 2 */

      color: #374355;
    }

    padding: 23px;
  }

  .wrap-image {
    height: 230px;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  background: #ffffff;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  width: 329px;
  height: 465px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  overflow: hidden;
`;
