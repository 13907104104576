import styled from "styled-components";
import ImageBannerBenefit from "../../../../assets/iconAndLogo/img_banner_benefit.png";

const headerNavtabs = [
  {
    title: "Messaging",
    icon: "",
    key: "messaging",
  },
  {
    title: "OOH (Out Of Home)",
    icon: "",
    key: "ooh",
  },
  {
    title: "Television",
    icon: "",
    key: "tv",
  },
  {
    title: "Radio",
    icon: "",
    key: "radio",
  },
  {
    title: "KOL",
    icon: "",
    key: "kol",
  },
];

const OurChannel = () => {
  return (
    <Container>
      <div className="title-our-channel">OUR VALUE</div>
      <div className="big-title-our-channel">Lorem ipsum dolor sit amet</div>
      <div className="sub-title-our-channel">
        Lorem ipsum dolor sit amet consectetur. Neque at auctor pellentesque id
        purus <br /> maecenas odio faucibus. Sagittis dictum molestie turpis
        dictum diam sit placerat.
      </div>

      <div id="container-navtabs">
        <ul className="nav nav-tabs" id="tabOurChannel" role="tablist">
          {headerNavtabs.map((item, idx) => (
            <li className="nav-item" role="presentation" key={idx}>
              <button
                className={`nav-link ${
                  idx === 0
                    ? "first-nav-link"
                    : headerNavtabs.length - 1 === idx
                    ? "last-nav-link"
                    : ""
                } ${idx === 0 ? "active" : ""}`}
                id={`${item.key}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#${item.key}`}
                type="button"
                role="tab"
                aria-controls={`${item.key}`}
                aria-selected="true"
              >
                {item.title}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="tabOurChannelContent">
          <div
            className="tab-pane fade show active"
            id="messaging"
            role="tabpanel"
            aria-labelledby="messaging-tab"
          >
            <ContainerMessaging className="container-messaging">
              <div className="container-text">
                <div className="blue-text">MESSAGING</div>
                <div className="title-messaging">
                  Lorem ipsum dolor sit amet
                </div>
                <div className="sub-title-messaging">
                  Lorem ipsum dolor sit amet consectetur. Neque at auctor
                  pellentesque id <br /> purus maecenas odio faucibus. Sagittis
                  dictum molestie turpis dictum diam <br /> sit placerat.
                </div>
                <button className="btn btn-see-details-messaging">
                  See Details
                </button>
              </div>
              <div className="container-image">
                <img
                  src="https://images.unsplash.com/photo-1607622017669-2b0fe5eb878b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1970&q=80"
                  alt="https://images.unsplash.com/photo-1607622017669-2b0fe5eb878b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1970&q=80"
                />
              </div>
            </ContainerMessaging>
          </div>
        </div>
      </div>

      <ContainerBenefit className="container-benefit">
        <div className="container-image">
          <img src={ImageBannerBenefit} alt={ImageBannerBenefit} />
        </div>
        <div className="container-text">
          <div className="blue-text">YOUR BENEFIT</div>
          <div className="title-messaging">Lorem ipsum dolor sit amet</div>
          <div className="sub-title-messaging">
            Lorem ipsum dolor sit amet consectetur. Neque at auctor pellentesque
            id <br /> purus maecenas odio faucibus. Sagittis dictum molestie
            turpis dictum diam <br /> sit placerat.
          </div>
          <button className="btn btn-see-detail">
            Lorem ipsum dolor sit amet consectetur -->
          </button>
        </div>
      </ContainerBenefit>

      <ContainerListCard className="container-list-card">
        {[1, 1, 1, 1].map((item, idx) => (
          <div className="container-card" key={idx}>
            <div className="container-card-img">
              <img
                src="https://images.unsplash.com/photo-1556477631-78689fffc8f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1978&q=80"
                alt="https://images.unsplash.com/photo-1556477631-78689fffc8f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1978&q=80"
              />
            </div>
            <div className="container-card-text">
              <div className="title-card">
                Lorem ipsum dolor sit amet consectetur. Eget senectus sed
                egestas sit semper neque.
              </div>
              <div className="sub-title-card">
                Lorem ipsum dolor sit amet consectetur. Ut nibh id diam vitae
                urna iaculis. Commodo cursus iaculis sed mattis placerat.
                Vehicula et laoreet amet mauris.
              </div>
            </div>
          </div>
        ))}
      </ContainerListCard>
    </Container>
  );
};

export default OurChannel;

const ContainerListCard = styled.div`
  margin-top: 40px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(548px, 1fr));
  grid-row-gap: 64px;
  .container-card {
    display: flex;
    gap: 24px;
    .container-card-text {
      .title-card {
        /* Text L/Semi Bold */

        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        letter-spacing: -0.011em;

        /* Neutral/100 */

        color: #222833;
        margin-bottom: 12px;
      }
      .sub-title-card {
        /* Text M/Regular */

        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        letter-spacing: -0.006em;

        /* Neutral/80 */

        color: #6d7078;
      }
    }

    .container-card-img {
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
    }
  }
`;

const ContainerBenefit = styled.div`
  display: flex;
  align-items: center;
  margin-top: 180px;
  .container-image {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 540px;
      height: 290px;
      object-fit: contain;
    }
  }
  .container-text {
    flex-grow: 1;

    .blue-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      letter-spacing: 5px;
      text-transform: uppercase;

      /* Brand Colors/Tertiary/100 */

      color: #2fbeee;
    }

    .title-messaging {
      /* Heading/L */

      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      /* identical to box height, or 122% */

      letter-spacing: -0.022em;

      /* Neutral/100 */

      color: #222833;
      margin-top: 12px;
    }
    .sub-title-messaging {
      /* Text L/Regular */

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      letter-spacing: -0.011em;

      /* Neutral/80 */

      color: #6d7078;
      margin-bottom: 42px;
      margin-top: 12px;
    }
    .btn-see-detail {
      /* Text L/Semi Bold */

      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      letter-spacing: -0.011em;

      /* Brand Colors/Primary/100 */

      color: #3b3e98;
      padding: 0;
    }
  }
`;

const ContainerMessaging = styled.div`
  display: flex;
  align-items: center;

  .container-text {
    flex-grow: 1;
  }

  .blue-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    letter-spacing: 5px;
    text-transform: uppercase;

    /* Brand Colors/Tertiary/100 */

    color: #2fbeee;
  }

  .title-messaging {
    /* Heading/L */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    /* identical to box height, or 122% */

    letter-spacing: -0.022em;

    /* Neutral/100 */

    color: #222833;
    margin-top: 12px;
  }
  .sub-title-messaging {
    /* Text L/Regular */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: -0.011em;

    /* Neutral/80 */

    color: #6d7078;
    margin-bottom: 42px;
    margin-top: 12px;
  }

  .btn-see-details-messaging {
    width: 160px;
    height: 45px;

    /* Brand Colors/Primary/100

3B3E98
*/
    background: #3b3e98;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Neutral/10 */

    color: #ffffff;
  }
  .container-image {
    img {
      width: 373px;
      height: 371px;
      object-fit: cover;
    }
  }
`;

const Container = styled.div`
  padding-top: 120px;
  .title-our-channel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;

    /* Brand Colors/Tertiary/100 */

    color: #2fbeee;
    margin-bottom: 12px;
  }

  .big-title-our-channel {
    /* Heading/L */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    /* identical to box height, or 122% */

    text-align: center;
    letter-spacing: -0.022em;

    /* Neutral/100 */

    color: #222833;

    margin-bottom: 12px;
  }

  .sub-title-our-channel {
    /* Text L/Regular */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    letter-spacing: -0.011em;

    /* Neutral/80 */

    color: #6d7078;
  }

  #container-navtabs {
    margin-top: 36px;
    .nav {
      justify-content: center;
      gap: 2px;
    }
    .nav-link {
      width: 150px;
      background-color: rgb(59, 62, 152, 0.2);
      /* Text L/Semi Bold */

      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      /* identical to box height, or 150% */

      letter-spacing: -0.011em;
      border-radius: 0;

      /* Brand Colors/Primary/100 */

      color: #3b3e98;
      height: 60px;
    }
    .first-nav-link {
      border-top-left-radius: 10px;
    }
    .last-nav-link {
      border-top-right-radius: 10px;
    }
    .nav-link.active {
      background: #3b3e98;
      border-bottom: none;
      color: white;
    }
    .nav-tabs {
      border-bottom: 0px;
    }

    .tab-content {
      width: 100%;
      height: 487px;
      background-color: #f5f6fb;
      padding: 58px 108px;
    }
  }
`;
