import Navbar from "landingPage/components/navbar_v2";
import styled from "styled-components";
import BannerAds from "./components/bannerAds";
import OurValueAds from "./components/our_value";
import OurChannel from "./components/our_channel";
import ManageService from "./components/manage_service";
import SelfService from "./components/self_service";
import WhyChooseUs from "./components/why_choose_us";
import OurClient from "./components/our_client";
import OurTesti from "./components/our_testi";
import SellNow from "./components/sell_now";
import Footer from "../../../components/footer_v2";

const AdvertiserPage = () => {
  return (
    <div>
      <Navbar />
      <BannerAds />
      <Container>
        <OurValueAds />
        <OurChannel />
        <ManageService />
        <SelfService />
      </Container>
      <WhyChooseUs />
      <Container>
        <OurClient />
      </Container>
      <OurTesti />
      <Container>
        <SellNow />
      </Container>
      <Footer />
    </div>
  );
};

export default AdvertiserPage;

const Container = styled.div`
  padding: 0 150px;

  .container-interest {
    margin-top: 80px;
  }

  .container-carousel {
    margin-top: 42px;
  }

  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding: 0 20px;
    overflow-x: hidden;
  }
`;
