import React from "react";
import styled from "styled-components";

const PressRealeseCard = () => {
  return (
    <Container>
      <img
        src="https://www.trustedreviews.com/wp-content/uploads/sites/54/2021/09/iphone-13-9-scaled.jpeg"
        alt=""
      />
      <div className="wrap-text">
        <div className="text-date">App | April 26, 2022</div>
        <div className="title-text">
          Lorem ipsum dolor sit amet, consectetur
        </div>
        <div className="desc">Lorem ipsum dolor sit amet consectetur</div>
        <div className="btn btn-readMore">Read More</div>
      </div>
    </Container>
  );
};

export default PressRealeseCard;

const Container = styled.div`
  width: 350px;
  height: auto;
  overflow: hidden;

  .wrap-text {
    .btn-readMore {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
      /* or 156% */

      /* Brand Color/Primary */

      color: #3b3e98;
    }
    .desc {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* or 156% */

      /* Color/Neutral 80 */

      color: #6d7078;
    }
    .text-date {
      margin-bottom: 32px;
    }
    .title-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;

      /* Color/Neutral 90 */

      color: #535861;
      margin-bottom: 24px;
    }
  }

  img {
    margin-bottom: 32px;
    width: 100%;
    height: 378px;
    object-fit: cover;
  }
`;
