import React, { useEffect } from "react";
import { Modal_Component as ModalComponent, TableData } from "component"
import { ApiCustomer as api } from "../../../../service/api";

const KonversiResult = ({
  show,
  onHide,
  param,
  btnShowModalCampaignDetail,
  listFilter,
  selected,
  setRecipientId
}) => {
  const {
    ResultKonversi: {
      data = [],
    }
  } = param;


  const header = [
    'Nama Customer',
    'MSISDN',
    'Jenis Kelamin',
    'Agama',
    'Email'
  ]


  const convert = () => {


    const getAge = listFilter.find((find) => find.field === "age") ?? {};
    const getReligion =
      listFilter
        .filter((find) => find.field === "religion")
        .map((item) => item.value)
        ?.join("|") ?? {};
    const getGender =
      listFilter
        .filter((find) => find.field === "gender")
        .map((item) => item.value === "male" ? "m" : "f")
        ?.join("|") ?? {};
    const funcGetReligion =
      getReligion !== ""
        ? {
          field_name: "religion",
          field_operator: "~",
          field_value: getReligion,
        }
        : delete {};
    const funcGetGender =
      getGender !== ""
        ? {
          field_name: "gender",
          field_operator: "~",
          field_value: getGender,
        }
        : delete {};
    const funcAge1 = getAge?.value
      ? {
        field_name: "age",
        field_operator: ">",
        field_value: getAge?.value[0],
      }
      : delete {};
    const funcAge2 = getAge?.value
      ? {
        field_name: "age",
        field_operator: "<",
        field_value: getAge?.value[1],
      }
      : delete {};
    let resultQuery = [funcGetGender, funcGetReligion, funcAge1, funcAge2];

    const body = {
      company_id: selected["company"]?.value,
      ms_inventory_id: selected["inventory"]?.value,
      select: ["name", "msisdn", "gender", "religion", "birth_date"],
      filter: {
        query: resultQuery.filter((filter) => filter !== true),
        page: 1,
        limit: 15
      },
      preview_status: false
    }

    api.postConvert(body)
      .then(response => {
        // console.log({ response_convert: response })
        if (response?.success) {
          setRecipientId(response?.data?.recipient_id)
        }

      })
  }



  const bntKonversi = () => {
    onHide()
    btnShowModalCampaignDetail()
    convert()
  }
  return (
    <ModalComponent
      title='Result Konversi'
      show={show}
      onHide={onHide}
      btnSubmit
      btnName="Konversi"
      onClick={bntKonversi}
    >
      <div className="row bg-white">
        <div className="col-12">
          <TableData header={header}>
            {
              data?.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td data-label={header[0]}>
                      {item.name}
                    </td>
                    <td data-label={header[1]}>
                      {item.msisdn}
                    </td>
                    <td data-label={header[2]}>
                      {item.gender == 'm' ? "Laki-Laki" : "Perempuan"}
                    </td>
                    <td data-label={header[3]}>
                      {item.religion}
                    </td>
                    <td data-label={header[4]}>
                      {item.email}
                    </td>
                  </tr>
                )
              })
            }
          </TableData>
        </div>
      </div>
    </ModalComponent>
  )
}


export default KonversiResult;