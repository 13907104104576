import { useState, useEffect } from "react";
import Select from "react-select";
import { Method as Axios } from "../../service";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const SelectTicket = ({
  placeholder = "Select Ticket",
  onChange = () => null,
  value,
  event_id = "",
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await Axios.get(`event/${event_id}`);

      if (data.success) {
        setData(
          data?.data?.seat?.seat_detail?.map((item) => ({
            value: item,
            label: item.seat_detail_name,
          }))
        );
      }
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getData();
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [event_id]);

  let newData =
    data?.length > 0
      ? [
          {
            value: "",
            label: "Pilih semua ticket",
          },
          ...data,
        ]
      : [];

  console.log({ location });

  return (
    <Select
      placeholder={placeholder}
      options={newData.filter((filter) =>
        location?.pathname == "/dashboard/event/report/attendees"
          ? filter.value !== ""
          : filter
      )}
      isClearable
      isLoading={loading}
      // onInputChange={handleInputChange}
      onChange={onChange}
      value={value}
    />
  );
};

export default SelectTicket;
