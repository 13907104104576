import React, { useContext, useEffect, useState } from "react";
import * as Components from "../../../../component";
import * as Style from "../../../../component/style/content/default";
import * as Services from "../../../../service";
import { Method } from "../../../../service";
import { CompanyInactive, Header } from "./components";

const api = Services.api.ApiRetail.toko;

export default function LoyaltyConfiguration() {
  const { selected_company } = useContext(Services.Context);
  const [form, setForm] = useState({
    cid: '',
    sid: '',
    active: false
  })
  const [loading, setLoading] = useState(false)
  const [allow, setAllow] = useState(false)

  const getConfig = async () => {
    await Method.get(`loyalty/conf/company/${selected_company.value}`)
    .then(async (res) => {
      console.log("res loyalty", res)
      if (res?.data?.status) {
        setForm({
          cid: res.data.data.cid,
          sid: res.data.data.sid,
          active: res.data.data.active === 1 ? true : false 
        })
        setAllow(true)
      }else{
        if(res?.data?.message === "restricted"){
          setAllow(false)
        }
      }
    })
  }

  const submit = async () => {
    setLoading(true);

    try {
      const data_post = {
        cid: form.cid,
        sid: form.sid,
        active: form.active === true ? "1" : "0"
      }

      await Method.post(`loyalty/conf/company/${selected_company.value}/set`, data_post).then(async (res) => {
        if (res?.data?.status) {
          await Promise.all([
            Components.AlertSuccess({ title: "Success", text: res?.data?.message })
          ]);
        } else {
          Components.AlertError({ title: "Error", text: res?.data?.message });
        }
      })
    } catch (err) {
      Components.AlertError({ title: "Error", text: err });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selected_company.value) {
      setForm({
        cid: "",
        sid: "",
        active: false 
      })

      // get config detail
      getConfig()
    }
  }, [selected_company.value]);

  if(!allow){
    return(
      <Style.ContainerContent>
        <Header title="Pengaturan"/>
        <CompanyInactive/>
      </Style.ContainerContent>  
    )
  }

  return (
    <Style.ContainerContent>
      <Header title="Pengaturan"/>

      <div className="mt-5 row">
        <div className="col-md-6">
          <div className="mb-3">
            <Style.Label color={Style.COLOR_SECONDARY}>Client ID</Style.Label>
            <input type="text" className="form-control" placeholder="Masukkan CID" value={form.cid} onChange={e => setForm({ ...form, cid: e.target.value })} disabled />
          </div>
          <div className="mb-3">
            <Style.Label color={Style.COLOR_SECONDARY}>Server ID</Style.Label>
            <input type="text" className="form-control" placeholder="Masukkan SID" value={form.sid} onChange={e => setForm({ ...form, sid: e.target.value })} disabled />
          </div>
          <div className="mb-3">
            <input
              className="form-check-input me-2"
              type="checkbox"
              id="daysEveryDay"
              onChange={e => setForm({ ...form, active: e.target.checked })}
              checked={form.active}
              disabled={loading ? true : false}
            />
            <Style.Label color={Style.COLOR_SECONDARY}>Aktif</Style.Label>
          </div>
          <Style.ButtonPrimary
            onClick={() => submit()}
            disabled={loading ? true : false}
          >
            Simpan
          </Style.ButtonPrimary>
        </div>
      </div>
    </Style.ContainerContent>
  );
}