import React from "react";
import styled from "styled-components";
import CardPressRealese from "./pressRealese.card";

const CardBusiness = () => {
  return (
    <div className="card-business">
      <div className="left-card-business">
        <img
          src="https://www.trustedreviews.com/wp-content/uploads/sites/54/2021/09/iphone-13-9-scaled.jpeg"
          alt=""
        />
      </div>
      <div className="right-card-business">
        <div className="title-card-business">Business | April 25, 2022</div>
        <div className="desc-card-business">
          Lorem ipsum dolor sit amet, consectetur
        </div>
      </div>
    </div>
  );
};

const OurLatesPost = () => {
  return (
    <Container>
      <div className="top">
        <div className="title-text">Our Latest Posts</div>
      </div>
      <div className="bottom">
        <div className="left d-flex" style={{ gap: "28px" }}>
          {[2, 2].map((item, idx) => (
            <CardPressRealese key={idx} />
          ))}
        </div>
        <div className="right">
          {[2, 2, 2, 2].map((item, idx) => (
            <CardBusiness key={idx} />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default OurLatesPost;

const Container = styled.div`
  .bottom {
    display: flex;
    .left {
      flex: 1;
    }
    .right {
      width: 378px;

      .card-business {
        display: flex;
        gap: 16px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: thin solid #e4e4e6;
        .right-card-business {
          .desc-card-business {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            /* or 162% */

            /* Color/Neutral 100 */

            color: #222833;
          }
          .title-card-business {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            /* identical to box height, or 162% */

            display: flex;
            align-items: center;

            /* Brand Color/Primary */

            color: #3b3e98;
          }
        }
        .left-card-business img {
          width: 110px;
          height: 80px;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }
  .top {
    .title-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      /* identical to box height */

      /* Brand Color/Primary */

      color: #3b3e98;
      margin-bottom: 50px;
      text-align: center;
    }
  }
`;
