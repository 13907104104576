import React, { useEffect } from "react";
import styled from "styled-components";
import SocialFacebook from "landingPage/assets/iconAndLogo/social-facebook.png";
import Sociallinkedin from "landingPage/assets/iconAndLogo/social-linkedin.png";
import SocialTwitter from "landingPage/assets/iconAndLogo/social-twitter.png";
import SocialInstagram from "landingPage/assets/iconAndLogo/social-instagram.png";
import Maps from "./maps";
import { Mobile_query_v2 } from "../../../util/Query"

const listSocial = [
  SocialFacebook,
  Sociallinkedin,
  SocialTwitter,
  SocialInstagram,
];

const Info = () => {
  return (
    <div style={{ position: 'relative', display: "flex", flexDirection: "column", width: '100%', height: 'auto' }}>
      <Container style={{ zIndex: '2' }}>
        <div className="container-form-text" style={{ marginTop: "100px", gap: "100px", display: "flex" }}>
          <div style={{ flex: 1 }}>
            <WrapLetsTalk className="wrap-lets-talk">
              <div className="title">Lets Talk</div>
              <div className="desc">
                Ask how we can help you. Please use this form to contact us and we
                will get back to you as soon as possible. We would be happy to
                answer your question.
              </div>
            </WrapLetsTalk>

            <WrapOurOffice className="wrap-our-office">
              <div className="title">Our Office</div>
              <div className="desc">
                Centennial Tower, 38’th floor, Unit H Jl. Gatot Subroto, kavling
                24-25, Jakarta Selatan, 12930, Indonesia
              </div>
            </WrapOurOffice>

            <WrapSosmed className="wrap-sosmed d-flex gap-3">
              {listSocial.map((item, idx) => (
                <div key={idx} className="wrap-image-sosmed">
                  <img src={item} alt={item} />
                </div>
              ))}
            </WrapSosmed>
          </div>
          <div style={{ flex: 1 }}>
            <WrapFormSignUp className="wrap-form-sign-up">
              <div className="mb-3">
                <label htmlFor="fullname" className=" mb-2" style={{ fontWeight: "700" }}>Enter Your Name</label>
                <input
                  type="text"
                  name="fullname"
                  className="form-control form-signup"
                  placeholder="Full Name"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className=" mb-2" style={{ fontWeight: "700" }}>Enter Your Email</label>
                <input
                  type="text"
                  name="email"
                  className="form-control form-signup"
                  placeholder="Email"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className=" mb-2" style={{ fontWeight: "700" }}>Enter Your Message</label>
                <textarea
                  type="text"
                  name="message"
                  className="form-control form-signup-textarea"
                  placeholder="Message"
                  rows={7}
                  style={{ resize: "none" }}
                />
              </div>

              <div className="mb-3">
                <button className="btn btn-signup" type="submit">
                  Sign Up
                </button>
              </div>
            </WrapFormSignUp>
          </div>
        </div>
      </Container>
      <ContainerMaps2 style={{ marginTop: '-150px', zIndex: '1' }}>
        <Maps />

      </ContainerMaps2>
    </div>
  );
};

export default Info;

const ContainerMaps2 = styled.div`
${Mobile_query_v2}{
  margin-top: -100px !important;
}
`

const Container = styled.div`
${Mobile_query_v2}{
  padding: 0 25px;
  .container-form-text{
    flex-direction:column;
  }
}
  /* min-height: 100vh; */
  padding: 0 93px;

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;

const WrapFormSignUp = styled.form`
${Mobile_query_v2}{
  width:100%;
  margin-top:35px;
  
  padding: 22px 20px;
  .form-signup{
    width:100% !important;
    height: 50px !important;
  }
  label{
    font-size:15px !important;
  }
  .btn-signup{
    width:100% !important;
    font-size:15px !important;
  }
}
  width: 550px;
  height: 651px;
  padding: 58px 50px;
  gap: 10px;

  /* Color/Neutral 10 */

  background: #ffffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  overflow: hidden;
  z-index: 2 !important;

  .btn-signup {
    width: 450px;
    height: 59px;

    /* Brand Color/Primary */

    background: #3b3e98;
    box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);

    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    border-radius: 52px;

    /* Color/Neutral 10 */

    color: #ffffff;
  }

  label {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    /* Color/Neutral 100 */

    color: #222833;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .form-signup-textarea {
    /* Color/Neutral 10 */

    background: #ffffff;
    /* Color/Neutral 40 */

    border: 1px solid #e4e4e6;
    border-radius: 9px !important;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    :focus {
      border-color: rgba(47, 190, 238, 1);
    }
  }

  .form-signup {
    width: 450px;
    height: 61px;

    /* Color/Neutral 10 */

    background: #ffffff;
    /* Color/Neutral 40 */

    border: 1px solid #e4e4e6;
    border-radius: 9px !important;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    :focus {
      border-color: rgba(47, 190, 238, 1);
    }
  }
`;

const WrapSosmed = styled.div`
  margin-top: 50px;

  .wrap-image-sosmed {
    width: 54px;
    height: 54px;
    background: #3b3e98;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const WrapOurOffice = styled.div`
  margin-top: 50px;
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;

    /* Brand Color/Primary */

    color: #3b3e98;
  }
  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */

    /* Color/Neutral 80 */

    color: #6d7078;
  }
`;

const WrapLetsTalk = styled.div`
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;

    /* Brand Color/Primary */

    color: #3b3e98;
    position: relative;
    z-index: 1;
    padding-left:20px;
    ::before {
      content: "";
      width: 81px;
      height: 52px;
      background: #fbe555;
      border-radius: 0px 0px 60px 0px;
      position: absolute;
      z-index: -1;
      left:0px;
    }
  }

  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */

    /* Color/Neutral 80 */

    color: #6d7078;
  }
`;
