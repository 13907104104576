import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Provider from "./service/context";
import "rc-slider/assets/index.css";
import { IdxDashboardRoute } from "./dashboard";
import "./fontello/css/fontello.css";
import "react-datepicker/dist/react-datepicker.css";
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={"Loading...."}>
      <Provider>
        <Router>
          <Switch>
            <IdxDashboardRoute />
          </Switch>
        </Router>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
