import Select from "react-select";
import api from "service/api/Event/microsite";
import React, { useEffect, useState } from "react";

function FormatJawabanSelection({ value, onChange }) {
  const [options, setOptions] = useState([]);

  const getData = () => {
    api.getMsFormat().then((res) => {
      if (res.success) {
        setOptions(
          res?.data?.map((item) => ({
            value: item.ms_format_identifier,
            label: item.ms_format_name,
          }))
        );
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return <Select options={options} value={value} onChange={onChange} />;
}

export default FormatJawabanSelection;
