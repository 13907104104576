import React from "react";
import styled from "styled-components";

const InputSelectProductAndSolution = ({ placeholder = "Choose ads type", options = [] }) => {
  return (
    <Container>
      <select>
        <option>{placeholder}</option>
        {
          options.map(item => (
            <option value={item}>{item}</option>
          ))
        }
      </select>
    </Container>
  );
};

export default InputSelectProductAndSolution;

const Container = styled.div`
  height: 115px;

  background: #ffffff;
  /* Primary */

  border: 2px solid #3b3e98;
  /* Input/Focus */

  box-shadow: 0px 2px 11px rgba(69, 65, 164, 0.06),
    0px 4px 10px rgba(31, 37, 89, 0.07);
  border-radius: 16px;

  select {
    height: 115px;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0px 37px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */

    /* Black 2 */

    color: #1a202c;
  }
`;
