import styled from "styled-components";
import { Mobile_query_v2 } from "../../../../util/Query";
import fbImg from "landingPage/assets/iconAndLogo/Group188.png";
import WAImg from "landingPage/assets/iconAndLogo/Group189.png";
import twitterImg from "landingPage/assets/iconAndLogo/VectorTwitter.png";
import LineImg from "landingPage/assets/iconAndLogo/VectorLine.png";

const listImg = [fbImg, WAImg, twitterImg, LineImg];

const BannerDetailCaraouselPressMedia = () => {
  return (
    <ContainerInti>
      <div className="wrapper-image">
        <img
          className="img-banner"
          src="https://images.unsplash.com/photo-1573164574572-cb89e39749b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
          alt="https://images.unsplash.com/photo-1573164574572-cb89e39749b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
        />
      </div>
      <div className="wrapper-text">
        <div className="left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod
        </div>
        <div className="right">
          <div>Published: April 25, 2022</div>
          <div>Category: Business</div>
        </div>
      </div>

      <Container className="wrapper-content">
        <div className="sub-wrapper-content">
          <div>
            <div className="text-bagikan">Bagikan</div>
            <div className="list-sosmed d-flex flex-column mt-2 gap-4">
              {listImg.map((item, idx) => (
                <div className={`sosmed-img-${idx}`} key={idx}>
                  <img src={item} alt="sosmed" />
                </div>
              ))}
            </div>
          </div>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in
            voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum
          </div>
        </div>
      </Container>
    </ContainerInti>
  );
};

export default BannerDetailCaraouselPressMedia;

const ContainerInti = styled.div`
  .wrapper-content {
    .sub-wrapper-content {
      .text-bagikan {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        /* or 30px */

        display: flex;
        align-items: center;
        letter-spacing: 0.2px;

        /* Dark/Default - 2 */

        color: #374355;
      }
      display: flex;
      gap: 86px;
      padding: 84px;
      background-color: #ffff !important;
    }
    background-color: #ffff !important;
    margin-top: -95px !important;
    display: flex;
  }
  .wrapper-text {
    display: flex;
    height: 326px;
    .left {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;

      color: #ffffff;
      ${Mobile_query_v2} {
        padding-left: 25px;
      }
      flex: 2;
      background: #3b3e98;
      padding-left: 93px;
      padding-top: 53px;
      padding-right: 62px;
    }
    .right {
      flex: 1;
      ${Mobile_query_v2} {
        padding-left: 25px;
      }
      background: #6899d1;
      color: #ffffff;
      padding-left: 42px;
      padding-top: 53px;
      padding-right: 93px;
    }
  }
  .img-banner {
    width: 100%;
    height: 719px;
    object-fit: cover;
  }
  margin-top: 57px;
`;

const Container = styled.div`
  ${Mobile_query_v2} {
    padding: 0 25px;
  }
  padding: 0 93px;

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;
