import styled from "styled-components";
const NavTabsInterestItem = ({ children, active = false }) => {
  return <Container active={active}>{children}</Container>;
};

export default NavTabsInterestItem;

const Container = styled.div`
  margin: 5px;
  cursor: default;
  min-width: 154px;
  min-height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ active }) => (active ? "#F0F2FF" : "#FFFF")};
  border: 1px solid ${({ active }) => (active ? "#3B3E98" : "#e0e0e0")};
  border-radius: 4px;

  /* Text M/Regular */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  letter-spacing: -0.006em;

  color: ${({ active }) => (active ? "#3B3E98" : "rgba(49, 53, 59, 0.68)")};
`;
