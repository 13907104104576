import { useEffect } from "react";

export const useTitle = (props) => {
  // eslint-disable-next-line
  useEffect(() => {
    const prevTitle = document.title;
    document.title = props;
    return () => {
      document.title = prevTitle;
    };
    // eslint-disable-next-line
  }, []); // eslint-disable-next-line
};
