import styled from "styled-components";
import { Mobile_query_v2 } from "../../../util/Query";

const VisiMisi = () => {
  return (
    <Container>
      <div className="wrapper">
        <div className="left">
          <div className="title">Visi</div>
          <div className="big-text">
            Brand new Presentation with <span>essentials</span>
          </div>
        </div>
        <div className="right">
          <div className="title">Misi</div>
          <div className="wrapper-list">
            {[1, 1, 1].map((item, idx) => (
              <div key={idx} className="list-text">
                A wonderful serenity has taken possession of my entire soul
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default VisiMisi;

const Container = styled.div`
  .wrapper {
    .right {
      .wrapper-list {
        margin-top: 58px;
      }
      .list-text {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
        line-height: 36px;
        /* or 103% */

        /* Black 3 */

        color: rgba(12, 27, 77, 0.6);
        margin-bottom: 40px;
      }
      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 32px;
        /* or 80% */

        color: #3b3e98;
        padding-bottom: 30px;
        position: relative;
        :before {
          content: "";
          width: 158px;
          height: 5px;
          background-color: #2fbeee;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
    .left {
      .big-text {
        span {
          background: linear-gradient(225deg, #017bff 0%, #05c8dd 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 72px;
        width: 376px;
        /* Theme/Dark/Default */

        color: #16192c;
        margin-top: 58px;
      }
      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 32px;
        /* or 80% */

        color: #3b3e98;
        padding-bottom: 30px;
        position: relative;
        :before {
          content: "";
          width: 158px;
          height: 5px;
          background-color: #2fbeee;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
    display: flex;
  }

  .wrapper > .left,
  .right {
    flex: 1;
  }
  padding-top: 161px !important;
  padding: 60px 93px;
  background-color: #ffff;

  ${Mobile_query_v2} {
    padding: 0 25px;
  }
`;
