import styled from "styled-components";
const BannerPublisher = () => {
  return (
    <Container className="container">
      <div className="container-text-banner">
        <div className="title">Publisher</div>
        <div className="sub-title w-100">
          Helps you keep and manage all the targeted customer profiles based on
          data, sales, and events comprehensively.
        </div>
        <div className="container-buttons mt-5">
          <button className="btn button-sell-now">Sell Now</button>
        </div>
      </div>
      <div className="container-img-banner d-flex align-items-end justify-content-end">
        <img
          src="https://www.mediacartz.com/static/media/ImgGroup38521.99ea85c2.png"
          alt="https://www.mediacartz.com/static/media/ImgGroup38521.99ea85c2.png"
        />
      </div>
    </Container>
  );
};

export default BannerPublisher;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 400px;

  .container-text-banner {
    .title {
      font-family: Poppins;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 46px;
      color: rgb(59, 62, 152);
    }
    .sub-title {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
      color: rgb(26, 32, 44);
      margin-top: 20px;
    }
    .button-sell-now {
      background: rgb(59, 62, 152);
      box-shadow: rgb(0 0 0 / 19%) 0px 0px 49px -2px;
      border-radius: 5px;
      font-family: Mulish;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
      border: thin solid rgb(59, 62, 152);
      color: rgb(255, 255, 255);
      width: 184px;
      height: 55px;
      overflow: hidden;
    }
  }
  .container-text-banner,
  .container-img-banner {
    flex: 1;
  }
`;
