import styled from "styled-components";
import LogoMediacartz from "../../assets/iconAndLogo/logo mediacartz resized-400-02-02 2.png";
import { ChevronDown } from "@styled-icons/evil";
import IcLogin from "../../../asset/version2/ic_login_landingpage.png";
import IcCart from "../../../asset/version2/ic_cart_landingpage.png";
import { Link, NavLink } from "react-router-dom";

const NavbarNavigation = () => {
  const totalCart = window.localStorage.getItem("cart") ? JSON.parse(window.localStorage.getItem("cart")) : []
  return (
    <Container>
      <div className="wrap_navigation w-100">
        <div className="row">
          <div className="col-auto d-flex align-items-center">
            <a href="/home">
              <img
                src={LogoMediacartz}
                alt={LogoMediacartz}
                className="logo-mediacartz"
              />
            </a>
          </div>
          <div className="col-auto d-flex flex-fill align-items-center justify-content-center">
            {navbarData.map((item, idx) => (
              <Link
                to={idx == 0 ? "/home/advertiser" : "#"}
                className="btn"
                key={idx}
              >
                {item}
                <ChevronDown width={20} />
              </Link>
            ))}
          </div>
          <div className="col-auto d-flex align-items-center row-button">
            <NavLink
              to="/login"
              className="btn btn-register-login d-flex align-items-center"
            >
              <img
                src={IcLogin}
                width={12}
                height={12}
                style={{ marginRight: "12px" }}
                alt=""
              />
              Login
            </NavLink>
            <div className="container-btn-cart position-relative" onClick={() => window.open("/home/product/detail?view_cart=true", "_parent")}>
              {
                totalCart.length > 0 ?
                  <div className="badge-cart">{totalCart.length}</div> : null
              }
              <button className="btn btn-register-register d-flex align-items-center" >
                <img
                  src={IcCart}
                  width={12}
                  height={12}
                  style={{ marginRight: "12px" }}
                  alt=""
                />
                Your Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NavbarNavigation;

const navbarData = ["Advertiser", "Publisher", "Channel", "About Us"];

const Container = styled.div`
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;

  padding: 0 50px;

  .container-interest {
    margin-top: 80px;
  }

  .container-carousel {
    margin-top: 42px;
  }

  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding: 0 20px;
    overflow-x: hidden;
  }

  .badge-cart {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    background-color: #ef144a;
    color: white;
    border-radius: 100%;
    border: 2px solid white;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    /* or 160% */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .row-button {
    gap: 12px;
  }

  .btn-register-register {
    height: 38px;
    overflow: hidden;

    /* Brand Color/Primary */

    background: #3b3e98;
    border-radius: 4px;
    /* CTA/Large - Bold */

    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    /* Color/Neutral 10 */

    color: #ffffff;
  }

  .btn-register-login {
    height: 38px;
    overflow: hidden;

    /* Brand Color/Primary */

    background: #3b3e98;
    border-radius: 4px;
    /* CTA/Large - Bold */

    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    /* Color/Neutral 10 */

    color: #ffffff;
  }
`;
