export const Mobile_query =
  "@media only screen and (min-width: 320px) and (max-width:425px)";

export const Tablet_query =
  "@media only screen and (min-width: 426px) and (max-width:768px)";

export const Mobile_query_v2 =
  "@media only screen and (min-width: 320px)   and (orientation: portrait)";


const screenSizes = {
  mobile: 468,
  tablet: 1024,
  desktop: 1366
}

export const MediaQueries = {
  mobileQueries: `(max-width: ${screenSizes.mobile}px)`,
  tabletQueries: `(min-width: ${screenSizes.mobile}px) and (max-width: ${screenSizes.tablet}px)`,
  desktopQueries: `(min-width: ${screenSizes.tablet}px)`
}
