import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { ButtonPrimary } from "../../../../../../component/style/content/default";
import Axios from "service/method";

const channel = [
  "Messaging",
  "Out Of Home",
  "Television",
  "Radio",
  "Online Media",
];

const inventory = [
  "Email Broadcast",
  "SMS Broadcast",
  "SMS LBA",
  "SMS Targeted",
  "MMS LBA",
  "USSD Targeted",
];

const ModalCreateCampaign = ({ show = false, onHide = () => null }) => {
  const [selected, setSelected] = useState({
    channel: "",
    inventory: "",
  });
  const [dataChannel, setDataChannel] = useState([]);
  const [dataInventory, setDataInventory] = useState([]);

  const getMSChannel = async () => {
    const { data: result } = await Axios.get("/list/ms_product?sort_by=ASC");

    console.log({ result });

    if (result.success) {
      setDataChannel(
        result?.data?.map((item) => ({
          ...item,
          ms_product_name:
            item.ms_product_name == "Digital Signage"
              ? "Out Of Home"
              : item.ms_product_name,
        }))
      );
    }
  };

  useEffect(() => {
    if (show) {
      getMSChannel();
    }
  }, [show]);

  const handSelectChannel = (item) => {
    console.log({ item });
    const inventory = item?.product || [];
    setDataInventory(inventory);
  };
  return (
    <Modal fullscreen show={show} onHide={onHide}>
      <Modal.Body>
        <div className="section-menu-channel container">
          <div className="row justify-content-center">
            {dataChannel.map((item, idx) => {
              return (
                <div className="col-md-2 p-1" key={idx}>
                  <ButtonSelected
                    className=" rounded-2"
                    active={
                      selected?.channel == item.ms_product_name ? true : false
                    }
                    onClick={() => {
                      setSelected((state) => ({
                        ...state,
                        channel: item.ms_product_name,
                      }));
                      handSelectChannel(item);
                    }}
                  >
                    <span>{item.ms_product_name}</span>
                  </ButtonSelected>
                </div>
              );
            })}
          </div>
        </div>
        <div className="section-menu-inv-content container">
          <div className="row">
            <div className="col-md-2">
              <div className="row">
                {dataInventory.map((item, idx) => {
                  return (
                    <div className="col-md-12 p-1" key={idx}>
                      <ButtonSelected
                        className=" rounded-2"
                        active={
                          selected?.inventory == item.product_name
                            ? true
                            : false
                        }
                        onClick={() => {
                          setSelected((state) => ({
                            ...state,
                            inventory: item.product_name,
                          }));
                        }}
                      >
                        <span>{item.product_name}</span>
                      </ButtonSelected>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12 p-1">
                    <img
                      src="https://images.unsplash.com/photo-1607082350899-7e105aa886ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="col-md-12 p-1 text-center d-flex align-content-center justify-content-center">
                    <ButtonPrimary
                      medium
                      onClick={() => {
                        window.open(
                          "/dashboard/iklans/campaigns/products/tv",
                          "__blank"
                        );
                      }}
                    >
                      View Products
                    </ButtonPrimary>
                    {/* <ButtonPrimary medium>Create Campaign</ButtonPrimary> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <h3 className="title">SMS LBA</h3>
              <div className="content">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi
                pariatur quo odit consequatur veniam voluptas? Numquam pariatur
                corrupti labore maiores, tempore nulla eum aliquam quae fugit
                harum? Facere nostrum culpa inventore, accusamus excepturi
                magnam neque repudiandae consectetur possimus fugit. Voluptates
                dolores asperiores quas perspiciatis veniam rerum omnis quae
                quidem voluptate ullam! Quia ab saepe expedita sapiente
                necessitatibus dolores omnis quisquam voluptatem dignissimos
                maxime eveniet id in deserunt praesentium et, libero eum illum
                soluta animi rerum dolorem non hic! Ratione perspiciatis quasi
                velit cumque sit quia eveniet dignissimos iste ea officia quam
                officiis libero minima, assumenda voluptatibus nam rem iure
                fugiat. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Odio asperiores veritatis, atque excepturi tenetur ipsam,
                nostrum exercitationem cum libero iste fuga temporibus iusto
                deleniti tempora. Placeat est, numquam possimus, fugit enim
                fuga, ipsum eius soluta ex pariatur veritatis. Libero doloremque
                neque qui dolorem beatae maxime eius cupiditate obcaecati
                praesentium. Veniam nobis nulla hic animi repudiandae ratione
                minus aspernatur accusantium error vero, ut temporibus a
                laboriosam, provident distinctio quidem tempora aliquid, porro
                nostrum amet deserunt? Consequatur natus aspernatur veniam est,
                enim qui delectus illo voluptates officia, sapiente, alias rem
                maxime quia quo nemo perferendis. Aperiam incidunt illo tenetur
                magni cupiditate perferendis.
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCreateCampaign;

const ButtonSelected = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${({ active }) =>
    active ? "#669dcc" : "transparent !important"};
  border: ${({ active }) =>
    active ? "1px solid #669dcc" : " 1px solid #0f0f0ff0"};
  span {
    color: ${({ active }) => (active ? "white" : "black")};
  }
`;
