import React from "react";
import styled from "styled-components";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";

import LogoKvision from "asset/logo/Logo Kvision.png";
import LogoPhoenix from "asset/logo/Logo Phoenix.png";
import LogoDiya from "asset/logo/Logo Diya.png";
import { Mobile_query_v2 } from "../../../util/Query";

const image_client = [LogoKvision, LogoPhoenix, LogoDiya];

const OurClient = () => {
  return (
    <Container className="d-flex">
      <div className="left">
        <div className="rounded-yellow" />
        <div className="title">Our Valuable Partners</div>
        <div className="desc">
          All successful growing business is built on partnership and
          friendship.
        </div>
      </div>
      <div className="right">
        <OwlCarousel
          margin={40}
          center
          autoWidth
          autoplay
          className="OwlCarousel-wrap"
        >
          {image_client.map((item, idx) => (
            <div
              className="item"
              key={idx}
              style={{ marginRight: "10px !important" }}
            >
              <img
                style={{ width: "100px", height: "70px", objectFit: "contain" }}
                src={item}
                alt={item}
              />
            </div>
          ))}
        </OwlCarousel>
      </div>
    </Container>
  );
};

export default OurClient;

const Container = styled.div`
  ${Mobile_query_v2} {
    flex-direction: column;
    .left {
      width: 100% !important;
      .rounded-yellow {
        left: 100px !important;
        top: 0;
      }
      .title,
      .desc {
        text-align: center;
      }
    }

    .right {
      width: 100% !important;

      .item {
        img {
          width: 70px !important;
        }
      }
      .OwlCarousel-wrap {
        margin-right: 0 !important;
        border: 1px solid #2fbeee !important;
        border-radius: 29px;
        width: 100% !important;
        overflow: hidden !important;

        .owl-stage-outer > .owl-stage {
          transform: translate3d(50px, 0px, 0px) !important;
        }
      }
    }
  }
  margin-top: 76px;
  .left {
    width: 30%;
    position: relative;
    z-index: 2;
    flex: 1;
    .rounded-yellow {
      width: 40px;
      height: 40px;
      background-color: #fcd45d;
      position: absolute;
      border-radius: 100px;
      z-index: -1;
      left: -10px;
      top: -10px;
    }
  }
  .right {
    flex: 1;
    /* width: 200px; */
    width: 70%;
  }

  .left > .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    color: #999999;
  }

  .left > .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Primary */

    color: #3b3e98;
  }
`;
