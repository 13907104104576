import React, { useState } from "react";
import { Modal_Component, AlertError } from "component";
import Select from "react-select";
import { SelectAgama, SelectJenisKelamin } from "component/select";
import { IdxSlider_range as SlideRange } from "component/slider_range";

const SelectField = ({ value, onChange, listFilter }) => {
  const options = [
    { value: "age", label: "age" },
    { value: "religion", label: "religion" },
    { value: "gender", label: "gender" },
  ];

  const FilterOptions = () => {
    return options.filter((item) => {
      if (listFilter.find((filter) => filter.field === "age")) {
        return (
          item.value !==
          listFilter.filter((filter) => filter.field === "age")[0].field
        );
      }
      // else if (listFilter.find((filter) => filter.field === 'gender')) {
      //   return (
      //     item.value !==
      //     listFilter.filter((filter) => filter.field === 'gender')[0].field
      //   );
      // }
      else {
        return item;
      }
    });
  };
  return (
    <Select
      options={FilterOptions()}
      value={value}
      onChange={onChange}
      placeholder="Select"
    />
  );
};
const SelectOperator = ({ value, onChange }) => {
  const options = [
    { value: "=", label: "=" },
    { value: "<", label: "<" },
    { value: "<=", label: "<=" },
    { value: ">", label: ">" },
    { value: ">=", label: ">=" },
    { value: "~", label: "~" },
  ];
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      placeholder="Select"
    />
  );
};

const KonversiFilter = ({ show, onHide, setListFilter, listFilter }) => {
  const [selected, setSelected] = useState({
    field: "",
    operator: "",
  });
  const [value, setValue] = useState("");

  const onChangeSelect = (e, name) => {
    switch (name) {
      case "field":
        if (e.value === "age") {
          setValue([20, 59]);
        } else {
          setValue("");
        }
        setSelected((state) => ({
          ...state,
          [name]: e,
        }));
        break;
      case "operator":
        setSelected((state) => ({
          ...state,
          [name]: e,
        }));
        break;

      default:
        break;
    }
  };

  const btnOnCloseHandler = () => {
    if (selected["field"] === "" || value === "") {
      AlertError({
        title: "Warning",
        text: "Harap Check kembali Inputan Anda!",
      });
    } else {
      onHide();
      setListFilter((state) => [
        ...state,
        {
          field: selected["field"].value,
          operator:
            selected["field"].value === "age"
              ? "<"
              : selected["field"].value === "religion"
                ? "~"
                : selected["field"].value === "gender"
                  ? "~"
                  : null,
          value:
            selected["field"].value === "age"
              ? value == ""
                ? [20, 59]
                : value
              : selected["field"].value === "religion"
                ? value?.label
                : selected["field"].value === "gender"
                  ? value?.label === "Laki-Laki"
                    ? "male"
                    : "female"
                  : null,
        },
      ]);

      setSelected({
        field: "",
        operator: "",
      });
      setValue("");
    }
  };

  const checkBeforeValueReligion = (filter) => {
    let newListFilter = listFilter.map((item) => item.value);

    if (newListFilter.length > 0) {
      let findNewListFilter = newListFilter.find(
        (find) => find === filter.value
      );
      if (findNewListFilter) {
        return filter.value !== findNewListFilter;
      } else {
        return filter;
      }
    } else {
      return filter;
    }
  };

  const checkBeforeValueGender = (filter) => {
    // console.log({ ini_filter: filter });

    if (listFilter.filter((filter) => filter.field === "gender").length > 0) {
      let newListFilter = listFilter.map((item) =>
        item.value === "female" ? "f" : "m"
      );

      let findNewListFilter = newListFilter.find(
        (find) => find === filter.value
      );
      if (findNewListFilter) {
        return filter.value !== findNewListFilter;
      } else {
        return filter;
      }
    } else {
      return filter;
    }
  };

  return (
    <Modal_Component
      show={show}
      onHide={onHide}
      size="sm"
      title="Filter"
      btnSubmit
      btnName="Submit"
      onClick={btnOnCloseHandler}
    >
      <div className="row">
        <div className="col-12">
          <label htmlFor="">Field</label>
          <SelectField
            value={selected["field"]}
            onChange={(e) => onChangeSelect(e, "field")}
            listFilter={listFilter}
          />
        </div>
        {selected["field"]?.value === "age" ? (
          <div className="col-12">
            <label htmlFor="">value</label>
            <SlideRange
              min={0}
              max={100}
              onChange={(value) => setValue(value)}
            />
          </div>
        ) : selected["field"].value === "religion" ? (
          <div className="col-12">
            <label htmlFor="">value</label>
            <SelectAgama
              value={value}
              onChange={(e) => setValue(e)}
              filterProps={checkBeforeValueReligion}
            />
          </div>
        ) : selected["field"].value === "gender" ? (
          <div className="col-12">
            <label htmlFor="">value</label>
            <SelectJenisKelamin
              value={value}
              onChange={(e) => setValue(e)}
              filterProps={checkBeforeValueGender}
            />
          </div>
        ) : null}
      </div>
    </Modal_Component>
  );
};

export default KonversiFilter;
