import { ButtonPrimary } from "component/style/content/default";
import React from "react";

const CardProduct = () => {
  return (
    <div className=" border">
      <img
        src="https://images.unsplash.com/photo-1607082350899-7e105aa886ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80"
        alt=""
        width="100%"
        height={200}
        style={{ objectFit: "cover" }}
      />
      <div className="container-content p-2">
        <h5 className="title-product text-center">
          Billboard Mall A OOH-Digital Outdor-BillBoard Mall A
        </h5>
        <div className="short-desc-product text-center mt-2 mb-2">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est,
          nesciunt!
        </div>
        <h5 className="text-disc-price text-center">Rp. 10.000.000</h5>
        <div
          className="text-price text-center"
          style={{ textDecoration: "line-through" }}
        >
          Rp. 20.000.000
        </div>
        <h5 className="text-disc-price text-center">Rp. 20.000.000</h5>
        <ButtonPrimary
          className="d-flex w-100 mt-lg-5"
          onClick={() =>
            (window.location.href =
              "/dashboard/iklans/campaigns/products/oohs/2")
          }
        >
          <span>Lihat Selengkapnya</span>
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default CardProduct;
