import card from "landingPage/assets/iconAndLogo/card.png";
import {
  DigitalSignage,
  TvAndRadio,
  KOL,
  POS,
  DataManagement,
  TvRadio2,
  PublicEvent,
  PrivateEvent,
  Microsite,
  Catalog,
  BankingAndFincance,
  adPublisher,
  Calendar,
  Retail,
  SmallMedium,
  Agency,
  Outsourcing,
  ApiImg,
  HowTo,
  FAQ,
  AboutUs,
  Contact,
  Careers,
  Investors,
  PressMedia,
} from "landingPage/assets/navbar";

const NavbarData = [
  {
    name: "Products",
    sub: [
      {
        header: "Advertising Placement",
        sub: [
          {
            icon: card,
            name: "Messaging",
            desc: "Telco (SMS & USSD), Whatsapp & Email",
            path: "/#",
          },
          {
            icon: DigitalSignage,
            name: "Digital Signage",
            desc: "Videotron, LED & pylone",
            path: "/#",
          },
          {
            icon: TvAndRadio,
            name: "TV and Radio",
            desc: "Commercial Break, Adlibs",
            path: "/#",
          },
          {
            icon: KOL,
            name: "Influencer (KOL)",
            desc: "Key Opinion Leaders, Influencers & Artists",
            path: "/#",
          },
        ],
      },
      {
        header: "Retail & POS",
        sub: [
          {
            icon: POS,
            name: "Retail & POS",
            desc: "Point of Sales system, production stock monitor and e-Commerce retail",
            path: "/#",
          },
          {
            icon: DataManagement,
            name: "Transaction Data Management",
            desc: "Acquire and manage data from various retail transaction into one",
            path: "/#",
          },
        ],
      },
      {
        header: "Ads Publishers",
        sub: [
          {
            icon: DigitalSignage,
            name: "Digital Signage",
            desc: "Register with Mediacartz",
            path: "/#",
          },
          {
            icon: TvRadio2,
            name: "TV & Radio",
            desc: "Register with Mediacartz",
            path: "/#",
          },
          {
            icon: KOL,
            name: "Influencer",
            desc: "Register with Mediacartz",
            path: "/#",
          },
        ],
      },
      {
        header: "Eventories",
        sub: [
          {
            icon: PublicEvent,
            name: "Store, Online & Public Events",
            desc: "Create, publish and manage your events on Eventories",
            path: "/#",
          },
          {
            icon: PrivateEvent,
            name: "Private Event",
            desc: "Create and manage your private and intimate events on Eventories",
            path: "/#",
          },
        ],
      },
      {
        header: "Portal Catalog",
        sub: [
          {
            icon: Microsite,
            name: "Microsite",
            desc: "Create your own single page website to promote your business are available",
            path: "/#",
          },
          {
            icon: Catalog,
            name: "Catalog",
            desc: "Create your own digital product display (storefront)",
            path: "/#",
          },
        ],
      },
    ],
  },
  {
    name: "Solutions",
    sub: [
      {
        icon: BankingAndFincance,
        name: "Banking and Financial",
        path: "/home/solutions?name=Banking and Financial",
      },
      {
        icon: adPublisher,
        name: "Ad Publisher",
        path: "/home/solutions?name=Ad Publisher",
      },
      {
        icon: Calendar,
        name: "Event Organizer",
        path: "/home/solutions?name=Event Organizer",
      },
      {
        icon: Retail,
        name: "Retail",
        path: "/home/solutions?name=Retail",
      },
      {
        icon: SmallMedium,
        name: "Small medium Enterprise",
        path: "/home/solutions?name=Small Medium Enterprise",
      },
      {
        icon: Agency,
        name: "Agency and Brand Company",
        path: "/home/solutions?name=Agency and Brand Company",
      },
      {
        icon: Outsourcing,
        name: "Outsourcing Business",
        path: "/home/solutions?name=Outsourcing Business",
      },
    ],
  },
  {
    name: "Resources",
    sub: [
      {
        icon: ApiImg,
        name: "Development API",
        path: "/#",
      },
      {
        icon: HowTo,
        name: "How To",
        path: "/#",
      },
      {
        icon: FAQ,
        name: "FAQ",
        path: "/#",
      },
    ],
  },
  {
    name: "Company",
    sub: [
      {
        icon: AboutUs,
        name: "About Us",
        path: "/home/about-us",
      },
      {
        icon: Careers,
        name: "Careers",
        path: "/#",
      },
      {
        icon: PressMedia,
        name: "Press & Media",
        path: "/home/company/press-and-media",
      },
      {
        icon: Contact,
        name: "Contact",
        path: "/home/company/contact",
      },
      {
        icon: Investors,
        name: "Investor Relations",
        path: "/#",
      },
    ],
  },
];

export default NavbarData;
