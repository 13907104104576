class Color {
  primary = "#3B3E98";
  secondary = "#6899D1";
  tertiary = "#2FBEEE";
  black1 = "#000000";
  black2 = "#1A202C";
  black3 = "rgba(12, 27, 77, 0.6)";
  gray1 = "#999999";
  gray2 = "#E0E0E0";
  gray3 = "#DEDEDE";
  white = "#FFFFFF";
}

export default new Color();
