import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import ukImage from "landingPage/assets/iconAndLogo/united kingdom.png";
import IdImage from "landingPage/assets/iconAndLogo/indonesia.png";
import styled from "styled-components";

const imgBahasa = [ukImage, IdImage];

const DropdownLeague = () => {
  const [bahasaSelected, setBahasaSelected] = useState(ukImage);

  return (
    <ContainerDropdonw>
      <Dropdown.Toggle variant="none" id="dropdown-basic">
        <img src={bahasaSelected} alt={bahasaSelected} />
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{
          background: "none",
          border: "none",
        }}
      >
        {imgBahasa.map((item) => (
          <DropdownItem
            href="#"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-start",
            }}
            onClick={() => setBahasaSelected(item)}
          >
            <img src={item} alt={item} />
          </DropdownItem>
        ))}
      </Dropdown.Menu>
    </ContainerDropdonw>
  );
};

export default DropdownLeague;

const DropdownItem = styled(Dropdown.Item)`
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
`;

const ContainerDropdonw = styled(Dropdown)`
  img {
    width: 40px;
    height: 40px;
  }
`;
