import ImgBannerFAQ from "../../../assets/iconAndLogo/img_banner_FAQ.png";
import styled from "styled-components";
import IcPinLocation from "../../../assets/iconAndLogo/ic_pin_location.png";

const BannerComponent = () => {
  return (
    <Container className="container">
      <div className="row">
        <div className="col-md-6">
          <img src={ImgBannerFAQ} className="img-banner" />
        </div>
        <div className="col-md-6">
          <h4 className="title">Frequently Asked Questions</h4>
          <div className="sub-title">
            Lorem ipsum dolor sit amet, consectetur adipiscing elt, sed do
            eiusmod tempor incididunt ut labore et dolore
          </div>

          <div
            className="wrap-button d-flex justify-content-center"
            style={{ gap: "12px", marginTop: "40px" }}
          >
            <Button className="btn" bgColor="#3B3E98">
              <span>
                LOREM &nbsp;<strong> IPSUM</strong>
              </span>
            </Button>
            <Button className="btn" bgColor="#6899D1">
              <span>
                LOREM &nbsp;<strong> IPSUM</strong>
              </span>
            </Button>
            <Button className="btn" bgColor="#2FBEEE">
              <span>
                LOREM &nbsp;<strong> IPSUM</strong>
              </span>
            </Button>
          </div>

          <WrapSearching
            className="wrap-searching"
            style={{ marginTop: "80px" }}
          >
            <h4 className="title-help">Hello, how can we help?</h4>

            <ContainerSearch className="d-flex container-search">
              <img src={IcPinLocation} className="ic-pin" />
              <InputSearch
                type="text"
                placeholder="Seacrh your question here"
                className="input-search"
              />
              <Button className="btn" bgColor="#3B3E98">
                <span>
                  <strong> search</strong>
                </span>
              </Button>
            </ContainerSearch>
          </WrapSearching>
        </div>
      </div>
    </Container>
  );
};

export default BannerComponent;

const WrapSearching = styled.div`
  margin-bottom: 74px;
  .title-help {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height */

    /* Brand Color/Primary */

    color: #3b3e98;
    text-align: center;
  }
`;

const ContainerSearch = styled.div`
  align-items: center;
  padding: 15px 30px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 53px;
  width: 100%;
  margin-top: 24px;
  .ic-pin {
    width: 18px;
    height: 24px;
    object-fit: contain;
  }
`;

const InputSearch = styled.input`
  flex-grow: 1;
  padding-left: 17px;
  border: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 21.0293px;
  line-height: 140%;

  color: rgba(12, 27, 77, 0.6);

  &:focus-within ~ ${ContainerSearch} {
    border: 1px solid #3b3e98 !important;
  }

  :focus {
    border: none;
    outline: none;
  }

  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 21.0293px;
    line-height: 140%;
    /* or 29px */

    /* Black 3 */

    color: rgba(12, 27, 77, 0.6);
  }
`;

const Button = styled.button`
  padding: 13px 28px;
  /* Brand Color/Primary */

  background: ${({ bgColor }) => bgColor};
  /* Brand Color/Primary */

  border: 1px solid ${({ bgColor }) => bgColor};
  border-radius: 800px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14.4px;
    line-height: 22px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }
`;

const Container = styled.div`
  background: radial-gradient(
      100% 324.22% at 0% 50%,
      #f4f7ff 0%,
      rgba(244, 247, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  .img-banner {
    width: 494px;
    height: 308px;
    object-fit: contain;
  }

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    /* Brand Color/Primary */

    color: #3b3e98;
    text-align: center;
    position: relative;
    z-index: 1;
    :before {
      z-index: -1;
      position: absolute;
      content: "";
      width: 40px;
      height: 40px;
      background-color: #fcd45d;
      border-radius: 100%;
    }
  }
  .sub-title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;

    /* Black 3 */

    color: rgba(12, 27, 77, 0.6);
  }
`;
