import * as Modal from "./../../modal";
import * as Style from "../../../../../component/style/content/default";
import { useContext, useEffect, useState } from "react";
import * as Services from "../../../../../service";
import * as Components from "../../../../../component";

const Header= (props) => {
  const { selected_company, dispatch} = useContext(Services.Context);
  const [modal, setModal] = useState({
    company: false,
    detail: false,
    create: false
  });

  const GetCompany = async () => {
    await Services.Method.get(`company`)
    .then(async (res) => {
      if (res?.data?.success) {
        if (!selected_company.value) {
          dispatch({
            type: "SELECTED_COMPANY",
            selected_company: {
              value: res?.data?.data[0]?.company_id,
              label: res?.data?.data[0]?.company_name,
            },
          });
        }
      } else {
        Components.AlertError({ title: "Error", text: res?.data?.error });
      }
    })
  };

  useEffect(() => {
    GetCompany();
  }, []);

  return(
    <>
        <Modal.ModalSelectCompany
          show={modal.company}
          onHide={() => setModal((state) => ({ ...state, company: false }))}
        />
        <div className="d-flex align-items-center">
          <div>
            <div><strong>Program Loyalty</strong></div>
            <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
              {props.title}
            </Style.HeaderPrimary>
          </div>
          <div className="wrap-select align-items-center ms-5">
            <button
              className="btn border"
              onClick={() => setModal((state) => ({ ...state, company: true }))}
            >
              {selected_company.label ? selected_company.label : 'Select Company'} <i className="demo-icon icon-chevron-down"></i>
            </button>
          </div>
        </div>
      </>
  )
}

export default Header