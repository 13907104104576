import styled from "styled-components";
import ArticleItem from "./article_item";
import { Link } from "react-router-dom";

const ArticleSection = ({ data = [] }) => {
  console.log({ nii_data: data });
  return (
    <Container>
      <div className="title-acrticle-section">
        Read Our Articles to Find Your Success Strategy
      </div>
      <div className="list-article-section">
        {data.map((item, idx) => (
          <Link to={`/home/articles/${item.slug}`}>
            <ArticleItem
              key={idx}
              title={item.title}
              subTitle={
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              }
              imgUrl={item.image}
              most_viewed={idx === 0 ? true : false}
            />
          </Link>
        ))}
      </div>
      <div className="text-center mt-5">
        <Link to="/home/article" className="btn">
          See More Articles
        </Link>
      </div>
    </Container>
  );
};

export default ArticleSection;

const Container = styled.div`
  margin-top: 120px;
  .list-article-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, 360px);
    overflow: auto;
    justify-content: flex-start;
    gap: 30px;
  }
  .title-acrticle-section {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.021em;

    color: #161c2b;
    margin-bottom: 74px;
  }

  @media (max-width: 1024px) {
    .list-article-section {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
`;
