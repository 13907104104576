import styled from "styled-components";
import LogoMediacartz from "../../assets/iconAndLogo/logo mediacartz resized-400-02-02 2.png";
import IconPin from "../../assets/iconAndLogo/icon-pin.svg";
import IconEmail from "../../assets/iconAndLogo/VectorEmail.png";
import IconPhone from "../../assets/iconAndLogo/VectorPhone.png";
import IconFb from "../../assets/iconAndLogo/icon-Facebook.svg";
import IconIg from "../../assets/iconAndLogo/icon-Instagram.svg";
import IconLinkedIn from "../../assets/iconAndLogo/icon-linkedin.png";
import IconYoutube from "../../assets/iconAndLogo/icon-youtube.svg";

const desktopFooter = () => {
  return (
    <Container>
      <div id="content" className="container">
        <div className="row">
          <div className="col" id="info-mediacartz">
            <div className="logo">
              <img src={LogoMediacartz} alt={LogoMediacartz} />
            </div>
            <div className="desc">
              An integrated digital engagement platfrom for your business needs
              and improvements
            </div>

            <div className="title">
              <p>Contact Us</p>
            </div>

            <div className="info-address">
              <div
                className="address d-flex gap-2 mb-2"
                style={{ alignItems: "baseline" }}
              >
                <img src={IconPin} alt={IconPin} className="icon" />
                <div className="info-address-text p-0">
                  Centennial Tower, 38’th floor, Unit H Jl. Gatot Subroto,
                  kavling 24-25, Jakarta Selatan, 12930, Indonesia
                </div>
              </div>
              <div
                className="email d-flex gap-2 mb-2"
                style={{ alignItems: "baseline" }}
              >
                <img src={IconEmail} alt={IconEmail} className="icon" />
                <div className="info-address-text p-0">cs@mediacartz.com</div>
              </div>
              <div
                className="email d-flex gap-2"
                style={{ alignItems: "baseline" }}
              >
                <img src={IconPhone} alt={IconPhone} className="icon" />
                <div className="info-address-text p-0">(021) 2295 - 8146</div>
              </div>
            </div>
          </div>
          <div className="col" id="products">
            <div className="product-title title">Products</div>
            <div className="products-list">
              <div className="product-list-text">Advertising Placement</div>
              <div className="product-list-text">Ads Publisher</div>
              <div className="product-list-text">Portal Catalog</div>
              <div className="product-list-text">Retail And Pos</div>
              <div className="product-list-text">Eventories</div>
            </div>
          </div>
          <div className="col" id="solutions">
            <div className="solution-title title">Solutions</div>
            <div className="solutions-list">
              <div className="solution-list-text">Banking and Financial</div>
              <div className="solution-list-text">Outsourcing Business</div>
              <div className="solution-list-text">Ad Publisher</div>
              <div className="solution-list-text">Retail</div>
              <div className="solution-list-text">Small Medium Enterprise</div>
              <div className="solution-list-text">Event Organizer</div>
              <div className="solution-list-text">Agency and Brand Company</div>
            </div>
          </div>
          <div className="col" id="resources">
            <div className="resource-title title">Resources</div>
            <div className="resources-list">
              <div className="resource-list-text">Development API</div>
              <div className="resource-list-text">How To</div>
              <div className="resource-list-text">FAQ</div>
            </div>
          </div>
          <div className="col" id="company">
            <div className="resource-title title">Company</div>
            <div className="resources-list">
              <div className="resource-list-text">About Us</div>
              <div className="resource-list-text">Contact</div>
              <div className="resource-list-text">Careers</div>
              <div className="resource-list-text">Investor Relations</div>
              <div className="resource-list-text">Press & Media</div>
            </div>
          </div>
        </div>

        {/* Social media List */}
        <div id="wrap-social-media">
          <div className="social-media-title">Social Media Kami</div>
          <div className="list-social-media d-flex">
            <img src={IconFb} alt="" className="icon-social-media" />
            <img src={IconIg} alt="" className="icon-social-media" />
            <img src={IconLinkedIn} alt="" className="icon-social-media" />
            <img src={IconYoutube} alt="" className="icon-social-media" />
          </div>
        </div>

        <div id="line"></div>

        <div id="wrap-copy-right">
          <div className="copy-right-text">
            Copyright © 2022 PT. Mediacartz Digital Indonesia
          </div>
        </div>
      </div>
    </Container>
  );
};

export default desktopFooter;

const Container = styled.div`
  margin-top: 20px;
  padding-top: 100px;
  background-color: #f7f7fb;

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    /* Color/Neutral 100 */

    color: #222833;
  }
  #info-mediacartz {
    .desc {
      margin-top: 36px;
      /* Body/Normal - Regular */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      /* or 162% */

      /* Color/Neutral 80 */

      color: #6d7078;
    }
    .logo > img {
      width: 157px;
      height: 26px;
      object-fit: contain;
    }

    .title {
      margin-top: 50px;
    }

    .info-address {
      .info-address-text {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        /* or 162% */

        color: #6d7078;

        /* Inside auto layout */
      }
    }
  }

  #products {
    .product-title {
      margin-bottom: 30px;
    }

    .products-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .product-list-text {
        /* Body/Normal - Regular */

        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;

        /* Color/Neutral 80 */

        color: #6d7078;
      }
    }
  }
  #solutions {
    .solution-title {
      margin-bottom: 30px;
    }

    .solutions-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .solution-list-text {
        /* Body/Normal - Regular */

        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;

        /* Color/Neutral 80 */

        color: #6d7078;
      }
    }
  }

  #resources,
  #company {
    .resource-title {
      margin-bottom: 30px;
    }

    .resources-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .resource-list-text {
        /* Body/Normal - Regular */

        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;

        /* Color/Neutral 80 */

        color: #6d7078;
      }
    }
  }

  #wrap-social-media {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .social-media-title {
      /* Body/Large - Bold */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */

      /* Color/Neutral 100 */

      color: #222833;
    }
    .list-social-media {
      margin-top: 16px;
      gap: 23px;
    }
  }

  #line {
    width: 100%;
    height: 1px;
    background-color: #c8c9cc;
    margin-top: 43px;
  }

  #wrap-copy-right {
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
