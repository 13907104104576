import styled from "styled-components";
import BannerAdsImage from "landingPage/assets/iconAndLogo/bannerAdvertisingPlacement.png";
import ImgPlay from "landingPage/assets/iconAndLogo/btn_play.png";
import { Mobile_query_v2 } from "../../../util/Query";

const BannerAdvertisingPlacement = ({ handlePreviewVideo }) => {
  return (
    <Container>
      <div className="left">
        <img src={BannerAdsImage} alt={BannerAdsImage} />
      </div>
      <div className="right">
        <div className="title-text">Advertising Placement</div>
        <div className="desc-text">
          Provides an objective campaign analysis and highlighted results, also
          identifies areas in which advertising strategy adjustments are
          appropriate.
        </div>
        <button className="btn see-how" onClick={handlePreviewVideo}>
          <img src={ImgPlay} />
          See How It Works
        </button>
      </div>
    </Container>
  );
};

export default BannerAdvertisingPlacement;

const Container = styled.div`
  ${Mobile_query_v2} {
    flex-direction: column;

    .right {
      .see-how {
        justify-content: center !important;
        text-align: center !important;
        align-items: center !important;
      }
      .desc-text {
        font-size: 90% !important;
        text-align: center;
        margin-bottom: 30px;
      }
      .title-text {
        font-size: 20px !important;
        text-align: center;
      }
    }

    .left {
      img {
        width: 300px;
      }
      margin-bottom: 30px;
    }
  }
  display: flex;
  align-items: center;
  padding-top: 77px;
  padding-bottom: 120px;

  .right {
    .see-how {
      margin-top:50px;
      padding:0px !important;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height */

      /* Brand Color/Primary */

      color: #3b3e98;
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .desc-text {
      /* Body/Large - SemiBold */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* or 156% */

      /* Color/Neutral 80 */

      color: #6d7078;
    }
    .title-text {
      /* Heading/H2 */

      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      /* identical to box height */

      /* Brand Color/Primary */

      color: #3b3e98;
      position: relative;
      z-index: 1;
      :before {
        z-index: -1;
        content: "";
        width: 45px;
        height: 45px;
        border-radius: 9999px;
        background-color: #fcd45d;
        position: absolute;
        left: -20px;
      }
    }
  }

  .left {
    text-align: center;
  }
  .right,
  .left {
    flex: 1;
  }
`;
