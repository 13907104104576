import React, { useEffect } from "react";
import MarkerMaps from "landingPage/assets/iconAndLogo/markerImg.png";
import { Mobile_query_v2 } from "../../../util/Query"
import styled from "styled-components"

const Maps = () => {
  function initMap() {
    const myLatLng = { lat: -6.2300364097951935, lng: 106.82087708431666 };
    const map = new window.google.maps.Map(document.getElementById("maps"), {
      zoom: 17,
      center: myLatLng,
    });

    new window.google.maps.Marker({
      position: myLatLng,
      map,
      title: "Mediacartz",
      label: {
        text: "Mediacartz",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "26px",
        color: "#3B3E98",
      },
      icon: {
        labelOrigin: new window.google.maps.Point(25, 80),
        url: MarkerMaps,
      },
    });
  }

  useEffect(() => {
    initMap();
  }, []);
  return (
    <MapsDiv
      className="wrap-maps"
      id="maps"
      style={{ width: "100%", height: "500px" }}
    />
  );
};

export default Maps;


const MapsDiv = styled.div`
${Mobile_query_v2}{
  margin-bottom: 50px !important;
}

`
