import styled from "styled-components";
import OurValueItem from "./our_value_item";

const OurValueAds = () => {
  return (
    <Container>
      <div className="title-our-value">OUR VALUE</div>
      <div className="big-title-our-value">Lorem ipsum dolor sit amet</div>
      <div className="sub-title-our-value">
        Lorem ipsum dolor sit amet consectetur. Neque at auctor pellentesque id
        purus <br /> maecenas odio faucibus. Sagittis dictum molestie turpis
        dictum diam sit placerat.
      </div>
      <div className="container-our-value-item">
        {[1, 1, 1, 1, 1].map((item, idx) => (
          <OurValueItem key={idx} />
        ))}
      </div>
    </Container>
  );
};

export default OurValueAds;

const Container = styled.div`
  padding-top: 120px;
  .title-our-value {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;

    /* Brand Colors/Tertiary/100 */

    color: #2fbeee;
    margin-bottom: 12px;
  }

  .big-title-our-value {
    /* Heading/L */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    /* identical to box height, or 122% */

    text-align: center;
    letter-spacing: -0.022em;

    /* Neutral/100 */

    color: #222833;

    margin-bottom: 12px;
  }

  .sub-title-our-value {
    /* Text L/Regular */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    letter-spacing: -0.011em;

    /* Neutral/80 */

    color: #6d7078;
  }

  .container-our-value-item {
    margin-top: 72px;
    display: flex;
    /* grid-template-columns: repeat(auto-fit, minmax(367px, 1fr)); */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 64px;
  }
`;
