import React, { lazy } from "react";
import Navbar from "landingPage/components/Navbar";
import styled from "styled-components";
import Footer from "landingPage/components/footer";
import queryString from "query-string";
import { Mobile_query_v2 } from "../../../util/Query";

const SolutionsRetail = lazy(() => import("./solutions.retail"));
const SolutionsMediumEnterprise = lazy(() =>
  import("./solutions.mediumEnterprise")
);
const SolutionsAddPublisher = lazy(() => import("./solutions.addPublisher"));
const SolutionsBanking = lazy(() => import("./solutions.banking"));
const SolutionsEvent = lazy(() => import("./solutions.event"));
const SolutionsOutsourcing = lazy(() => import("./solutions.outsourcing"));
const SolutionsAgency = lazy(() => import("./solutions.agency"));

const Solutions = () => {
  let { name } = queryString.parse(window.location.search);

  if (!name) {
    return window.location.replace("/home/solutions?name=Retail");
  }
  return (
    <ContainerInti>
      <Navbar />
      <Container>
        {name === "Retail" ? (
          <SolutionsRetail />
        ) : name === "Event Organizer" ? (
          <SolutionsEvent />
        ) : name === "Banking and Financial" ? (
          <SolutionsBanking />
        ) : name === "Outsourcing Business" ? (
          <SolutionsOutsourcing />
        ) : name === "Agency and Brand Company" ? (
          <SolutionsAgency />
        ) : name === "Small Medium Enterprise" ? (
          <SolutionsMediumEnterprise />
        ) : name === "Ad Publisher" ? (
          <SolutionsAddPublisher />
        ) : (
          <SolutionsRetail />
        )}
      </Container>
      <Footer />
    </ContainerInti>
  );
};

export default Solutions;

const ContainerInti = styled.div`
  background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  );
`;

const Container = styled.div`
  /* min-height: 100vh; */
  padding: 0 93px;

  ${Mobile_query_v2} {
    padding: 0 25px;
  }

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;
