import React from "react";
import styled from "styled-components";
import CardPressRealese from "./pressRealese.card";
import PaginationPressRealese from "./pressRealese.pagination";

const PressRealese = () => {
  return (
    <Container>
      <div className="top">
        <div className="left">
          <div className="title-text">Press Release</div>
        </div>
        <div className="right">
          <button className="btn btn-showAll">Show All</button>
        </div>
      </div>
      <div className="middle">
        <div className="list-card">
          {[2, 2, 2, 2, 2, 2].map((item, idx) => (
            <CardPressRealese key={idx} />
          ))}
        </div>
      </div>
      <div className="bottom d-flex align-items-center justify-content-center">
        <PaginationPressRealese />
      </div>
    </Container>
  );
};

export default PressRealese;

const Container = styled.div`
  margin-bottom: 123px;
  .middle {
    margin-bottom: 100px;
    .list-card {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
    }
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    .left {
      .title-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        /* identical to box height */

        /* Brand Color/Primary */

        color: #3b3e98;
      }
    }

    .right {
      .btn-showAll {
        width: 156px;
        height: 55px;

        /* Brand Color/Primary */

        border: 1px solid #3b3e98;
        border-radius: 8px;
        background-color: transparent;
        /* CTA/Large - Bold */

        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        text-align: center;

        /* Brand Color/Primary */

        color: #3b3e98;
      }
    }
  }
`;
