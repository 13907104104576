import { useState, useEffect } from "react";
import Select from "react-select";
import { Method as Axios } from "../../service";

const SelectEvent = ({
  placeholder = "Select Event",
  onChange = () => null,
  value,
  company_id = "",
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState({
    event_name: "",
  });

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await Axios.get(
        `event/own?event_name=${param?.event_name}${
          company_id !== "" ? `&company_id=${company_id}` : ""
        }`
      );

      if (data.success) {
        setData(
          data?.data?.map((item) => ({
            value: item,
            label: item.event_name,
          }))
        );
      }
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  };

  const handleInputChange = (event) => {
    setParam((state) => ({
      ...state,
      event_name: event,
    }));
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getData();
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [param.event_name, company_id]);

  return (
    <Select
      placeholder={placeholder}
      options={data}
      isClearable
      isLoading={loading}
      onInputChange={handleInputChange}
      onChange={onChange}
      value={value}
    />
  );
};

export default SelectEvent;
