import styled from "styled-components";
import { Mobile_query_v2 } from "../../../../util/Query";

const CardBerita = () => {
  return (
    <ContainerCard>
      <img
        src="https://images.unsplash.com/photo-1659301254614-8d6a9d46f26a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
        alt="https://images.unsplash.com/photo-1659301254614-8d6a9d46f26a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
      />

      <div className="title-card">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
        quidem!
      </div>
      <div className="desc-card">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat vel
        nostrum vitae. Suscipit officiis id doloremque, provident vero molestias
        quae!
      </div>
    </ContainerCard>
  );
};

const AnotherNews = () => {
  return (
    <div style={{ backgroundColor: "#FFFF" }}>
      <Container>
        <div className="title">Berita Lainnya</div>
        <div className="list-berita d-flex">
          {[2, 2, 2].map((item, idx) => (
            <CardBerita key={idx} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default AnotherNews;

const ContainerCard = styled.div`
  .desc-title {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    letter-spacing: -0.02em;

    color: #64607d;
  }
  .title-card {
    margin-top: 55px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    letter-spacing: -0.03em;

    color: #000000;
  }
  img {
    width: 378px;
    height: 270px;
    object-fit: cover;
  }
  width: 378px;
  height: 495px;
`;

const Container = styled.div`
  .list-berita {
    gap: 25px;
    margin-top: 60px;
  }
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 135%;
    text-align: center;
    /* or 43px */

    color: #3b3e98;
  }
  margin-top: 86px;
  ${Mobile_query_v2} {
    padding: 0 25px;
  }
  padding: 0 93px;

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;
