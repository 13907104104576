import styled from "styled-components";
import ToaImg from "landingPage/assets/iconAndLogo/toaAdvertisingPlacement.png";
import SettingLayang from "landingPage/assets/iconAndLogo/settinglayar.png";
import insight from "landingPage/assets/iconAndLogo/insight.png";
import Img1 from "landingPage/assets/iconAndLogo/1.png";
import Img2 from "landingPage/assets/iconAndLogo/2.png";
import Img3 from "landingPage/assets/iconAndLogo/3.png";
import { Mobile_query_v2 } from "../../../util/Query";

const listCard = [
  {
    imgIcon: ToaImg,
    title: "The Importance of Advertising",
    desc: "Advertising is all about fostering an identity for your company. You must promote your brand, if you want your enterprise to stand out in the business world.",
    bgImg: Img1,
  },
  {
    imgIcon: SettingLayang,
    title: "Advertise With Mediacartz",
    desc: "Mediacartz is an advertising automation platform that helps you to create and manage your advertising campaigns easily and effectively, by simply register your business.",
    bgImg: Img2,
  },
  {
    imgIcon: insight,
    title: "Real Time Reports & Insights",
    desc: "Mediacartz dashboard provides live campaign results reports, so you can get all the information you need and organize it anywhere and anytime.",
    bgImg: Img3,
  },
];

const Card = ({
  imgIcon = ToaImg,
  title = "The Importance of Advertising",
  desc = "Advertising is all about fostering an identity for your company. You must promote your brand, if you want your enterprise to stand out in the business world.",
  bgImg = Img1,
}) => {
  return (
    <div className="warp-card">
      <WrapTop className="wrap-top" bgImg={bgImg}>
        <img src={imgIcon} alt={imgIcon} className="icon-img" />
        <div className="title-text">{title}</div>
      </WrapTop>

      <div className="desc-text">{desc}</div>
    </div>
  );
};

const ProcessAdvertisingPlacemenet = () => {
  return (
    <Container>
      {listCard.map((item, idx) => (
        <Card
          key={idx}
          imgIcon={item.imgIcon}
          title={item.title}
          desc={item.desc}
          bgImg={item.bgImg}
        />
      ))}
    </Container>
  );
};

export default ProcessAdvertisingPlacemenet;

const WrapTop = styled.div`
  :before {
    background-image: ${({ bgImg = Img1 }) => `url(${bgImg})`};
    background-repeat: no-repeat;
  }
`;

const Container = styled.div`
  ${Mobile_query_v2} {
    flex-direction: column !important;
    gap: 20px;
    justify-content: center !important;
    align-items: center;
  }
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
  .warp-card {
    width: 280px;
    min-height: 345px;
    .wrap-top {
      position: relative;

      :before {
        content: "";
        min-width: 120px;
        min-height: 204px;
        position: absolute;
        right: 0px;
      }
    }
    .desc-text {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      /* or 162% */

      /* Color/Neutral 80 */

      color: #6d7078;
    }
    .title-text {
      /* Heading/H5 - SemiBold */
      margin-bottom: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;

      /* Color/Neutral 100 */

      color: #222833;
    }
    img {
      width: 64px;
      height: 64px;
      margin-bottom: 24px;
    }
  }
`;
