import styled from "styled-components";
import ImgSellNow from "../../../../assets/iconAndLogo/img_sell_now_banner.png";

const SellNow = () => {
  return (
    <Container>
      <div className="container-image-banner position-relative">
        <img src={ImgSellNow} alt={ImgSellNow} />
      </div>
      <div className="container-text-sell-now">
        <div className="title-sell-now">Lorem ipsum dolor sit amet</div>
        <div className="sub-title-sell-now">
          Lorem ipsum dolor sit amet consectetur. Convallis amet sit eu et eu
          faucibus turpis volutpat mauris.
        </div>

        <button className="btn btn-sell-now">Sell Now</button>
      </div>
    </Container>
  );
};

export default SellNow;

const Container = styled.div`
  margin-top: 217px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 380px;
  width: 100%;
  background-color: #3b3e98;
  border-radius: 32px;
  .container-image-banner {
    height: 380px;
    flex: 1;
    img {
      height: 592px;
      position: absolute;
      bottom: 0;
    }
  }
  .container-text-sell-now {
    padding: 0px 87px;
    flex: 1;
    /* padding: 80px; */

    .title-sell-now {
      /* Heading/L */

      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      /* or 122% */

      letter-spacing: -0.022em;

      color: #ffffff;
    }

    .sub-title-sell-now {
      /* Text L/Regular */

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      letter-spacing: -0.011em;

      color: #ffffff;
      margin-top: 20px;
      margin-bottom: 56px;
    }
    .btn-sell-now {
      width: 157px;
      height: 48px;

      background: #ffffff;
      border-radius: 5px;

      /* Text Style - MyAds/CTA/Secondary */

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: center;

      color: #3b3e98;
    }
  }
`;
