import { useState } from "react";
import { ChevronDown } from "@styled-icons/boxicons-regular";
import styled, { keyframes } from "styled-components";
import { Mobile_query_v2 } from "../../../util/Query";

const CardSolutionV2 = ({ item }) => {
  const [active, setActive] = useState("");

  const hanleActiveCard = (title) => {
    if (active !== "") {
      setActive("");
    } else {
      setActive(title);
    }
  };
  return (
    <Container
      onClick={() => hanleActiveCard(item.title)}
      active={item.title === active ? true : false}
    >
      <div className="top">
        <div className="left">
          <img src={item.icon} alt={item.icon} />
        </div>
        <div className="right">
          <div className="wrap-text">
            <div className="title-text"> {item.title}</div>
            <div className="desc-text">{item.desc}</div>
          </div>
          <div className="wrap-icon">
            <ChevronDown width={16} />
          </div>
        </div>
      </div>
      <div className="bottom">
        <img src={item.image} alt={item.image} className="img-solutions" />
      </div>
    </Container>
  );
};

export default CardSolutionV2;

const Container = styled.div`
  ${Mobile_query_v2} {
    .bottom {
      margin-top: 50px;
      text-align: center;
    }
    .top {
      display: flex;
      gap: 15px;
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 5px;
        .wrap-text {
          .desc-text {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            /* Dark Light */

            color: rgba(12, 27, 77, 0.6);
          }
          .title-text {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */

            color: #1a202c;
          }
        }
      }
    }
    border: 1px solid #2fbeee;
    border-radius: 15px;
    padding: 14px 11px;
  }

  .bottom {
    opacity: ${({ active }) => (active ? "1" : "0")};
    justify-content: center;
    transform: ${({ active }) => (active ? "scaleY(1)" : "scaleY(0)")};
    transform-origin: top;
    transition: transform 0.26s ease;
  }
  height: ${({ active }) => (active ? "auto" : "92px")};
  overflow: hidden;
  cursor: pointer;
`;
