import styled from "styled-components";

const SelectedFilterItem = ({ title = "button", onClickProp = () => null }) => {
  return (
    <Container className="btn">
      <div className="title-selected-item">{title}</div>
      <i className="icon-selected-item bi-x-lg" onClick={() => onClickProp()} />
    </Container>
  );
};

export default SelectedFilterItem;

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 301px;
  height: 40px;

  background: #f5f8fa;
  border-radius: 4px;
  padding: 10px 20px;

  .title-selected-item {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.006em;

    color: #161c2b;
  }

  .icon-selected-item {
    color: #95908b;
    font-size: 12px;
  }
`;
