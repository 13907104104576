import styled from "styled-components";
import ic_shop_self_Service from "../../../assets/iconAndLogo/ic_shop_self_Service.png";

const SelfService = () => {
  return (
    <Container>
      <div className="container-left">
        <div className="text-blue">SELF SERVICE</div>
        <div className="title-self-service">Lorem ipsum dolor sit amet</div>
        <div className="desc-sel-service">
          Lorem ipsum dolor sit amet consectetur. Neque at auctor pellentesque
          id purus maecenas odio faucibus. Sagittis dictum molestie turpis
          dictum diam sit placerat.
        </div>
        <button className="btn btn-see-detail-sel-service">
          See Details >
        </button>
      </div>
      <div className="container-right">
        {[1, 1, 1, 1, 1].map((item, idx) => (
          <CardSelfService key={idx} active={idx == 0 ? true : false} />
        ))}
      </div>
    </Container>
  );
};

export default SelfService;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 120px;

  .container-left,
  .container-right {
    flex: 1;
  }

  .container-left {
    .btn-see-detail-sel-service {
      width: 160px;
      height: 45px;

      /* Neutral/10

White
*/
      background: #ffffff;
      /* Brand Colors/Primary/100

3B3E98
*/
      border: 1px solid #3b3e98;
      border-radius: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* Brand Colors/Primary/100 */

      color: #3b3e98;
      margin-top: 40px;
    }
    .desc-sel-service {
      /* Text L/Regular */

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      letter-spacing: -0.011em;

      /* Neutral/80 */

      color: #6d7078;
    }
    .title-self-service {
      /* Heading/L */

      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      /* or 122% */

      letter-spacing: -0.022em;

      /* Neutral/100 */

      color: #222833;
    }
    .text-blue {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      letter-spacing: 5px;
      text-transform: uppercase;

      /* Brand Colors/Tertiary/100 */

      color: #2fbeee;
    }
  }

  .container-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2px;
    row-gap: 40px;
  }
`;

const CardSelfService = ({ active = false }) => {
  return (
    <ContainerCard active={active}>
      <div className="container-icon">
        <img src={ic_shop_self_Service} alt={ic_shop_self_Service} />
      </div>
      <div className="title-card-self-service">Lorem ipsum</div>
      <div className="desc-card-self-service">
        Lorem ipsum dolor sit amet consectetur. Vitae egestas sit molestie id.
      </div>
    </ContainerCard>
  );
};

const ContainerCard = styled.div`
  width: 251px;
  height: 287px;
  background:${({ active }) => (active ? "#ffffff" : "transparent")};
  box-shadow:${({ active }) =>
    active
      ? "40px 0px 70px rgba(121, 121, 121, 0.05),-40px 100px 70px rgba(121, 121, 121, 0.05)"
      : "unset"} 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  .desc-card-self-service {
    /* Text M/Regular */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;
    letter-spacing: -0.006em;

    /* Neutral/80 */

    color: #6d7078;
  }
  .title-card-self-service {
    margin-top: 20px;
    /* Text L/Semi Bold */

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    letter-spacing: -0.011em;

    /* Neutral/100 */

    color: #222833;
  }
  .container-icon {
    img {
      width: 40px;
    }
  }
`;
