import React from "react";
import * as Component from "../../../../component";
import * as Util from "../../../../util";
import styled from "styled-components";
import * as Style from "../../../../component/style/content/default";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import moment from "moment";

const ModalDetailVoucher = (props) => {
  const { show, onHide, data } = props;

  return (
    <Component.Modal_Component
      size="lg"
      title="Detail Voucher"
      onHide={onHide}
      show={show}
    >
      <div className="container">
        <div className="container">
          <Row className="row bg-white">
            <div className="col-md-4 col-sm-4">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Nama Voucher</strong>
            </div>
            <div className="col-md-8 col-sm-8">
              {data.voucher_name}
            </div>
          </Row>
          <Row className="row bg-white">
            <div className="col-md-4 col-sm-4">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Kode</strong>
            </div>
            <div className="col-md-8 col-sm-8">
              {data.voucher_code}
            </div>
          </Row>
          <Row className="row bg-white">
            <div className="col-md-4 col-sm-4">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Status</strong>
            </div>
            <div className="col-md-8 col-sm-8">
              {
                <div
                  dangerouslySetInnerHTML={{
                    __html: Util.displayStatus(
                      data.voucher_active_status_name
                    ),
                  }}
                />
              }
            </div>
          </Row>
          {
            data.voucher_discount_percentage &&
            <Row className="row bg-white">
              <div className="col-md-4 col-sm-4">
                <strong style={{ color: Style.COLOR_SECONDARY }}>Diskon Persentase</strong>
              </div>
              <div className="col-md-8 col-sm-8">
                {data.voucher_discount_percentage}%
              </div>
            </Row>
          }
          {
            data.voucher_discount_maximum_amount &&
            <Row className="row bg-white">
              <div className="col-md-4 col-sm-4">
                <strong style={{ color: Style.COLOR_SECONDARY }}>Maksimal Nominal</strong>
              </div>
              <div className="col-md-8 col-sm-8">
                {Util.FormatCurrency.currency(data.voucher_discount_maximum_amount)}
              </div>
            </Row>
          }
          {
            data.voucher_minimum_order &&
            <Row className="row bg-white">
              <div className="col-md-4 col-sm-4">
                <strong style={{ color: Style.COLOR_SECONDARY }}>Minimal Order</strong>
              </div>
              <div className="col-md-8 col-sm-8">
                {Util.FormatCurrency.currency(data.voucher_minimum_order)}
              </div>
            </Row>
          }
          <Row className="row bg-white">
            <div className="col-md-4 col-sm-4">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Publik</strong>
            </div>
            <div className="col-md-8 col-sm-8">
              {data.voucher_public_status===1?'Ya':'Tidak'}
            </div>
          </Row>
          <Row className="row bg-white">
            <div className="col-md-4 col-sm-4">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Mulai</strong>
            </div>
            <div className="col-md-8 col-sm-8">
              {moment(data.voucher_active_from_datetime).format('DD-MMM-YYYY HH:mm:ss')}
            </div>
          </Row>
          <Row className="row bg-white">
            <div className="col-md-4 col-sm-4">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Sampai</strong>
            </div>
            <div className="col-md-8 col-sm-8">
              {moment(data.voucher_active_until_datetime).format('DD-MMM-YYYY HH:mm:ss')}
            </div>
          </Row>
          <Row className="row bg-white">
            <div className="col-md-4 col-sm-4">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Jumlah Voucher</strong>
            </div>
            <div className="col-md-8 col-sm-8">
              {Util.FormatCurrency.input(data.voucher_stock_quantity)}
            </div>
          </Row>
          <Row className="row bg-white">
            <div className="col-md-4 col-sm-4">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Digunakan</strong>
            </div>
            <div className="col-md-8 col-sm-8">
              {Util.FormatCurrency.input(data.voucher_usage_quantity)}
            </div>
          </Row>
        </div>
        {
          data.voucher_exclusive_for_menu_info && data.voucher_exclusive_for_menu_info.length &&
          reenderMenu()
        }
      </div>
    </Component.Modal_Component>
  );

  function reenderMenu() {
    const menuData = groupByStoreName(data.voucher_exclusive_for_menu_info);

    return(
      <div className="mt-3">
        <div className="container">
          <Row className="row bg-white">
            <div className="col-md-12">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Menu Terpilih</strong>
            </div>
            {
              Object.keys(menuData).map((item, index) => (
                <div key={'ad-'+index} className="col-md-12">
                  <Row className="row bg-white">
                    <div className="col-md-4 col-sm-4">
                      <strong style={{ color: Style.COLOR_SECONDARY }}>{item}</strong>
                    </div>
                    <div className="col-md-8 col-sm-8">
                      {
                        menuData[item].map((item, idx)=>(
                          <div key={'da-'+idx} className="col-md-12 d-flex flex-row">
                            <div className="me-2">•</div>
                            <div>{item.quantity} {item.item_name}</div>
                          </div>
                        ))
                      }
                    </div>
                  </Row>
                </div>
              ))
            }
          </Row>
        </div>
      </div>
    )
  }

  function groupByStoreName (array){
    return array.reduce((result, currentValue) => {
      (result[currentValue.store_name] = result[currentValue.store_name] || []).push(
        currentValue
      );
      return result;
    }, {});
  };
};

export default ModalDetailVoucher;

const Row = styled.div`
border:1px solid transparent;
border-bottom-color:#ccc;
padding:5px 5px;



&:last-child{
  border-bottom-color: transparent;
}
`
