import React from "react";
import styled from "styled-components";
import ImgHowto from "landingPage/assets/iconAndLogo/Asset Explore How To.png";
import { Mobile_query_v2 } from "../../../util/Query";

const Card = ({ active, imgLogo, title, desc }) => {
  if (active === true) {
    return (
      <ContainerActive>
        <div className="wrap-image">
          <img src={ImgHowto} alt={ImgHowto} />
        </div>
        <div className="titleActive">Explore</div>
        <div className="descActive">
          <span>Enjoy free services from Mediacartz</span>
          <br />
          <br />
          <p>
            Start exploring all the multi-inventories based on your budget or
            needs. You can also top-up your balance in your dashboard.
          </p>
        </div>
      </ContainerActive>
    );
  } else {
    return (
      <Container>
        <div className="wrap-image">
          <img src={imgLogo} alt={imgLogo} />
        </div>
        <div className="titleUnActive">{title}</div>
        <div className="descUnActive">{desc}</div>
      </Container>
    );
  }
};

export default Card;

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: -1px 6px 22px 1px rgba(0, 0, 0, 0.17);
  border-radius: 16px;
  padding: 34px 34px;
  width: 300px;
  height: 380px;
  background-color: #ffffff;
  transform: matrix(1, 0, 0, 1, 0, 0);

  .wrap-image > img {
    width: 120px;
    height: 90px;
  }
  .titleUnActive {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #3b3e98;

    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  .descUnActive {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;

    color: rgba(12, 27, 77, 0.6);

    transform: matrix(1, 0, 0, 1, 0, 0);
  }
`;

const ContainerActive = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: -1px 6px 22px 1px rgba(0, 0, 0, 0.17);
  border-radius: 16px;
  padding: 34px 34px;
  width: 335px;
  height: 430px;
  background-color: #3b3e98;

  .wrap-image > img {
    width: 185px;
    height: 145px;
  }
  .titleActive {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;

    color: #ffffff;
  }

  .descActive {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-align: center;

    color: #ffffff;
  }
`;
