import React from "react";
import {
  Home,
  SearchBudget,
  Solutions,
  Contact,
  PressAndMedia,
  AdvertisingPlacement,
  ProductMessaging,
  AboutUs,
  DetailCarouselPressMedia,
  FAQ,
} from "./pages2";
import {
  HomePage,
  DetailArticle,
  AdvertiserPage,
  PublisherPage,
  ListArticle,
  ProductDetailPage,
  PaymentConfirmationSection,
} from "./pages2/version2";
import { Route } from "react-router-dom";

export const AppLanding = () => {
  return (
    <React.Fragment>
      {/* ==== ROUTING SALES CATALOGUE ==== */}
      {/* <Route exact path="/home" component={HomePage} />
      <Route exact path="/home/advertiser" component={AdvertiserPage} />
      <Route path="/home/publisher" component={PublisherPage} />
      <Route path="/home/articles/:id_article" component={DetailArticle} />
      <Route path="/home/article" component={ListArticle} />
      <Route path="/home/FAQ" component={FAQ} />
      <Route path="/home/about-us" component={AboutUs} />
      <Route path="/home/searchBudget" component={SearchBudget} />
      <Route path="/home/solutions" component={Solutions} />
      <Route path="/home/company/contact" component={Contact} />
      <Route path="/home/company/press-and-media" component={PressAndMedia} />
      <Route
        path="/home/company/press-and-medias/detail"
        component={DetailCarouselPressMedia}
      />
      <Route
        path="/home/product/advertising-placement"
        component={AdvertisingPlacement}
      />
      <Route path="/home/product/messaging" component={ProductMessaging} />
      <Route path="/home/product/detail" component={ProductDetailPage} />
      <Route
        path="/home/payment-confirmation"
        component={PaymentConfirmationSection}
      /> */}
      {/* ==== END ROUTING SALES CATALOGUE ==== */}

      {/* ==== ROUTING OLD LANDING PAGE ==== */}
      <Route exact path="/home" component={Home} />
      <Route exact path="/home/FAQ" component={FAQ} />
      <Route exact path="/home/about-us" component={AboutUs} />
      <Route path="/home/searchBudget" component={SearchBudget} />
      <Route path="/home/solutions" component={Solutions} />
      <Route path="/home/company/contact" component={Contact} />
      <Route path="/home/company/press-and-media" component={PressAndMedia} />
      <Route
        path="/home/company/press-and-medias/detail"
        component={DetailCarouselPressMedia}
      />
      <Route
        path="/home/product/advertising-placement"
        component={AdvertisingPlacement}
      />
      <Route path="/home/product/messaging" component={ProductMessaging} />
      {/* ==== END ROUTING OLD LANDING PAGE ==== */}
    </React.Fragment>
  );
};
