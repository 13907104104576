import React from "react";
import styled from "styled-components";

const InputProductAndSolution = (
  // { label, icon = personOutline, placeholder = "placeholder..." },
  { props, register = () => null, name, required }
) => {
  return (
    <Container>
      <label htmlFor={props?.name}>{props?.label}</label>
      <div className="containerInput">
        <input {...props} {...register(name, { required: required })} />
        <img src={props?.icon} alt={props?.icon} className="icon-img" />
      </div>
    </Container>
  );
};

export default InputProductAndSolution;


export const InputSelectProductAndSolution = (
  { props, register = () => null, name, required, options = [] }
) => {
  return (
    <Container>
      <label htmlFor={props?.name}>{props?.label}</label>
      <div className="containerInput">
        <select {...props} {...register(name, { required: required })}>
          <option value="">Business Category</option>
          <option value="a">Business Category 2</option>
          {
            options?.map(item => (
              <option value={item}>{item}</option>
            ))
          }
        </select>
        <img src={props?.icon} alt={props?.icon} className="icon-img" />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .containerInput {
    margin-top: 18px;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #eff0f7;
    /* Input/Default */

    box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
    border-radius: 16px;
    height: 66px;
    padding: 0px 20px;

    input,select {
      width: 100%;
      height: 66px;
      border: none;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  label {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */

    /* Black 2 */

    color: #1a202c;
  }
`;
