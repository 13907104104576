import React from "react";
import styled from "styled-components";
import Navbar from "landingPage/components/Navbar";
import { Mobile_query_v2 } from "../../util/Query";
import { FooterV2 } from "../components";
import { Container } from "react-bootstrap";

const FaqPage = () => {
  return (
    <ContainerInti>
      <Navbar />
      <Container className="pt-5 pb-5">
        <h5>Kebijakan Privasi untuk Mediacartz</h5>

        <p>Di Mediacartz, dapat diakses dari https://mediacartz.com/, salah satu prioritas utama kami adalah privasi pengunjung kami. Dokumen Kebijakan Privasi ini berisi jenis informasi yang dikumpulkan dan direkam oleh Mediacartz dan bagaimana kami menggunakannya.</p>

        <p>Jika Anda memiliki pertanyaan tambahan atau memerlukan informasi lebih lanjut tentang Kebijakan Privasi kami, jangan ragu untuk menghubungi kami.</p>

        <h5>Log File</h5>

        <p>Mediacartz mengikuti prosedur standar menggunakan file log. File-file ini mencatat pengunjung ketika mereka mengunjungi situs web. Semua perusahaan hosting melakukan ini dan bagian dari analisis layanan hosting. Informasi yang dikumpulkan oleh file log termasuk protokol internet ( IP ) alamat, jenis browser, Penyedia Layanan Internet ( ISP ), cap tanggal dan waktu, halaman rujukan / keluar, dan mungkin jumlah klik. Ini tidak terkait dengan informasi apa pun yang dapat diidentifikasi secara pribadi. Tujuan dari informasi ini adalah untuk menganalisis tren, mengelola situs, melacak pergerakan pengguna di situs web, dan mengumpulkan informasi demografis. Kebijakan Privasi kami dibuat dengan bantuan&nbsp;<a href="https://www.privacypolicyonline.com/privacy-policy-generator/">Generator Kebijakan Privasi</a>.</p>

        <h5>Cookie dan Web Beacon</h5>

        <p>Seperti situs web lainnya, Mediacartz menggunakan &quot;cookies&quot;. Cookie ini digunakan untuk menyimpan informasi termasuk preferensi pengunjung, dan halaman di situs web yang diakses atau dikunjungi pengunjung. Informasi ini digunakan untuk mengoptimalkan pengalaman pengguna dengan menyesuaikan konten halaman web kami berdasarkan jenis browser pengunjung dan / atau informasi lainnya.</p>

        <p>Untuk informasi lebih umum tentang cookie, silakan baca&nbsp;<a href="https://www.privacypolicyonline.com/what-are-cookies/">artikel &quot;Cookies&quot; dari Privacy Policy Generator</a>.</p>

        <h5>Google DoubleClick DART Cookie</h5>

        <p>Google adalah salah satu vendor pihak ketiga di situs kami. Ini juga menggunakan cookie, yang dikenal sebagai cookie DART, untuk menayangkan iklan kepada pengunjung situs kami berdasarkan kunjungan mereka ke www.website.com dan situs lain di internet. Namun, pengunjung dapat memilih untuk menolak penggunaan cookie DART dengan mengunjungi iklan Google dan jaringan konten Kebijakan Privasi di URL berikut &ndash;&nbsp;<a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>

        <h5>Kebijakan Privasi</h5>

        <p>Anda dapat berkonsultasi dengan daftar ini untuk menemukan Kebijakan Privasi untuk masing-masing mitra periklanan Mediacartz.</p>

        <p>Server iklan pihak ketiga atau jaringan iklan menggunakan teknologi seperti cookie, JavaScript, atau Web Beacon yang digunakan dalam iklan dan tautan masing-masing yang muncul di Mediacartz, yang dikirim langsung ke browser pengguna. Mereka secara otomatis menerima alamat IP Anda ketika ini terjadi. Teknologi ini digunakan untuk mengukur efektivitas kampanye iklan mereka dan / atau untuk mempersonalisasi konten iklan yang Anda lihat di situs web yang Anda kunjungi.</p>

        <p>Perhatikan bahwa Mediacartz tidak memiliki akses ke atau kontrol atas cookie ini yang digunakan oleh pengiklan pihak ketiga.</p>

        <h5>Kebijakan Privasi Pihak Ketiga</h5>

        <p>Kebijakan Privasi Mediacartz tidak berlaku untuk pengiklan atau situs web lain. Dengan demikian, kami menyarankan Anda untuk berkonsultasi dengan Kebijakan Privasi masing-masing server iklan pihak ketiga ini untuk informasi yang lebih rinci. Ini mungkin termasuk praktik dan instruksi mereka tentang cara memilih keluar dari opsi tertentu.</p>

        <p>Anda dapat memilih untuk menonaktifkan cookie melalui opsi browser individual Anda. Untuk mengetahui informasi lebih rinci tentang manajemen cookie dengan browser web tertentu, dapat ditemukan di situs web masing-masing browser. Apa itu Cookie?</p>

        <h5>Informasi Anak</h5>

        <p>Bagian lain dari prioritas kami adalah menambahkan perlindungan untuk anak-anak saat menggunakan internet. Kami mendorong orang tua dan wali untuk mengamati, berpartisipasi, dan / atau memantau dan memandu aktivitas online mereka.</p>

        <p>Mediacartz tidak secara sadar mengumpulkan Informasi Identifikasi Pribadi dari anak-anak di bawah usia 13 tahun. Jika Anda berpikir bahwa anak Anda memberikan informasi semacam ini di situs web kami, kami sangat menganjurkan Anda untuk segera menghubungi kami dan kami akan melakukan upaya terbaik kami untuk segera menghapus informasi tersebut dari catatan kami.</p>

        <h5>Hanya Kebijakan Privasi Online</h5>

        <p>Kebijakan Privasi ini hanya berlaku untuk aktivitas online kami dan berlaku untuk pengunjung situs web kami sehubungan dengan informasi yang mereka bagikan dan / atau kumpulkan dalam Mediacartz. Kebijakan ini tidak berlaku untuk informasi apa pun yang dikumpulkan secara offline atau melalui saluran selain situs web ini.</p>

        <h5>Persetujuan</h5>

        <p>Dengan menggunakan situs web kami, Anda dengan ini menyetujui Kebijakan Privasi kami dan menyetujui Syarat dan Ketentuannya.</p>

      </Container>
      <FooterV2 />
    </ContainerInti>
  );
};

export default FaqPage;

const ContainerInti = styled.div`
  ${Mobile_query_v2} {
    overflow-x: hidden;
  }
  background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  );
  z-index: 1;
`;
