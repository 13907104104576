import DekstopFooter from "./dekstop";
import MobileFooter from "./mobile";
import { useMediaQuery } from "react-responsive";
import { MediaQueries } from "../../../util/Query"
import LogoMediacartz from "../../assets/iconAndLogo/logo mediacartz resized-400-02-02 2.png";



const FooterV2 = () => {
  const isDesktop = useMediaQuery({ query: MediaQueries.desktopQueries })
  const isMobile = useMediaQuery({ query: MediaQueries.mobileQueries })

  console.log({ isDesktop, isMobile })

  if (isDesktop) {
    return <DekstopFooter />
  }
  else {
    return <MobileFooter
      logo={LogoMediacartz}
      desc={"An integrated digital engagement platfrom for your business needs and improvements"}
    />
  }
}

export default FooterV2;