import React from "react";
import styled from "styled-components";
import FacebookSvg from "landingPage/assets/iconAndLogo/f.svg";
import Lingked from "landingPage/assets/iconAndLogo/l.svg";
import Twitter from "landingPage/assets/iconAndLogo/t.svg";
import Instagram from "landingPage/assets/iconAndLogo/ig.svg";
import ImgMediacartz from "landingPage/assets/iconAndLogo/logo MEDIACARTZ-02 1.png";
import phoneLogo from "landingPage/assets/iconAndLogo/phone-call.png";
import IconEmailOutline from "landingPage/assets/iconAndLogo/emailoutline.png";
import { Mobile_query_v2 } from "../../util/Query";

const Footer = () => {
  return (
    <Container>
      <div className="top d-flex">
        <div className="left">
          <img
            src={ImgMediacartz}
            alt={ImgMediacartz}
            className="LogoMediacartz"
          />
          <div className="text-random">
            An integrated digital engagement platfrom for your business needs
            and improvements.
          </div>
          <div className="wrap-icon-sosmed d-flex gap-4">
            {[FacebookSvg, Lingked, Twitter, Instagram].map((item, idx) => (
              <img src={item} alt={item} key={idx} />
            ))}
          </div>
        </div>
        <div className="right-mobile">
          <div className="title">MENU</div>
          <div className="wrap-menu d-flex">
            {["Products", "Resources", "Solutions", "Company"].map(
              (item, idx) => (
                <div key={idx} className="text-menu">
                  {item}
                </div>
              )
            )}
          </div>
        </div>
        <div className="center">
          <div className="text-information">INFORMATION</div>
          <div className="address">
            Centennial Tower, 38’th floor, Unit H Jl. Gatot Subroto, kavling
            24-25, Jakarta Selatan, 12930, Indonesia
          </div>
          <div className="wrap-icon-sosmed-mobile gap-4">
            {[FacebookSvg, Lingked, Twitter, Instagram].map((item, idx) => (
              <img src={item} alt={item} key={idx} />
            ))}
          </div>
          <div className="wrap-text-small d-flex gap-4">
            <div className="contact d-flex align-items-center">
              <img src={phoneLogo} alt={phoneLogo} />
              <div className="text">(021)-22958146</div>
            </div>
            <div className="contact d-flex align-items-center">
              <img src={IconEmailOutline} alt={IconEmailOutline} />
              <div className="text">cs@mediacartz.com</div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="title">MENU</div>
          <div className="wrap-menu d-flex">
            {["Products", "Resources", "Solutions", "Company"].map(
              (item, idx) => (
                <div key={idx} className="text-menu">
                  {item}
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="text-bottom text-center">
          Copyright © 2022 Mediacartz. All Rights Reserved
        </div>
      </div>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  ${Mobile_query_v2} {
    .center {
      .wrap-text-small {
        margin-top: 20px;
      }
    }
    .top {
      .right {
        display: none;
      }

      .right-mobile {
        display: unset !important;
        flex-wrap: wrap;
      }
      .right-mobile > .wrap-menu {
        gap: 43px;
        flex-wrap: wrap;
      }

      .right-mobile > .wrap-menu > .text-menu {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 36px;
        text-align: center;

        color: #ffffff;
        text-transform: capitalize;
      }

      .right-mobile > .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 45px;
        margin-bottom: 43px;

        color: #ffffff;
      }
      .right-mobile {
        .title {
          margin-bottom: 20px !important;
        }
        .wrap-menu {
          font-size: 14px !important;
          gap: 24px !important;
        }
      }
      .center {
        .wrap-icon-sosmed-mobile {
          display: flex !important;
        }
        .address {
          margin-top: 20px !important;
          font-size: 15px !important;
        }
      }
      .left {
        .wrap-icon-sosmed {
          display: none !important;
        }
        .LogoMediacartz {
          width: 179px !important;
          height: 32px;
          object-fit: contain;
        }
        .text-random {
          font-size: 15px !important;
          margin-bottom: 20px;
        }
      }
      flex-direction: column !important;
      padding: 47px 32px !important;
    }
  }
  .bottom {
    background: linear-gradient(0deg, #3b3e98 0.82%, #1c4370 94.48%);
    min-height: 110px;
    padding: 24px 0px;

    .text-bottom {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 36px;
      /* identical to box height, or 257% */

      text-align: center;

      color: #ffffff;
    }
  }
  .top {
    background-color: #3b3e98;
    min-height: 385px;
    padding: 62px 137px;
    border-bottom: 10px solid #2fbeee;
    gap: 85px;
    .right-mobile {
      display: none;
    }
    .right {
      flex-wrap: wrap;
    }
    .right > .wrap-menu {
      gap: 43px;
      flex-wrap: wrap;
    }

    .right > .wrap-menu > .text-menu {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 36px;
      text-align: center;

      color: #ffffff;
      text-transform: capitalize;
    }

    .right > .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 45px;
      margin-bottom: 43px;

      color: #ffffff;
    }

    .center {
      .wrap-icon-sosmed-mobile {
        display: none;
      }
      .text-information {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 45px;

        color: #ffffff;
      }

      .address {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        margin-top: 43px;
        margin-bottom: 14px;
        color: #ffffff;
      }

      .contact {
        gap: 15px;
      }

      .contact > img {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }

      .contact > .text {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 36px;
        /* or 225% */

        color: #ffffff;
      }
    }

    .left .LogoMediacartz {
      width: 230px;
    }

    .left {
      .text-random {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-top: 43px;
        color: #ffffff;
      }
    }

    .left,
    .center,
    .right,
    .right-mobile {
      flex: 1;
    }
  }
  margin-top: 110px;
  min-height: 495px;
`;
