import styled from 'styled-components'


export const ContainerLogin = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100vw;
min-height:100vh;
flex-direction: column;
`

export const FormInputForgot = styled.div`

`

export const TextForgot = styled.p`
color:#757575;
text-align:center;
`