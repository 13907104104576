import React from "react"
import { Modal_Component } from "component"
import styled from "styled-components"

const ModalPratinjauHtml = ({
  show = false,
  onHide,
  params = {}
}) => {
  const { htmlContentBuilder } = params


  return (
    <Modal_Component title="Pratinjau HTML" fullscreen show={show} onHide={onHide}>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: htmlContentBuilder }} />
      </Container>
    </Modal_Component>
  )
}


export default ModalPratinjauHtml;


const Container = styled.div`

margin: 150px auto; max-width: 1050px; width:100%; padding:0 35px; box-sizing: border-box;
`