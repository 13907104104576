import styled from "styled-components";
import KVisionLogo from "../../../../assets/iconAndLogo/kvisionLogo.png";
import Ismaya from "../../../../assets/iconAndLogo/ismaya.png";

const OurClient = () => {
  return (
    <Container>
      <div className="blue-text">OUR CLIENT</div>
      <div className="title-our-client">Lorem ipsum dolor sit amet</div>
      <div className="container-list-logo-our-client">
        <img src={KVisionLogo} alt={KVisionLogo} />
        <img src={Ismaya} alt={Ismaya} />
      </div>
    </Container>
  );
};

export default OurClient;

const Container = styled.div`
  margin-top: 126px;
  .blue-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 5px;
    text-transform: uppercase;

    /* Brand Colors/Tertiary/100 */

    color: #2fbeee;
  }

  .title-our-client {
    /* Heading/S */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */

    text-align: center;
    letter-spacing: -0.017em;

    /* Neutral/100 */

    color: #222833;
  }

  .container-list-logo-our-client {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 39px;
    gap: 56px;
    flex-wrap: wrap;
    img {
      height: 100px;
      object-fit: contain;
    }
  }
`;
