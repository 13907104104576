import React, { useState, useEffect, useContext } from "react";
import * as Component from "../../../../../component";
import * as Style from "../../../../../component/style/content/default";
import * as Services from "../../../../../service";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Select from "react-select";

const api = Services.api.ApiEvent.Microsite;

const ModalEditMicrosite = (props: {
  show: Boolean,
  onHide: Function,
  dataProps: any,
  params: any,
}) => {
  const { htmlContentBuilder, dispatch, data_user } = useContext(
    Services.Context
  );
  const { show, onHide, dataProps, params } = props;
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      microsite_name: "",
      microsite_content: "",
      microsite_form_name: "",
      microsite_form_description: "",
      microsite_form_success_message: "",
      microsite_own_url: "",
    },
  });
  const { microsite_content } = getValues()
  const [selected, setSelected] = useState({
    company: "",
  });
  const [options, setOptions] = useState({
    company: [],
  });
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [modal, setModal] = useState(false);

  const getCompany = async () => {
    await api.getCompany().then((res) => {
      if (res.success) {
        setOptions((state) => ({
          ...state,
          company: res?.data?.map((item) => ({
            value: item.company_id,
            label: item.company_name,
          })),
        }));
      }
    });
  };

  const getDetailMicrosite = async () => {
    setLoadingPage(true);
    await api
      .getMicrositeDetail(params.id)
      .then((res) => {
        let dataResult = res.data;
        if (res?.success) {
          setValue("microsite_name", dataResult.microsite_name);
          setValue("microsite_content", dataResult.microsite_content);
          setValue("microsite_form_name", dataResult.microsite_form_name);
          setValue(
            "microsite_form_description",
            dataResult.microsite_form_description
          );
          setValue(
            "microsite_form_success_message",
            dataResult.microsite_form_success_message
          );
          setValue("microsite_own_url", dataResult.microsite_own_url);
          setSelected((state) => ({
            ...state,
            company: {
              value: dataResult.company_id,
              label: dataResult.company?.company_name,
            },
          }));
        }
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  useEffect(async () => {
    if (show) {
      await getCompany();
      await getDetailMicrosite();
    }
  }, [show]);

  useEffect(() => {
    setValue("microsite_content", htmlContentBuilder);
  }, [htmlContentBuilder]);

  const BtnSubmit = async (body) => {
    body = {
      ...body,
      company_id: selected.company.value,
      user_id: data_user.id,
    };
    setLoading(true);
    await api.putMicrosite(body, params.id).then(async (res) => {
      // console.log("ini response res", res)
      if (res?.success) {
        await Promise.all([
          // dataProps?.getList(),
          Component.AlertSuccess({ title: "Success", text: res?.success }),
          onHide(),
          window.location.reload()
        ]);
      } else {
        Component.AlertError({ title: "Error", text: res?.error });
      }
      setLoading(false);
    });
  };

  return (
    <Component.Modal_Component
      size="xl"
      show={show}
      onHide={onHide}
      title="Microsite"
      btnSubmit
      btnName={loading ? <Component.LoadingIcon /> : "Simpan"}
      onClick={loading ? null : handleSubmit(BtnSubmit)}
    >
      {loadingPage ? (
        <Component.Loadingfunc />
      ) : (
        <Container>
          {/* MODAL ==== */}
          <Component.IdxContentBuilder
            show={modal}
            onHide={() => setModal(false)}
            html={microsite_content}
          />
          <div className="form">
            <div className="mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>
                Nama Microsite
              </Style.Label>
              <input
                type="text"
                placeholder="Masukkan Nama Microsite"
                className="form-control"
                {...register("microsite_name", { required: true })}
              />
              {errors.microsite_name && "Field is Required"}
            </div>
            <div className="mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>
                Konten Microsite
              </Style.Label>
              <Style.ButtonPrimary
                className="mb-3"
                onClick={() => setModal(true)}
              >
                Buka Html Builder
              </Style.ButtonPrimary>
              <textarea
                type="text"
                placeholder="Html Builder"
                className="form-control"
                {...register("microsite_content")}
              />
            </div>
            <div className="mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>
                Nama Form Feedback / Survey
              </Style.Label>
              <input
                type="text"
                placeholder="Masukkan Nama Form Feedback / Survey"
                className="form-control"
                {...register("microsite_form_name", { required: true })}
              />
              {errors.microsite_form_name && "Field is Required"}
            </div>
            <div className="mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>
                Deskripsi Form Feedback / Survey
              </Style.Label>
              <input
                type="text"
                placeholder="Masukkan Deskripsi Form"
                className="form-control"
                {...register("microsite_form_description", { required: true })}
              />
              {errors.microsite_form_description && "Field is Required"}
            </div>
            <div className="mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>
                Pesan Berhasil / Terimakasih (Setelah user mengisi form)
              </Style.Label>
              <input
                type="text"
                placeholder="Masukkan Pesan"
                className="form-control"
                {...register("microsite_form_success_message", {
                  required: true,
                })}
              />
              {errors.microsite_form_success_message && "Field is Required"}
            </div>
            <div className="mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>
                Url Kepemilikan Microsite
              </Style.Label>
              <input
                type="text"
                placeholder="Masukkan Url https://yourwebsite.com"
                className="form-control"
                {...register("microsite_own_url")}
              />
            </div>
            <div className="mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>
                Perusahaan
              </Style.Label>
              <Select
                placeholder="Select Perusahaan"
                options={options.company}
                value={selected.company}
                onChange={(e) =>
                  setSelected((state) => ({ ...state, company: e }))
                }
                isDisabled
              />
            </div>
          </div>
        </Container>
      )}
    </Component.Modal_Component>
  );
};

export default ModalEditMicrosite;

const Container = styled.div`
  .form {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    color: rgba(10, 10, 10, 0.5);
    max-width: 100%;
    position: relative;
    border-radius: 10px;
  }
`;
