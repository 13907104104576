import styled from "styled-components";
import CardSolutions from "../../components/ProductAndSolution/cardSolutions";

const Ourvalue = () => {
  return (
    <Container className=" pt-5">
      <div className="container">
        <div className="title text-center">Our value</div>
        <div className="sub-title text-center">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. <br /> Enim
          optio aspernatur quae possimus autem sint esse saepe veniam architecto
          doloribus.
        </div>
        <div className="row justify-content-center overflow-auto mt-5">
          <div className="col-auto">
            <CardSolutions />
          </div>
          <div className="col-auto">
            <CardSolutions />
          </div>
          <div className="col-auto">
            <CardSolutions />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Ourvalue;

const Container = styled.div`
  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 46px;
    color: rgb(59, 62, 152);
  }
`;
