import { Method } from "../..";

class Microsite {
  getMicrositeList(page) {
    return Method.get(
      `microsite?order_by=microsite_id&sort_by=DESC&page=${page}`
    )
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  getMicrositeDetail(microsite_id) {
    return Method.get(`microsite/${microsite_id}`)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  getCompany() {
    return Method.get(
      `company?company_active_status=1&company_verified_status_name=verified`
    )
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  postMicrosite(body) {
    return Method.post(`microsite`, body)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  putMicrosite(body, microsite_id) {
    return Method.put(`microsite/${microsite_id}`, body)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  getMicrositeForm(microsite_id) {
    return Method.get(`microsite/${microsite_id}/form`)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  getMsFormat() {
    return Method.get(`list/ms_format`)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  postFormMicrosite(body, microsite_id) {
    return Method.post(`microsite/form/${microsite_id}`, body)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  putFormMicrosite(body, microsite_form_id) {
    return Method.put(`microsite/form/${microsite_form_id}`, body)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }
  deleteFormMicrosite(microsite_form_id) {
    return Method.delete(`microsite/form/${microsite_form_id}`)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  getListFeedback(microsite_id, page = 1) {
    return Method.get(`microsite/${microsite_id}/feedback?page=${page}`)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }

  getSummaryFeedback(microsite_id) {
    return Method.get(`microsite/${microsite_id}/profiling`)
      .then((res) => res?.data)
      .catch((err) => err?.response);
  }
}

export default new Microsite();
