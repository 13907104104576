import styled from "styled-components";

const BenefitsPublisher = () => {
  return (
    <Container className="container pt-5">
      <div className=" container-fluid">
        <div className="title text-center">Benefits With Us</div>
        <div className="row mt-5">
          {[1, 1, 1, 1, 2, 2, 2, 2].map((item, idx) => (
            <div className="col-md-3 col-sm-12 p-2" key={idx}>
              <Card
                color={
                  idx == 1 || idx == 3 || idx == 4 || idx == 6 ? true : false
                }
              />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default BenefitsPublisher;

const Card = ({ color = false }) => {
  return (
    <ContainerCard color={color}>
      <h3 className="number-card">01</h3>
      <div className="title-card">Hight Quality</div>
      <div className="sub-title-card">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
        repellendus totam, fuga perspiciatis nulla est. Obcaecati optio velit
        saepe, amet repudiandae aspernatur debitis! Fugiat omnis illum in dolore
        mollitia quae.
      </div>
    </ContainerCard>
  );
};

const Container = styled.div`
  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 46px;
    color: rgb(59, 62, 152);
  }
`;

const ContainerCard = styled.div`
  background-color: ${({ color }) =>
    color ? "rgb(59, 62, 152)" : "transparent"};
  border-radius: 10px;
  text-align: center;
  padding: 2em;
  height: 350px;

  .number-card {
    font-weight: 700;
    color: ${({ color }) => (color ? "white" : "rgb(59, 62, 152)")};
  }
  .title-card {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 46px;
    color: ${({ color }) => (color ? "white" : "rgb(59, 62, 152)")};
  }
  .sub-title-card {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    margin-top: 21px;

    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    display: -webkit-box;

    /* Dark Light */

    color: ${({ color }) => (color ? "white" : "rgba(12, 27, 77, 0.6)")};
  }
`;
