import styled from "styled-components";

const OurTestiItem = () => {
  return (
    <Container>
      <div className="description-testi-item">
        Agaya Cafe & Eatery baru buka beberapa bulan, walau lokasi kami bukan di
        jalan utama tapi Puji Tuhan resto kami mulai ramai karena mendapat
        informasi dari SMS Blast yang kami kirimkan. Selain customer yang
        datang, transaksi online juga meningkat karena kami memberikan free
        delivery untuk radius 5km dari resto, hal ini karena sistem dari
        Mediacartz kita setting sendiri radius SMS yang akan kita blast.
      </div>
      <div className="container-img-and-name d-flex">
        <div className="container-img">
          <img
            src="https://images.unsplash.com/photo-1457449940276-e8deed18bfff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80"
            alt="https://images.unsplash.com/photo-1457449940276-e8deed18bfff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80"
          />
        </div>
        <div className="containter-title-and-sub-title">
          <div className="title-testi-item">John Doe</div>
          <div className="sub-title-testi-item">
            Owner Agaya Resto & Cafe Semarang
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OurTestiItem;

const Container = styled.div`
  /* Neutral/100 */

  background: #ffffff;
  /* Neutral/300 */

  border: 1px solid #eff0f7;
  /* Cards/Short Default */

  box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
  border-radius: 20px;
  padding: 55px 41px;

  .description-testi-item {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    /* Neutral/600 */

    color: #6f6c90;
  }

  .container-img-and-name {
    margin-top: 27px;
    gap: 7px;
    align-items: center;
    .container-img {
      img {
        width: 76px;
        height: 76px;
        overflow: hidden;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    .containter-title-and-sub-title {
      .title-testi-item {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height, or 111% */

        /* Neutral/800 */

        color: #170f49;
      }
      .sub-title-testi-item {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height, or 111% */

        /* Neutral/600 */

        color: #6f6c90;
      }
    }
  }
`;
