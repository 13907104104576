import React from "react";
import styled from "styled-components";
import Navbar from "landingPage/components/Navbar";
import Banner from "./banner";
import Product from "./product";
import { Mobile_query_v2 } from "../../../util/Query"
import Footer from "landingPage/components/footer";

const ProductMessaging = () => {
  return (
    <Container>
      <Navbar />
      <Container2>
        <Banner />
      </Container2>
      <Product />
      <Footer />
    </Container>
  );
};

export default ProductMessaging;

const Container2 = styled.div`
${Mobile_query_v2} {
    overflow-x: hidden;
    padding: 0 25px;
  }
  padding: 0 93px;

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;

const Container = styled.div`
  background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  );
  position: relative;
`;
