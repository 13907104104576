import React from "react";
import styled from "styled-components";
import Input, {
  InputSelectProductAndSolution,
} from "landingPage/components/input/inputProductAndSolution";
import InputRadio from "landingPage/components/input/inputRadioProductAndSolution";
import Select from "landingPage/components/input/inputSelectProductAndSolution";
import personOutline from "landingPage/assets/iconAndLogo/person-outline.png";
import mailOutline from "landingPage/assets/iconAndLogo/mail-outline.png";
import phoneOutline from "landingPage/assets/iconAndLogo/phone-outline.png";
import businessOutline from "landingPage/assets/iconAndLogo/business-outline.png";
import imgDocresult from "landingPage/assets/iconAndLogo/Group37964.png";
import { useForm } from "react-hook-form";

const dataProgress = [
  {
    step: 1,
    active: true,
    activeFull: false,
  },
  {
    step: 2,
    active: false,
    activeFull: false,
  },
  {
    step: 3,
    active: false,
    activeFull: false,
  },
  {
    step: 4,
    active: false,
    activeFull: false,
  },
];

const FormInput = () => {
  const [stepProgress, setStepProgress] = React.useState(dataProgress);
  const [activeStep, setActiveStep] = React.useState(1);
  const { register, handleSubmit } = useForm();

  const btnNextSubmit = (e) => {

    let newState = stepProgress.map((item) => {
      if (item.step === activeStep) {
        return {
          ...item,
          activeFull: true,
        };
      }
      if (item.step === activeStep + 1) {
        return {
          ...item,
          active: true,
        };
      }
      return item;
    });
    setStepProgress(newState);
    setActiveStep(activeStep + 1);
  };
  const btnPrev = () => {
    let newState = stepProgress.map((item) => {
      if (item.step === activeStep - 1) {
        return {
          ...item,
          activeFull: false,
        };
      }
      if (item.step === activeStep - 1 + 1) {
        return {
          ...item,
          active: false,
        };
      }
      return item;
    });
    setStepProgress(newState);
    setActiveStep(activeStep - 1);
  };

  return (
    <Container>
      <div className="title-form">CRM Data Customer</div>
      <div className="desc-form">
        Mediacartz will help you with understand customer need and bring
        solution on the go
      </div>
      <div className="list-progress d-flex border-bottom justify-content-between">
        {stepProgress.map((item, idx) => (
          <ContainerProgress
            active={item.active}
            activeFull={item.activeFull}
            key={idx}
            className="wrap-progress d-flex gap-4 align-items-center"
          >
            <div className="rounded-point">{item.step}</div>
            {stepProgress.slice(-1)[0]?.step === item.step ? null : (
              <div className="line-point" />
            )}
          </ContainerProgress>
        ))}
      </div>

      {stepProgress.map((item) => {
        if (
          item.activeFull === false &&
          item.step === 1 &&
          item.active === true
        ) {
          {
            /* section 1 */
          }
          return (
            <ContainerInput className="container-input">
              <div className="title">Contact Detail</div>
              <div className="desc">
                Please fill your information so we can get in touch with you.
              </div>

              <form className="container-input">
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      register={register}
                      required={true}
                      name="name"
                      props={{
                        name: "name",
                        label: "Name",
                        placeholder: "Name",
                        icon: personOutline,
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      register={register}
                      required={true}
                      name="email"
                      props={{
                        name: "email",
                        label: "email",
                        placeholder: "Email",
                        icon: mailOutline,
                        type: "email",
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <div style={{ marginTop: "40px" }}>
                      <Input
                        register={register}
                        required={true}
                        name="phoneNumber"
                        props={{
                          name: "Phone Number",
                          label: "Phone Number",
                          placeholder: "Phone Number",
                          icon: phoneOutline,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div style={{ marginTop: "40px" }}>
                      <InputSelectProductAndSolution
                        register={register}
                        required={true}
                        name="businessCategory"
                        props={{
                          name: "BusinessCategory",
                          label: "Business Category",
                          placeholder: "Business Category",
                          icon: businessOutline,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </ContainerInput>
          );
        }
        if (
          item.activeFull === false &&
          item.step === 2 &&
          item.active === true
        ) {
          // /* section 2 */

          return (
            <ContainerInput className="container-input">
              <div className="title">How much your product?</div>
              <div className="desc">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              </div>

              <form className="container-input">
                <div className="row">
                  <div className="col-md-6">
                    <InputRadio name="omzet" label="< 50 pcs" />
                  </div>
                  <div className="col-md-6">
                    <InputRadio name="omzet" label="50 - 100 pcs" />
                  </div>
                  <div className="col-md-6">
                    <div style={{ marginTop: "20px" }}>
                      <InputRadio name="omzet" label="100 - 500 pcs" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div style={{ marginTop: "20px" }}>
                      <InputRadio name="omzet" label="> 5000 pcs" />
                    </div>
                  </div>
                </div>
              </form>
            </ContainerInput>
          );
        }
        if (
          item.activeFull === false &&
          item.step === 3 &&
          item.active === true
        ) {
          {
            /* section 3 */
          }
          return (
            <ContainerInput className="container-input">
              <div className="title">
                do you have data transaction from customer ?
              </div>
              <div className="desc">please select one.</div>
              <form className="container-input">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div style={{ marginTop: "20px" }}>
                      <InputRadio name="customer" label="Yes" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div style={{ marginTop: "20px" }}>
                      <InputRadio name="customer" label="No" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="mb-3">
                      What do you use data customer for ?
                    </label>
                    <Select placeholder="Select Function" />
                  </div>
                </div>
              </form>
            </ContainerInput>
          );
        }
        // if (
        //   item.activeFull === false &&
        //   item.step === 4 &&
        //   item.active === true
        // ) {
        //   {
        //     /* section 4 */
        //   }
        //   return (
        //     <ContainerInput className="container-input">
        //       <div className="title">Target Event</div>
        //       <div className="desc">Please Select target audience for your event plan</div>

        //       <form className="container-input">
        //         <div className="row">
        //           <div className="col-md-6">
        //             <InputRadio name="target" label="Yes" />
        //           </div>
        //           <div className="col-md-6">
        //             <div className="mt-2">
        //               <InputRadio name="target" label="No" />
        //             </div>
        //           </div>
        //         </div>
        //       </form>
        //     </ContainerInput>
        //   );
        // }
        if (
          item.activeFull === false &&
          item.step === 4 &&
          item.active === true
        ) {
          {
            /* // section 5 */
          }
          return (
            <ContainerFinalSection>
              <img src={imgDocresult} alt={imgDocresult} />
              <div className="title">Submit your quote request</div>
              <div className="mini-text">
                Please review all the information you previously typed in the
                past steps, and if all is okay, submit your message to receive a
                feedback in 24 - 48 hours.
              </div>
            </ContainerFinalSection>
          );
        }
      })}
      <ContainerButton className="container-button d-flex align-items-center justify-content-between">
        {activeStep === 1 ? null : (
          <button className="btn-previous" onClick={btnPrev}>
            Previous Step.
          </button>
        )}
        {activeStep === 4 ? (
          <button className="btn-submit">Submit</button>
        ) : (
          <button className="btn-submit" onClick={handleSubmit(btnNextSubmit)}>
            Next Step
          </button>
        )}
      </ContainerButton>
    </Container>
  );
};

export default FormInput;

const ContainerButton = styled.div`
  margin-top: 70px;

  .btn-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px 21px;
    gap: 8px;

    width: 165px;
    height: 61px;

    /* Primary */

    background: #3b3e98;
    box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
    border: thin solid #3b3e98;
    border-radius: 56px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */

    text-align: center;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .btn-previous {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 19px 40px 21px;

    width: 200px;
    height: 60px;

    /* Primary */

    border: 1px solid #3b3e98;
    border-radius: 66px;
    background-color: transparent;

    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height, or 111% */

    text-align: center;

    /* Primary */

    color: #3b3e98;
  }
`;

const ContainerFinalSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    /* identical to box height, or 146% */

    text-align: center;

    /* Black 2 */

    color: #1a202c;
  }

  .mini-text {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    text-align: center;

    /* Neutral/600 */

    color: #6f6c90;
  }
`;

const ContainerInput = styled.div`
  margin-top: 65px;
  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 167% */

    /* Neutral/600 */

    color: #6f6c90;
  }

  .title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    /* identical to box height, or 146% */

    /* Black 2 */

    color: #1a202c;
    margin-bottom: 8px;
  }
`;

const ContainerProgress = styled.div`
  .line-point {
    width: 100px;
    height: 6px;
    background-color: #eff0f7;
    border-radius: 40px;
    position: relative;
    overflow: hidden;

    ${({ active, activeFull }) =>
    active &&
    `
      &::after {
        content: "";
        width: ${activeFull ? "100px" : "50px"};
        height: 6px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 40px;
        background-color: #3b3e98;
      }
    
    `}
  }
  .rounded-point {
    display: flex;
    width: 34px;
    height: 34px;
    background-color: ${({ active }) => (active ? "#3B3E98" : "#eff0f6")};
    color: ${({ active }) => (active ? "#fff" : "#6f6c90")};
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */

    border-radius: 100px;
    align-items: center;
    justify-content: center;
    position: relative;

    ${({ active }) =>
    active &&
    `&::after {
      content: "";

      width: 44px;
      height: 44px;
      background-color: transparent;
      border-radius: 100px;
      border: 2px solid #3b3e98;
      position: absolute;
    }`}
  }
`;

const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 1068px;
  border-top-left-radius: 10000px;
  border-bottom-left-radius: 10000px;
  padding-left: 322px !important;
  padding: 100px;
  overflow: hidden;

  .list-progress {
    margin-top: 60px;
    padding-bottom: 40px;
  }

  .title-form {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    /* identical to box height, or 135% */

    text-align: center;

    /* Neutral/800 */

    color: #3b3e98;
  }

  .desc-form {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    text-align: center;

    /* Dark Light */

    color: rgba(12, 27, 77, 0.6);
  }
`;
