import styled from "styled-components";
import PlacementMTRItem from "./placement_mtr_item";

const PlacementMTR = () => {
  return (
    <Container>
      <div className="title-placementMTR">
        Television - Commercial Brake 15s Reguler
      </div>
      <div className="list-placementMTR">
        {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, dix) => (
          <PlacementMTRItem key={dix} />
        ))}
      </div>
    </Container>
  );
};

export default PlacementMTR;

const Container = styled.div`
  min-height: 531px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  /* Shadow 3 */

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.12);
  padding: 45px 41px;

  .list-placementMTR {
    height: 379px;
    overflow: auto;
    margin-top: 32px;
    padding: 28px;

    background: #ffffff;
    border: 1px solid #c1c1c1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    overflow: auto;
    justify-content: center;
    gap: 12px;
    row-gap: 40px;
  }

  .title-placementMTR {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 33px;
    text-transform: capitalize;

    color: #161c2b;
  }
`;
