import React from "react";
import styled from "styled-components";
import ImgLeft from "landingPage/assets/iconAndLogo/Group 33439.png";
import imgDollar from "landingPage/assets/iconAndLogo/bagRupiah.png";
import imgPinLoc from "landingPage/assets/iconAndLogo/Icon Pin Loc.png";
import iconSearch from "landingPage/assets/iconAndLogo/Icon Search.png";
import SelectWithSearch from "./SelectOptions/SelectWithSearch";
import { Mobile_query_v2 } from "../../util/Query";

const Budget = () => {
  return (
    <Container className="d-flex">
      <div className="left">
        <img src={ImgLeft} alt={ImgLeft} />
      </div>
      <div className="right">
        <div className="title">Budget Friendly</div>
        <div className="desc">
          Search and explore our omni-channels of engagement, advertising &
          communication based on your budget, here!
        </div>
        <div className="wrapper-img-banner" style={{ display: "none" }}>
          <img className="img-banner" src={ImgLeft} alt={ImgLeft} />
        </div>

        <div className="wrap-form d-flex align-items-center">
          <div className="left-form d-flex align-items-center">
            <div className="wrap-icn">
              <img src={imgDollar} alt={imgDollar} />
            </div>
            <div className="wrap-txt">
              <div className="text-top">Fill Your</div>
              <input
                className="form-control"
                placeholder="Budget In Here"
                style={{ border: "none" }}
              />
            </div>
          </div>

          <div className="center-form d-flex align-items-center">
            <div className="wrap-icn">
              <img src={imgPinLoc} alt={imgPinLoc} />
            </div>
            <div className="wrap-txt d-flex flex-grow-1  flex-column">
              <div className="text-top">Choose Your</div>
              {/* <div className="text-bottom">Target Ads Location</div> */}
              <SelectWithSearch />
            </div>
          </div>

          <div className="right-form">
            <button>
              <img src={iconSearch} alt={iconSearch} />
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Budget;

const Container = styled.div`
  ${Mobile_query_v2} {
    .left {
      display: none;
    }
    .right {
      &::after {
        display: none;
      }
      .title {
        text-align: center;
      }
      .desc {
        font-size: 14px !important;
        text-align: center;
      }
      .wrapper-img-banner {
        text-align: center;
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-top: 21px;
        margin-bottom: 10px;
      }
      .img-banner {
        width: 297px;
      }

      .wrap-form {
        flex-direction: column;
        width: 100% !important;
        height: auto !important;

        .left-form {
          border-right: none !important;
          width: 100% !important;
          margin-bottom: 20px;
        }

        .center-form {
          width: 100% !important;
        }
        .right-form {
          width: 100% !important;
          height: auto !important;
          button {
            width: 100% !important;
            height: auto !important;
            padding: 0px 10px;
          }
        }
      }
    }
  }
  .left > img {
    width: 630px;
    height: 350px;
  }
  .right {
    /* overflow: hidden; */
    position: relative;
    z-index: 2;

    &::after {
      content: "";
      width: 80px;
      height: 80px;
      background-color: #fbe555;
      position: absolute;
      top: -30px;
      left: -30px;
      z-index: -1;
      border-radius: 100px;
    }

    .wrap-form {
      padding: 20px;

      .right-form > button {
        background: #3b3e98;
        box-shadow: 0px 64.81px 94.29px rgba(119, 172, 241, 0.0607),
          0px 38.52px 51.28px rgba(119, 172, 241, 0.0486);
        border-radius: 8px;
        width: 60px;
        height: 60px;
        border: thin solid #3b3e98;
      }

      .center-form {
        gap: 10px;
        margin-left: 24px;
        .wrap-txt {
          .text-top {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;

            color: #999999;
          }

          .text-bottom {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;

            color: #1a202c;
          }
        }
      }
      .left-form {
        gap: 10px;
        border-right: thin solid rgba(184, 207, 255, 0.5);
        .wrap-txt {
          .text-top {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;

            color: #999999;
          }

          .text-bottom {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;

            color: #1a202c;
          }
        }
      }
      .left-form,
      .center-form {
        flex: 1;
      }
    }

    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      margin-left: 25px;

      /* Primary */

      color: #3b3e98;
    }

    .desc {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 36px;
      /* or 200% */

      color: rgba(12, 27, 77, 0.6);
      margin-top: 30px;
      margin-bottom: 23px;
    }

    .wrap-form {
      background-color: #ffff;
      /* width: 570px; */
      height: 120px;
      box-shadow: 0px 31px 23px -15px rgba(0, 0, 0, 0.14);
      border-radius: 16px;
    }
  }
  margin-top: 80px;
  overflow: hidden;
  .left,
  .right {
    flex: 1;
  }
`;
