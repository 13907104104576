import React from "react";
import {
  ButtonDisabled,
  ButtonPrimary,
} from "../../../../component/style/content/default";
import { CarouselBanner } from "../../../../component/carousel/carousel.banner";

const OOH = () => {
  return (
    <div className=" pb-lg-5">
      <div className="container">
        <div className="section-header-information text-center pb-5 border-bottom">
          <h3>
            {/* nama channel */}
            OUT OF HOME
          </h3>
          <span>
            {/* nama inventory yang di ambil */}
            OUTDOOR DIGITAL
          </span>
        </div>

        <div className="section-content mt-5">
          <div className="row">
            <div className="col-md-6 col-sm-12 p-1">
              <span>PRODUCT NAME</span>
              <h4>OOH - DIGITAL OUTDOOR - BILLBOARD MALL A</h4>
              <div className="product-desc mt-2">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Excepturi iste perspiciatis dicta voluptatem porro eum expedita
                corrupti deleniti culpa, aliquam accusantium mollitia minima
                error, quas magni eligendi quis aspernatur dignissimos!
                Molestias facere quas accusamus unde at facilis adipisci
                officiis natus cupiditate. In dicta similique omnis quam, fuga,
                accusantium, laudantium quo ducimus repellendus iste animi non
                illo odit et neque voluptate quod nam! Exercitationem
                blanditiis, est quo fugiat, asperiores eveniet ipsa eaque sequi
                cupiditate expedita omnis odio sapiente veritatis at accusamus
                voluptas officiis natus voluptatibus consequuntur nostrum et.
                Debitis nihil eveniet, dolorem ipsa omnis voluptatem ad
                dignissimos, autem quis error ut!
              </div>

              <div className="row container-price mt-lg-5">
                <div className="col-auto">
                  <label htmlFor="">
                    <strong>NORMAL PRICE</strong>
                  </label>
                  <div>Rp.20.000.000</div>
                </div>
                <div className="col-auto">
                  <label htmlFor="">
                    <strong>DISCOUNT PRICE</strong>
                  </label>
                  <div>Rp.20.000.000</div>
                </div>
                <div className="col-auto">
                  <label htmlFor="">
                    <strong>ORDER LIMIT</strong>
                  </label>
                  <div>100 TIMES</div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-auto">
                  <label htmlFor="">
                    <strong>CONTENT DURATION</strong>
                  </label>
                  <div>2 HOURS</div>
                </div>
                <div className="col-auto">
                  <label htmlFor="">
                    <strong>CLASFICATION</strong>
                  </label>
                  <div>TIER-1A</div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6 col-sm-12">
                  <label htmlFor="">
                    <strong>PRODUCT AVAILABILITY</strong>
                  </label>
                  <div>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <th>FROM</th>
                        <td>
                          <div>: 05-jun-2023 00:00</div>
                        </td>
                      </tr>
                      <tr>
                        <th>TO</th>
                        <td>
                          <div>: 05-jun-2023 00:00</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12 col-sm-12">
                  <label htmlFor="">
                    <strong>PRODUCT AVAILABILITY</strong>
                  </label>
                  <div>
                    <table style={{ width: "100%" }}>
                      {[
                        "SUNDAY",
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                        "SATURDAY",
                      ].map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <th>{item}</th>
                            <td>
                              <div className="row">
                                <div className="col-auto">(00:00 - 00:00)</div>
                                <div className="col-auto">(00:00 - 00:00)</div>
                                <div className="col-auto">(00:00 - 00:00)</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>

              <div className="mt-lg-5 d-flex align-items-center gap-2">
                <ButtonDisabled>Back</ButtonDisabled>
                <ButtonPrimary>Create Campaign</ButtonPrimary>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 p-1">
              <div className="container-carousel">
                <CarouselBanner
                  ArrImage={[
                    "https://plus.unsplash.com/premium_photo-1681131449465-d5245b9bddfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3271&q=80",
                    "https://images.unsplash.com/photo-1523800378286-dae1f0dae656?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3352&q=80",
                  ]}
                />
              </div>

              <div className="container-content mt-5">
                <span>PLACEMENT</span>
                <h4>BILLBOARD MALL A</h4>
                <div className="product-desc mt-2">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Excepturi iste perspiciatis dicta voluptatem porro eum
                  expedita corrupti deleniti culpa, aliquam accusantium mollitia
                  minima error, quas magni eligendi quis aspernatur dignissimos!
                  Molestias facere quas accusamus unde at facilis adipisci
                  officiis natus cupiditate. In dicta similique omnis quam,
                  fuga, accusantium, laudantium quo ducimus repellendus iste
                  animi non illo odit et neque voluptate quod nam!
                  Exercitationem blanditiis, est quo fugiat, asperiores eveniet
                  ipsa eaque sequi cupiditate expedita omnis odio sapiente
                  veritatis at accusamus voluptas officiis natus voluptatibus
                  consequuntur nostrum et. Debitis nihil eveniet, dolorem ipsa
                  omnis voluptatem ad dignissimos, autem quis error ut!
                </div>
                <div className="row mt-4">
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="">
                      <strong>PLACEMENT INFORMATION</strong>
                    </label>
                    <div>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th>Location Name</th>
                          <td>
                            <div>: Mall A</div>
                          </td>
                        </tr>
                        <tr>
                          <th>Location Type</th>
                          <td>
                            <div>: Mall Building</div>
                          </td>
                        </tr>
                        <tr>
                          <th>Address</th>
                          <td>
                            <div>: Jln. Bagelen</div>
                          </td>
                        </tr>
                        <tr>
                          <th>Display Size</th>
                          <td>
                            <div>: 50"</div>
                          </td>
                        </tr>
                        <tr>
                          <th>Display Orientation</th>
                          <td>
                            <div>: Landscape</div>
                          </td>
                        </tr>
                        <tr>
                          <th>Media Format</th>
                          <td>
                            <div>: Digital Frame</div>
                          </td>
                        </tr>
                        <tr>
                          <th>Total Display</th>
                          <td>
                            <div>: 1 Spot</div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OOH;
