import styled from "styled-components";
import color from "./color"


class Container{

  rectangle_55 = styled.div `
   
    border-radius: 8px;
    background: ${color.NEUTRAL_10};
    border-radius: 8px;
  `
};


export default new Container()
