import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

const ModalBannerAdvertisingPlacement = ({ show, onHide }) => {
  return (
    <ModalContent show={show} onHide={onHide}>
      <ModaBody>
        <iframe
          src="https://www.youtube.com/embed/_IoT0A-GH0s?autoplay=0&controls=0&loop=0"
          frameborder="0"
          style={{ width: "100%", height: "300px", borderRadius: "35px" }}
        ></iframe>
      </ModaBody>
    </ModalContent>
  );
};

export default ModalBannerAdvertisingPlacement;

const ModalContent = styled(Modal)`
  .modal-content {
    border-radius: 35px !important;
  }
`;

const ModaBody = styled(Modal.Body)`
  border-radius: 35px !important;
`;
