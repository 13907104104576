

 class color{
   PRIMARY_150 = "#171953";
   PRIMARY_130 = "#272975";
   PRIMARY_100 = "#3A3D97"; //DEFAULT COLOR
   PRIMARY_80  = "#5056AB";
   PRIMARY_70  = "#6871BE";
   PRIMARY_60  = "#828DCF";
   PRIMARY_50  = "#9EA9DD";
   PRIMARY_30  = "#BCC5EA";
   PRIMARY_20  = "#DBE2F5";
   PRIMARY_10  = "#F5F7FD";


   INTERACTIVE_10  = "#F3FCFF"
   INTERACTIVE_20  = "#D8F6FF"
   INTERACTIVE_30  = "#BAEDFF"
   INTERACTIVE_50  = "#9EE5FD"
   INTERACTIVE_60  = "#81DCFB"
   INTERACTIVE_70  = "#64D2F7"
   INTERACTIVE_80  = "#49C8F3"
   INTERACTIVE_100 = "#2EBDED"
   INTERACTIVE_130 = "#2084A6"
   INTERACTIVE_150 = "#175F77"
  
   HIGHTIGHT_10   = "#F4F9FE"
   HIGHTIGHT_20   = "#E3F2FB"
   HIGHTIGHT_30   = "#CCE6F7"
   HIGHTIGHT_50   = "#B6D8F1"
   HIGHTIGHT_60   = "#A1C9EA"
   HIGHTIGHT_70   = "#8DB9E3"
   HIGHTIGHT_80   = "#7AA8DA"
   HIGHTIGHT_100  = "#6897D0"
   HIGHTIGHT_130  = "#3E6696"
   HIGHTIGHT_150  = "#1C3654"

   NEUTRAL_0    = '#FFFFFF'
   NEUTRAL_10   = '#F8F8F9'
   NEUTRAL_15   = '#F0F0F3'
   NEUTRAL_20   = '#E3E3EA'
   NEUTRAL_30   = '#C2C2CC'
   NEUTRAL_40   = '#A7A8B6'
   NEUTRAL_50   = '#8C8D9F'
   NEUTRAL_60   = '#727388'
   NEUTRAL_70   = '#585871'
   NEUTRAL_80   = '#3D3E5A'
   NEUTRAL_90   = '#232443'
   NEUTRAL_100  = '#090A2C'


   CRITICAL_10    = "#FEEDED";
   CRITICAL_30    = "#F7B9B9";
   CRITICAL_60    = "#DF6969";
   CRITICAL_80    = "#C5353B";
   CRITICAL_100   = "#A4021F";


   WARNING_10     ="#FEFCE8";   
   WARNING_30     ="#F8EAB9";   
   WARNING_60     ="#E2B369";
   WARNING_80     ="#CA8435";
   WARNING_100    ="#AB5110";


   COLOR_GRADIENT_01 = "linear-gradient(135deg, #171953 0%, #6871BE 100%)"
   COLOR_GRADIENT_02 = "linear-gradient(135deg, #171953 0%, #3A3D97 100%)"

}

export default new color();

