import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CardProduct from "./cardProduct";
import BgDeco from "landingPage/assets/iconAndLogo/BgDeco.png";
import CardSolutions from "./cardSolutions";
import "./styles.contentTAbsV2.css";
import ArrowRight from "landingPage/assets/iconAndLogo/arrow-left-circle.png";
import BgGroup38519 from "landingPage/assets/iconAndLogo/BgGroup38519.png";
import AseetAdvertiding from "landingPage/assets/iconAndLogo/AseetAdvertiding.png";
import AssetEvent from "landingPage/assets/iconAndLogo/AssetEvent.png";
import AssetPublisher from "landingPage/assets/iconAndLogo/AssetPublisher.png";
import AssetCRM from "landingPage/assets/iconAndLogo/AssetCRM.png";
import AssetRetailPOS from "landingPage/assets/iconAndLogo/AssetRetailPOS.png";
import AssetPortalCatalog from "landingPage/assets/iconAndLogo/AssetPortalCatalog.png";
import BgGroup38288 from "landingPage/assets/iconAndLogo/BgGroup38288.png";
import BgGroup38237 from "landingPage/assets/iconAndLogo/BgGroup38237.png";
import BgGroup38511 from "landingPage/assets/iconAndLogo/BgGroup38511.png";
import BgGroup38520 from "landingPage/assets/iconAndLogo/BgGroup38520.png";
import solutionBanking from "landingPage/assets/iconAndLogo/solutionBanking.png";
import solutionRetail from "landingPage/assets/iconAndLogo/solutionRetail.png";
import solutionAgency from "landingPage/assets/iconAndLogo/solutionAgency.png";
import solutionAdPublisher from "landingPage/assets/iconAndLogo/solutionAdPublisher.png";
import solutionEvent from "landingPage/assets/iconAndLogo/solutionevent.png";
import solutionOutSourcing from "landingPage/assets/iconAndLogo/solutionOutsourcing.png";
import ImageCard from "landingPage/assets/iconAndLogo/BgGroup38295.png";
import { Mobile_query_v2 } from "../../../util/Query";
import CardSolutionV2 from "./cardSolutionV2";

const dataCardProduct = [
  {
    image: AssetEvent,
    title: "Eventories",
    desc: "A platform for you to create events, promotions, loyalty programs, and ticketing.",
    href: "/home/product/advertising-placement",
  },
  {
    image: AseetAdvertiding,
    title: "Advertising Placement",
    desc: "Deliver your advertising campaigns effectively, efficiently, and accurately using our omni-channel inventories.",
    href: "/home/product/advertising-placement",
  },
  {
    image: AssetPublisher,
    title: "Ads Publishers",
    desc: "Aggregating multi ads publishers, both conventional and digital, to be part of Mediacartz inventories. ",
    href: "/home/product/advertising-placement",
  },
  {
    image: AssetCRM,
    title: "CRM",
    desc: "Helps you keep and manage all the targeted customer profiles based on data, sales, and events comprehensively.",
    href: "/home/product/advertising-placement",
  },
  {
    image: AssetRetailPOS,
    title: "Retail & POS",
    desc: "Answering the needs of business owners, to have a customized POS system, and e-Commerce retail. ",
    href: "/home/product/advertising-placement",
  },
  {
    image: AssetPortalCatalog,
    title: "Portal Catalog",
    desc: "Building products information and catalogs, personal brands. All impression can be monitord by traffic insight.",
    href: "/home/product/advertising-placement",
  },
];

const dataCardSolutions = [
  {
    image: ImageCard,
    title: "Retail",
    desc: "Business Owners can promote their product or brand digitally",
    icon: solutionRetail,
  },
  {
    image: BgGroup38288,
    title: "Event Organizer",
    desc: "Manage all things relate to event organizing in one platform",
    icon: solutionEvent,
  },
  {
    image: BgGroup38237,
    title: "Banking and Financial",
    desc: "Managing transaction validations using OTP in real-time",
    icon: solutionBanking,
  },
  {
    image: BgGroup38511,
    title: "Outsourcing Business",
    desc: "All the outsourcing task management activities can be monitored in real-time",
    icon: solutionOutSourcing,
  },
  {
    image: BgGroup38520,
    title: "Agency and Brand Company",
    desc: "Manage, create, monitor, and track your campaigns",
    icon: solutionAgency,
  },
  {
    image: BgGroup38520,
    title: "Small Medium Enterprise",
    desc: "Partly, Mediacartz inventories are designed to be very suitable for SMEs who want their businesses to expand and grow",
    icon: solutionAgency,
  },
  {
    image: BgGroup38520,
    title: "Ad Publisher",
    desc: "Utilizing unused available slots from various advertising Publishers into one platform",
    icon: solutionAdPublisher,
  },
];

const ContentTabsV2 = ({ eventKey, handlerChangeThumnail }) => {
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => setWidth(window.innerWidth));
  const swiper = () => {
    new window.Swiper(".slide-content", {
      slidesPerView: 3,
      spaceBetween: 25,
      loop: false,
      centerSlide: "true",
      fade: "true",
      grabCursor: "true",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        520: {
          slidesPerView: 2,
        },
        950: {
          slidesPerView: 3,
        },
      },
    });
  };
  useEffect(() => {
    swiper();
  }, [window.innerWidth]);
  if (eventKey === "products") {
    return (
      <Container>
        <div className="desc">
          Mediacartz provides you with the most effective forms of engagement,
          advertising, communication and management digitally, with its various
          targeted, accurate and efficient features.
        </div>

        <div className="wrap-list-card">
          {dataCardProduct.map((item, idx) => (
            <CardProduct
              key={idx}
              image={item.image}
              title={item.title}
              desc={item.desc}
              href={item.href}
            />
          ))}
        </div>
      </Container>
    );
  } else if (eventKey === "solutions") {
    return (
      <ContainerSolutions>
        <div className="desc">
          Mediacartz provides you with the most effective forms of engagement,
          advertising, communication and management digitally, with its various
          targeted, accurate and efficient features.
        </div>

        {width <= 550 ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {dataCardSolutions.map((item, idx) => (
              <CardSolutionV2 item={item} key={idx} />
            ))}
          </div>
        ) : (
          <div className="slide-container swiper">
            <div className="slide-content">
              <div className="card-wrapper swiper-wrapper">
                {dataCardSolutions.map((item, idx) => (
                  <div className="swiper-slide">
                    <CardSolutions
                      key={idx}
                      image={item.image}
                      title={item.title}
                      desc={item.desc}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="arrow-container position-relative d-flex align-items-center gap-lg-3 p-2 justify-content-end">
              <div className="swiper-button-prev">
                <img src={ArrowRight} alt={ArrowRight} />
              </div>
              <div className="swiper-button-next">
                <img src={ArrowRight} alt={ArrowRight} />
              </div>
            </div>
            {/* <div className="swiper-pagination"></div> */}
          </div>
        )}
      </ContainerSolutions>
    );
  }
};

export default ContentTabsV2;

const ContainerSolutions = styled.div`
  ${Mobile_query_v2} {
    .desc {
      font-size: 14px !important;
    }
    .arrow-container {
      gap: 50px;
    }

    .swiper-slide {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::before {
      background-image: none !important;
    }
  }
  .wrap-list-card-solutions {
    display: flex;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  .desc {
    text-align: center;
    margin-bottom: 80px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    /* or 200% */

    text-align: center;

    color: rgba(12, 27, 77, 0.6);
  }

  z-index: 1;
  position: relative;
  &::before {
    content: "";
    background-image: url(${BgGroup38519});
    z-index: -1;
    width: 533px;
    height: 533px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const Container = styled.div`
  ${Mobile_query_v2} {
    .desc {
      font-size: 14px !important;
    }
    .wrap-list-card {
      display: flex !important;
      flex-direction: column !important;
      grid-column-gap: 10px !important;
      grid-row-gap: 10px !important;
    }
  }
  .wrap-list-card {
    display: grid;
    grid-template-columns: repeat(3, 0fr);
    grid-template-rows: repeat(2, 0fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      width: 660px;
      height: 660px;
      position: absolute;
      background-image: url(${BgDeco});
      background-repeat: no-repeat;
      z-index: -1;
    }
  }

  .desc {
    text-align: center;
    margin-bottom: 80px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    /* or 200% */

    text-align: center;

    color: rgba(12, 27, 77, 0.6);
  }
`;
