import React, { useContext, useEffect, useState } from "react";
import * as Components from "../../../../component";
import * as Style from "../../../../component/style/content/default";
import * as Services from "../../../../service";
import { Method } from "../../../../service";
import { CompanyInactive, Header } from "./components";
import { Numeric } from "../../../../util";
import * as Modal from "./../modal";

const header = [
  "No",
  "MSISDN",
  "Aksi"
];

const data_more = [
  { name: "Ubah" },
  { name: "Hapus" }
];

export default function LoyaltyBlacklistPhone() {
  const { selected_company } = useContext(Services.Context);
  const [loading, setLoading] = useState(false)
  const [allow, setAllow] = useState(false)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  })
  const [form, setForm] = useState(null)
  const [loadingAction, setLoadingAction] = useState(false)

  const getConfig = async () => {
    await Method.get(`loyalty/conf/company/${selected_company.value}`)
    .then(async (res) => {
      if (res?.data?.status) {
        setAllow(true)
      }else{
        if(res?.data?.message === "restricted"){
          setAllow(false)
        }
      }
    })
  }

  const getData = async (pageData = 1) => {
    setLoading(true);

    await Method.get(
      `loyalty/blacklist_phone/company/${selected_company.value}?page=${pageData}`
    )
      .then(async (res) => {
        console.log("res", res)
        if (res.data.success) {
          setData((state) => ({
            ...state,
            data: res.data.data,
            page: res.data.page,
            lastPage: res.data.last_page,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          Components.AlertError({ title: "Error", text: error.response.data.error });
        } else {
          Components.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
        }
      });
  };

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  const deleteData = async (loyalty_blacklist_phone_id) => {
    setLoadingAction(true);
    try {
      await Method.delete(`/loyalty/blacklist_phone/${loyalty_blacklist_phone_id}`)
        .then(async (res) => {
          if (res?.data?.success) {
            Components.AlertSuccess({ title: "Success", text: res?.data?.success });
            getData()
          } else {
            Components.AlertError({ title: "Error", text: res?.data?.error });
          }
        })
    } catch (err) {
      Components.AlertError({ title: "Error", text: err });
    } finally {
      setLoadingAction(false);
    }
  }

  const btnMore = async (name, item) => {
    switch (name) {
      case "Ubah":
        setForm(item);
        break;
      case "Hapus":
        await Components.AlertQuestion({
          title: "Konfirmasi",
          text: "Apakah anda yakin mau menghapus data ini?",
        }).then(async (res) => {
          if (res.isConfirmed) {
            deleteData(item.loyalty_blacklist_phone_id)
          }
        })
        break;
      default:
        return name;
    }
  }

  useEffect(() => {
    if (selected_company.value) {
      // get config detail
      getData()
      getConfig()
    }
  }, [selected_company.value]);

  if(!allow){
    return(
      <Style.ContainerContent>
        <Header title="Blokir MSISDN"/>
        <CompanyInactive/>
      </Style.ContainerContent>  
    )
  }

  return (
    <Style.ContainerContent>
      <Header title="Blokir MSISDN"/>

      <Modal.ModalLoader show={loadingAction}/>

      <Modal.ModalFormBlacklistPhone
        show={form}
        onHide={() => setForm(null)}
        onSubmit={() => {
          getData();
          setForm(null);
        }}
      />

      <section className="form-table-data">
        <div className="mb-2">
          <Style.ButtonPrimary
            onClick={() => setForm('add')}
          >
            Tambah MSISDN
          </Style.ButtonPrimary>
        </div>
        <Components.Form>
          <section className="mb-5 mb-md-5">
            <Components.TableData header={header}>
              {loading ? (
                <tr>
                  <td colSpan={header.length}>
                    <Components.Loadingfunc />
                  </td>
                </tr>
              ) : data.data.length === 0 ? (
                <tr>
                  <td colSpan={header.length} className="text-center">
                    <div>Data Tidak Ditemukan</div>
                  </td>
                </tr>
              ) : (
                data.data.map((item, index) => (
                  <tr
                    key={index}
                  >
                    <td data-label="No">{Numeric({ page: data.page, idx: index })}</td>
                    <td data-label="MSISDN" style={{width:"100%"}}>{item.customer_msisdn}</td>
                    <td data-label="Aksi">
                      <Components.DropDown_More
                        title={
                          <div class="dropdown-trigger">
                            <button
                              class="button is-primary is-small mt-0"
                              aria-haspopup="true"
                              aria-controls="dropdown-menu"
                            >
                              <span class="icon is-small">•••</span>
                            </button>
                          </div>
                        }
                        data_more={data_more}
                        id={item.loyalty_blacklist_phone_id}
                        onClick={({ name, id }) => btnMore(name, item)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </Components.TableData>
          </section>

          {
            data.data.length > 0 &&
            <section>
              <Components.Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            </section>
          }
        </Components.Form>
      </section>
    </Style.ContainerContent>
  );
}