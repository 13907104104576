import styled from "styled-components";
import Navbar from "landingPage/components/Navbar";
import Footer from "landingPage/components/footer";
import { Mobile_query_v2 } from "../../../util/Query";
import Banner from "./banner";
import LetTalks from "./letsTalk";
import MidBanner from "./midBanner";
import VisiMisi from "./visiMisi";

const AboutUs = () => {
  return (
    <ContainerInti>
      <Navbar />
      <Container>
        <Banner />
      </Container>
      <LetTalks />
      <MidBanner />
      <VisiMisi />
      <Footer />
    </ContainerInti>
  );
};

export default AboutUs;

const ContainerInti = styled.div`
  ${Mobile_query_v2} {
    overflow-x: hidden;
  }
  background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  );
  z-index: 1;
`;

const Container = styled.div`
  padding: 0 93px;

  ${Mobile_query_v2} {
    padding: 0 25px;
  }
  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;
