import React, { useState } from "react";
import styled from "styled-components";
import { Mobile_query_v2 } from "../../util/Query";
import IconMenu from "landingPage/assets/iconAndLogo/IconMenu.png";
import { CloseOutline } from "@styled-icons/evaicons-outline";
import LogoMediacartz from "landingPage/assets/iconAndLogo/logo mediacartz resized-400-02-02 2.png";
import IconPeople from "landingPage/assets/iconAndLogo/Group37777.png";
import { ChevronDown } from "@styled-icons/boxicons-regular";
import NavbarData from "./Navbar-data";

const NavbarMobileVersion = () => {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState({
    menu: "",
    subMenu: "",
  });

  const handleRedirectSubMenu = (path) => {
    if (path) {
      return (window.location.href = path);
    }
  };


  const handleSelectMenu = (item) => {
    if (open.menu !== "") {
      setOpen(state => ({
        ...state, menu: ""
      }))
    }
    else {

      setOpen((state) => ({ ...state, menu: item.name }))
    }
  }

  const handleSelectSubMenu = (itemSub) => {
    if (open.subMenu !== "") {
      setOpen((state) => ({
        ...state,
        subMenu: "",
      }))

    }
    else {
      setOpen((state) => ({
        ...state,
        subMenu: itemSub.header,
      }))

    }
  }

  return (
    <Container active={active}>
      <button className="btn btn-menu" onClick={() => setActive(true)}>
        <img src={IconMenu} alt={IconMenu} />
      </button>

      <div id="container-navbar-menu">
        <div className="header-button-icon d-flex align-items-center justify-content-between">
          <img
            src={LogoMediacartz}
            alt={LogoMediacartz}
            onClick={() => (window.location.href = "/home")}
          />
          <button className="btn" onClick={() => setActive(false)}>
            <CloseOutline width={20} />
          </button>
        </div>

        <div className="wrap-account-started d-flex align-items-center justify-content-between">
          <button className="btn d-flex my-account" style={{ gap: "10px" }} onClick={() => window.location.href = "/dashboard"}>
            <img src={IconPeople} alt={IconPeople} />
            My Account
          </button>

          <button className="btn get-started" onClick={() => window.location.href = "/register"}>Get Started</button>
        </div>

        <div className="list-navbar">
          {NavbarData.map((item, idx) => (
            <React.Fragment key={idx}>
              <ContainerMenu
                activeMenu={item.name === open["menu"] ? true : false}
                className="menu-text"
                onClick={() =>
                  handleSelectMenu(item)
                }
              >
                <div className="left-menu-text">
                  <img src="" alt="" />
                  {item.name}
                </div>
                {item.sub?.length > 0 && (
                  <div className="right-menu-text">
                    <ChevronDown width={17} />
                  </div>
                )}
              </ContainerMenu>

              {item.sub?.length > 0 &&
                open["menu"] === item.name &&
                item.sub.map((itemSub) => (
                  <React.Fragment>
                    <ContainerSubMenu
                      activeSubMenu={
                        open["subMenu"] === itemSub.header ? true : false
                      }
                      onClick={() => {
                        handleSelectSubMenu(itemSub);
                        handleRedirectSubMenu(itemSub.path);
                      }}
                      className="sub-menu-text"
                    >
                      <div className="left-sub-menu-text">
                        <img src="" alt="" />
                        {itemSub.header === "Advertising Placement" ||
                          itemSub.header === "Retail & POS" ||
                          itemSub.header === "Ads Publishers" ||
                          itemSub.header === "Eventories" ||
                          itemSub.header === "Portal Catalog"
                          ? itemSub.header
                          : itemSub.name}
                      </div>
                      {itemSub.sub?.length > 0 && (
                        <div className="right-sub-menu-text">
                          <ChevronDown width={17} />
                        </div>
                      )}
                    </ContainerSubMenu>
                    {itemSub.sub?.length > 0 &&
                      open["menu"] === item.name &&
                      open["subMenu"] === itemSub.header &&
                      itemSub.sub?.map((itemSubSub) => (
                        <div
                          className="sub-sub-menu-text"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            (window.location.href = itemSubSub.path)
                          }
                        >
                          <div className="left-sub-sub-menu-text">
                            {itemSubSub.name}
                          </div>
                        </div>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default NavbarMobileVersion;

const ContainerSubMenu = styled.div`
  transition: 250ms;
  cursor: pointer;
  .right-sub-menu-text {
    transform: ${({ activeSubMenu }) =>
    activeSubMenu ? "rotate(180deg)" : "rotate(360deg)"};
  }
`;

const ContainerMenu = styled.div`
  transition: 250ms;
  cursor: pointer;
  .right-menu-text {
    transform: ${({ activeMenu }) =>
    activeMenu ? "rotate(180deg)" : "rotate(360deg)"};
  }
`;

const Container = styled.div`
  display: none;

  ${Mobile_query_v2} {
    #container-navbar-menu {
      .list-navbar > .sub-sub-menu-text {
        .left-sub-sub-menu-text {
          flex: 1;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;

          color: #1a202c;
          display: flex;
          gap: 10px;
        }
        padding-left: 40px;
        display: flex;
        margin-bottom: 12px;
      }
      .list-navbar > .sub-menu-text {
        .left-sub-menu-text {
          flex: 1;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;

          color: #1a202c;
          display: flex;
          gap: 10px;
        }
        padding-left: 15px;
        display: flex;
        margin-bottom: 12px;
      }
      .list-navbar > .menu-text {
        .left-menu-text {
          flex: 1;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;

          color: #1a202c;
          display: flex;
          gap: 10px;
        }
        display: flex;
        margin-bottom: 15px;
      }

      .list-navbar {
        display: flex;
        flex-direction: column;
      }

      .wrap-account-started {
        .get-started {
          background: #3b3e98;
          border-radius: 24px;
          width: 115px;
          height: 37px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          letter-spacing: 0.005em;

          color: #ffffff;
        }
        .my-account {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height */

          color: #1a202c;
        }
        margin-bottom: 30px;
        padding-bottom: 34px;
        border-bottom: 1px solid rgba(47, 190, 238, 0.5);
      }
      .header-button-icon {
        img {
          width: 99px;
        }
        margin-bottom: 60px;
      }
      transition: 500ms;
      width: 100%;
      min-height: 100vh;
      background-color: #fff;
      position: fixed;
      top: ${({ active }) => (active ? "0" : "-150vh")};
      left: 0;
      z-index: 12;
      padding: 0px 27px;
    }
    display: flex;
    flex-direction: column;
  }
`;
