import styled from "styled-components";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import tokcrypto from "landingPage/assets/iconAndLogo/tokocrypto.png";
import ibis from "landingPage/assets/iconAndLogo/ibis.png";
import ismaya from "landingPage/assets/iconAndLogo/ismaya.png";
import blibli from "landingPage/assets/iconAndLogo/BlibLi.png";
import kvision from "landingPage/assets/iconAndLogo/kvisionLogo.png";
import grab from "landingPage/assets/iconAndLogo/grabLogo.png";
import doss from "landingPage/assets/iconAndLogo/doss.png";
import erafone from "landingPage/assets/iconAndLogo/erafone.png";
import debenhams from "landingPage/assets/iconAndLogo/debenhams.png";
import { Mobile_query_v2 } from "../../../util/Query";
import { useState } from "react";

const image_client = [
  tokcrypto,
  ibis,
  ismaya,
  blibli,
  kvision,
  grab,
  doss,
  erafone,
  debenhams,
];

const ListImgMobile = () => {
  return (
    <ContainerMobile>
      {image_client.map((item, idx) => (
        <div className={`div-img wrap-img-${idx}`}>
          <img src={item} alt={item} />
        </div>
      ))}
    </ContainerMobile>
  );
};
const RespectiveClient = () => {
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => setWidth(window.innerWidth));

  return (
    <Container>
      <div className="title">Our Respective Client</div>
      <div className="desc">
        The companies who trust us to help them engage with their customers and
        grow.
      </div>
      <div className="mt-5">
        <div className="row justify-content-center">
          <div className="col-md-5">
            {width <= 550 ? (
              <ListImgMobile />
            ) : (
              <OwlCarousel margin={50} loop center autoWidth autoplay>
                {image_client.map((item, idx) => (
                  <div className="item" key={idx}>
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                      src={item}
                      alt={item}
                    />
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RespectiveClient;

const ContainerMobile = styled.div`
  ${Mobile_query_v2} {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
    .div-img {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        filter: gray;
        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1);
        width: 125px;
      }
    }
  }
`;

const Container = styled.div`
  ${Mobile_query_v2} {
    .desc {
      width: 327px !important;
      font-size: 14px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      line-height: 26px !important;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px !important;
  }
  margin-top: 172px;

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    /* identical to box height */

    /* Primary */

    color: #3b3e98;
    margin-bottom: 25px;
  }

  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    /* identical to box height, or 200% */

    text-align: center;

    color: rgba(12, 27, 77, 0.6);
  }
`;
