import styled, { keyframes } from "styled-components";
import { ArrowIosDownward } from "@styled-icons/evaicons-solid";
import TvAndRadio from "landingPage/assets/iconAndLogo/Group39686.png";
import IconVideotron from "landingPage/assets/iconAndLogo/IconVideotron.png";
import IconDOOH from "landingPage/assets/iconAndLogo/IconDOOH.png";
import { useState } from "react";
import { Mobile_query_v2 } from "../../../util/Query";

const listCard = [
  {
    imgProps: IconDOOH,
    title: "TV",
    desc: "Still considered as the most credible and relevant means of advertising.",
  },
  {
    imgProps: IconVideotron,
    title: "Radio",
    desc: "The ability to target your audience. Radio is more into community based listeners.",
  },
];

const Card = ({
  activeCard = "",
  handleOpenCard,
  imgProps = IconVideotron,
  title = "Broadcast",
  desc = "A digital sign boards, billboards and similar display devices used for displaying visual information, most commonly in outdoor public areas",
}) => {
  return (
    <ContainerCard activeCard={activeCard === title ? true : false}>
      <div className="left">
        <img src={imgProps} />
      </div>
      <div className="middle">
        <div className="middle-title-text">{title}</div>
        <div className="middle-desc">{desc}</div>
      </div>
      <div className="right">
        <button className="btn coll" onClick={handleOpenCard}>
          <ArrowIosDownward width={12} className="icn-arrow" />
        </button>
      </div>
    </ContainerCard>
  );
};

const BannerTvAndRadio = () => {
  const [activeCard, setActiveCard] = useState("");

  const handleOpenCard = (titleProps) => {
    setActiveCard(titleProps);
  };

  return (
    <Container>
      <div className="title-text">TV and Radio</div>
      <div className="wrap-left-right">
        <div className="left">
          <div className="desc">
            Reach is a major advantage with TV and radio ads. Advertisers have
            the best chance to reach a large audience through television and
            radio.
          </div>

          <div className="wrap-card">
            {listCard.map((item, idx) => (
              <Card
                key={idx}
                title={item.title}
                desc={item.desc}
                handleOpenCard={() => handleOpenCard(item.title)}
                activeCard={activeCard}
                imgProps={item.imgProps}
              />
            ))}
          </div>

          <button className="btn see-detail">See Detail</button>
        </div>
        <div className="right">
          <img src={TvAndRadio} alt={TvAndRadio} />
        </div>
      </div>
    </Container>
  );
};

export default BannerTvAndRadio;

const animationHeightTrue = keyframes`

0% { height:134px;}
50%{ height:250px;}
70%{height:200px}
100%{height:auto}

`;
const animationHeightFalse = keyframes`

0%{height:134px}
100% { height:134px;}

`;

const ContainerCard = styled.div`
  ${Mobile_query_v2} {
    width: 100% !important;
  }
  .left > img {
    width: 65px;
    object-fit: contain;
  }
  .right {
    .coll {
      .icn-arrow {
        transform: ${({ activeCard }) =>
          activeCard ? "rotate(180deg)" : "rotate(360deg)"};
      }
    }
  }
  .middle {
    .middle-desc {
      /* Body/Normal - Regular */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      /* identical to box height, or 162% */

      /* Color/Neutral 80 */

      color: #6d7078;
      ${({ activeCard }) =>
        !activeCard
          ? `
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        `
          : null};

      animation-delay: 2s;
    }
    .middle-title-text {
      /* Heading/H5 - SemiBold */
      padding-bottom: 24px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height */

      /* Color/Neutral 100 */

      color: #222833;
      position: relative;
      :before {
        content: "";
        width: 65px;
        height: 4px;
        background-color: #222833;
        position: absolute;
        bottom: 10px;
      }
    }
  }
  gap: 16px;
  display: flex;
  padding: 32px 24px;
  width: 591px;
  height: ${({ activeCard }) => (!activeCard ? "134px" : "auto")};
  overflow: hidden;

  /* Color/Neutral 10 */

  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  animation-name: ${({ activeCard }) =>
    activeCard ? animationHeightTrue : animationHeightFalse};
  animation-duration: 1.5s;
  transition: 1.5s;
`;

const Container = styled.div`
  ${Mobile_query_v2} {
    .wrap-left-right > .left {
      .desc {
        font-size: 15px !important;
      }
    }
    .wrap-left-right > .right {
      display: none !important;
      img {
        display: none !important;
      }
    }

    .wrap-left-right {
      flex-wrap: wrap !important;
    }
  }
  margin-bottom: 100px;
  .wrap-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 46px;
  }
  .title-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;

    /* Brand Color/Primary */

    color: #3b3e98;
    margin-bottom: 24px;
  }

  .wrap-left-right {
    display: flex;
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left {
    .see-detail {
      width: 180px;
      height: 46px;

      /* Brand Color/Primary */

      background: #3b3e98;
      border-radius: 28px;
      /* CTA/Extra Large - Bold */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      text-align: center;

      /* Color/Neutral 10 */

      color: #ffffff;
    }
    .desc {
      /* Body/Large - SemiBold */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* or 156% */

      /* Color/Neutral 80 */

      color: #6d7078;
      margin-bottom: 29px;
    }
  }

  .left,
  .right {
    flex: 1;
  }
`;
