import React from "react";
import contactUsImage from "landingPage/assets/iconAndLogo/ContactUsImage.png";
import styled from "styled-components";
import Group33157 from "landingPage/assets/iconAndLogo/Group33157.png";
import Group33156 from "landingPage/assets/iconAndLogo/Group33156.png";
import { Mobile_query_v2 } from "../../../util/Query"

const BannerContact = () => {
  return (
    <div className="row mt-5">
      <div className="col-md-6 col-sm-12 text-center">
        <img src={contactUsImage} alt={contactUsImage} />
      </div>
      <div className="col-md-6 col-sm-12">
        <WrapText className="wrap-text">
          <div className="title text-center"><div className="text-contact-us">Contact Us</div></div>
          <div className="desc">
            We’re friendly and available to chat. Reach out to us anytime and
            we’ll happily answer your questions. Here are a few ways to get in
            touch with us
          </div>
          <div className="info">
            <div className="row ">
              <div className="col-6 align-items-center justify-content-center d-flex gap-2">
                <img src={Group33157} alt="img" />
                <div>021 - 22958146</div>
              </div>
              <div className="col-6 align-items-center justify-content-center d-flex gap-2">
                <img src={Group33156} alt="img" />
                <div>cs@mediacartz.com</div>
              </div>
            </div>
          </div>
        </WrapText>
      </div>
    </div>
  );
};

export default BannerContact;

const WrapText = styled.div`
  .info {
    margin-top:24px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    /* Color/Neutral 80 */

    color: #6d7078;
  }
  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #6d7078;
  }
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* identical to box height */

    /* Brand Color/Primary */

    color: #3b3e98;
    display:flex;
    align-items:center;
    justify-content: center;
    
   .text-contact-us{
    z-index: 1;
    position: relative;
    ::before {
      content: "";
      width: 40px;
      height: 40px;
      background: #fcd45d;
      position: absolute;
      border-radius: 9999px;
      z-index: -1 !important;
      top: 0px;
      left:-10px;
    }
   }
  }
`;
