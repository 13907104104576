import styled from "styled-components";
import { Mobile_query_v2 } from "../../../../util/Query";
import Navbar from "landingPage/components/Navbar";
import Banner from "./banner";
import AnotherNews from "./anotherNews";
import Footer from "landingPage/components/footer";

const DetailCarouselPressMedia = () => {
  return (
    <Container>
      <Navbar />
      <Container2>
        <div id="container-text">
          <div className="big-text">
            Lorem ipsum dolor sit amet consectutor adipiscing elit, sed do
            eiusmod
          </div>
          <div className="small-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elt, sed do
          </div>
        </div>
      </Container2>
      <Banner />
      <>
        <AnotherNews />
      </>
      <Footer />
    </Container>
  );
};

export default DetailCarouselPressMedia;

const Container2 = styled.div`
  #container-text {
    .small-text {
      /* Body/Large - SemiBold */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */

      text-align: center;

      /* Black 3 */

      color: rgba(12, 27, 77, 0.6);
    }
    .big-text {
      width: 822px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      text-align: center;

      /* Brand Color/Primary */

      color: #3b3e98;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 119px;
  }

  ${Mobile_query_v2} {
    padding: 0 25px;
  }
  padding: 0 93px;

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;

const Container = styled.div`
  /* background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  ); */
  position: relative;
  background-color: #fff;
`;
