import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver"


const ExportExcel = ({
  excelData = [], filename
}) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
  const fileExtension = '.xlsx'

  if (excelData !== '') {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = {
      Sheets: {
        'data': ws
      },
      SheetNames: ['data']
    }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })


    return FileSaver.saveAs(data, filename + fileExtension)

  }
  else {
    alert('File must be Array')
  }


}

export default ExportExcel;