import React, { useEffect, useState } from "react";
import { Modal_Component, AlertError } from "../../../../../../component";
import PropTypes from "prop-types";
import { Card } from "./component/card";
import { Accordion } from "./component/accordion";
import { Card_campaign } from "./component/card_campaign";
import { api_insight } from "../../../../../../service/api";
import styled from "styled-components";
import {
  ButtonPrimary,
  COLOR_SECONDARY,
} from "../../../../../../component/style/content/default";
import { Moment, FormatCurrency } from "../../../../../../util";
import { Verified } from "@styled-icons/material";
import { DonutBar } from "../../../../../../component/chart";
import { Table } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const Modal_detail = ({ show, onHide, props }) => {
  const [data, setData] = useState({});
  const [active_card, setActive_card] = useState("campaign");

  useEffect(() => {
    if (show === true && props.id) {
      api_insight.get_insight_detail({ insight_id: props.id }).then((res) => {
        if (res?.success) {
          setData(res.data);
        }
      });
    }
  }, [show]);

  function getTransactionSaldo() {
    let nominal = 0;
    let report = data.campaign_report;
    for (let i = 0; i < report?.length; i++) {
      let fee = report[i].campaign_fee;

      for (let j = 0; j < fee?.length; j++) {
        nominal += fee[j].balance_history_debit
          ? fee[j].balance_history_debit
          : 0;
        nominal -= fee[j].balance_history_credit
          ? fee[j].balance_history_credit
          : 0;
      }
    }

    return nominal;
  }
  function getTransactionBucket() {
    let nominal = 0;
    let report = data.campaign_report;
    for (let i = 0; i < report?.length; i++) {
      let fee = report[i].campaign_fee;

      for (let j = 0; j < fee?.length; j++) {
        nominal += fee[j].bucket_history_debit
          ? fee[j].bucket_history_debit
          : 0;
        nominal -= fee[j].bucket_history_credit
          ? fee[j].bucket_history_credit
          : 0;
      }
    }

    return nominal;
  }

  function getCampaignRecipient() {
    let campaign = 0;

    let report = data.campaign_report;
    for (let i = 0; i < report?.length; i++) {
      campaign += report[i].campaign_total_recipient;
    }

    return campaign;
  }

  function getEventTiketSell() {
    let ticket = 0;

    let report = data.event_report;
    for (let i = 0; i < report?.length; i++) {
      let arr_tiket_sales = report[i].ticket_sales;

      for (let j = 0; j < arr_tiket_sales?.length; j++) {
        ticket += arr_tiket_sales[j].transaction_total_seat;
      }
    }

    return ticket;
  }

  function getEventTiketPeserta() {
    let ticket = 0;

    let report = data.event_report;
    for (let i = 0; i < report?.length; i++) {
      ticket += report[i].ticket_total_used;
    }

    return ticket;
  }

  function getMicrosite() {
    let microsite = 0;

    let report = data.microsite_report;
    for (let i = 0; i < report?.length; i++) {
      microsite += report[i].total_clicked;
    }
    return microsite;
  }
  function getCatalogue() {
    let catalogue = 0;

    let report = data.catalogue_report;
    for (let i = 0; i < report?.length; i++) {
      catalogue += report[i].catalogue_total_clicked;
    }
    return catalogue;
  }

  function getMicrositeSubmit() {
    let microsite = 0;

    let report = data.microsite_report;
    for (let i = 0; i < report?.length; i++) {
      microsite += report[i].total_submitted;
    }
    return microsite;
  }

  function downloadShortenedReport(shortened_id) {
    api_insight.download_shortened(shortened_id).then((res) => {
      if (res) {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(res);
        link.setAttribute("download", `${shortened_id}-shortened-report.csv`);
        document.body.appendChild(link);
        link.click();
      } else {
        AlertError({
          title: "ERROR",
          text: "Shortened URL report file not found",
        });
      }
    });
  }

  function downloadCampaign(el) {
    html2canvas(document.querySelector(el), {
      scale: 1,
      padding: 1,
    }).then((canvas) => {
      document.body.appendChild(canvas); // if you want see your screenshot in body.
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  }

  function CatalogueDonat(item) {
    let catalogue_product = item.catalogue_product;
    let banner = catalogue_product.filter(
      (filter) => filter.catalogue_product_type === "BANNER"
    );
    let product = catalogue_product.filter(
      (filter) => filter.catalogue_product_type === "PRODUCT"
    );
    let promo = catalogue_product.filter(
      (filter) => filter.catalogue_product_type === "PROMO"
    );
    let marketplace = catalogue_product.filter(
      (filter) => filter.catalogue_product_type === "MARKETPLACE"
    );
    let social_media = catalogue_product.filter(
      (filter) => filter.catalogue_product_type === "SOCIAL_MEDIA"
    );

    let sumBanner = banner.reduce(
      (previousValue, currentValue) =>
        previousValue +
        parseInt(currentValue.shortened?.shortened_total_clicked),
      0
    );
    let sumProduct = product.reduce(
      (previousValue, currentValue) =>
        previousValue +
        parseInt(currentValue.shortened?.shortened_total_clicked),
      0
    );
    let sumMarketPlace = marketplace.reduce(
      (previousValue, currentValue) =>
        previousValue +
        parseInt(currentValue.shortened?.shortened_total_clicked),
      0
    );
    let sumSocialMedia = social_media.reduce(
      (previousValue, currentValue) =>
        previousValue +
        parseInt(currentValue.shortened?.shortened_total_clicked),
      0
    );
    let sumPromo = promo.reduce(
      (previousValue, currentValue) =>
        previousValue +
        parseInt(currentValue.shortened?.shortened_total_clicked),
      0
    );

    return (
      <div>
        <div>
          <DivRow className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <DivTitle>LANDING PAGE</DivTitle>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              {item.catalogue_total_clicked}
            </div>
          </DivRow>
          <DivRow className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <DivTitle>BANNER</DivTitle>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">{sumBanner}</div>
          </DivRow>
          <DivRow className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <DivTitle>PRODUCT</DivTitle>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">{sumProduct}</div>
          </DivRow>
          <DivRow className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <DivTitle>MARKETPLACE</DivTitle>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">{sumMarketPlace}</div>
          </DivRow>
          <DivRow className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <DivTitle>SOCIAL MEDIA</DivTitle>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">{sumSocialMedia}</div>
          </DivRow>
          <DivRow className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <DivTitle>PROMO</DivTitle>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">{sumPromo}</div>
          </DivRow>
        </div>

        <div>
          <div className="chart-donut" style={{ width: "300px" }}>
            <Doughnut
              data={{
                labels: [
                  "Landing Page",
                  "BANNER",
                  "PRODUCT",
                  "MARKETPLACE",
                  "SOCIAL MEDIA",
                  "PROMO",
                ],
                datasets: [
                  {
                    data: [
                      item.catalogue_total_clicked,
                      sumBanner,
                      sumProduct,
                      sumMarketPlace,
                      sumSocialMedia,
                      sumPromo,
                    ],
                    backgroundColor: [
                      "rgba(255, 99, 132, 1)",
                      "rgba(54, 162, 235, 1)",
                      "rgba(255, 206, 86, 1)",
                      "rgba(75, 192, 192, 1)",
                      "rgba(153, 102, 255, 1)",
                      "rgba(255, 159, 64, 1)",
                    ],
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal_Component
      show={show}
      onHide={onHide}
      title="Detail Insight"
      size="xl"
    >
      <div className="container mb-3 mb-md-3">
        <div className="row" style={{ overflow: "hidden" }}>
          <div className="col-md-3 col-xs-6 p-2">
            <Card
              saldo={getTransactionSaldo()}
              bucket={getTransactionBucket()}
            />
          </div>
          <div
            className="col-md-3 col-xs-6 p-2"
            onClick={() => setActive_card("campaign")}
          >
            <Card_campaign
              multi={false}
              title="CAMPAIGN"
              value1={{
                text:
                  data.campaign_report &&
                  data?.campaign_report[0]?.product?.ms_channel
                    ?.ms_channel_name,
                number: getCampaignRecipient(),
              }}
              active={active_card === "campaign" ? true : false}
            />
          </div>
          <div
            className="col-md-3 col-xs-6 p-2"
            onClick={() => setActive_card("event")}
          >
            <Card_campaign
              multi
              title="EVENT"
              value1={{
                text: "Tiket Terjual",
                number: getEventTiketSell(),
              }}
              value2={{
                text: "Peserta",
                number: getEventTiketPeserta(),
              }}
              active={active_card === "event" ? true : false}
            />
          </div>
          <div
            className="col-md-3 col-xs-6 p-2"
            onClick={() => setActive_card("microsite")}
          >
            <Card_campaign
              multi
              title="MICROSITE"
              value1={{
                text: "Terklik",
                number: getMicrosite(),
              }}
              value2={{
                text: "Terkirim",
                number: getMicrositeSubmit(),
              }}
              active={active_card === "microsite" ? true : false}
            />
          </div>
          <div
            className="col-md-3 col-xs-6 p-2"
            onClick={() => setActive_card("catalogue")}
          >
            <Card_campaign
              multi={false}
              title="CATALOGUE"
              value1={{
                text: "Terklik",
                number: getCatalogue(),
              }}
              active={active_card === "catalogue" ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="container mb-3 mb-md-3">
        <DivRow className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <DivTitle>Nama Insight</DivTitle>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            {data?.programme_name}
          </div>
        </DivRow>
        <DivRow className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <DivTitle>Monitoring</DivTitle>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div>
              {`Campaign #id : ${
                data.programme_monitor &&
                JSON.parse(data.programme_monitor).campaign_id
              }`}
            </div>
            <div>
              {`Event #id : ${
                data.programme_monitor &&
                JSON.parse(data.programme_monitor).event_id
              }`}
            </div>
          </div>
        </DivRow>
        <DivRow className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <DivTitle>Dibuat Tanggal</DivTitle>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            {Moment(data?.programme_created_datetime)}
          </div>
        </DivRow>
        <DivRow className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <DivTitle>Dibuat Oleh</DivTitle>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            {data?.programme_created_by?.name} &nbsp;
            {data?.programme_created_by?.verified_status_name === "verified" ? (
              <Verified width={20} color="#35bd15" />
            ) : null}
          </div>
        </DivRow>
        <DivRow className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <DivTitle>Perusahaan</DivTitle>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            {data?.company?.company_name}
          </div>
        </DivRow>
      </div>

      {active_card === "campaign" && (
        <div>
          <div
            id="campaign_capture"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: "#eaeaea",
            }}
          >
            {data?.campaign_report?.map((item, idx) => {
              return (
                <div className="container mb-3 mb-md-3" key={idx}>
                  <Accordion
                    header={`${item.campaign_name} ID: #${item.campaign_id}`}
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="mb-3 mb-md-3">
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>ID Kampanye</DivTitle>
                            </div>
                            <div className="col-md-6">{item.campaign_id}</div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Nama Kampanye</DivTitle>
                            </div>
                            <div className="col-md-6">{item.campaign_name}</div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Nama Produk</DivTitle>
                            </div>
                            <div className="col-md-6">
                              {item.product?.product_name}
                            </div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Status Kampanye</DivTitle>
                            </div>
                            <div className="col-md-6">
                              {item.campaign_status_name}
                            </div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Total Penerima</DivTitle>
                            </div>
                            <div className="col-md-6">
                              {item.campaign_total_recipient}
                            </div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Total Batch</DivTitle>
                            </div>
                            <div className="col-md-6">
                              {item.campaign_total_wave}
                            </div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Tanggal Batch</DivTitle>
                            </div>
                            <div className="col-md-6">
                              {`${Moment(
                                item.wave[0]?.campaign_date_from_datetime
                              )} ~ ${Moment(
                                item.wave[0]?.campaign_date_until_datetime
                              )}`}
                            </div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Status Batch</DivTitle>
                            </div>
                            <div className="col-md-6">
                              {item.wave[0]?.campaign_date_status_name}
                            </div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Total Penerima</DivTitle>
                            </div>
                            <div className="col-md-6">
                              {item.wave[0]?.campaign_date_total_recipient}
                            </div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Total Terproses</DivTitle>
                            </div>
                            <div className="col-md-6">
                              {item.wave[0]?.campaign_date_total_delivered}
                            </div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Total Refund</DivTitle>
                            </div>
                            <div className="col-md-6">
                              {item.wave[0]?.campaign_date_total_refund}
                            </div>
                          </DivRow>
                        </div>

                        {/* shortened Links */}
                        {item.shortened.length > 0 && (
                          <div className="mb-3">
                            <div className="title">Shortened Links</div>
                            {item.shortened?.map((short) => {
                              return (
                                <React.Fragment>
                                  <DivRow className="row">
                                    <div className="col-md-6">
                                      <DivTitle>Original URL</DivTitle>
                                    </div>
                                    <div className="col-md-6">
                                      <a
                                        href={
                                          short.shortened
                                            .shortened_original_link
                                        }
                                        class="is-link"
                                        target="_blank"
                                      >
                                        {
                                          short.shortened
                                            .shortened_original_link
                                        }
                                      </a>
                                    </div>
                                  </DivRow>
                                  <DivRow className="row">
                                    <div className="col-md-6">
                                      <DivTitle>Shortened URL</DivTitle>
                                    </div>
                                    <div className="col-md-6">
                                      <a
                                        href={
                                          short.shortened.shortened_full_url
                                        }
                                        class="is-link"
                                        target="_blank"
                                      >
                                        {short.shortened.shortened_full_url}
                                      </a>
                                    </div>
                                  </DivRow>
                                  <DivRow className="row">
                                    <div className="col-md-6">
                                      <DivTitle>Shortened Total Click</DivTitle>
                                    </div>
                                    <div className="col-md-6">
                                      {FormatCurrency.input(
                                        short.shortened.shortened_total_clicked
                                      )}
                                      {short.shortened.shortened_total_clicked >
                                        0 && (
                                        <a
                                          href={null}
                                          onClick={() =>
                                            downloadShortenedReport(
                                              short.shortened.shortened_id
                                            )
                                          }
                                          title="Download File"
                                        >
                                          <span class="icon is-small">
                                            &#128193;
                                          </span>
                                        </a>
                                      )}
                                    </div>
                                  </DivRow>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div style={{ width: "300px" }}>
                          <DonutBar
                            title="Ringkasan Kampanye Iklan"
                            color={item.wave[0]?.report?.map(
                              (item) => item.color
                            )}
                            data_single={item.wave[0]?.report?.map(
                              (item) => item.value
                            )}
                            labels={item.wave[0]?.report?.map(
                              (item) => item.label
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </div>
              );
            })}
          </div>
          <ButtonPrimary onClick={() => downloadCampaign("#campaign_capture")}>
            Download Report Campaign
          </ButtonPrimary>
        </div>
      )}

      {active_card === "event" && (
        <div>
          <div
            id="event_capture"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: "#eaeaea",
            }}
          >
            {data?.event_report?.map((item, idx) => (
              <div className="container mb-3 mb-md-3" key={idx}>
                <Accordion header={`${item.event_name} ID : #${item.event_id}`}>
                  <div className="row">
                    <div className="col-lg-16 col-md-6 col-sm-6">
                      <div className="mb-3 mb-md-3">
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Nama Event</DivTitle>
                          </div>
                          <div className="col-md-6">{item.event_name}</div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Tanggal Event</DivTitle>
                          </div>
                          <div className="col-md-6">{`${Moment(
                            item.event_start_datetime
                          )} ~ ${Moment(item.event_end_datetime)}`}</div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Total Click</DivTitle>
                          </div>
                          <div className="col-md-6">
                            {item.event_total_clicked}
                          </div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Total Like</DivTitle>
                          </div>
                          <div className="col-md-6">
                            {item.event_total_liked}
                          </div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>External URL</DivTitle>
                          </div>
                          <div className="col-md-6">
                            {item.event_external_url}
                          </div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>External URL Click</DivTitle>
                          </div>
                          <div className="col-md-6">
                            {item.event_external_url_total_clicked}
                          </div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Total Ticket Terscan</DivTitle>
                          </div>
                          <div className="col-md-6">
                            {item.ticket_total_used}
                          </div>
                        </DivRow>
                      </div>
                      <div>
                        <div>
                          <label>Shortened Links</label>
                        </div>
                        <div>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Original URL</DivTitle>
                            </div>
                            <div
                              className="col-md-6"
                              style={{ wordWrap: "break-word" }}
                            >
                              {item.event_external_platform}
                            </div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Shortened URL</DivTitle>
                            </div>
                            <div className="col-md-6">{`${item.event_external_url}`}</div>
                          </DivRow>
                          <DivRow className="row">
                            <div className="col-md-6">
                              <DivTitle>Shortened Total Click</DivTitle>
                            </div>
                            <div className="col-md-6">{`${item.event_external_url_total_clicked}`}</div>
                          </DivRow>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-16 col-md-6 col-sm-6">
                      {item?.report?.length > 0 && (
                        <div
                          style={{ width: "300px" }}
                          className="mb-3 mb-md-3"
                        >
                          <DonutBar
                            title="Ringkasan Transaksi"
                            color={item.report.map((item) => item.color)}
                            data_single={item.report.map((item) => item.value)}
                            labels={item.report.map((item) => item.label)}
                          />
                        </div>
                      )}

                      {item?.ticket_sales?.length > 0 && (
                        <div>
                          <Table responsive striped hover bordered>
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th>Total Seat</th>
                                <th>Total Transaction</th>
                                <th>Total Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.ticket_sales?.map((item2, idx2) => (
                                <tr key={idx2}>
                                  <td>
                                    {item2.transaction_approve_status_name}
                                  </td>
                                  <td>{item2.transaction_total_seat}</td>
                                  <td>{item2.transaction_total_count}</td>
                                  <td>
                                    {FormatCurrency.currency(
                                      item2.transaction_total_amount
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion>
              </div>
            ))}
          </div>
          <ButtonPrimary onClick={() => downloadCampaign("#event_capture")}>
            Download Report Event
          </ButtonPrimary>
        </div>
      )}

      {active_card === "microsite" && (
        <div>
          <div
            id="microsite_capture"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: "#eaeaea",
            }}
          >
            {data?.microsite_report?.map((item, idx) => (
              <div className="container mb-3 mb-md-3" key={idx}>
                <Accordion
                  header={`${item.microsite_name} ID : #${item.microsite_id}`}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="mb-3 mb-md-3">
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Nama Microsite</DivTitle>
                          </div>
                          <div className="col-md-6">{item.microsite_name}</div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Slug</DivTitle>
                          </div>
                          <div className="col-md-6">{item.microsite_slug}</div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Full URL</DivTitle>
                          </div>
                          <div className="col-md-6">
                            {item.microsite_full_url}
                          </div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Total Klik</DivTitle>
                          </div>
                          <div className="col-md-6">{item.total_clicked}</div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Total Terkirim</DivTitle>
                          </div>
                          <div className="col-md-6">{item.total_submitted}</div>
                        </DivRow>
                      </div>
                    </div>
                  </div>
                </Accordion>
              </div>
            ))}
          </div>
          <ButtonPrimary onClick={() => downloadCampaign("#microsite_capture")}>
            Download Report Microsite
          </ButtonPrimary>
        </div>
      )}
      {active_card === "catalogue" && (
        <div>
          <div
            id="catalogue_capture"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: "#eaeaea",
            }}
          >
            {data?.catalogue_report?.map((item, idx) => (
              <div className="container mb-3 mb-md-3" key={idx}>
                <Accordion
                  header={`${item.catalogue_name} ID : #${item.catalogue_id}`}
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="mb-3 mb-md-3">
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Nama Catalogue</DivTitle>
                          </div>
                          <div className="col-md-6">{item.catalogue_name}</div>
                        </DivRow>
                        <DivRow className="row">
                          <div className="col-md-6">
                            <DivTitle>Total Klik</DivTitle>
                          </div>
                          <div className="col-md-6">
                            {item.catalogue_total_clicked}
                          </div>
                        </DivRow>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      {CatalogueDonat(item)}
                    </div>
                  </div>
                </Accordion>
              </div>
            ))}
          </div>
          <ButtonPrimary onClick={() => downloadCampaign("#catalogue_capture")}>
            Download Report Catalogue
          </ButtonPrimary>
        </div>
      )}
    </Modal_Component>
  );
};

const DivRow = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 2.5px 0px;

  &:last-child {
    border-bottom: none;
  }
`;

const DivTitle = styled.strong`
  color: ${COLOR_SECONDARY};
`;

Modal_detail.defaultProps = {
  show: true,
};

Modal_detail.propType = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  props: PropTypes.object,
};
