import React from "react";
import styled from "styled-components";
import color from "landingPage/assets/color";
import imgAds from "landingPage/assets/iconAndLogo/Group 33460.png";
import ArrowRight from "landingPage/assets/iconAndLogo/Group 33142.png";
import { Mobile_query_v2 } from "../../util/Query";

const { white, primary, black3 } = color;

const NavbarDropdown = ({ content = {} }) => {
  const { name, sub } = content;

  if (name && name !== "Products") {
    return (
      <Dropdown>
        <Left>
          <div className="row">
            {sub.map((item) => (
              <div className="col-md-4 mb-2">
                <div className="list-content">
                  <div className="wrap-content d-flex align-items-center gap-2">
                    <a
                      href={item.path}
                      className="wrap-content d-flex align-items-center gap-2"
                    >
                      <div className="cont-image">
                        <img src={item.icon} alt={item.icon} />
                      </div>
                      <div className="cont-text">
                        <div className="title">{item.name}</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Left>
      </Dropdown>
    );
  } else if (name && name === "Products") {
    return (
      <Dropdown>
        <div className="wrap d-flex">
          <Left className="left flex-fill">
            <div className="row">
              {sub.map((item) => (
                <div className="col-md-6" style={{ marginBottom: "40px" }}>
                  <div className="header-title">{item.header}</div>
                  {item?.sub.map((sub) => (
                    <div className="list-content">
                      <div className="wrap-content d-flex gap-2">
                        <div className="cont-image">
                          <img src={sub.icon} alt={sub.icon} />
                        </div>
                        <div className="cont-text">
                          <div className="title">{sub.name}</div>
                          <div className="desc">{sub.desc}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Left>
          <Right className="right d-flex flex-column justify-content-center">
            <img src={imgAds} alt={imgAds} />
            <div className="desc">
              Mediacartz provides you with the most effective forms of
              engagement, advertising, communication and management digitally,
              with its various features in a targeted, accurate and efficient
              manner.
            </div>
            <div
              style={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                display: "flex",
                marginTop: "30px",
              }}
            >
              <div className="btn-learn d-flex align-items-center gap-1 justify-content-center">
                Learn More
                <img
                  height="17px"
                  style={{ objectFit: "contain", width: "37px" }}
                  src={ArrowRight}
                  alt={ArrowRight}
                />
              </div>
            </div>
          </Right>
        </div>
      </Dropdown>
    );
  } else {
    return <Dropdown></Dropdown>;
  }
};

export default NavbarDropdown;

const Left = styled.div`
  margin-top: 52px;
  margin-bottom: 52px;
  margin-left: 65px;
  margin-right: 20px;
  .header-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #1a202c;
  }
  .list-content {
    margin-top: 5px;
  }
  .list-content > .wrap-content > .cont-image > img {
    width: 40px;
    height: 40px;
  }
  .list-content > .wrap-content > .cont-text > .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: #1a202c;
  }
  .list-content > .wrap-content > .cont-text > .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;

    color: rgba(12, 27, 77, 0.6);
  }
`;

const Right = styled.div`
  background-color: #f5f8fa;
  /* width: 494px; */
  min-height: 613px;
  padding: 0px 53px;

  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    /* or 214% */

    color: ${black3};
  }

  img {
    width: 365px;
  }

  .btn-learn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    /* identical to box height */

    text-align: center;
    color: ${primary};
  }
`;

const Dropdown = styled.div`
  box-shadow: -1px 6px 22px rgba(0, 0, 0, 0.17);
  border-radius: 100px;
  /* position: absolute; */
  margin-top: 40px;
  width: 100%;
  /* min-height: 613px; */
  overflow: hidden;
  background-color: #ffff;
  z-index: 9999;
  ${Mobile_query_v2} {
    display: none;
  }
`;
