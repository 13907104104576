import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import * as Component from "../../../component";
import * as Style from "../../../component/style/content/default";
import * as Services from "../../../service";
import * as Modal from "./modal";
import { Method } from "../../../service";
import { Numeric, displayStatus } from "../../../util";
import { Form } from "react-bootstrap";
import api_permission from "service/api/permission";
import moment from "moment";

const statusList = [
  { value: "", label: "Semua" },
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const header = [
  "No",
  "Nama Voucher",
  "Kode",
  "Mulai",
  "Sampai",
  "Status",
  "Aksi",
];


const data_more = [
  { name: "Lihat Detail" },
  { name: "Ubah" },
  { name: "Nonaktifkan" },
  { name: "Aktifkan" }
];

export default function Voucher() {
  const { selected_company, dispatch, retail_permission, data_user } = useContext(Services.Context);
  const [modal, setModal] = useState({
    company: false,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  });
  const [search, setSearch] = useState('');
  const [statusSelected, setStatusSelected] = useState({ value: 1, label: "Active" });
  const [modalDetail, setModalDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [modalForm, setModalForm] = useState(false);
  const [modalFormType, setModalFormType] = useState(null);

  useEffect(() => {
    if(selected_company.value){
      api_permission(dispatch, selected_company.value, data_user.id)
    }
  }, [selected_company])

  const GetCompany = async () => {
    await Method.get(`company`)
      .then(async (res) => {
        if (res?.data?.success) {
          if (!selected_company.value) {
            dispatch({
              type: "SELECTED_COMPANY",
              selected_company: {
                value: res?.data?.data[0]?.company_id,
                label: res?.data?.data[0]?.company_name,
              },
            });
          }
        } else {
          Component.AlertError({ title: "Error", text: res?.data?.error });
        }
      })
      .catch((err) => {
      });
  };

  const getData = async (pageData = 1) => {
    setLoading(true);

    var data_post = {
      voucher_name: search,
      voucher_active_status: statusSelected.value,
    };

    await Method.get(
      `company/${selected_company.value}/voucher?page=${pageData}`,
      { params: data_post }
    )
      .then(async (res) => {
        console.log('response', res)
        if (res.data.success) {
          setData((state) => ({
            ...state,
            data: res.data.data,
            page: res.data.page,
            lastPage: res.data.last_page,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          Component.AlertError({ title: "Error", text: error.response.data.error });
        } else {
          Component.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
        }
      });
  };

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  const btnMore = async (name, id) => {
    const row = data?.data?.find((find) => find.voucher_id === id);

    switch (name) {
      case "Lihat Detail":
        getDetailData(row.voucher_id, 'detail');
        break;

      case "Ubah":
        getDetailData(row.voucher_id, 'form');
        break;

      case "Aktifkan":
        await Component.AlertQuestion({
          title: "Warning",
          text: `Do you want to activate ${row.item_name}`,
        }).then((res) => {
          if (res.isConfirmed) {
            //action
            changeStatus(row.voucher_id, 'activate')
          }
        });
        break;

      case "Nonaktifkan":
        await Component.AlertQuestion({
          title: "Warning",
          text: `Do you want to inactivate ${row.item_name}`,
        }).then((res) => {
          if (res.isConfirmed) {
            //action
            changeStatus(row.voucher_id, 'inactivate')
          }
        });
        break;

      default:
        return name;
    }
  }

  const getDetailData = async (voucher_id, type) => {
    await Method.get(`voucher/${voucher_id}`)
      .then(async (res) => {
        if (res.data.success) {
          setDataDetail(res.data.data);
          setTimeout(() => {
            if (type === 'detail') {
              setModalDetail(true);
            } else {
              setModalForm(true);
              setModalFormType('edit');
            }
          }, 500);
        } else {
          Component.AlertError({ title: "Error", text: res.error });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          Component.AlertError({ title: "Error", text: error.response.data.error });
        } else {
          Component.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
        }
      });
  }

  const changeStatus = async (voucher_id, status) => {
    await Method.put(`voucher/${voucher_id}/${status}`)
      .then(async (res) => {
        if (res.data.success) {
          Component.AlertSuccess({ title: "Success", text: res.success });
          getData();
        } else {
          Component.AlertError({ title: "Error", text: res.error });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          Component.AlertError({ title: "Error", text: error.response.data.error });
        } else {
          Component.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
        }
      });
  }

  const filter = () => {
    getData();
  };

  const resetFilter = () => {
    setSearch("");
    setStatusSelected({ value: 1, label: "Active" });
  };

  useEffect(() => {
    if (
      search === "" &&
      statusSelected.value === 1
    ) {
      getData();
    }
  }, [search, statusSelected]);

  useEffect(() => {
    GetCompany();
  }, []);

  useEffect(() => {
    getData();
  }, [selected_company]);


  return (
    <Style.ContainerContent>
      {/* MODAL =========== */}
      <Modal.ModalSelectCompany
        show={modal.company}
        onHide={() => setModal((state) => ({ ...state, company: false }))}
      />

      <Modal.ModalDetailVoucher
        show={modalDetail}
        onHide={() => setModalDetail(false)}
        data={dataDetail}
      />

      <Modal.ModalFormVoucher
        show={modalForm}
        type={modalFormType}
        onHide={() => setModalForm(false)}
        onSubmit={() => {
          getData();
          setModalForm(false);
        }}
        data={dataDetail}
      />

      <section>
        <div className="d-flex">
          <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
            Voucher
          </Style.HeaderPrimary>
          <div className="wrap-select align-items-center ms-5">
            <button
              className="btn border"
              onClick={() => setModal((state) => ({ ...state, company: true }))}
            >
              {selected_company.label ? selected_company.label : "Select Company"}{" "}
              <i className="demo-icon icon-chevron-down"></i>
            </button>
          </div>
        </div>
      </section>

      {
        retail_permission.data?.type!=='staff' ? (

          <section>
            <Style.ButtonPrimary
              onClick={() => {
                setModalForm(true);
                setModalFormType('add');
              }}
              className="mb-2"
            >
              Tambah Voucher
            </Style.ButtonPrimary>

            <Component.Form>
              <section>
                <div className="row">
                  <div className="col-md-3 mb-2">
                    <Style.Label color={Style.COLOR_SECONDARY}>Keyword</Style.Label>
                    <Form.Control
                      placeholder="Cari nama voucher..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>

                  <div className="col-md-3 mb-2">
                    <Style.Label color={Style.COLOR_SECONDARY}>Status</Style.Label>
                    <Select
                      placeholder="Semua Status"
                      value={statusSelected}
                      options={statusList}
                      onChange={(e) => setStatusSelected(e)}
                    />
                  </div>

                  <div className="col-md-3 pt-4 pb-4 d-flex flex-row">
                    <Style.ButtonPrimary onClick={() => filter()}>
                      Cari
                    </Style.ButtonPrimary>
                    <button
                      className="btn bg-light border border-gray ms-1"
                      onClick={() => resetFilter()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </section>

              <section className="mb-5 mb-md-5">
                <Component.TableData header={header}>
                  {loading ? (
                    <tr>
                      <td colSpan={header.length}>
                        <Component.Loadingfunc />
                      </td>
                    </tr>
                  ) : data.data.length === 0 ? (
                    <tr>
                      <td colSpan={header.length} className="text-center">
                        <div>Data Tidak Ditemukan</div>
                      </td>
                    </tr>
                  ) : (
                    data.data.map((item, index) => {
                      return(
                        <tr
                          key={index}
                        >
                          <td data-label="No">{Numeric({ page: data.page, idx: index })}</td>
                          <td data-label="Nama Voucher">{item.voucher_name}</td>
                          <td data-label="Kode">{item.voucher_code}</td>
                          <td data-label="Mulai">{moment(item.voucher_active_from_datetime).format('DD-MMM-YYYY HH:mm:ss')}</td>
                          <td data-label="Sampai">{moment(item.voucher_active_until_datetime).format('DD-MMM-YYYY HH:mm:ss')}</td>
                          <td data-label="Status">
                            {
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: displayStatus(
                                    item.voucher_active_status_name
                                  ),
                                }}
                              />
                            }
                          </td>
                          <td data-label="Aksi">
                            <Component.DropDown_More
                              title={
                                <div className="dropdown-trigger">
                                  <button
                                    className="button is-primary is-small"
                                    aria-haspopup="true"
                                    aria-controls="dropdown-menu"
                                  >
                                    <span className="icon is-small">•••</span>
                                  </button>
                                </div>
                              }
                              data_more={data_more.filter((filter) =>
                                item.voucher_active_status_name === "active"
                                  ? filter.name !== "Aktifkan"
                                  : filter.name !== "Nonaktifkan"
                              )}
                              id={item.voucher_id}
                              onClick={({ name, id }) => btnMore(name, id)}
                            />
                          </td>
                        </tr>
                      )
                    })
                  )}
                </Component.TableData>
              </section>

              {
                data.data.length > 0 &&
                <section>
                  <Component.Pagination
                    page={data.page}
                    totalPage={data.lastPage}
                    handleOnChange={(e) => btnPagination(e)}
                  />
                </section>
              }
            </Component.Form>
          </section>
        ):(
          <div className="alert alert-warning mt-lg-5" role="alert">
            <h5>Akses Ditolak</h5>
            Anda sebagai staff tidak mempunyai akses di perusahaan ini
          </div>
        )
      }
    </Style.ContainerContent>
  );
}