import { Modal_Component as ModalComponent } from '../../../../../component';
import { api_shorten_url } from "../../../../../service/api";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form"

const ModalEdit = ({
  show,
  onHide,
  param,
  getData
}) => {
  const { control, setValue, register, handleSubmit, reset } = useForm({
    defaultValues: {
      originalLink: null,
      newLink: null
    }
  })

  const originalLink = useWatch({
    control,
    name: 'originalLink'
  })


  useEffect(() => {
    if (show) {
      api.getDetailShort(param.shortened_parameter)
        .then(res => {
          console.log({ res })
          const { success, data: dataResult } = res

          if (success) {
            setValue('originalLink', dataResult.shortened_original_link)

          }
        })
    }
  }, [show])



  const btnUpdateShortened = (data) => {
    api.putShortened({
      parameter_id: param.parameter_id,
      body: {
        shortened_original_link: data.originalLink,
        shortened_parameter: data.newLink
      }
    })
      .then(res => {
        console.log({ res })
        if (res.success) {
          onHide()
          getData()
          reset({
            newLink: null
          })
        }
      })
  }


  return (
    <ModalComponent show={show} onHide={onHide} title="Edit Short URL">
      <div className="form-horizontal mb-2">
        <label className="form-label">
          Original Link
        </label>
        <input type="text" value={originalLink} disabled className="form-control" />
      </div>
      <div className="form-horizontal mb-2">
        <label className="form-label">
          New Shortened
        </label>
        <input type="text" placeholder='new shortened' className="form-control" {...register('newLink')} />
      </div>
      <button className="btn btn-primary" onClick={handleSubmit(btnUpdateShortened)}>
        Simpan
      </button>
    </ModalComponent>
  )
}

export default ModalEdit;



class classApi {

  async getDetailShort(shortened_parameter) {
    const result = await api_shorten_url.get_shorten_url_detail(shortened_parameter)

    return result
  }

  async putShortened({
    parameter_id,
    body
  }) {
    const result = await api_shorten_url.putShortened({
      parameter_id,
      body
    })

    return result

  }
}

const api = new classApi();