import styled from "styled-components";
import Navbar from "landingPage/components/navbar_v2";
import ArticleItem from "../../../components/version2/article/article_item";
import Pagination from "./components/pagination";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { AlertError } from "component";
import qs from "qs"

const baseUrl = "https://stadscore.mediacartz.com/api/v1";

const ListArticlePage = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(10);
  const [search, setSearch] = useState("");
  const [param, setParam] = useState({
    dataCategory: [],
    dataArticle: [],
  });
  const [selected, setSelected] = useState("");
  const [filter, setFilter] = useState({
    params: {
      page: 1,
      rows: 10,
      order: [
        {
          field: "views",
          sort: "DESC",
        },
      ],
      filter: [
        {
          field: "article_category_id",
          operator: "=",
          value: 1,
        },
        {
          field: "title",
          operator: "=",
          value: "",
        },
      ],
    },
  });

  const getCategory = async () => {
    try {
      const response = await axios
        .get(`${baseUrl}/article/category`)
        .then((res) => res.data);
      setParam((state) => ({
        ...state,
        dataCategory: response.data,
      }));
    } catch (error) {
      AlertError({ title: "Error", text: error });
    }
  };

  const getArticle = async () => {
    try {
      const response = await axios
        .get(`${baseUrl}/article`, {
          params: filter.params,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        })
        .then((res) => res.data);

      console.log({ response: response.data });
      setPage(response.data.page);
      setTotalPage(response.data.lastPage);
      // setTotalPage(2);
      setParam((state) => ({
        ...state,
        dataArticle: response.data.data,
      }));
    } catch (error) {
      AlertError({ title: "Error", text: error });
    }
  };

  const btnPagination = (newPage) => {
    setPage(newPage);
  };

  const btnSearch = (e) => {
    e.preventDefault();
    setPage(1);
    getArticle();
    setFilter((state) => ({
      params: {
        ...state.params,
        filter: state.params.filter.map((obj, idx) =>
          idx === 1 ? { ...obj, value: search } : obj
        ),
      },
    }));
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getArticle();
  }, [page, filter.params.filter]);

  const btnHandleSelectCategory = (item) => {
    setFilter((state) => ({
      params: {
        ...state.params,
        filter: state.params.filter.map((obj, idx) =>
          idx === 0 ? { ...obj, value: item.article_category_id } : obj
        ),
      },
    }));
  };

  return (
    <div>
      <Navbar />
      <Container>
        <h1 className=" mt-5 text-center">
          Read Our Articles to Find Your Success Strategy
        </h1>

        <div className=" mt-4 d-flex align-items-center justify-content-center container-category-articles">
          <div className="list-category w-50 flex-wrap gap-2 d-flex align-items-center justify-content-center gap-4">
            {param.dataCategory.map((item) => (
              <button
                onClick={() => btnHandleSelectCategory(item)}
                className="btn category-text-article"
              >
                {item.article_category_name}
              </button>
            ))}
          </div>
        </div>

        <form onSubmit={btnSearch}>
          <div className="container-search d-flex align-items-center justify-content-center mt-4">
            <input
              type="search"
              placeholder="Search Article By Title/Content"
              className="form-control w-25"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <button type="submit" style={{ display: "none" }}>
            search
          </button>
        </form>

        <div className="container-articles mt-4">
          <div className="list-articles">
            {param.dataArticle.map((item, idx) => (
              <ArticleItem
                imgUrl={item.image}
                title={item.title}
                subTitle={item.content}
                author={item.author}
                published={item.created_at}
                slug={item.slug}
                most_viewed={idx === 0 ? true : false}
              />
            ))}
          </div>
        </div>

        <div className="container-pagination">
          <Pagination
            total={totalPage}
            current={page}
            btnPagination={(newPage) => btnPagination(newPage)}
          />
        </div>
      </Container>
    </div>
  );
};

export default ListArticlePage;

const Container = styled.div`
  padding: 0 50px;

  .container-pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-articles[single="true"] {
    display: grid;
    grid-template-columns: repeat(auto-fit, 360px);
    overflow: auto;
    justify-content: flex-start;
    gap: 30px;
  }

  .list-articles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    overflow: auto;
    justify-content: flex-start;
    gap: 30px;
  }
  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding: 0 20px;
    overflow-x: hidden;
  }
`;
