import styled from "styled-components";

const PlacementMTRItem = ({ title = "Channel A" }) => {
  return <Container>{title}</Container>;
};

export default PlacementMTRItem;

const Container = styled.div`
  background: #ffffff;
  border: 0.5px solid #c1c1c1;
  /* Shadow 1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  height: 140px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #161c2b;
`;
