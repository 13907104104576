import React, { useState, useEffect } from "react";
import {
  Form,
  Pagination,
  Loadingfunc,
  TableData,
  DropDown_More,
} from "../../../../component";
import {
  ButtonPrimary,
  COLOR_PRIMARY,
  ContainerContent,
  HeaderPrimary,
  Tr,
  Td,
} from "../../../../component/style/content/default";
import { MoreCircle } from "@styled-icons/fluentui-system-regular";
import { api_template } from "../../../../service/api";
import { Numeric, displayStatus, useTitle } from "../../../../util";
import { Modal_clone, Modal_create, Modal_detail, Modal_edit } from "./modal";
import Select from "react-select";

const header = [
  "No",
  "Nama Template",
  "Perusahaan",
  "Tipe Channel",
  "Status Persetujuan",
  "More",
];

const data_more = [
  { name: "Lihat Detail" },
  { name: "Ubah" },
  { name: "Clone Template" },
];

export const IdxTemplate = () => {
  useTitle("Beriklan | Template");
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    page: 1,
    last_page: null,
  });
  const [data_detail, setData_detail] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    detail: false,
    create: false,
    edit: false,
    clone: false,
  });
  const [template_id, setTemplate_id] = useState(null);
  const [options, setOptions] = useState({
    ms_inventory: [],
  });
  const [selected, setSelected] = useState({
    ms_inventory: "",
  });

  const get_data = async () => {
    setLoading(true);
    await api_template.get_template_list({ page: page.page }).then((res) => {
      if (res?.success) {
        setData(res.data);
        setPage((state) => ({
          ...state,
          page: res.page,
          last_page: res.last_page,
        }));
      }
    });
    setLoading(false);
  };

  const get_ms_channel = async () => {
    const result = await api_template.get_channel();
    if (result.success) {
      setOptions((state) => ({
        ...state,
        ms_inventory: [
          {
            value: "",
            label: "Lihat Semua",
          },
          ...result?.data?.map((item) => ({
            value: item.ms_inventory_id,
            label: item.ms_inventory_identifier,
          })),
        ],
      }));
    }
  };

  const btnPagination = async (e) => {
    setLoading(true);
    await api_template
      .get_template_list({
        page: e.selected + 1,
        param: {
          ms_inventory_id: selected["ms_inventory"]?.value,
        },
      })
      .then((res) => {
        if (res?.success) {
          setData(res.data);
          setPage((state) => ({
            ...state,
            page: res.page,
            last_page: res.last_page,
          }));
        }
      });
    setLoading(false);
  };

  const getDetailTemplate = async (template_idParam) => {
    await api_template
      .get_template_detail({ template_id: template_idParam })
      .then((res) => {
        if (res.success) {
          setData_detail(res.data);
        }
      });
  };

  const btnMore = ({ name, id }) => {
    setTemplate_id(id);
    switch (name) {
      case "Lihat Detail":
        setModal((modal) => ({ ...modal, detail: true }));
        break;
      case "Ubah":
        setModal((modal) => ({ ...modal, edit: true }));
        break;
      case "Clone Template":
        setModal((modal) => ({ ...modal, clone: true }));
        getDetailTemplate(id);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    get_data();
    get_ms_channel();
  }, []);

  const handleSelectMsInventory = async (e) => {
    setLoading(true);
    setSelected((state) => ({
      ...state,
      ms_channel: e,
    }));
    await api_template
      .get_template_list({
        page: 1,
        param: {
          ms_inventory_id: e.value,
        },
      })
      .then((res) => {
        if (res?.success) {
          setData(res.data);
          setPage((state) => ({
            ...state,
            page: 1,
            last_page: res.last_page,
          }));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ContainerContent>
      <HeaderPrimary color={COLOR_PRIMARY}>Daftar Template</HeaderPrimary>

      {/* MODAL ================ */}
      <Modal_detail
        show={modal.detail}
        onHide={() => setModal((modal) => ({ ...modal, detail: false }))}
        template_id={template_id}
      />

      <Modal_create
        show={modal.create}
        onHide={() => setModal((modal) => ({ ...modal, create: false }))}
        get_data1={get_data}
      />

      <Modal_clone
        show={modal.clone}
        onHide={() => setModal((modal) => ({ ...modal, clone: false }))}
        get_data1={get_data}
        nama_template={
          data.find((id) => id.template_id === template_id)
            ? data.find((id) => id.template_id === template_id).template_name
            : "null"
        }
        channel={
          data.find((id) => id.template_id === template_id)
            ? data.find((id) => id.template_id === template_id).ms_inventory
            : "null"
        }
        template_konten={data_detail?.template_content}
      />

      <Modal_edit
        show={modal.edit}
        onHide={() => setModal((modal) => ({ ...modal, edit: false }))}
        get_data1={get_data}
        template_id1={template_id}
      />

      <section>
        <Form>
          <section className=" mb-3">
            <div className="row">
              <div className="col-auto">
                <ButtonPrimary
                  onClick={() =>
                    setModal((modal) => ({ ...modal, create: true }))
                  }
                >
                  Create Template
                </ButtonPrimary>
              </div>
              <div className="col-md-3">
                <Select
                  options={options.ms_inventory}
                  onChange={handleSelectMsInventory}
                  placeholder="Select Channel"
                />
              </div>
            </div>
          </section>

          <section>
            <TableData header={header}>
              {loading ? (
                <tr>
                  <td colSpan={6}>
                    <Loadingfunc />
                  </td>
                </tr>
              ) : (
                data.map((item, idx) => (
                  <Tr key={idx}>
                    <Td data-label="No">
                      {Numeric({ idx: idx, page: page.page })}
                    </Td>
                    <Td data-label="Nama Template">{item.template_name}</Td>
                    <Td data-label="Perusahaan">
                      {item.company && item.company.company_name}
                    </Td>
                    <Td data-label="Tipe Channel">
                      {item.ms_inventory &&
                        item.ms_inventory.ms_inventory_identifier}
                    </Td>
                    <Td data-label="Status Persetujuan">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: displayStatus(
                            item.template_approve_status_name
                          ),
                        }}
                      />
                    </Td>
                    <Td data-label="More">
                      <DropDown_More
                        data_more={data_more}
                        id={item.template_id}
                        title={
                          <MoreCircle
                            style={{
                              width: "25px",
                              color: "#ccc",
                              cursor: "pointer",
                            }}
                          />
                        }
                        onClick={btnMore}
                      />
                    </Td>
                  </Tr>
                ))
              )}
            </TableData>
          </section>

          <section>
            <Pagination
              totalPage={page.last_page}
              page={page.page}
              handleOnChange={btnPagination}
            />
          </section>
        </Form>
      </section>
    </ContainerContent>
  );
};
