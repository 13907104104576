import styled from "styled-components";
import ImageBannerBenefit from "../../../../assets/iconAndLogo/img_self_service_banner.png";

const SelfService = () => {
  return (
    <Container className="container-benefit">
      <div className="container-image">
        <img src={ImageBannerBenefit} alt={ImageBannerBenefit} />
      </div>
      <div className="container-text">
        <div className="blue-text">SELF SERVICE</div>
        <div className="title-messaging">Lorem ipsum dolor sit amet</div>
        <div className="sub-title-messaging">
          Lorem ipsum dolor sit amet consectetur. Neque at auctor pellentesque
          id purus maecenas odio faucibus. Sagittis dictum molestie turpis
          dictum diam sit placerat. Lorem ipsum dolor sit amet consectetur.
          Neque at auctor pellentesque id purus maecenas odio faucibus.
        </div>
        <button className="btn btn-see-detail">
          See Detail &nbsp;&nbsp; >{" "}
        </button>
      </div>
    </Container>
  );
};

export default SelfService;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 180px;
  .container-image {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 540px;
      height: 290px;
      object-fit: contain;
    }
  }
  .container-text {
    flex-grow: 1;

    .blue-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      letter-spacing: 5px;
      text-transform: uppercase;

      /* Brand Colors/Tertiary/100 */

      color: #2fbeee;
    }

    .title-messaging {
      /* Heading/L */

      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      /* identical to box height, or 122% */

      letter-spacing: -0.022em;

      /* Neutral/100 */

      color: #222833;
      margin-top: 12px;
    }
    .sub-title-messaging {
      /* Text L/Regular */

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      letter-spacing: -0.011em;

      /* Neutral/80 */

      color: #6d7078;
      margin-bottom: 42px;
      margin-top: 12px;
    }
    .btn-see-detail {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      gap: 8px;

      width: 160px;
      height: 45px;

      /* Neutral/10

White
*/
      background: #ffffff;
      /* Brand Colors/Primary/100

3B3E98
*/
      border: 1px solid #3b3e98;
      border-radius: 8px;

      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* Brand Colors/Primary/100 */

      color: #3b3e98;
    }
  }
`;
