const CompanyInactive= () => {
  return(
    <div className="mt-5">
      <div class="alert alert-warning" role="alert">
        Perusahaan tidak mengikuti <b>Program Loyalty</b>, silahkan menghubungi <b>Admin Mediacartz</b> untuk mengikuti program ini.
      </div>
    </div>
  )
}

export default CompanyInactive