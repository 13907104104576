import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const BreadCrumb = () => {
  const { pathname } = useLocation();

  const removeSlash = () => {
    let newPhatname = pathname.split("/");

    return newPhatname.filter((filter) => filter !== "");
  };


  return (
    <Container>
      {removeSlash()?.map((item) => {
        if (removeSlash()?.slice(-1)[0] === item) {
          return (
            <React.Fragment>
              <span className="text active">{item}</span>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <span className="text">{item}</span>
              <span className="penghubung"> {">"} </span>
            </React.Fragment>
          );
        }
      })}
    </Container>
  );
};

export default BreadCrumb;

const Container = styled.div`
  .active {
    color: #1a202c !important;
  }
  .text,
  .penghubung {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #2b3582;
  }

  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  height: 78px;
  display: flex;
  align-items: center;
  margin-top: 60px;
  padding: 30px 36px;
  gap: 20px;
`;
