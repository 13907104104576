import React, { useEffect, useState, useRef, useContext } from "react";
import * as Component from "../../../../component";
import styled from "styled-components";
import * as Style from "../../../../component/style/content/default";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Select from "react-select";
import { Method, Context } from "../../../../service";
import { Form, Tabs, Tab, Row } from 'react-bootstrap';
import { Plus } from "@styled-icons/bootstrap";
import { Delete } from "@styled-icons/typicons";
import * as Services from "../../../../service";
import { Card } from "../../../../component/1.LANDINGPAGE";
import moment from "moment";

const api = Services.api.ApiRetail.toko;

const ModalFormVoucher = (props) => {
  const { selected_company } = useContext(Context);
  const { show, onHide, type, onSubmit, data } = props;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [percentage, setPercentage] = useState('0');
  const [maxAmount, setMaxAmount] = useState("");
  const [fixAmount, setFixAmount] = useState("");
  const [typeDiscSelected, setTypeDiscSelected] = useState(1);
  const [stock, setStock] = useState('1');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minOrder, setMinOrder] = useState('');
  const [isPublic, setIsPublic] = useState(1);
  const [isMenu, setIsMenu] = useState(0);
  const [menuData, setMenuData] = useState([]);
  const [modalMenu, setModalMenu] = useState(false);
  const [produkData, setProdukData] = useState([]);
  const [loadingProduk, setLoadingProduk] = useState(false);
  const [storeSelected, setStoreSelected] = useState("");
  const [store, setStore] = useState([]);
  const [key, setKey] = useState("home");

  console.log('startdate', startDate)

  const BtnSubmit = () => {
    if (type === 'add') {
      saveProcess();
    } else {
      editProcess();
    }
  }

  const saveProcess = async () => {
    setLoading(true);
    try {
      var menu = [];
      for(var i=0; i < menuData.length; i++){
        menu.push(menuData[i].menu_id)
      };

      var data_post = {
        voucher_name: name,
        voucher_code: code.toUpperCase(),
        voucher_discount_percentage: typeDiscSelected===1?parseFloat(percentage):null,
        voucher_discount_maximum_amount: typeDiscSelected===1 && (maxAmount!=='' || maxAmount!=='0')?parseInt(maxAmount):null,
        voucher_discount_fixed_amount: typeDiscSelected===0?parseInt(fixAmount):null,
        voucher_stock_quantity: parseInt(stock),
        voucher_active_from_datetime: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        voucher_active_until_datetime: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        company_id: selected_company.value,
        voucher_public_status: isPublic,
        voucher_minimum_order: minOrder!=='' || minOrder!=='0'?parseInt(minOrder):null,
        voucher_exclusive_for_menu_id: menu,
        voucher_active_status: 1
      }

      await Method.post(`voucher`, data_post)
        .then(async (res) => {
          if (res?.data?.success) {
            Component.AlertSuccess({ title: "Success", text: res?.data?.success });
            onSubmit()
          } else {
            Component.AlertError({ title: "Error", text: res?.data?.error });
          }
        })
    } catch (err) {
      Component.AlertError({ title: "Error", text: err });
    } finally {
      setLoading(false);
    }
  }

  const editProcess = async () => {
    setLoading(true);
    try {
      var menu = [];
      for(var i=0; i < menuData.length; i++){
        menu.push(menuData[i].menu_id)
      };
      
      var data_post = {
        voucher_name: name,
        voucher_code: code.toUpperCase(),
        voucher_discount_percentage: typeDiscSelected===1?parseFloat(percentage):null,
        voucher_discount_maximum_amount: typeDiscSelected===1 && (maxAmount!=='' || maxAmount!=='0')?parseInt(maxAmount):null,
        voucher_discount_fixed_amount: typeDiscSelected===0?parseInt(fixAmount):null,
        voucher_stock_quantity: parseInt(stock),
        voucher_active_from_datetime: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        voucher_active_until_datetime: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        company_id: selected_company.value,
        voucher_public_status: isPublic,
        voucher_minimum_order: minOrder!=='' || minOrder!=='0'?parseInt(minOrder):null,
        voucher_exclusive_for_menu_id: menu,
        voucher_active_status: 1
      }

      await Method.put(`voucher/${data.voucher_id}`, data_post)
        .then(async (res) => {
          if (res?.data?.success) {
            Component.AlertSuccess({ title: "Success", text: res?.data?.success });
            onSubmit();
          } else {
            Component.AlertError({ title: "Error", text: res?.data?.error });
          }
        })
    } catch (err) {
      Component.AlertError({ title: "Error", text: err });
    } finally {
      setLoading(false);
    }
  }

  const clearForm = () => {
    setCode("")
    setName("")
    setPercentage('0')
    setMaxAmount("")
    setFixAmount("")
    setTypeDiscSelected(1)
    setStock('1')
    setStartDate("")
    setEndDate("")
    setMinOrder('')
    setIsPublic(1)
    setIsMenu(0)
    setMenuData([])
    setModalMenu(false)
    setProdukData([])
    setLoadingProduk(false)
    setStoreSelected("")
  }

  useEffect(() => {
    if (show) {
      if (type === 'edit') {
        setName(data.voucher_name);
        setCode(data.voucher_code);
        if(data.voucher_discount_fixed_amount){
          setTypeDiscSelected(0)
        }else{
          setTypeDiscSelected(1)
        }
        setPercentage(data.voucher_discount_percentage?data.voucher_discount_percentage.toString():"");
        setMaxAmount(data.voucher_discount_maximum_amount);
        setStock(data.voucher_stock_quantity);
        setMinOrder(data.voucher_minimum_order);
        setStartDate(moment(data.voucher_active_from_datetime).format('YYYY-MM-DDTHH:mm'));
        setEndDate(moment(data.voucher_active_until_datetime).format('YYYY-MM-DDTHH:mm'));
        setIsPublic(data.voucher_public_status);
        setFixAmount(data.voucher_discount_fixed_amount);
        setIsMenu(data.voucher_exclusive_for_menu_id?.length>0?1:0);
        setMenuData(data.voucher_exclusive_for_menu_info?data.voucher_exclusive_for_menu_info:[]);
      }else{
        clearForm();
      }
    }
  }, [show, type])

  useEffect(() => {
    if (produkData) {
      setKey(Object.keys(produkData)[0])
    }
  }, [produkData])

  useEffect(() => {
    if (storeSelected) {
      setLoadingProduk(true);
      api.getTokoDetalMenu(storeSelected.value).then((res) => {
        if (res?.success) {
          setProdukData(res?.data?.menu);
        }
        setLoadingProduk(false);
      })
    }
  }, [storeSelected])

  if(modalMenu===false){
    return (
      <Component.Modal_Component
        size="lg"
        title={type === "add" ? "Tambah Voucher" : "Edit Voucher"}
        btnSubmit
        btnName={loading ? <Component.LoadingIcon /> : "Simpan"}
        onClick={loading ? null : BtnSubmit}
        onHide={onHide}
        show={show}
      >
        <Container className="container">
          <div className="mb-3">
            <Style.Label color={Style.COLOR_SECONDARY}>Nama Voucher</Style.Label>
            <Form.Control
              placeholder="Nama Voucher"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <Style.Label color={Style.COLOR_SECONDARY}>Kode Voucher</Style.Label>
            <Form.Control
              placeholder="Kode"
              value={code}
              onChange={(e) => setCode(e.target.value.toUpperCase())}
            />
          </div>

          <Style.Label color={Style.COLOR_SECONDARY}>Tipe Voucher</Style.Label>
          <div className="d-flex flex-row mb-2">
            <Form.Check
              type="radio"
              value={1}
              label={`Persentase`}
              onChange={() => setTypeDiscSelected(1)}
              name="type"
              checked={typeDiscSelected === 1}
            />

            <Form.Check
              type="radio"
              value={0}
              label={`Jumlah Tetap`}
              className="ms-4"
              onChange={() => setTypeDiscSelected(0)}
              name="type"
              checked={typeDiscSelected === 0}
            />
          </div>
          {
            typeDiscSelected===1?(
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 mb-3">
                  <Style.Label color={Style.COLOR_SECONDARY}>Persentase(%)</Style.Label>
                  <Form.Control
                    placeholder="Persentase"
                    type="number"
                    value={percentage}
                    onChange={(e) => setPercentage(e.target.value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 mb-3">
                  <Style.Label color={Style.COLOR_SECONDARY}>Maksimal Nominal(Rp)</Style.Label>
                  <Form.Control
                    placeholder="Nominal"
                    type="number"
                    value={maxAmount}
                    onChange={(e) => setMaxAmount(e.target.value)}
                  />
                </div>
              </div>
            ):(
              <div className="mb-3">
                <Style.Label color={Style.COLOR_SECONDARY}>Nominal(Rp)</Style.Label>
                <Form.Control
                  placeholder="Nominal"
                  type="number"
                  value={fixAmount}
                  onChange={(e) => setFixAmount(e.target.value)}
                />
              </div>
            )
          }

          <div className="mb-3">
            <Style.Label color={Style.COLOR_SECONDARY}>Jumlah Voucher</Style.Label>
            <Form.Control
              placeholder="Jumlah"
              type="number"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <Style.Label color={Style.COLOR_SECONDARY}>Minimal Order</Style.Label>
            <Form.Control
              placeholder="Minimal Order"
              type="number"
              value={minOrder}
              onChange={(e) => setMinOrder(e.target.value)}
            />
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>Dari Tanggal</Style.Label>
              <input
                type="datetime-local"
                className="form-control"
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>Sampai Tanggal</Style.Label>
              <input
                type="datetime-local"
                className="form-control"
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate}
                value={endDate}
              />
            </div>
          </div>

          <Style.Label color={Style.COLOR_SECONDARY}>Tampilkan Sebagai Publik?</Style.Label>
          <div className="d-flex flex-row mb-2">
            <Form.Check
              type="radio"
              value={1}
              label={`Ya`}
              onChange={() => setIsPublic(1)}
              name="public"
              checked={isPublic === 1}
            />

            <Form.Check
              type="radio"
              value={0}
              label={`Tidak`}
              className="ms-4"
              onChange={() => setIsPublic(0)}
              name="public"
              checked={isPublic === 0}
            />
          </div>

          <Style.Label color={Style.COLOR_SECONDARY}>Tambahkan Menu Ekslusif</Style.Label>
          <div className="d-flex flex-row mb-2">
            <Form.Check
              type="radio"
              value={1}
              label={`Ya`}
              onChange={() => setIsMenu(1)}
              name="ekslusif"
              checked={isMenu === 1}
            />

            <Form.Check
              type="radio"
              value={0}
              label={`Tidak Aktif`}
              className="ms-4"
              onChange={() => setIsMenu(0)}
              name="ekslusif"
              checked={isMenu === 0}
            />
          </div>

          {
            isMenu===1 &&
            <div className="mb-3">
              <Style.Label color={Style.COLOR_SECONDARY}>Tambahkan Menu</Style.Label>
              <div>
                {
                  menuData.length === 0 ? (
                    <div>Menu belum ditambahkan</div>
                  ) : reenderMenu()
                }
                <Style.ButtonPrimary
                  className="mt-2"
                  onClick={() => setModalMenu(true)}
                >
                  Tambah Menu
                </Style.ButtonPrimary>
              </div>
            </div>
          }
        </Container>
      </Component.Modal_Component>
    );
  }else{
    return(
      <Component.Modal_Component
        size="lg"
        title={'Pilih Menu'}
        onHide={() => setModalMenu(false)}
        show={modalMenu}
        closeText="Ok"
      >
        <div className="container">
          <Select
            placeholder="Pilih Toko"
            options={store}
            onChange={(e) => {
              setStoreSelected(e);
            }}
            onInputChange={(e) => {
              setTimeout(async () => {
                await Method.get(`company/${selected_company.value}/store?store_name=${e}&limit=*`)
                  .then(async (res) => {
                    if (res?.data?.success) {
                      setStore(
                        res?.data?.data?.map((item) => ({
                          value: item.store_id,
                          label: item.store_name
                        }))
                      )
                    }
                  })
              }, 400)
            }}
            value={storeSelected}
          />

          {
            storeSelected !== "" &&
            <div className="mt-3">
              {
                loadingProduk ? (
                  <Component.Loadingfunc />
                ) : (
                  <div>
                    {
                      Object.keys(produkData).length === 0 ? (
                        <div className="text-center">Menu tidak ditemukan</div>
                      ) : (
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key}
                          onSelect={(k) => setKey(k)}
                          className="mb-3"
                        >
                          {produkData &&
                            Object.keys(produkData)?.map((item, idx) => (
                              <StyledTab eventKey={item} title={item} key={idx}>
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="list-product d-grid gap-4"
                                    style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
                                  >
                                    {produkData[item]?.map((menu, idxMenu) => {
                                      if(menu.bundling_item && menu.bundling_item.length > 0){
                                        return null
                                      }else{
                                        var check = menuData.findIndex(x => x.menu_id === menu.menu_id);

                                        return(
                                          <Card.CardProduct
                                            menu={menu}
                                            selected={check >= 0}
                                            menuData={produkData}
                                            key={idxMenu}
                                            onClickDetail={() => {
                                              if (check < 0) {
                                                setMenuData([...menuData, {...menu, store_name: storeSelected.label}]);
                                              } else {
                                                setMenuData(menuData.filter(x => x.menu_id !== menu.menu_id))
                                              }
                                            }}
                                          />
                                        )
                                      }
                                    })}
                                  </div>
                                </div>
                              </StyledTab>
                            ))}
                        </Tabs>
                      )
                    }
                  </div>
                )
              }
            </div>
          }
        </div>
      </Component.Modal_Component>
    )
  }

  function reenderMenu() {
    const menuDatas = groupByStoreName(menuData);

    return(
      <div className="mt-3">
        <div className="container">
          <Row className="row bg-white">
            <div className="col-md-12">
              <strong style={{ color: Style.COLOR_SECONDARY }}>Menu Terpilih</strong>
            </div>
            {
              Object.keys(menuDatas).map((item, index) => (
                <div key={'ad-'+index} className="col-md-12">
                  <Row className="row bg-white">
                    <div className="col-md-4 col-sm-4">
                      <strong style={{ color: Style.COLOR_SECONDARY }}>{item}</strong>
                    </div>
                    <div className="col-md-8 col-sm-8">
                      {
                        menuDatas[item].map((item, idx)=>(
                          <div key={'da-'+idx} className="col-md-12 d-flex flex-row">
                            <div className="me-2">•</div>
                            <div>{item.quantity} {item.item_name}</div>
                          </div>
                        ))
                      }
                    </div>
                  </Row>
                </div>
              ))
            }
          </Row>
        </div>
      </div>
    )
  }

  function groupByStoreName (array){
    return array.reduce((result, currentValue) => {
      (result[currentValue.store_name] = result[currentValue.store_name] || []).push(
        currentValue
      );
      return result;
    }, {});
  };
};

export default ModalFormVoucher;

const StyledTab = styled(Tab)``;

const Container = styled.div`
  .list-gambar {
    .btn-delete {
      position: absolute;
      top: -10px;
      right: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: red;
      background-color: #fff;
      border-radius: 50%;
    }
  }
`;