import React from "react";
import styled from "styled-components";
import ImgRetailSolutions from "landingPage/assets/iconAndLogo/imgretailSolutions.svg";

const FormInput = () => {
  return (
    <Container>
      <div className="title">Lorem ipsum dolor</div>
      <div className="desc">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut
      </div>
      <div className="wrap-img text-center">
        <img src={ImgRetailSolutions} alt={ImgRetailSolutions} />
      </div>
    </Container>
  );
};

export default FormInput;

const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 1068px;
  border-top-left-radius: 10000px;
  border-bottom-left-radius: 10000px;
  padding-left: 322px !important;
  padding: 100px;
  overflow: hidden;

  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    text-align: center;

    /* Dark Light */

    color: rgba(12, 27, 77, 0.6);
    margin-bottom: 30px;
  }

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    /* identical to box height, or 135% */

    text-align: center;

    /* Black 2 */

    color: #1a202c;
    margin-bottom: 30px;
  }
`;
