import styled from "styled-components";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

const ContentDetailArticle = ({
  author = "Muharis",
  published = "02/04/2023 22:22:22",
  title = " Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio ducimus nobis nostrum, culpa sint officiis excepturi explicabo seddolore suscipit.",
  content = "",
  shareLink = "",
}) => {
  return (
    <Container>
      <div className="row-1">
        <div className="title-text">
          <p>{title}</p>
        </div>
        <div className="info-text">
          <span>Author : {author}</span>
          <span>Published : {published}</span>
        </div>
      </div>

      <div className="container-content">
        <div className="row-content">
          <div className="container-social-media">
            <FacebookShareButton url={shareLink}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareLink}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton url={shareLink}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            <TelegramShareButton url={shareLink}>
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
          </div>
          <div className="container-content-text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContentDetailArticle;

const Container = styled.div`
  .container-content {
    min-height: 500px;
    height: 100%;
    background-color: white;
    margin-left: 92px;
    margin-top: -100px;
    position: relative;
    z-index: 2;

    .row-content {
      display: flex;

      .container-social-media {
        padding: 20px 10px 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      .container-content-text {
        flex: 1;
        padding: 20px 100px 10px 10px;
      }
    }
  }

  .row-1 {
    display: flex;
    color: white;
    height: 365px;
    position: relative;
    z-index: 1;

    .info-text {
      background: #6899d1;
      display: flex;
      flex-direction: column;
      flex: 0.4;
      padding: 68px 0px 0px 42px;
    }

    .title-text {
      background-color: #3b3e98;
      flex: 1;
      padding: 53px 62px 0px 92px;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        /* or 122% */

        letter-spacing: -0.022em;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
`;
