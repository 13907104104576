import React, { useState } from "react";
import styled from "styled-components";
import Provinsi from "landingPage/assets/iconAndLogo/Group37586.png";
import BgMask from "landingPage/assets/iconAndLogo/BgMask.png";
import iconCompanies from "landingPage/assets/iconAndLogo/IconCompanies.png";
import usevector from "landingPage/assets/iconAndLogo/uservektor.png";
import roket from "landingPage/assets/iconAndLogo/roket.png";
import partner from "landingPage/assets/iconAndLogo/partner.png";
import impressions from "landingPage/assets/iconAndLogo/impressions.png";
import { Mobile_query_v2 } from "../../util/Query";

const line1 = [
  {
    icon: iconCompanies,
    name: "Companies",
    number: "584",
  },
  {
    icon: roket,
    name: "Campaign",
    number: "869",
  },
  {
    icon: usevector,
    name: "Users",
    number: "347",
  },
];
const line2 = [
  {
    icon: impressions,
    name: "Impressions",
    number: "+14541K",
  },
  {
    icon: partner,
    name: "Partners",
    number: "22",
  },
];

const CardEgagement = ({ img, number, name }) => {
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function () {
    return setWidth(window.innerWidth);
  });
  if (width <= 550) {
    return (
      <ContainerCardMobile>
        <div className="text-number">{number}</div>
        <div className="wrap-bottom d-flex align-items-center justify-content-center">
          <div className="left-bottom">
            <img src={img} alt={img} />
          </div>
          <div className="right-bottom">{name}</div>
        </div>
      </ContainerCardMobile>
    );
  } else {
    return (
      <ContainerCard>
        <div className="wrap-icon">
          <img src={img} alt={img} />
        </div>
        <div className="title">{number}</div>
        <button className="btn-card">{name}</button>
      </ContainerCard>
    );
  }
};

const EgagementNumbers = () => {
  return (
    <ContainerInti>
      <Container className="d-flex">
        <div className="left">
          <div className="title">Engagement Numbers</div>
          <div className="wrap-line d-flex">
            <div className="line1 line" />
            <div className="line2 line" />
          </div>
          <div className="desc">
            Mediacartz users advertising campaign stats.
          </div>
        </div>
        <div className="right">
          <div className="top d-flex" style={{ gap: "92px" }}>
            {line1.map((item, idx) => (
              <div className={`div-card-${idx}`}>
                <CardEgagement
                  key={idx}
                  img={item.icon}
                  name={item.name}
                  number={item.number}
                />
              </div>
            ))}
          </div>
          <div
            className="bottom d-flex justify-content-center"
            style={{ marginTop: "60px", gap: "92px" }}
          >
            {line2.map((item, idx) => (
              <div className={`div-card-${idx}`}>
                <CardEgagement
                  key={idx}
                  img={item.icon}
                  name={item.name}
                  number={item.number}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </ContainerInti>
  );
};

export default EgagementNumbers;

const ContainerCardMobile = styled.div`
  ${Mobile_query_v2} {
    .wrap-bottom {
      gap: 10px;
      margin-top: 5px;
      .right-bottom {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        color: rgba(0, 0, 0, 0.87);
      }
    }
    .text-number {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      text-align: center;

      /* Brand Color/Primary */

      color: #3b3e98;
    }
    width: 111px;
  }
`;

const ContainerCard = styled.div`
  width: 150px;
  height: 125px;
  background: #ffffff;
  box-shadow: -1px 6px 22px rgba(99, 102, 241, 0.17);
  border-radius: 28px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .btn-card {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    color: #1a202c;
    border: none;
    background-color: transparent;
    min-height: 33px;
  }

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 52px;
    flex: 1;
    /* identical to box height */

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #1a202c;
    border-bottom: thin solid rgba(222, 222, 222, 1);
    width: 100%;
    padding: 5px 5px;
  }

  .wrap-icon {
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -15px;
    top: -15px;
  }
`;

const ContainerInti = styled.div`
  ${Mobile_query_v2} {
    background-image: url(${BgMask});
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 367px;
    width: 100%;
  }
`;

const Container = styled.div`
  ${Mobile_query_v2} {
    flex-direction: column;
    .right {
      margin-top: 35px !important;
      background-image: unset !important;
      .bottom {
        gap: 0 !important;
        .div-card-0 {
          border-right: 1px solid #d1c4e9;
          padding-right: 10px;
        }
      }
      .top {
        .div-card-1 {
          padding-left: 10px;
          border-right: 1px solid #d1c4e9;
          padding-right: 10px;
        }
        .div-card-0 {
          border-right: 1px solid #d1c4e9;
          padding-right: 10px;
        }
        gap: 0 !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }
  padding: 0 93px;

  ${Mobile_query_v2} {
    padding: 0 25px;
  }
  .right {
    background-image: url(${Provinsi});
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 367px;
    width: 100%;
  }

  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    /* or 200% */

    color: rgba(12, 27, 77, 0.6);
  }

  .left > .wrap-line > .line1 {
    width: 70px !important;
  }
  .left > .wrap-line > .line2 {
    width: 25px !important;
  }
  .left > .wrap-line > .line {
    width: 70px;
    height: 10px;
    background-color: #2fbeee;
    border-radius: 5px;
    margin-right: 8px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .left > .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;

    /* Primary */

    color: #3b3e98;
  }

  .left,
  .right {
    flex: 1;
  }

  margin-top: 200px;
`;
