import BannerPublisher from "./banner";
import OurValue from "./our_value";
import BenefitPublisher from "./benefits";
import WhyChooseMe from "./why_choose_me";
import RespectiveClient from "landingPage/components/respectiveClient";
import OurClient from "landingPage/components/our-client/index";
import styled from "styled-components";

const PublisherPage = () => {
  return (
    <Container>
      <BannerPublisher />
      <OurValue />
      <BenefitPublisher />
      <RespectiveClient />
      <WhyChooseMe />
      <div className="container">
        <OurClient />
      </div>
      <section className="section-contactUs container">
        <div className="form-contactUs d-flex align-items-center justify-content-between">
          <div className="form-contactUs-left">
            <div className="title">Ready to get started ?</div>
            <div className="desc">Lorem ipsum dolor sit amet cunstructutor</div>
          </div>
          <div className="form-contactUs-right">
            <button className="button-contactUs">Contact Us</button>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default PublisherPage;

const Container = styled.div`
  .section-contactUs {
    margin-top: 150px;
    position: relative;
    z-index: 1;

    .form-contactUs {
      background: #ffffff;
      /* Primary */

      border: 4px solid #3b3e98;
      box-shadow: 0px 3px 49px 9px rgba(0, 0, 0, 0.06);
      border-radius: 47px;
      padding: 48px 59px;
      width: 100%;

      .form-contactUs-right > .button-contactUs {
        background: #3b3e98;
        box-shadow: 0px 0px 49px -2px rgba(0, 0, 0, 0.19);
        border-radius: 81px;
        font-family: "Mulish";
        font-style: bold;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        text-transform: uppercase;
        border: thin solid #3b3e98;
        color: #ffffff;
        width: 194px;
        height: 65px;
        overflow: hidden;
      }
      .form-contactUs-left > .desc {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        /* or 200% */

        /* Black 2 */

        color: #1a202c;
      }
      .form-contactUs-left > .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        /* or 150% */

        /* Black 2 */

        color: #1a202c;
      }
    }
  }
`;
