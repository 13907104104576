import React from "react";
import styled from "styled-components";
import { Mobile_query_v2 } from "../../../util/Query";

const CardProduct = ({
  image = "",
  title = "Eventories",
  desc = "A platform for you to create events, promotions, loyalty programs, and ticketing.",
  href,
}) => {
  // console.log("screen", window.innerWidth);
  return (
    <Container
      onClick={() => {
        if (window.innerWidth <= 540) {
          return (window.location.href = href);
        }
      }}
    >
      <div className="warp-image">
        <img src={image} alt={image} />
      </div>
      <div className="wrap-text">
        <div className="title">{title}</div>
        <div className="desc">{desc}</div>
      </div>

      {/* <a href="/home/solutions"> */}
      <ButtonSeeDetail href={href} className="see-detail1">
        See Detail
      </ButtonSeeDetail>
      {/* </a> */}
    </Container>
  );
};

export default CardProduct;

const Container = styled.div`
  ${Mobile_query_v2} {
    display: flex;
    width: 100%;
    height: auto;
    border: 1px solid transparent;
    box-shadow: none;
    background: linear-gradient(transparent, transparent) padding-box,
      linear-gradient(180deg, transparent, transparent) border-box;
    gap: 35px;
    &:hover {
      margin-top: 0px;
      padding-top: 0px;
      height: auto !important;
      background: transparent;
      background: linear-gradient(transparent, transparent) padding-box,
        linear-gradient(180deg, transparent, transparent) border-box;
    }
    .wrap-text {
      .title {
        font-size: 16px !important;
      }
      .desc {
        font-size: 14px !important;
      }
    }
    .warp-image {
      display: flex;
      align-items: center;
      margin-top: 0 !important;
      text-align: center;
      img {
        width: 45px;
      }
    }
  }
  .warp-image {
    margin-top: 63px;
  }
  .wrap-text {
    .desc {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      margin-bottom: 20px;

      /* Dark Light */

      color: rgba(12, 27, 77, 0.6);
    }

    .title {
      margin-top: 30px;
      margin-bottom: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      color: #1a202c;
    }
  }

  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06);
  border-radius: 25px;
  width: 270px;
  height: 333px;
  overflow: hidden;
  padding: 22px;
  background: linear-gradient(#ffff, #fff) padding-box,
    linear-gradient(180deg, rgba(59, 62, 152, 0.6), rgba(59, 62, 152, 0))
      border-box;
  border: 1px solid transparent;
  transition: 0.2s;

  &:hover {
    margin-top: -35px;
    padding-top: 35px;
    height: 361.37px;
    background: #f6fffe;
    background: linear-gradient(#f6fffe, #f6fffe) padding-box,
      linear-gradient(180deg, rgba(47, 190, 238, 0.6), rgba(47, 190, 238, 0))
        border-box;
  }
`;

const ButtonSeeDetail = styled.a`
  ${Mobile_query_v2} {
    font-size: 14px !important;
    display: none !important;
    ${Container}:hover & {
      opacity: 0 !important;
      display: none !important;
    }
  }
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  /* Primary */

  color: #3b3e98;
  cursor: pointer;
  opacity: 0;
  display: none;
  transition: 0.2s;

  ${Container}:hover & {
    opacity: 1;
    display: unset;
  }
`;
