import { useEffect } from 'react';
import './css/loader.css';

const ModalLoader = (props) => {

  useEffect(() => {
    if(props.show){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'unset';
    }
  }, [props.show])

  if(props.show){
    return(
      <div className="wrapper-loader">
        <div className="container-loader">
          <div className="dot dot-1"></div>
          <div className="dot dot-2"></div>
          <div className="dot dot-3"></div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"/>
            </filter>
          </defs>
        </svg>
      </div>
    )
  }else{
    return null;
  }
}

export default ModalLoader