import React, { useState, useEffect } from "react";
import { Modal_Component, AlertError } from "component";
import SelectPerusahaan from "component/select/Perusahaan";
import SelectInventory from "component/select/inventory";
import { Plus } from "@styled-icons/boxicons-regular";
import { Times } from "@styled-icons/typicons";
import { COLOR_PRIMARY } from "component/style/content/default";
import ModalFilter from "./konversi.filter";
import { ApiCustomer as api } from "../../../../service/api";
import { Method } from "service";

const Konversi = (props) => {
  const {
    show,
    onHide,
    setData,
    handleShowKonversiResult,
    setSelectedProduct,
    setSelectedCompany,
    listFilter,
    setListFilter,
    selected,
    setSelect,
  } = props;
  const [bool, setBool] = useState(false);

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);

  const BtnDeleteFilterHandler = (idx) => {
    const filter = listFilter.filter((filter, idx2) => idx2 !== idx);

    setListFilter(filter);
  };

  const BtnDeleteFilterHandlerReligion = () => {
    const filter = listFilter.filter((filter) => filter.field !== "religion");
    setListFilter(filter);
  };
  const BtnDeleteFilterHandlerGender = () => {
    const filter = listFilter.filter((filter) => filter.field !== "gender");
    setListFilter(filter);
  };

  const btnSubmitKonversi = () => {
    setLoading(true);

    const getAge = listFilter.find((find) => find.field === "age") ?? {};
    const getReligion =
      listFilter
        .filter((find) => find.field === "religion")
        .map((item) => item.value)
        ?.join("|") ?? {};
    const getGender =
      listFilter
        .filter((find) => find.field === "gender")
        .map((item) => (item.value === "male" ? "m" : "f"))
        ?.join("|") ?? {};
    const funcGetReligion =
      getReligion !== ""
        ? {
            field_name: "religion",
            field_operator: "~",
            field_value: getReligion,
          }
        : delete {};
    const funcGetGender =
      getGender !== ""
        ? {
            field_name: "gender",
            field_operator: "~",
            field_value: getGender,
          }
        : delete {};
    const funcAge1 = getAge?.value
      ? {
          field_name: "age",
          field_operator: ">",
          field_value: getAge?.value[0],
        }
      : delete {};
    const funcAge2 = getAge?.value
      ? {
          field_name: "age",
          field_operator: "<",
          field_value: getAge?.value[1],
        }
      : delete {};
    let resultQuery = [funcGetGender, funcGetReligion, funcAge1, funcAge2];
    const body = {
      company_id: selected["company"]?.value,
      ms_inventory_id: selected["inventory"]?.value,
      select: ["id", "name", "msisdn", "gender", "religion", "birth_date"],
      filter: {
        query: resultQuery.filter((filter) => filter !== true),
        page: 1,
        limit: 15,
      },
      preview_status: true,
    };

    api
      .postConvert(body)
      .then((res) => {
        // console.log("convert post", res);
        if (res?.success) {
          onHide();
          setData((state) => ({
            ...state,
            data: res?.data,
            page: res?.page,
            last_page: res?.last_page,
          }));
          // console.log({
          //   product: product,
          //   selected: selected["inventory"]?.value,
          // });
          setSelectedProduct(
            product.find(
              (find) => find.product_id === selected["inventory"]?.value
            )
          );
          handleShowKonversiResult();
        } else {
          AlertError({ title: "ERROR", text: res.error });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeSelectHandler = (e, name) => {
    if (name === "company") {
      setSelectedCompany(e);
    }
    setSelect((state) => ({
      ...state,
      [name]: e,
    }));
  };

  const onCloseHandler = () => {
    onHide();
  };

  const loadProduct = () => {
    Method.get(
      "product?product_available_for_campaign_status_name=true&ms_product_active_status_name=active&relate_to_ms_inventory_has_sender_status_name=true&relate_to_ms_channel_name=BROADCAST&limit=*"
    )
      .then((res) => res?.data)
      .then((response) => {
        // console.log({ response_product: response });
        setProduct(response.data);
      });
  };

  useEffect(() => {
    if (show) {
      loadProduct();
    }
  }, [show]);

  return (
    <Modal_Component
      title="Konversi"
      show={show}
      onHide={onCloseHandler}
      btnSubmit
      btnName={loading ? "Loading" : "Submit"}
      onClick={loading ? null : btnSubmitKonversi}
    >
      <div className="card">
        <div className="p-3">
          <div className="row mb-3">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="">Perusahaan</label>
              <SelectPerusahaan
                value={selected["company"]}
                onChange={(e) => onChangeSelectHandler(e, "company")}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="">Inventory</label>
              <SelectInventory
                filterBroadcast
                value={selected["inventory"]}
                onChange={(e) => onChangeSelectHandler(e, "inventory")}
              />
            </div>
          </div>

          {/* filter */}
          <div className="wrap-filter">
            <label htmlFor="">Operator dan Value</label>
            <div className="filter-data d-flex align-items-center gap-3 flex-wrap">
              {listFilter
                .filter(
                  (filter) =>
                    filter.field !== "religion" && filter.field !== "gender"
                )
                .map((item, idx) => (
                  <div
                    key={idx}
                    className="card-filter d-flex align-items-center gap-2"
                    style={{
                      backgroundColor: COLOR_PRIMARY,
                      color: "#fff",
                      borderRadius: "999px",
                      fontWeight: "600",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    {item.field === "age"
                      ? item.value[0] === item.value[1]
                        ? `${item.field} = ${item.value[0]}`
                        : `${item.field} >= ${item.value[0]} dan ${item.field} <= ${item.value[1]}`
                      : null}
                    <Times
                      width={17}
                      cursor="pointer"
                      onClick={() => BtnDeleteFilterHandler(idx)}
                    />
                  </div>
                ))}
              {listFilter.filter((filter) => filter.field === "religion")
                .length > 0 ? (
                <div
                  className="card-filter d-flex align-items-center gap-2"
                  style={{
                    backgroundColor: COLOR_PRIMARY,
                    color: "#fff",
                    borderRadius: "999px",
                    fontWeight: "600",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  {listFilter
                    .filter((filter) => filter.field === "religion")
                    .map((item2) => `${item2.field} = ${item2.value}`)
                    .join(" , ")}
                  <Times
                    width={17}
                    cursor="pointer"
                    onClick={() => BtnDeleteFilterHandlerReligion()}
                  />
                </div>
              ) : null}
              {listFilter.filter((filter) => filter.field === "gender").length >
              0 ? (
                <div
                  className="card-filter d-flex align-items-center gap-2"
                  style={{
                    backgroundColor: COLOR_PRIMARY,
                    color: "#fff",
                    borderRadius: "999px",
                    fontWeight: "600",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  {listFilter
                    .filter((filter) => filter.field === "gender")
                    .map(
                      (item2) =>
                        `${item2.field} = ${
                          item2.value === "male" ? "Laki-Laki" : "Perempuan"
                        }`
                    )
                    .join(" , ")}
                  <Times
                    width={17}
                    cursor="pointer"
                    onClick={() => BtnDeleteFilterHandlerGender()}
                  />
                </div>
              ) : null}

              <div
                className=" btn d-flex align-items-center"
                style={{
                  minWidth: "50px",
                  border: `thin solid ${COLOR_PRIMARY}`,
                }}
                onClick={() => setBool(true)}
              >
                <Plus width={15} />
                Tambah Filter
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL ===== */}
      <ModalFilter
        setListFilter={setListFilter}
        show={bool}
        onHide={() => setBool(false)}
        listFilter={listFilter}
      />
    </Modal_Component>
  );
};

export default Konversi;
