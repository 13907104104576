import styled from "styled-components";
import Select from "react-select";
import ProductOOHItem from "./placement_ooh_item";

const ProductOOH = ({
  data,
  category,
  btnSelectCategeory,
  selected,
  selected_placement,
  btnHandlePlacement,
  btnSubmitSearchPlacement,
  onChangeSearchPlacement,
}) => {
  const { province, city: citySelected, district: districtSelected } = selected;

  const provinsi = category?.map((item) => ({
    value: item,
    label: item?.category_name,
  }));

  const city = category
    ?.find((filter) => filter?.category_id == province)
    ?.children?.map((item) => ({
      value: item,
      label: item?.category_name,
    }));

  const district = category
    ?.find((filter) => filter?.category_id == province)
    ?.children?.find((filter) => filter?.category_id === citySelected)
    ?.children?.map((item) => ({
      value: item,
      label: item?.category_name,
    }));

  const subDistrict = category
    ?.find((filter) => filter?.category_id == province)
    ?.children?.find((filter) => filter?.category_id === citySelected)
    ?.children?.find((filter) => filter?.category_id === districtSelected)
    ?.children?.map((item) => ({
      value: item,
      label: item?.category_name,
    }));

  console.log({ selected_placement });
  return (
    <Container>
      <div className="title-product-ooh">
        {selected_placement?.placement[0]?.shorten} -{" "}
        {selected_placement?.placement[0]?.inventory_name}
      </div>
      <div className="list-select-product-ooh">
        <Select
          isClearable
          className="select-province"
          placeholder="Select Province"
          options={provinsi}
          onChange={(e) => btnSelectCategeory(e, "province")}
        />
        <Select
          isClearable
          className="select-province"
          placeholder="Select City"
          options={city}
          onChange={(e) => btnSelectCategeory(e, "city")}
        />
        <Select
          isClearable
          className="select-province"
          placeholder="Select District"
          options={district}
          onChange={(e) => btnSelectCategeory(e, "district")}
        />
        <Select
          isClearable
          className="select-province"
          placeholder="Select Sub District"
          options={subDistrict}
          onChange={(e) => btnSelectCategeory(e, "subDistrict")}
        />
        <FormSearch
          onChange={onChangeSearchPlacement}
          btnSubmit={btnSubmitSearchPlacement}
        />
      </div>
      <div className="column-placement-ooh justify-content-start">
        {data?.map((item, idx) => {
          return (
            <div
              style={{ height: "180px" }}
              onClick={() => btnHandlePlacement(item)}
            >
              <ProductOOHItem
                key={idx}
                imgUrl={
                  item?.background_image ||
                  "https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                }
                title={item?.placement_name}
                active={selected_placement?.inventory_placement?.filter(
                  (filter) =>
                    filter.placement_name === item?.placement_name
                      ? true
                      : false
                )}
              />
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default ProductOOH;

const Container = styled.div`
  min-height: 798px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  /* Shadow 3 */

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.12);
  padding: 45px 41px;

  .column-placement-ooh {
    margin-top: 42px;
    background: #ffffff;
    border: 1px solid #c1c1c1;
    justify-content: center;
    overflow: auto;
    height: 581px;
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 245px);
    overflow: auto;
    gap: 27px;
    row-gap: 40px;
  }

  .list-select-product-ooh {
    margin-top: 28px;
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;

    .css-b62m3t-container {
      min-width: 200px;

      .css-1s2u09g-control {
        height: 32px !important;
        background: #ffffff !important;
        border: 1px solid #c1c1c1 !important;
        border-radius: 4px !important;
      }

      .css-1okebmr-indicatorSeparator {
        display: none !important;
      }
      .css-14el2xx-placeholder {
        /* Text S/Semi Bold */

        font-family: "Inter" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 12px !important;
        line-height: 16px !important;

        color: rgba(49, 53, 59, 0.68) !important;
      }
    }
  }

  .title-product-ooh {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 33px;
    /* or 110% */

    text-transform: capitalize;

    color: #161c2b;
  }
`;

const FormSearch = ({ onChange, btnSubmit }) => {
  return (
    <ContainerSearch onSubmit={btnSubmit}>
      <i className="bi-search" />
      <input onChange={onChange} type="text" placeholder="Search" />
    </ContainerSearch>
  );
};

const ContainerSearch = styled.form`
  background: #ffffff;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 7.5px;
  gap: 20px;
  i {
    font-size: 15px;
    color: #999999;
  }

  input {
    border: none;

    :focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
`;
