import styled from "styled-components";
import NavTabsInterestItem from "./nav_tabs_interest_item";

const NavTabsInterest = ({
  data,
  btnHandleChannel,
  dataInventory,
  btnHandleInventory,
  selected,
}) => {
  const validateActiveItem = (itemInv) => {
    switch (selected?.channel?.has_placement) {
      case 1:
        return selected?.placement[0]?.inventory_name ===
          itemInv?.inventory_name
          ? true
          : false;
        break;

      default:
        return selected?.inventory?.filter(
          (filter) => filter?.inventory_name === itemInv?.inventory_name
        )?.length === 1
          ? false
          : false;

        break;
    }
  };

  return (
    <Container>
      <nav className="container-nav-interest">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          {data?.map((item, idx) => (
            <button
              key={idx}
              className={`nav-link`}
              id={`nav-${item.channel_id}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#nav-${item.channel_id}`}
              type="button"
              role="tab"
              aria-controls={`nav-${item.channel_id}`}
              aria-selected="true"
              onClick={() => btnHandleChannel(item)}
            >
              {item.channel_name}
            </button>
          ))}
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        {data.map((item, idx) => (
          <div
            key={idx}
            className={`tab-pane fade`}
            id={`nav-${item.channel_id}`}
            role="tabpanel"
            aria-labelledby={`nav-${item.channel_id}-tab`}
          >
            <div className="container-tab-pane w-100 d-flex align-items-center justify-content-center flex-wrap">
              {dataInventory?.map((itemInv, key) => (
                <div onClick={() => btnHandleInventory(itemInv)}>
                  <NavTabsInterestItem
                    key={key}
                    active={validateActiveItem(itemInv)}
                  >
                    {itemInv?.inventory_name}
                  </NavTabsInterestItem>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default NavTabsInterest;

const Container = styled.div`
  .container-nav-interest {
    display: flex;
    justify-content: center;
  }
  .tab-content {
    width: 100% !important;
  }
  .tab-pane {
    width: 100%;
    border: 1px solid #dadada !important;
    /* Shadow 3 */

    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.12) !important;
    background-color: white !important;
    padding: 24px;
  }

  .nav-link.active {
    background-color: white !important;
  }
  .nav-tabs {
    border: 2px solid #e0e0e0 !important;
    border-style: solid !important;
    border-color: #e0e0e0 !important;
  }
  .nav-link {
    background: #f5f8fa !important;
    border-style: solid !important;
    border-color: transparent !important;
    padding: 12px !important;
    border-radius: 0px !important;

    /* Text M/Semi Bold */

    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* identical to box height, or 143% */

    display: flex !important;
    align-items: center !important;
    letter-spacing: -0.006em !important;
    justify-content: center;
    flex-grow: 1;
    min-width: 176px !important;
    min-height: 48px !important;

    color: #161c2b !important;
  }
`;
