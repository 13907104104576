import styled from "styled-components";
import IconEmail from "../../assets/iconAndLogo/VectorEmail.png";
import IconPhone from "../../assets/iconAndLogo/VectorPhone.png";
import IconFb from "../../assets/iconAndLogo/icon-Facebook.svg";
import IconIg from "../../assets/iconAndLogo/icon-Instagram.svg";
import IconLinkedIn from "../../assets/iconAndLogo/icon-linkedin.png";
import IconYoutube from "../../assets/iconAndLogo/icon-youtube.svg";

const MobileFooter = ({ logo, desc }) => {
  return (
    <Container>
      <div className="container">
        <div className="row logo justify-content-center align-items-center">
          <div className="col-auto">
            <img src={logo} alt={logo} />
          </div>
        </div>
        <div className="row descriptions">
          <div className="col-auto text-center desc-text">{desc}</div>
        </div>

        <div className="row menus mt-4 justify-content-center">
          <div className="col-auto text-center">
            <div className="menus-title text-center">MENU</div>
            <div className="row mt-4">
              <div className="col-auto menus-menu-text">Products</div>
              <div className="col-auto menus-menu-text">Resources</div>
              <div className="col-auto menus-menu-text">Solutions</div>
              <div className="col-auto menus-menu-text">Company</div>
            </div>
          </div>
        </div>

        <div className="row contact-us mt-4 justify-content-center">
          <div className="col-auto">
            <div className="menus-title text-center">Contact Us</div>
            <div className="row mt-4">
              <div className="col-auto menus-menu-text">
                Centennial Tower, 38’th floor, Unit H Jl. Gatot Subroto, kavling
                24-25, Jakarta Selatan, 12930, Indonesia
              </div>
            </div>
            <div className="row mt-3 justify-content-between">
              <div className="col-auto d-flex align-items-center gap-3">
                <img src={IconEmail} alt={IconEmail} className="icon" />
                <div className="menus-menu-text">cs@mediacartz.com</div>
              </div>
              <div className="col-auto d-flex align-items-center gap-3">
                <img src={IconPhone} alt={IconPhone} className="icon" />
                <div className="menus-menu-text">(021) 2295 - 8146</div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 social-medias justify-content-center">
          <div className="col-auto">
            <div className="social-media-title">Social Media Kami</div>
            <div className="row list-social-media mt-2">
              <div className="col">
                <img src={IconFb} alt="" className="list-social-media-icon" />
              </div>
              <div className="col">
                <img src={IconIg} alt="" className="list-social-media-icon" />
              </div>
              <div className="col">
                <img
                  src={IconLinkedIn}
                  alt=""
                  className="list-social-media-icon"
                />
              </div>
              <div className="col">
                <img
                  src={IconYoutube}
                  alt=""
                  className="list-social-media-icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div id="line"></div>

        <div className="row copyright mt-3 justify-content-center">
          <div className="col-auto copyright-text">
            Copyright © 2022 <br />
            PT. Mediacartz Digital Indonesia
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MobileFooter;

const Container = styled.div`
  padding-top: 47px;
  padding-bottom: 47px;

  .copyright-text {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;

    /* Color/Neutral 80 */

    color: #6d7078;
  }

  #line {
    width: 100%;
    height: 1px;
    background-color: #c8c9cc;
    margin-top: 25px;
  }
  .list-social-media-icon {
    width: auto;
  }

  .social-media-title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    /* Color/Neutral 100 */

    color: #222833;
  }
  .menus-menu-text {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    color: #6d7078;
  }
  .menus-title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #000000;
  }
  .descriptions {
    margin-top: 24px;

    .desc-text {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      /* Color/Neutral 80 */

      color: #6d7078;
    }
  }
`;
