import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import { ApiCustomer as api } from "../../service/api";
import PropTypes from "prop-types";
import axios from "../../service/method";
import { Context } from "../../service"

const SelectInventory = (props) => {
  const [data, setData] = useState([]);
  const { onChange, value, filterBroadcast } = props;
  const { dispatch } = useContext(Context)

  const getData = async () => {
    await axios("/product?order_by=product_id&limit=30&sort_by=ASC").then(
      (result) => {
        const res = result?.data;
        if (res?.success) {
          const mapping = res?.data?.map((item) => ({
            value: item.product_id,
            label: item.product_name,
          }));
          setData(mapping);
        }
      }
    );
  };
  useEffect(() => {
    getData();
  }, []);

  const onSelectHandler = (e) => {
    onChange(e)
    dispatch({
      type: "SET_SELECTED_INV",
      payload: e
    })
  };

  return (
    <Select
      options={
        filterBroadcast
          ? data.filter((filter) => filter.value === 1 || filter.value === 2)
          : data
      }
      onChange={onSelectHandler}
      value={value}
      placeholder="Pilih Inventory"
    />
  );
};

export default React.memo(SelectInventory);

SelectInventory.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};
