import React from "react";
import styled from "styled-components";
import BannerPressMediaImage from "landingPage/assets/iconAndLogo/BannerPressmedia.png";

const BannerPressMedia = () => {
  return (
    <div className="row mt-5" style={{ marginBottom: "76px" }}>
      <div className="col-md-6 col-sm-12 text-center">
        <img src={BannerPressMediaImage} alt={BannerPressMediaImage} />
      </div>
      <div className="col-md-6 col-sm-12 d-flex flex-column align-items-center justify-content-center">
        <WrapText className="wrap-text">
          <div className="title text-center">Press Media</div>
          <div className="desc">
            Catch up on the latest news coverage, product updates, promotions,
            and more about Mediacartz
          </div>
        </WrapText>
      </div>
    </div>
  );
};

export default BannerPressMedia;

const WrapText = styled.div`
  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #6d7078;
  }
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* identical to box height */

    /* Brand Color/Primary */

    color: #3b3e98;
    z-index: 1;
    position: relative;

    ::before {
      content: "";
      width: 40px;
      height: 40px;
      background: #fcd45d;
      position: absolute;
      border-radius: 9999px;
      z-index: -1 !important;
      top: 10px;
    }
  }
`;
