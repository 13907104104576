import styled from "styled-components";
import OurTestiItem from "./our_testi_item";

import OwlCarousel from "react-owl-carousel";
const OurTesti = () => {
  return (
    <Container>
      <div className="blue-text">OUR TESTIMMONIALS</div>
      <div className="title-our-client">Don't take our word for it</div>

      <div className="container-list-testi" id="list-testi">
        <OwlCarousel className="owl-theme" margin={30}>
          {[1, 1, 1, 1, 1].map((item, idx) => (
            <div className="item" key={idx}>
              <OurTestiItem />
            </div>
          ))}
        </OwlCarousel>
      </div>
    </Container>
  );
};

export default OurTesti;

const Container = styled.div`
  margin-top: 121px;
  padding-left: 150px;
  margin-right: 10px;

  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding-left: 20px;
    overflow-x: hidden;
  }

  #list-testi {
    .owl-dots {
      display: none;
    }
  }
  .blue-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    letter-spacing: 5px;
    text-transform: uppercase;

    /* Brand Colors/Tertiary/100 */

    color: #2fbeee;
  }

  .title-our-client {
    /* Heading/S */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */

    text-align: start;
    letter-spacing: -0.017em;

    /* Neutral/100 */

    color: #222833;
  }

  .container-list-testi {
    margin-top: 27px;
  }
`;
