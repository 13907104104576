import React, { useContext, useEffect, useState } from "react";
import * as Component from "../../../../component";
import styled from "styled-components";
import * as Style from "../../../../component/style/content/default";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Method } from "service";
import * as Services from "../../../../service";
import * as Util from "../../../../util";

const ModalStoreProduk = (props) => {
  const { selected_company } = useContext(Services.Context);
  const { show, onHide, data} = props;
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [status, setStatus] = useState(0);
  const [store, setStore] = useState([])
  const [storeSelected, setStoreSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [custom, setCustom] = useState(0);

  useEffect(()=>{
    if(show){
      setPrice(data.item_regular_price)
      setDiscount(data.item_discount_price)
      loadStore()
      setLoading(false)
    }
  },[show])

  const loadStore = async () => {
    await Method.get(`company/${selected_company?.value}/store?limit=*`)
    .then(async (res) => {
      if(res.data.data.length>0){
        setStore(
          res.data.data.map((item) => ({
            value: item.store_id,
            label: item.store_name,
          }))
        )
      }else{
        Component.AlertError({ title: "Error", text: 'Toko tidak ditemukan' });
      }
    })
    .catch(error => {
      onHide()
      if (error.response) {
        Component.AlertError({ title: "Error", text: error.response.data.error });
      } else {
        Component.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
      }
    })  
  }

  const BtnSubmit = async () => {
    if(storeSelected.length===0){
      Component.AlertError({ title: "Error", text: 'Toko tidak boleh kosong' });
      return false
    }

    setLoading(true)
    try {
      const data_post = {
        menu_custom_price_status: custom,
        menu_regular_price: price,
        menu_discount_price: discount,
        store_id: storeSelected.map(x=>(x.value)),
        menu_absolute_price_status: status
      }

      await Method.post(`item/${data.item_id}/bulk`, data_post)
        .then(async (res) => {
          if (res?.data?.success) {
            Component.AlertSuccess({ title: "Success", text: res?.data?.success });
            onHide();
          } else {
            Component.AlertError({ title: "Error", text: res?.data?.error });
          }
        })
    } catch (err) {
      Component.AlertError({ title: "Error", text: err });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Component.Modal_Component
      size="lg"
      title={"Pasang ke Toko"}
      onHide={onHide}
      show={show}
      btnSubmit
      btnName={loading ? <Component.LoadingIcon /> : "Simpan"}
      onClick={loading ? null : BtnSubmit}
    >
      <div className="container">
        <Box className="mb-3">
          <div className="d-flex flex-row align-items-center">
            <img src={data?.item_image[0]} style={{ witdh: 100, height: 100, resizeMode: 'cover' }} alt=""/>
            <div className="ms-4">
              <div>{data?.category_display?.category_display_name}</div>
              <div>{data?.item_sku}</div>
              <b>{data?.item_name}</b>
            </div>
          </div>
        </Box>
        <div className="mb-3">
          <Style.Label color={Style.COLOR_SECONDARY}>Custom Harga</Style.Label>
          <div className="d-flex flex-row">
            <Form.Check
              type="radio"
              value={1}
              label={`Ya`}
              onChange={() => setCustom(1)}
              name="custom_price"
              checked={custom === 1}
            />

            <Form.Check
              type="radio"
              value={0}
              label={`Tidak`}
              className="ms-4"
              onChange={() => setCustom(0)}
              name="custom_price"
              checked={custom === 0}
            />
          </div>
        </div>
        {
          custom===1 ? (
            <div>
              <div className="mb-3">
                <Style.Label color={Style.COLOR_SECONDARY}>Harga</Style.Label>
                <Form.Control
                  placeholder="Harga"
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <Style.Label color={Style.COLOR_SECONDARY}>Harga Setelah Diskon</Style.Label>
                <Form.Control
                  placeholder="Harga Setelah Diskon"
                  type="number"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </div>
            </div>
          ):(
            <div>
              <div>
                <Style.Label color={Style.COLOR_SECONDARY}>Harga</Style.Label>
                <div>{Util.FormatCurrency.currency(data?.item_regular_price)}</div>
                <Style.Label color={Style.COLOR_SECONDARY} className="mt-3">Harga Setelah Diskon</Style.Label>
                <div className="mb-3">{Util.FormatCurrency.currency(data?.item_discount_price)}</div>
              </div>
            </div>
          )
        }
        <div className="mb-3">
          <Style.Label color={Style.COLOR_SECONDARY}>Harga Bisa Dipotong Voucher</Style.Label>
          <div className="d-flex flex-row">
            <Form.Check
              type="radio"
              value={0}
              label={`Ya`}
              onChange={() => setStatus(0)}
              name="status"
              checked={status === 0}
            />

            <Form.Check
              type="radio"
              value={1}
              label={`Tidak`}
              className="ms-4"
              onChange={() => setStatus(1)}
              name="status"
              checked={status === 1}
            />
          </div>
        </div>
        <div className="mb-3">
          <Style.Label color={Style.COLOR_SECONDARY}>Toko</Style.Label>
          <Select
            isMulti
            placeholder="Pilih Toko"
            options={store}
            value={storeSelected}
            onChange={(e) => setStoreSelected(e)}
          />
        </div>
      </div>
    </Component.Modal_Component>
  );
};


const Box = styled.div`
  border-radius: 5px;
  background-color: #fff;
  padding:10px;
  border: 1px solid #ccc;
  img {
    border-radius: 5px;
  }
`

export default ModalStoreProduk;