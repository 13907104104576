import * as Style from "../../../../component/style/content/default";
import {
  SelectPerusahaan,
  SelectEvent,
  SelectTicket,
} from "../../../../component/select";
import { Loadingfunc, Pagination, TableData } from "../../../../component";
import { useState } from "react";
import { Method as Axios } from "service";
import QueryStringFunction from "util/queryString";

const { saveAs } = require("file-saver");

const headerTable = ["Full Name", "Job Title", "Company", "Phone", "Email"];

const Registrans = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [param, setParam] = useState({
    page: 1,
    lastPage: 1,
    selected_event: null,
    selected_ticket: null,
    selected_company: null,
  });

  const getReport = async (page = 1) => {
    setLoading(true);
    const query = QueryStringFunction({
      page: page,
      event_id: param?.selected_event?.value?.event_id,
      seat_detail_id: param?.selected_ticket?.value?.seat_detail_id,
      // company_id: param?.selected_company?.value,
    });
    try {
      const { data } = await Axios.get(`ticket/report/list?${query}`);

      if (data?.success) {
        setData(data?.data);
        setParam((state) => ({
          ...state,
          page: data?.page,
          lastPage: data?.last_page,
        }));
      } else {
        alert(data?.error);
      }
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const handlerGenerateReport = () => {
    getReport();
  };

  const btnPagination = (newPage) => {
    console.log({ newPage });
    getReport(newPage?.selected + 1);
  };

  const handlerDownload = async () => {
    const query = QueryStringFunction({
      event_id: param?.selected_event?.value?.event_id,
      seat_detail_id: param?.selected_ticket?.value?.seat_detail_id
    });
    try {
      const { data } = await Axios.get(
        `ticket/report/download/registration?${query}`,
        {
          responseType: "blob",
        }
      );
      console.log({ data });
      var blob = data;
      saveAs(blob, `Event_Registrants_Report_${new Date().getTime()}.xlsx`);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="container" style={{ "margin-bottom": "100px" }}>
      <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
        Event Registrants Report
      </Style.HeaderPrimary>
      <div className="card rounded-1 shadow-1 mt-5">
        <div className="card-body">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <FormInput param={param} setParam={setParam} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="d-flex gap-2">
                <Style.ButtonPrimary onClick={() => handlerGenerateReport()}>
                  Generate Report
                </Style.ButtonPrimary>
                <Style.ButtonPrimary onClick={handlerDownload}>
                  Download Report
                </Style.ButtonPrimary>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <TableData header={headerTable}>
                {loading ? (
                  <tr>
                    <td colSpan={headerTable.length}>
                      <Loadingfunc />
                    </td>
                  </tr>
                ) : data.length > 0 ? (
                  data?.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{item?.ticket_issued_for?.name}</td>
                        <td>{item?.ticket_issued_for?.title}</td>
                        <td>
                          {
                            item?.ticket_issued_for?.first_company?.company
                              ?.company_name
                          }
                        </td>
                        <td>{item?.ticket_issued_for?.msisdn}</td>
                        <td>{item?.ticket_issued_for?.email}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerTable.length}>Data Not Found.</td>
                  </tr>
                )}
              </TableData>
            </div>
            <div className="col-md-12">
              <Pagination
                page={param.page}
                totalPage={param.lastPage}
                handleOnChange={btnPagination}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registrans;

const FormInput = ({ param, setParam }) => {
  return (
    <div className="row">
      <div className="col-12 mb-2">
        <label htmlFor="" className="form-label">
          Company
        </label>
        <SelectPerusahaan
          value={param.selected_company}
          onChange={(e) => {
            setParam((state) => ({
              ...state,
              selected_company: e,
              selected_event: "",
              selected_ticket: "",
            }));
          }}
        />
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="" className="form-label">
          Event
        </label>
        <SelectEvent
          value={param.selected_event}
          onChange={(e) => {
            setParam((state) => ({
              ...state,
              selected_event: e,
              selected_ticket: "",
            }));
          }}
          company_id={param?.selected_company?.value || ""}
        />
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="" className="form-label">
          Ticket
        </label>
        <SelectTicket
          value={param.selected_ticket}
          onChange={(e) => {
            setParam((state) => ({
              ...state,
              selected_ticket: e,
            }));
          }}
          event_id={param?.selected_event?.value?.event_id || ""}
        />
      </div>
    </div>
  );
};
