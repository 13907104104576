import * as Style from "../../../../component/style/content/default";
import {
  SelectPerusahaan,
  SelectEvent,
  SelectTicket,
} from "../../../../component/select";
import { Loadingfunc, Pagination, TableData } from "../../../../component";
import { useState } from "react";
import QueryStringFunction from "util/queryString";
import { Method as Axios } from "service";
const { saveAs } = require("file-saver");

const headerTable = ["Full Name", "Job Title", "Company", "Phone", "Email"];

const Attendess = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataAbsent, setDataAbsent] = useState([]);
  const [param, setParam] = useState({
    page: 1,
    lastPage: 1,
    pageAbsent: 1,
    lastPageAbsent: 1,
    selected_event: null,
    selected_ticket: null,
    selected_company: null,
  });
  // attendance 1
  // attendees
  // 0 absent
  const getDataEvent = async ({ page = 1 }) => {
    setLoading(true);
    const query = QueryStringFunction({
      page: page,
      event_id: param?.selected_event?.value?.event_id,
      seat_detail_id: param?.selected_ticket?.value?.seat_detail_id || "",
      company_id: param?.selected_company?.value,
      sort_by: "DESC",
      attendance: 1, // 0 absent , 1 attendance
    });

    try {
      const { data } = await Axios.get(`ticket/report/list?${query}`);

      if (data?.success) {
        setData(data?.data);
        setParam((state) => ({
          ...state,
          page: data?.page,
          lastPage: data?.last_page,
        }));
      } else {
        alert(data?.error);
      }
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const getDataAbsent = async ({ page = 1 }) => {
    setLoading(true);
    const query = QueryStringFunction({
      page: page,
      event_id: param?.selected_event?.value?.event_id,
      seat_detail_id: param?.selected_ticket?.value?.seat_detail_id || "",
      company_id: param?.selected_company?.value,
      sort_by: "DESC",
      attendance: 0, // 0 absent , 1 attendance
    });

    try {
      const { data } = await Axios.get(`ticket/report/list?${query}`);

      if (data?.success) {
        setDataAbsent(data?.data);
        setParam((state) => ({
          ...state,
          pageAbsent: data?.page,
          lastPageAbsent: data?.last_page,
        }));
      } else {
        alert(data?.error);
      }
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const handlerGenerateReport = () => {
    if(!param.selected_event){
      alert('Event required')
      return false
    }

    if(!param.selected_ticket){
      alert('Ticket required')
      return false
    }else{
      if(param.selected_ticket.value===""){
        alert('Ticket required')
        return false
      }
    }

    getDataEvent({ page: 1 });
    getDataAbsent({ page: 1 });
  };

  const btnPaginationEvent = (newPage) => {
    getDataEvent({ page: newPage?.selected + 1 });
  };
  const btnPaginationAbsent = (newPage) => {
    getDataAbsent({ page: newPage?.selected + 1 });
  };

  const handleDownloadReport = async () => {
    if(!param.selected_event){
      alert('Event required')
      return false
    }

    if(!param.selected_ticket){
      alert('Ticket required')
      return false
    }else{
      if(param.selected_ticket.value===""){
        alert('Ticket required')
        return false
      }
    }

    const query = QueryStringFunction({
      event_id: param?.selected_event?.value?.event_id,
      seat_detail_id: param?.selected_ticket?.value?.seat_detail_id
    });
    try {
      const { data } = await Axios.get(
        `ticket/report/download/attendance?${query}`,
        {
          responseType: "blob",
        }
      );
      console.log({ data });
      var blob = data;
      saveAs(blob, `Event_Attendees_Report_${new Date().getTime()}.xlsx`);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="container">
      <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
        Event Attendees Report
      </Style.HeaderPrimary>
      <div className="card rounded-1 shadow-1 mt-5">
        <div className="card-body">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <FormInput param={param} setParam={setParam} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="d-flex gap-2">
                <Style.ButtonPrimary onClick={handlerGenerateReport}>
                  Generate Report
                </Style.ButtonPrimary>
                <Style.ButtonPrimary onClick={handleDownloadReport}>
                  Download Report
                </Style.ButtonPrimary>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">Event Attendess</div>
            <div className="col-md-12">
              <TableData header={headerTable}>
                {loading ? (
                  <Loadingfunc />
                ) : data.length > 0 ? (
                  data?.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{item?.ticket_issued_for?.name}</td>
                        <td>{item?.ticket_issued_for?.title}</td>
                        <td>
                          {
                            item?.ticket_issued_for?.first_company?.company
                              ?.company_name
                          }
                        </td>
                        <td>{item?.ticket_issued_for?.msisdn}</td>
                        <td>{item?.ticket_issued_for?.email}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerTable.length}>Data Not Found.</td>
                  </tr>
                )}
              </TableData>
            </div>
            <div className="col-md-12">
              <Pagination
                page={param.page}
                totalPage={param.lastPage}
                handleOnChange={btnPaginationEvent}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">Absent Attendess</div>
            <div className="col-md-12">
              <TableData header={headerTable}>
                {loading ? (
                  <Loadingfunc />
                ) : dataAbsent.length > 0 ? (
                  dataAbsent?.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{item?.ticket_issued_for?.name}</td>
                        <td>{item?.ticket_issued_for?.title}</td>
                        <td>
                          {
                            item?.ticket_issued_for?.first_company?.company
                              ?.company_name
                          }
                        </td>
                        <td>{item?.ticket_issued_for?.msisdn}</td>
                        <td>{item?.ticket_issued_for?.email}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerTable.length}>Data Not Found.</td>
                  </tr>
                )}
              </TableData>
            </div>
            <div className="col-md-12">
              <Pagination
                page={param.pageAbsent}
                totalPage={param.lastPageAbsent}
                handleOnChange={btnPaginationAbsent}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendess;

const FormInput = ({ param, setParam }) => {
  return (
    <div className="row">
      <div className="col-12 mb-2">
        <label htmlFor="" className="form-label">
          Company
        </label>
        <SelectPerusahaan
          value={param.selected_company}
          onChange={(e) => {
            setParam((state) => ({
              ...state,
              selected_company: e,
              selected_event: "",
              selected_ticket: "",
            }));
          }}
        />
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="" className="form-label">
          Event
        </label>
        <SelectEvent
          value={param.selected_event}
          onChange={(e) => {
            setParam((state) => ({
              ...state,
              selected_event: e,
              selected_ticket: "",
            }));
          }}
          company_id={param?.selected_company?.value || ""}
        />
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="" className="form-label">
          Ticket
        </label>
        <SelectTicket
          value={param.selected_ticket}
          onChange={(e) => {
            setParam((state) => ({
              ...state,
              selected_ticket: e,
            }));
          }}
          event_id={param?.selected_event?.value?.event_id || ""}
        />
      </div>
    </div>
  );
};
