import * as Style from "../../../../component/style/content/default";
import { ButtonPrimary } from "../../../../component/style/content/default";
import { useState, useEffect } from "react";
import AddRecipientModal from "./modal/add_recipient";
import Axios from "../../../../service/method";
import { decrypt } from "../../../../service";
import { AlertSuccess, AlertError, Loadingfunc } from "../../../../component";
import Cookie from "js-cookie";
import ws from "@lukinco/adonis-websocket-client";
import Select from "react-select";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Regex } from "../../../../util";

const SummaryPage = () => {
  const [param, setParam] = useState({
    filter_from_datetime: "",
    filter_until_datetime: "",
    store_id: [],
    seller_company_id: [],
    recipient_email: [],
  });
  const [options, setOptions] = useState({
    company: [],
    outlet: [],
  });
  const [selected, setSelected] = useState({
    company: "",
    outlet: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [date, setDate] = useState({
    min: new Date(),
    max: new Date(),
  });
  const [input, setInput] = useState({
    email: "",
    idParam: "",
    time: "",
    daysEveryDay: "",
    days: days.map((item) => ({
      key: item.key,
      value: "",
    })),
  });
  const [modal, setModal] = useState({
    add: false,
  });

  const company = decrypt(Cookie.get("company") || {});

  const getScheduler = async (company_id) => {
    setLoadingPage(true);
    const data = await Axios.get(
      `/company/${company_id ?? param.seller_company_id[0]}/scheduler`
    )
      .then((res) => res.data)
      .finally(() => setLoadingPage(false));

    console.log("data nya", data);

    try {
      if (data.success) {
        if (data.data) {
          const getObjKey = Object.keys(data.data).filter(
            (filter) =>
              filter !== "scheduler_id" &&
              filter !== "scheduler_recipient_email" &&
              filter !== "scheduler_updated_datetime" &&
              filter !== "scheduler_updated_by_user_id" &&
              filter !== "company_id"
          );
          const newArrDays = getObjKey.map((item) => data.data[item]);

          console.log(
            "time",
            newArrDays.every((time) => time !== null)
          );

          setInput((state) => ({
            ...state,
            daysEveryDay: newArrDays.every((time) => time !== null),
            time: newArrDays.filter((filter) => filter !== null)[0],
            days: [
              ...days.map((item, idx) => {
                console.log({
                  data: newArrDays[idx],
                });
                return {
                  key: item.key,
                  value:
                    // newArrDays[idx]?.split(":")[0] !== "00" ||
                    // newArrDays[idx]?.split(":")[1] !== "00"
                    newArrDays[idx] !== null ? item.key : "",
                };
              }),
            ],
          }));

          getListStoreByCompany(data?.data?.company_id).then((resultStore) => {
            if (
              data?.data?.store_id?.length == 0 ||
              data?.data?.store_id == null
            ) {
              setSelected((state) => ({
                ...state,
                outlet: {
                  value: "not_selected_store",
                  label: "Tidak Pilih Toko",
                },
              }));
            }

            if (data?.data?.store_id?.length > 1) {
              setSelected((state) => ({
                ...state,
                outlet: {
                  value: "selected_all_store",
                  label: "Pilih Semua Toko",
                },
              }));
            }

            if (data?.data?.store_id?.length === 1) {
              setSelected((state) => ({
                ...state,
                outlet: {
                  value: resultStore?.data?.find(
                    (filter) => filter?.store_id == data?.data?.store_id[0]
                  ),
                  label: resultStore?.data?.find(
                    (filter) => filter?.store_id == data?.data?.store_id[0]
                  )?.store_name,
                },
              }));
            }
            
            setParam((state) => ({
              ...state,
              store_id: resultStore?.data?.map((x) => x.store_id)
            }));
          });

          setParam((state) => ({
            ...state,
            recipient_email: data.data.scheduler_recipient_email.map(
              (item, idx) => ({
                id: idx + 1,
                email: item,
              })
            )
          }));
        } else {
          setInput((state) => ({
            ...state,
            daysEveryDay: "",
            time: "",
            days: days.map((item) => ({
              key: item.key,
              value: "",
            })),
          }));
          setParam((state) => ({
            ...state,
            recipient_email: [],
          }));
        }
      }
    } catch (error) {
      // alert(error);
    }
  };

  console.log({ selected });

  const putScheduler = async () => {
    setLoadingPage(true);

    const scheduler_send_date = input.days
      .filter((filter) => filter.key !== "every_day")
      .map((item) => (item.value === "" ? "" : input.time));
    let body = {
      scheduler_recipient_email: param.recipient_email.map(
        (item) => item.email
      ),
      scheduler_time_of_day_1: scheduler_send_date[0] || null,
      scheduler_time_of_day_2: scheduler_send_date[1] || null,
      scheduler_time_of_day_3: scheduler_send_date[2] || null,
      scheduler_time_of_day_4: scheduler_send_date[3] || null,
      scheduler_time_of_day_5: scheduler_send_date[4] || null,
      scheduler_time_of_day_6: scheduler_send_date[5] || null,
      scheduler_time_of_day_0: scheduler_send_date[6] || null,
      store_id: [selected?.outlet?.value?.store_id],
    };

    if (selected?.outlet?.value == "not_selected_store") {
      body = {
        ...body,
        store_id: [],
      };
    }

    if (selected?.outlet?.value == "selected_all_store") {
      body = {
        ...body,
        store_id: options?.outlet
          ?.map((item) => item?.value?.store_id)
          .filter((filter) => filter != undefined),
      };
    }

    try {
      const data = await Axios.put(
        `/company/${selected.company?.value || company?.company_id}/scheduler`,
        body
      )
        .then((res) => res.data)
        .finally(() => {
          setLoadingPage(false);
          getScheduler();
        });

      AlertSuccess({ title: "Successfully", text: data.success, timer: 5000 });
      // alert(data.success);
    } catch (error) {
      alert("Something Error", error.message);
    }
  };

  const initCookie = () => {
    const company = decrypt(Cookie.get("company") || {});

    setParam((state) => ({
      ...state,
      seller_company_id: [selected.company?.value || company?.company_id],
    }));
  };

  const btnHandleModalAddRecipient = (type, emailParam, idParam) => {
    setInput((state) => ({
      ...input,
      email: emailParam,
      idParam,
    }));
    setModal((state) => ({
      ...state,
      add: type === "open" ? true : false,
    }));
  };

  const btnAddRecipient = (type) => {
    console.log("type", type);
    const length = param.recipient_email.length;
    const getLastArray = param.recipient_email[length - 1]?.id ?? 0;

    console.log("email", !Regex.email.test(input.email));
    if (!Regex.email.test(input.email)) {
      alert("Please Input Email Valid !");
      return;
    }

    if (type === "edit") {
      setParam((state) => ({
        ...state,
        recipient_email: param.recipient_email.map((obj) =>
          parseInt(obj.id) === parseInt(input.idParam)
            ? { ...obj, email: input.email }
            : obj
        ),
      }));
    } else {
      setParam((state) => ({
        ...state,
        recipient_email: [
          ...param.recipient_email,
          {
            id: getLastArray + 1,
            email: input.email,
          },
        ],
      }));
    }
  };

  const btnDeleteRecipient = (idxParam) => {
    setParam((state) => ({
      ...state,
      recipient_email: param.recipient_email.filter(
        (filter, idx) => idx !== idxParam
      ),
    }));
  };

  const handleOnChangeDatetime = (e, name) => {
    const value = e.target.value;

    if (name === "filter_from_datetime") {
      setDate({
        min: value,
      });
    } else {
      setDate({
        max: value,
      });
    }
    setParam((state) => ({
      ...state,
      [name]: value.split("T").join(" "),
    }));
  };

  const btnDownloadReport = () => {
    setLoading(true);

    let body = param;
    body = {
      ...body,
      filter_from_datetime: `${body.filter_from_datetime}:00`,
      filter_until_datetime: `${body.filter_until_datetime}:00`,
    };
    Axios.post(`/report/transaction/summary`, body);
  };

  const onChangeCheckBox = (e) => {
    let value = e.target.value;
    let checked = e.target.checked;

    if (value === "every_day") {
      setInput((state) => ({
        ...state,
        days: days.map((item) => ({
          key: item.key,
          value: item.key,
        })),
      }));
    } else {
      if (input.days.find((find) => find.value === value)) {
        setInput((state) => ({
          ...state,
          daysEveryDay: false,
          days: input.days.map((obj) =>
            obj.key === value ? { ...obj, value: "" } : obj
          ),
        }));
      } else {
        setInput((state) => ({
          ...state,
          days: input.days.map((obj) =>
            obj.key === value ? { ...obj, value: value } : obj
          ),
        }));
      }
    }
  };

  const getListCompany = async () => {
    const result = await Axios.get(`/company?limit=*`).then(
      (res) => res.data?.data
    );

    setOptions((state) => ({
      ...state,
      company: result?.map((item) => ({
        value: item.company_id,
        label: item.company_name,
      })),
    }));
  };

  const getListStoreByCompany = async (company_id) => {
    if (!company_id) {
      alert("Please Select Company");
      return;
    }
    const result = await Axios.get(`/company/${company_id}/store?limit=*&store_active_status=1`);

    if (result.data?.data) {
      setOptions((state) => ({
        ...state,
        outlet: [
          {
            value: "not_selected_store",
            label: "Tidak Pilih Toko",
          },
          {
            value: "selected_all_store",
            label: "Pilih Semua Toko",
          },
          ...result?.data?.data?.map((item) => ({
            label: item?.store_name,
            value: item,
          })),
        ],
      }));
    }

    return result.data;
  };

  const btnSubmitScheduler = () => {
    if (param.recipient_email.length === 0) {
      alert("Please add email recipient");
    } else if (input.time === "") {
      alert("Please select time scheduler");
    } else {
      putScheduler();
    }
  };

  const btnHandleCompany = (e) => {
    try {
      setSelected((state) => ({ ...state, company: e }));
      getScheduler(e.value);
      getListStoreByCompany(e?.value);
      setSelected((state) => ({
        ...state,
        outlet: "",
      }));
    } catch (error) {
      // alert(error);
    }
  };

  const simulate = async () => {
    const { data } = await axios.get(
      "https://core.mediacartz.com/simulate_socket"
    );

    console.log({ dataSimulate: data });
  };

  useEffect(() => {
    initCookie();
  }, [selected.company?.value]);

  useEffect(() => {
    // simulate();
    getScheduler();
    initCookie();
    getListCompany();

    const socket = ws(
      // `wss://core.mediacartz.com`
      `wss://${process.env.REACT_APP_BASE_URL_SOCKET}`
    ).connect();
    socket.on("open", (evt) => {
      console.log({ evt });
    });

    const channel = socket.subscribe(
      // `summary:sales_request_by_user_id_252`
      `summary:sales_request_by_user_id_${company.user_id}`
    );
    channel.on("message", (res) => {
      console.log("response WS", res);
      if (res?.success) {
        //download file
        const link = document.createElement("a");
        link.href = res?.data?.full_path;
        link.setAttribute("target", "_blank");
        link.setAttribute(
          "download",
          `summary_report_${new Date().getTime()}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        AlertError({ title: "Failed", text: res?.error, timer: 5000 });
      }

      setLoading(false);
    });
  }, []);

  const handleSelectedOutlet = (e) => {
    setSelected((state) => ({
      ...state,
      outlet: e,
    }));
    if (e?.value === "selected_all_store") {
      setParam((state) => ({
        ...state,
        store_id: options?.outlet
          ?.map((item) => item?.value?.store_id)
          .filter((filter) => filter != null),
      }));
      return;
    }

    if (e?.value === "not_selected_store") {
      setParam((state) => ({
        ...state,
        store_id: [],
      }));
      return;
    }
    setParam((state) => ({
      ...state,
      store_id: [e?.value?.store_id],
    }));
  };

  return (
    <div className="container">
      <div>
        <div><strong>Report</strong></div>
        <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
          Summary Transaction
        </Style.HeaderPrimary>
      </div>
      <div className="card rounded-0 mb-2">
        <div className="card-body">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-sm-12 p-1">
                <label htmlFor="" className="form-label">
                  Select Company
                </label>
                <Select
                  isClearable
                  placeholder="select company"
                  options={options.company}
                  onChange={(e) => btnHandleCompany(e)}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 col-lg-4 col-sm-12 p-1">
                <label htmlFor="">Select Outlet</label>
                <Select
                  placeholder="Select Outlet"
                  options={options.outlet}
                  isClearable
                  onChange={handleSelectedOutlet}
                  value={selected.outlet}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card rounded-0">
        <div className="card-body">
          <div className="container">
            <h5>Sales Summary Configuration</h5>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12 p-2">
                <label htmlFor="" className="form-label">
                  From
                </label>
                <div className="container-input d-flex gap-2">
                  <input
                    type="datetime-local"
                    // step="1"
                    className="form-control"
                    onChange={(e) =>
                      handleOnChangeDatetime(e, "filter_from_datetime")
                    }
                    max={date.max}
                    value={date.min}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 p-2">
                <label htmlFor="" className="form-label">
                  To
                </label>
                <div className="container-input d-flex gap-2">
                  <input
                    type="datetime-local"
                    name=""
                    id=""
                    // step="1"
                    className="form-control"
                    min={date.min}
                    onChange={(e) =>
                      handleOnChangeDatetime(e, "filter_until_datetime")
                    }
                    value={date.max}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4 col-lg-4 col-sm-6">
                <ButtonPrimary onClick={loading ? null : btnDownloadReport}>
                  {loading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{" "}
                      &nbsp; Generating Report
                    </>
                  ) : (
                    "Download Report"
                  )}
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card rounded-0 mt-2">
        <div className="card-body">
          <div className="container">
            <h5>Scheduled Report Delivery</h5>
            <div className="row mt-5">
              <div className="col-md-4 col-lg-4 col-sm-6">
                <ButtonPrimary
                  onClick={() => btnHandleModalAddRecipient("open")}
                >
                  Add New Recipient
                </ButtonPrimary>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 col-lg-6 col-sm-12 p-1">
                <table className="table table-responsive table-bordered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Recipients Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {param.recipient_email.map((item, idx) => (
                      <tr kwy={idx}>
                        <td>{item.id}</td>
                        <td>{item.email}</td>
                        <td>
                          <div className="gap-2 d-flex">
                            <button
                              className="btn"
                              onClick={() =>
                                btnHandleModalAddRecipient(
                                  "open",
                                  item.email,
                                  item.id
                                )
                              }
                            >
                              Edit
                            </button>
                            <button
                              className="btn"
                              onClick={() => btnDeleteRecipient(idx)}
                            >
                              hapus
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 p-1">
                <label htmlFor="" className="form-label">
                  Choose Schedule
                </label>
                <div className="container-list-schedule">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="daysEveryDay"
                      onChange={(e) => {
                        setInput((state) => ({
                          ...state,
                          daysEveryDay: e.target.checked,
                          days: days.map((item) => ({
                            key: item.key,
                            value: item.key,
                          })),
                        }));
                      }}
                      checked={input.daysEveryDay ? true : false}
                    />
                    <label className="form-check-label" htmlFor="daysEveryDay">
                      Every Day
                    </label>
                  </div>
                  {days.map((item, idx) => (
                    <div className="form-check" key={idx}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={idx}
                        value={item.key}
                        onChange={onChangeCheckBox}
                        checked={
                          input.days.find((find) => find.value === item.key)
                            ? true
                            : false
                        }
                      />
                      <label className="form-check-label" for={idx}>
                        {item.title}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="row mt-5">
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <div className="">
                      <label htmlFor="" className="form-label">
                        Sending At
                      </label>
                      <input
                        type="time"
                        name=""
                        width="50%"
                        className="form-control"
                        id=""
                        value={input.time}
                        onChange={(e) =>
                          setInput((state) => ({
                            ...state,
                            time: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-3">
                {loadingPage ? (
                  <Loadingfunc />
                ) : (
                  <ButtonPrimary onClick={() => btnSubmitScheduler()}>
                    Submit
                  </ButtonPrimary>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log("idParam", input.idParam)}
      {/* Modal */}
      <AddRecipientModal
        show={modal.add}
        onHide={() => btnHandleModalAddRecipient("close")}
        btnAddRecipient={() =>
          btnAddRecipient(input.idParam ? "edit" : "create")
        }
        setInput={setInput}
        input={input}
      />
    </div>
  );
};

export default SummaryPage;

const days = [
  {
    title: "Monday",
    key: "monday",
  },
  {
    title: "Tuesday",
    key: "tuesday",
  },
  {
    title: "Wednesday",
    key: "wednesday",
  },
  {
    title: "Thursday",
    key: "thursday",
  },
  {
    title: "Friday",
    key: "friday",
  },
  {
    title: "Saturday",
    key: "saturday",
  },
  {
    title: "Sunday",
    key: "sunday",
  },
];
