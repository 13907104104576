import React from "react";
import styled, { keyframes } from "styled-components";

const InputRadioProductAndSolution = (props) => {
  return (
    <Container>
      <label>
        <input type="radio" {...props} />
        <span className="radio-pulse" />
        <span className="radio-button">
          <span className="radio-button-inner" />
        </span>
        <span className="radio-label">{props.label}</span>
      </label>
    </Container>
  );
};

export default InputRadioProductAndSolution;

const pulse = keyframes`
0%{
  opacity:0;
  transform:scale(0.5);
}
50%{
  opacity:0.25;
}
100%{
  opacity:0;
  transform:scale(1.75)
}
`;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  height: 114.77px;
  pointer-events: auto;

  background: #ffffff;
  border: 1px solid #eff0f7;
  /* Input/Default */

  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 16px;
  padding: 45px 32px;

  label {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  label > input {
    position: absolute;
    transform: scale(0);
  }

  label > .radio-button {
    position: relative;
    z-index: 1;
    display: grid;
    place-items: center;
    width: 20px;
    height: 20px;
    padding: 0px;
    border-radius: 50%;
    border: 5px solid black;
    transition: all 0.35s;
  }

  label > .radio-label {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */

    /* Black 2 */

    color: #1a202c;
  }

  label > .radio-pulse {
    position: absolute;
    display: none;
    top: -11px;
    left: -11px;
    z-index: 0;
    opacity: 0;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #8f44fd;
    animation: ${pulse} 0.5s linear;
  }

  label > input:checked ~ .radio-pulse {
    display: block;
  }

  label > input:checked ~ .radio-button {
    border-color: #8f44fd;
  }

  label > input:checked ~ .radio-label {
    color: black;
  }
`;
