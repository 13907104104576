import {
  ChevronLeft,
  ChevronDoubleLeft,
  ChevronRight,
  ChevronDoubleRight,
} from "@styled-icons/bootstrap";
import styled from "styled-components";

const Pagination = ({
  total = 50,
  current = 1,
  btnPagination = () => null,
}) => {
  const handlePageChanged = (newPage) => {
    btnPagination(newPage);
  };
  let newTotal = Array.from(Array(total).keys());

  let start = current - 3 < 0 ? 0 : current - 3;
  let end = current + 3 > total ? total : current + 3;

  const initPaging2 = () => {
    return (
      <ul className="pagination" id="pagination-landing">
        <li
          className={
            current === 1 ? "disabled page-item first" : "page-item first"
          }
        >
          <a className="page-link" onClick={() => handlePageChanged(1)}>
            <ChevronDoubleLeft size={12} />
          </a>
        </li>
        <li
          className={
            current === 1 ? "page-item prev disabled" : "page-item prev"
          }
        >
          <a
            className="page-link"
            onClick={() => handlePageChanged(current - 1)}
          >
            <ChevronLeft size={12} />
          </a>
        </li>
        {newTotal.slice(start, end).map((item, idx) => (
          <li
            className={`page-item  ${item + 1 === current ? "active" : null}`}
            style={{ cursor: "pointer" }}
            key={idx}
          >
            <a
              className="page-link"
              onClick={() => handlePageChanged(item + 1)}
            >
              {item + 1}
            </a>
          </li>
        ))}
        <li
          className={
            current === total ? "page-item next disabled" : "page-item next"
          }
        >
          <a
            className="page-link"
            onClick={() => handlePageChanged(current + 1)}
          >
            <ChevronRight size={12} />
          </a>
        </li>
        <li
          className={
            current === total ? "page-item last disabled" : "page-item last"
          }
        >
          <a className="page-link" onClick={() => handlePageChanged(total)}>
            <ChevronDoubleRight size={12} />
          </a>
        </li>
      </ul>
    );
  };

  return <Container aria-label="Page navigation">{initPaging2()}</Container>;
};

export default Pagination;

const Container = styled.nav`
  #pagination-landing {
    .page-link {
      border: none;
    }

    .page-item.active .page-link {
      background-color: transparent !important;
      font-weight: bold;
      font-size: 18px;
    }
  }
`;
