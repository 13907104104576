import styled from "styled-components";
import IcQuotes from "../../assets/iconAndLogo/ic_quotes.png";

const WhyChooseMe = () => {
  return (
    <Container className="mt-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 col-sm-12 p-2">
            <h2 className="title">Why Us ?</h2>
            <div className="sub-title">
              <strong className="strong-sub-title">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatum non nostrum aperiam! Quod eius vel, atque sed
                nesciunt reiciendis alias.
              </strong>
              <br />
              <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam
              optio, consectetur laudantium qui quos dolorum! Fuga optio et
              impedit dicta, excepturi blanditiis magni ullam earum, nesciunt
              reiciendis iure. Sed ipsam sit neque consectetur provident vero ea
              exercitationem aperiam, veritatis doloribus, cum suscipit nulla
              fugiat ducimus. Doloremque autem ea magnam delectus.
            </div>
            <button className="btn btn-learn-more mt-3">Learn More</button>
          </div>
          <div className="col-md-6 col-sm-12 p-2">
            <div className="row">
              {[2, 2, 2].map((item, idx) => (
                <div className="col-auto p-2" key={idx}>
                  <Card />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhyChooseMe;

const Card = () => {
  return (
    <ContainerCard>
      <div className="sub-title-why-us">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora
        consectetur, suscipit quisquam a odit amet sunt cupiditate unde,
        consequuntur aperiam assumenda nisi asperiores minus magni nulla
        blanditiis velit debitis rerum incidunt sequi atque corporis odio
        mollitia reiciendis! Veniam eum incidunt iste quas, quasi labore? Ex
        expedita quam enim temporibus sunt. Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Omnis sunt perspiciatis quo blanditiis
        repudiandae! Eius, aliquam, officiis quibusdam amet eligendi animi eos
        harum aut, cum quasi possimus. Suscipit autem nam soluta recusandae
        veniam rem dolorem? Beatae dicta porro exercitationem inventore quae
        dolor dolorem labore, maxime necessitatibus, nobis vel modi voluptatibus
        vero ratione illo corrupti deleniti voluptate? Consequatur beatae
        laudantium exercitationem similique hic officiis adipisci commodi
        accusamus quia, perferendis voluptatem quidem cumque praesentium, facere
        veritatis quis obcaecati ex ratione. Cumque iste inventore asperiores
        sed aliquam, voluptas veniam. Magni, vitae aspernatur sequi omnis
        dolore, quam deserunt deleniti expedita corrupti quisquam consectetur
        minima?
      </div>
      <h3 className="title-card-why-us mt-3">John Doe</h3>
    </ContainerCard>
  );
};

const Container = styled.div`
  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 46px;
    color: rgb(59, 62, 152);
  }
  .strong-sub-title {
    color: rgb(59, 62, 152);
  }

  .btn-learn-more {
    background: rgb(59, 62, 152);
    box-shadow: rgb(0 0 0 / 19%) 0px 0px 49px -2px;
    border-radius: 5px;
    font-family: Mulish;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    border: thin solid rgb(59, 62, 152);
    color: rgb(255, 255, 255);
    width: 184px;
    height: 35px;
    overflow: hidden;
  }
`;

const ContainerCard = styled.div`
  width: 100%;
  height: 15em;
  color: white;
  background-color: rgb(59, 62, 152);
  padding: 1.5em;

  overflow: hidden;
  white-space: pre-wrap;
  border-radius: 10px;

  &:before {
    content: "";
    display: flex;
    background-image: url(${IcQuotes});
    background-repeat: no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
  }

  .title-card-why-us {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 46px;
    color: white;
  }
  .sub-title-why-us {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    height: 6em;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* white-space: nowrap; */

    /* Dark Light */

    color: white;
  }
`;
