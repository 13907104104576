import styled from "styled-components";
import { Mobile_query_v2 } from "../../../util/Query";

const CardBanner = ({ index }) => {
  return (
    <ContainerCard className={`container-card-${index}`} index={index}>
      <div className="wrap-img">
        <img
          src="https://i.pinimg.com/564x/d0/c4/1b/d0c41b2734aa37dd363f4cc9ebf8ff90.jpg"
          alt="https://i.pinimg.com/564x/d0/c4/1b/d0c41b2734aa37dd363f4cc9ebf8ff90.jpg"
        />
      </div>
      <div className="wrap-content">
        <div className="title">Lorem ipsum</div>
        <div className="desc">Lorem ipsum dolor sit</div>
        <div className="wrap-sosmed d-flex align-items-center justify-content-center">
          {[1, 1, 1, 1].map((item, idx) => (
            <div className="wrap-icon" key={idx}>
              <img src="icon" alt="icon" />
            </div>
          ))}
        </div>
      </div>
    </ContainerCard>
  );
};

const MidBanner = () => {
  return (
    <div style={{ backgroundColor: "#fff", paddingTop: "176px" }}>
      <Container>
        <div className="left">
          <div className="big-text">
            <b>Star-Blue offers offshore services</b> in many development
          </div>
          <div className="small-text">
            As an important partner of our customers, we support everything from
            development, operation, and maintenance of various systems.
          </div>
        </div>
        <div className="right">
          <div className="wrapper-grid">
            {[1, 1, 1, 1].map((item, idx) => (
              <CardBanner index={idx} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MidBanner;

const ContainerCard = styled.div`
  .wrap-content {
    .desc {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height, or 167% */

      /* Black 3 */

      color: rgba(12, 27, 77, 0.6);
      text-align: center;
      margin-bottom: 20px;
    }
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 35px;
      /* or 250% */

      /* Black 2 */

      color: #1a202c;
      text-align: center;
    }
  }
  .wrap-img {
    width: 222px;
    height: 159px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      transition: 300ms;
      width: 222px;
      height: 159px;
      object-fit: cover;
      border-radius: 30px;
    }
  }
  width: 222px;
  height: 159px;
  overflow: hidden;
  transition: 300ms;
  border-radius: 30px;
  background-color: #fff !important;
  box-shadow: 0px 2px 12px rgba(20, 20, 43, 0.08);
  :hover {
    .wrap-img {
      img {
        width: 166px;
        height: 119px;
      }
    }
    height: 263px;
  }
`;

const Container = styled.div`
  .right {
    position: relative;
    min-height: 200px;
    z-index: 1;
    .wrapper-grid {
      .container-card-0 {
        :hover ~ .container-card-2 {
          margin-top: 0;
        }
      }
      .container-card-2 {
        margin-top: -100px;
      }
      .container-card-1 {
        margin-top: 100px;
      }
      top: -100px;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      /* grid-template-columns: auto auto; */
      justify-content: center;
      z-index: 2;
    }
  }
  .left {
    .big-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 36px !important;
      line-height: 54px;

      color: #ffffff;
      margin-bottom: 30px;
    }
    .small-text {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      color: #ffffff;
    }
  }

  .left,
  .right {
    flex: 1;
  }
  display: flex;
  align-items: center;

  padding: 60px 93px;
  background-color: #3b3e98;

  ${Mobile_query_v2} {
    padding: 0 25px;
  }
  @media only screen and (min-width: 1366px) {
    margin: auto;
  }
`;
