import React, { useState } from "react";
import styled from "styled-components";
import Navbar from "landingPage/components/Navbar";
import Banner from "./banner";
import Process from "./process";
import BannerMarketing from "./bannerMessageMarketing";
import BannerDigitalSignage from "./bannerDigitalSignage";
import BannerTvAndRadio from "./bannerTvAndradio";
import BannerKOL from "./bannerKOL";
import Footer from "landingPage/components/footer";
import ModalPreviewVideoAdvertisingPlacement from "landingPage/components/modal/modalBannerAdvertisingPlacement";
import { Mobile_query_v2 } from "../../../util/Query";

const AdvertisingPlacement = () => {
  const [bool, setBool] = useState({
    previewVideo: false,
  });

  const handlePreviewVideo = () =>
    setBool((state) => ({ ...state, previewVideo: !bool["previewVideo"] }));
  return (
    <Container>
      <Navbar />
      <Container2>
        <Banner handlePreviewVideo={handlePreviewVideo} />
        <Process />
        <BannerMarketing />
        <BannerDigitalSignage />
        <BannerTvAndRadio />
        <BannerKOL />
      </Container2>
      <Footer />
      {/* Modal */}
      <ModalPreviewVideoAdvertisingPlacement
        onHide={handlePreviewVideo}
        show={bool["previewVideo"]}
      />
    </Container>
  );
};

export default AdvertisingPlacement;

const Container2 = styled.div`
  ${Mobile_query_v2} {
    padding: 0 25px !important;
  }
  padding: 0 93px;

  @media only screen and (min-width: 1366px) {
    max-width: 1366px;
    margin: auto;
  }
`;

const Container = styled.div`
  background: radial-gradient(
    97.92% 298.31% at 2.08% 7.7%,
    #f4f7ff 0%,
    rgba(244, 247, 255, 0) 100%
  );
  position: relative;
`;
