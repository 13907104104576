"use strich";

import React, { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { ButtonDanger, ButtonPrimary } from "../style/content/default";
import { Helmet } from "react-helmet";
import ModalModule from "module/Modal";
import { Context } from "../../service";
import styled from "styled-components";

export const IdxContentBuilder = ({ show, onHide, html }) => {
  const { dispatch } = useContext(Context);
  const [srcSCript, setSrcScript] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(setSrcScript(true), 1000);
    }
  }, [show]);

  const btnSimpanBuilder = () => {
    dispatch({
      type: "SET_CONTENT_BUILDER",
      htmlContentBuilder: window.builder.html(),
    });
    onHide();
    if (window.builder) window.builder.destroy();
    const elem = document.querySelector(".moveable-control-box");
    elem.style.display = "none";
    setSrcScript(false);
  };

  return (
    <ModalModule
      size="fullscreen"
      show={show}
      onHide={onHide}
      animation={false}
      backdrop={false}
    >
      <Modal.Body>
        {srcSCript && (
          <Helmet>
            {/* <link
              rel="stylesheet"
              href="/contentbuilder/assets/bootstrap/css/bootstrap.css"
            /> */}
            <link
              rel="stylesheet"
              href="/contentbuilder/contentbuilder/contentbuilder.css"
              type="text/css"
            />
            <link
              rel="stylesheet"
              href="/contentbuilder/assets/minimalist-blocks/content.css"
            />
            <link
              rel="stylesheet"
              href="/contentbuilder/assets/email-blocks/foundation.css"
              type="text/css"
            />
          </Helmet>
        )}
        <Container id="container">
          {html ? (
            <div
              id="html"
              style={{ display: "flex", flexDirection: "column" }}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          ) : null}
        </Container>

        <div className="d-flex align-items-center justify-content-center mt-5">
          <ButtonPrimary onClick={btnSimpanBuilder}>
            Simpan sebagai HTML
          </ButtonPrimary>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ButtonDanger
            onClick={() => {
              onHide();
              if (window.builder) window.builder.destroy();
              const elem = document.querySelector(".moveable-control-box");
              elem.style.display = "none";
              setSrcScript(false);

              // const removeElements = (elms) =>
              //   elms.forEach((el) => el.remove());
              // removeElements(
              //   document.querySelectorAll(".moveable-control")
              // );
              // removeElements(document)
            }}
          >
            Batal
          </ButtonDanger>
        </div>

        {/* SCRIPT TAG */}
        <Helmet>
          <script
            type="text/javascript"
            src="/contentbuilder/assets/jquery.min.js"
          />
          <script
            type="text/javascript"
            src="/contentbuilder/contentbuilder/contentbuilder.js"
          />
          <script
            type="text/javascript"
            src="/contentbuilder/assets/minimalist-blocks/content-v4.js"
          />
          {/* <script
            src="https://code.jquery.com/jquery-3.6.0.js"
            integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk="
            crossorigin="anonymous"
          ></script> */}
        </Helmet>

        {srcSCript && (
          <Helmet>
            <script type="text/javascript" src="/contentbuilder/script.js" />
          </Helmet>
        )}
      </Modal.Body>
    </ModalModule>
  );
};

const Container = styled.div`
  margin: 150px auto;
  max-width: 1050px;
  width: 100%;
  padding: 0 35px;
  box-sizing: border-box;
`;
