import React, { useEffect } from "react";
import ArrowRight from "landingPage/assets/iconAndLogo/arrow-left-circle.png";
import styled from "styled-components";
import CardSolutions from "../../components/ProductAndSolution/cardSolutions";
import { Mobile_query_v2 } from "../../../util/Query"

const NewRealese = () => {
  const swiper = () => {
    new window.Swiper(".slide-content", {
      slidesPerView: 3,
      spaceBetween: 100,
      loop: false,
      centerSlide: "true",
      fade: "true",
      grabCursor: "true",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        520: {
          slidesPerView: 2,
        },
        950: {
          slidesPerView: 3,
        },
      },
    });
  };
  useEffect(() => {
    swiper();
  }, []);
  return (
    <ContainerSolutions>
      <div className="slide-container swiper">
        <div className="wrap-top d-flex justify-content-between">
          <div className="title">
            <div className="title-text">New Release</div>
          </div>

          <div className="arrow-container position-relative d-flex align-items-center gap-lg-3 gap-3 p-2 justify-content-end wrap-arrow">
            <div className="swiper-button-prev">
              <img src={ArrowRight} alt={ArrowRight} />
            </div>
            <div className="swiper-button-next">
              <img src={ArrowRight} alt={ArrowRight} />
            </div>
          </div>
        </div>
        <div className="slide-content">
          <div className="card-wrapper swiper-wrapper">
            {[1, 2, 3, 4, 5].map((item, idx) => (
              <WrapSlideImage key={idx} className="swiper-slide">
                <img
                  className="swiper-slide-image"
                  src="https://thumbs.dreamstime.com/b/people-working-modern-office-interior-view-room-diverse-computers-indoors-163526321.jpg"
                />

                <div className="when-hover">
                  <img
                    className="swiper-slide-image"
                    src="https://thumbs.dreamstime.com/b/people-working-modern-office-interior-view-room-diverse-computers-indoors-163526321.jpg"
                  />
                  <div className="wrap-text">
                    <div className="title">Collaboration LSPR</div>
                    <div className="desc">
                      Agreement Joint Partnership Program LSPR with Mediacartz
                    </div>
                    <div className="btn btn-readMore">Read More</div>
                  </div>
                </div>
              </WrapSlideImage>
            ))}
          </div>
        </div>

        {/* <div className="swiper-pagination"></div> */}
      </div>
    </ContainerSolutions>
  );
};

export default NewRealese;

const WrapSlideImage = styled.div`
  position: relative;
  transition: 250ms;
  z-index: 9999;
  .when-hover {
    transition: 250ms;
    top: 250px;
    position: absolute;
    /* display: none; */
    background: red;
    display: flex;
    border-radius: 20px;
    align-items: center;
    background: #f9fbff;
    border: 1px solid #2fbeee;
    width: 350px !important;
    height: 200px !important;
    padding: 10px 20px;
    gap: 10px;

    .wrap-text {
      .title {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        /* or 156% */

        /* Color/Neutral 100 */

        color: #222833;
      }

      .desc {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        /* or 162% */

        /* Color/Neutral 80 */

        color: #6d7078;
      }

      .btn-readMore {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        /* or 162% */

        /* Brand Color/Primary */

        color: #3b3e98;
      }
    }

    img {
      width: 126px !important;
      height: 158px !important;
      object-fit: cover;
    }
  }

  &:hover {
    .when-hover {
      top: 0 !important;
    }
  }
`;

const ContainerSolutions = styled.div`
${Mobile_query_v2}{
  .arrow-container{
    gap:30px;
  }
}
  .wrap-top {
    margin-bottom: 50px;
  }

  .title-text {
    position: relative;
    z-index: 1;
    :before {
      content: "";
      width: 81px;
      height: 81px;
      background: #fbe555;
      z-index: -1;
      border-radius: 0px 0px 60px 0px;
      position: absolute;
    }
  }

  .slide-content {
    .swiper-wrapper {
      padding: 10px;
      .swiper-slide {
        .swiper-slide-image {
          width: 350px;
          height: 200px;
          object-fit: cover;
          border-radius: 20px;
        }
      }
    }
  }

  .title {
    .title-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;

      /* Brand Color/Primary */

      color: #3b3e98;
    }
  }

  z-index: 1;
  position: relative;
  margin-bottom: 128px;
  &::before {
    content: "";
    z-index: -1;
    width: 533px;
    height: 533px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
