import styled from "styled-components";

const ProductOOHItem = ({
  imgUrl = "https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  title = "gedung a",
  active,
}) => {
  return (
    <Container active={active}>
      <img src={imgUrl} alt={imgUrl} />
      <div className="title-product-ooh-item">{title}</div>
    </Container>
  );
};

export default ProductOOHItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;

  width: 100%;

  .title-product-ooh-item {
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height, or 133% */
    text-transform: capitalize;
    color: #161c2b;
  }

  img {
    width: auto;
    height: 125px;
    object-fit: cover;
    border: 2px solid ${({ active }) => (active ? "#3B3E98" : "#e0e0e0")};
  }
`;
