import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./style.css";
import ContentTabs from "./contentTabsV2";
import styled from "styled-components";
import imgRoundLogo from "landingPage/assets/iconAndLogo/Asset Round Bubble PS@3x.png";
import FormInput from "./formInput";
import FormInputEventories from "./formInputEventories";
import FormInputPortalCatalog from "./formInputPortalCatalog";
import FormInputRetail from "./formInputRetail";
import FormInputCRM from "./formInputCRM";
import FormInputPublisher from "./formInputPublisher";
import FormRetailSolutions from "./formInputRetailSolutions";
import FormBankingFinancialSolutions from "./formInputBankingFinancialSolutions";
import FormEventSolutions from "./formInputEventsolutions";
import FormAgencysolutions from "./formInputAgencySolutions";
import FormPublisherSolutions from "./formInputPublisherSolutions";

const listTab = [
  {
    eventKey: "products",
    name: "PRODUCTS",
  },
  {
    eventKey: "solutions",
    name: "SOLUTIONS",
  },
];
const ProductAndSolution = () => {
  const [Activethumbnail, setActiveThumbnail] = useState("");

  const handlerChangeThumnail = (thumbnail) => setActiveThumbnail(thumbnail);
  return (
    <Container id="container-tabs">
      <div className="round-buble" />
      <Tabs
        defaultActiveKey="products"
        id="uncontrolled-tab-example"
        className="mb-3"
        style={{ marginTop: "69px" }}
      >
        {listTab.map((item, idx) => (
          <Tab eventKey={item.eventKey} title={item.name} key={idx}>
            <ContentTabs
              eventKey={item.eventKey}
              handlerChangeThumnail={handlerChangeThumnail}
            />
          </Tab>
        ))}
      </Tabs>
      <div className="container-form-input">
        {Activethumbnail === "advertising" ? (
          <FormInput />
        ) : Activethumbnail === "eventories" ? (
          <FormInputEventories />
        ) : Activethumbnail === "portalCatalog" ? (
          <FormInputPortalCatalog />
        ) : Activethumbnail === "retail" ? (
          <FormInputRetail />
        ) : Activethumbnail === "CRM" ? (
          <FormInputCRM />
        ) : Activethumbnail === "ads_publisher" ? (
          <FormInputPublisher />
        ) : Activethumbnail === "retail_solutions" ? (
          <FormRetailSolutions />
        ) : Activethumbnail === "bankingFinancialSolutions" ? (
          <FormBankingFinancialSolutions />
        ) : Activethumbnail === "eventSolutions" ? (
          <FormEventSolutions />
        ) : Activethumbnail === "agencySolutions" ? (
          <FormAgencysolutions />
        ) : Activethumbnail === "publisherSolutions" ? (
          <FormPublisherSolutions />
        ) : null}
      </div>
    </Container>
  );
};

export default ProductAndSolution;

const Container = styled.div`
  position: relative;
  min-height: 266px;
  margin-bottom: 72px;

  .round-buble {
    width: 137px;
    height: 266px;
    background-image: url(${imgRoundLogo});
    position: absolute;
    right: -93px;
    top: 0;
  }
`;
