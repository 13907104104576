import { Link, useParams } from "react-router-dom";
import Navbar from "landingPage/components/navbar_v2";
import styled from "styled-components";
import BreadCumbsArticle from "./components/breadcumbs";
import BannerDetailArticle from "./components/banner";
import ContentDetailArticle from "./components/content";
import ArticleItem from "../../../components/version2/article/article_item";
import FooterV2 from "landingPage/components/footer_v2";
import axios from "axios";
import { useEffect, useState } from "react";
import { AlertError } from "component";

const baseUrl = "https://stadscore.mediacartz.com/api/v1";
const DetailArticle = () => {
  const { id_article } = useParams();
  const [param, setParam] = useState({
    dataDetail: {},
    dataArticle: [],
  });

  const getArticle = async () => {
    const params = {
      page: 1,
      rows: 10,
      order: [
        {
          field: "views",
          sort: "DESC",
        },
      ],
      filter: [
        {
          field: "title",
          operator: "=",
          value: "",
        },
      ],
    };
    try {
      const response = await axios
        .get(`${baseUrl}/article`, {
          params: params,
        })
        .then((res) => res.data);

      setParam((state) => ({
        ...state,
        dataArticle: response.data.data,
      }));
    } catch (error) {
      AlertError({ title: "Error", text: error });
    }
  };

  const getDetail = async () => {
    try {
      const response = await axios
        .get(`${baseUrl}/article/slug/${id_article}`)
        .then((res) => res.data);

      setParam((state) => ({
        ...state,
        dataDetail: response.data,
      }));
    } catch (error) {
      AlertError({ title: "Error", text: error });
    }
  };

  useEffect(() => {
    getDetail();
    getArticle();
  }, []);

  console.log({ param, url: window.location.href });
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <BreadCumbsArticle title={param.dataDetail?.title} />
      </div>
      <BannerDetailArticle url={param.dataDetail?.image} />
      <ContentDetailArticle
        author={param.dataDetail?.author}
        published={param.dataDetail?.created_at}
        title={param.dataDetail?.title}
        content={param.dataDetail?.content}
        shareLink={window.location.href}
      />
      <Container className="container-fluid mt-3">
        <hr />

        <div
          className="container-related-article text-center"
          style={{ marginTop: "80px" }}
        >
          <h4>Related Article</h4>

          <div className="list-article mt-lg-5">
            {param.dataArticle?.slice(0, 3).map((item, idx) => (
              <ArticleItem
                slug={item.slug}
                title={item.title}
                subTitle={item.content}
                imgUrl={item.image}
                author={item.author}
                published={item.created_at}
              />
            ))}
          </div>
          <div className="text-center mt-5">
            <Link to="/home/article" className="btn">
              See More Articles
            </Link>
          </div>
        </div>
      </Container>
      <FooterV2 />
    </div>
  );
};

export default DetailArticle;

const Container = styled.div`
  .list-article[single="true"] {
    grid-template-columns: repeat(auto-fit, max(360px));
    overflow: auto;
    justify-content: flex-start;

    display: grid;
  }

  .list-article {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    overflow: auto;
    justify-content: flex-start;
    gap: 20px;
  }

  .container-interest {
    margin-top: 80px;
  }

  .container-carousel {
    margin-top: 42px;
  }

  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding: 0 20px;
    overflow-x: hidden;
  }
`;
