import React, { useContext, useEffect, useState } from "react";
import * as Components from "../../../../component";
import * as Style from "../../../../component/style/content/default";
import * as Services from "../../../../service";
import { Method } from "../../../../service";
import { CompanyInactive, Header } from "./components";
import { Form } from "react-bootstrap";
import moment from "moment";

const api = Services.api.ApiRetail.toko;

export default function LoyaltyGetPoint() {
  const { selected_company } = useContext(Services.Context);
  const [allow, setAllow] = useState(false)
  const [status, setStatus] = useState(false)
  const [point, setPoint] = useState({
    type: "",
    value: "",
    amount: ""
  })
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDate, setIsDate] = useState(true);
  const [loading, setLoading] = useState(false);

  const getConfig = async () => {
    await Method.get(`loyalty/conf/company/${selected_company.value}`)
    .then(async (res) => {
      if (res?.data?.status) {
        setAllow(true)
      }else{
        if(res?.data?.error === "not_found"){
          setAllow(false)
        }
      }
    })
  }

  const getRuleTransaction = async () => {
    await Method.get(`loyalty/rules/company/${selected_company.value}/transaction`)
    .then(async (res) => {
      if (res?.data?.status) {
        setPoint({
          type: res.data.data.point_type,
          value: res.data.data.value,
          amount: res.data.data.amount
        })

        setStatus(res.data.data.status === 1 ? true : false)
        setIsDate(res.data.data.is_timeless === 1 ? true : false)
        setStartDate(res.data.data.startDate ? res.data.data.startDate : "")
        setEndDate(res.data.data.endDate ? res.data.data.endDate : "")
      }else{
        setPoint({
          type: "",
          value: "",
          amount: ""
        })

        setStatus(false)
        setIsDate(true)
        setStartDate("")
        setEndDate("")
      }
    })
  }

  const submitTransaction = () => {
    Components.AlertQuestion({
      title: "Ubah Data",
      text: "Apakah anda yakin mau mengubah data ini",
    }).then((res) => {
      if (res.isConfirmed) {
        //action
        processTransaction()
      }
    });
  }

  const processTransaction = async () => {
    setLoading(true);

    let dataForm = {
      point_type: point.type,
      value: point.value,
      status: status ? "1" : "0",
      is_timeless: isDate ? "1" : "0"
    }

    if(point.type === "fixed_purchase_min" || point.type === "fixed_multiple"){
      dataForm['amount'] = point.amount;
    }

    if(!isDate){
      dataForm['start_date'] = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : ""
      dataForm['end_date'] = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : ""
    }

    await Method.post(`loyalty/rules/company/${selected_company.value}/transaction`, dataForm)
    .then(async (res) => {
      console.log("res rules transaction", res)
      if (res?.data?.status) {
        Components.AlertSuccess({ title: "Success", text: res?.data?.message });
      }else{
        Components.AlertError({ title: "Error", text: res?.data?.error });
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (selected_company.value) {
      // get config detail
      getConfig()
      getRuleTransaction()
    }
  }, [selected_company.value]);

  if(!allow){
    return(
      <Style.ContainerContent>
        <Header title="Perolehan Point"/>
        <CompanyInactive/>
      </Style.ContainerContent>  
    )
  }

  return (
    <Style.ContainerContent>
      <Header title="Perolehan Point"/>

      <section className="mt-5">
        <div className="row">
          <h5 className="col-md-12 mb-3"><strong>Transaksi</strong></h5>
          <div className="col-md-3">
            <Style.Label color={Style.COLOR_SECONDARY}><b>Point Diberikan</b></Style.Label>
            <p>Pilih nilai unit yang digunakan dalam perhitungan</p>
          </div>
          <div className="col-md-9 mb-3">
            <div className="d-flex flex-row align-items-center mb-2">
              <Form.Check
                type={'radio'}
                checked={point.type === "percentage"}
                onChange={() => setPoint({...point, type: 'percentage'})}
                disabled={loading}
              />
              <div className="ms-2" style={{width:135}}>
                <div><strong>Persentasi:</strong> Beri</div>
              </div>
              <input
                type="number"
                className="form-control ms-2"
                style={{width:80}}
                value={point.type === "percentage" ? point.value : ""}
                min="1"
                max="100"
                onChange={(e) => {
                  let { value, min, max } = e.target;
                  const res = Math.max(min, Math.min(max, Number(value)));
                  setPoint({...point, value: res})
                }}
                disabled={loading || point.type !== "percentage"}
              />
              <div className="ms-2">% dari total pembelian</div>
            </div>

            <div className="d-flex flex-row align-items-center mb-2">
              <Form.Check
                type={'radio'}
                checked={point.type === "fixed_every_purchase"}
                onChange={() => setPoint({...point, type: 'fixed_every_purchase'})}
                disabled={loading}
              />
              <div className="ms-2" style={{width:135}}>
                <div><strong>Jumlah tetap:</strong> Beri</div>
              </div>
              <input
                type="number"
                className="form-control ms-2"
                style={{width:80}}
                value={point.type === "fixed_every_purchase" ? point.value : ""}
                min="1"
                onChange={(e) => {
                  let { value, min } = e.target;
                  const res = Math.max(min, Number(value));
                  setPoint({...point, value: res})
                }}
                disabled={loading || point.type !== "fixed_every_purchase"}
              />
              <div className="ms-2">point setiap pembelian</div>
            </div>
            <div className="d-flex flex-row align-items-center mb-2">
              <Form.Check
                type={'radio'}
                checked={point.type === "fixed_purchase_min"}
                onChange={() => setPoint({...point, type: 'fixed_purchase_min'})}
                disabled={loading}
              />
              <div className="ms-2" style={{width:135}}>
                <div><strong>Jumlah tetap:</strong> Beri</div>
              </div>
              <input
                type="number"
                className="form-control ms-2"
                style={{width:80}}
                value={point.type === "fixed_purchase_min" ? point.value : ""}
                min="1"
                onChange={(e) => {
                  let { value, min } = e.target;
                  const res = Math.max(min, Number(value));
                  setPoint({...point, value: res})
                }}
                disabled={loading || point.type !== "fixed_purchase_min"}
              />
              <div className="ms-2">point setiap pembelian minimal</div>
              <input
                type="number"
                className="form-control ms-2"
                style={{width:100}}
                value={point.type === "fixed_purchase_min" ? point.amount : ""}
                min="1"
                onChange={(e) => {
                  let { value, min } = e.target;
                  const res = Math.max(min, Number(value));
                  setPoint({...point, amount: res})
                }}
                disabled={loading || point.type !== "fixed_purchase_min"}
              />
              <div className="ms-2">RUPIAH</div>
            </div>
            <div className="d-flex flex-row align-items-center mb-2">
              <Form.Check
                type={'radio'}
                checked={point.type === "fixed_multiple"}
                onChange={() => setPoint({...point, type: 'fixed_multiple'})}
                disabled={loading}
              />
              <div className="ms-2" style={{width:135}}>
                <div><strong>Jumlah tetap:</strong> Beri</div>
              </div>
              <input 
                type="number"
                className="form-control ms-2" 
                style={{width:80}}
                value={point.type === "fixed_multiple" ? point.value : ""}
                min="1"
                onChange={(e) => {
                  let { value, min } = e.target;
                  const res = Math.max(min, Number(value));
                  setPoint({...point, value: res})
                }}
                disabled={loading || point.type !== "fixed_multiple"}
              />
              <div className="ms-2">point setiap kelipatan</div>
              <input 
                type="number"
                className="form-control ms-2" 
                style={{width:100}}
                value={point.type === "fixed_multiple" ? point.amount : ""}
                min="1"
                onChange={(e) => {
                  let { value, min } = e.target;
                  const res = Math.max(min, Number(value));
                  setPoint({...point, amount: res})
                }}
                disabled={loading || point.type !== "fixed_multiple"}
              />
              <div className="ms-2">RUPIAH saat pembelian</div>
            </div>
          </div>
          <div className="col-md-3">
            <Style.Label color={Style.COLOR_SECONDARY}><b>Status</b></Style.Label>
          </div>
          <div className="col-md-9 mb-3">
            <Form.Check
              type="switch"
              label={status?"Aktif":"Tidak Aktif"}
              checked={status}
              onChange={(e) => setStatus(!status)}
              disabled={loading}
            />
          </div>
          <div className="col-md-3">
            <Style.Label color={Style.COLOR_SECONDARY}><b>Tangal Mulai/Berakhir</b></Style.Label>
            <p>Tentukan berapa lama aturan ini aktif</p>
          </div>
          <div className="col-md-9 mb-3">
            <div className="d-flex flex-row">
              <div className="mb-3">
                <Style.Label color={Style.COLOR_SECONDARY}>Dari Tanggal</Style.Label>
                <input
                  style={{maxWidth:200}}
                  type="datetime-local"
                  className="form-control"
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                  disabled={isDate || loading}
                />
              </div>
              <div className="mb-3 ms-3">
                <Style.Label color={Style.COLOR_SECONDARY}>Sampai Tanggal</Style.Label>
                <input
                  style={{maxWidth:200}}
                  type="datetime-local"
                  className="form-control"
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate}
                  value={endDate}
                  disabled={isDate || loading}
                />
              </div>
            </div>
            <Form.Check
              type={'checkbox'}
              checked={isDate}
              onChange={() => setIsDate(!isDate)}
              label="Tanpa batas waktu"
              disabled={loading}
            />
          </div>
          <div className="col-md-12">
            <Style.ButtonPrimary
              onClick={() => submitTransaction()}
              disabled={loading ? true : false}
            >
              {
                loading ? (
                  <><Components.LoadingIcon /> Loading...</>
                ) : (
                  <>Simpan</>
                )
              }
            </Style.ButtonPrimary>
          </div>
        </div>
      </section>
    </Style.ContainerContent>
  );
}