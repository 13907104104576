import { Method } from "../.."
import * as Component from "../../../component";

const api_permission = (dispatch, company_id, user_id) => {
  dispatch({ type: "SET_RETAIL_PERMISSION", data:null, loading: true });
  Method.get(`company/${company_id}/link?user_id=${user_id}`)
  .then(res => {
    if (res.data.success) {
      if(res.data.data.length> 0){
        dispatch({ type: "SET_RETAIL_PERMISSION", data: res.data.data[0].retail_permission, loading: false }); 
      }else{
        dispatch({ type: "SET_RETAIL_PERMISSION", data:null, loading: false });  
      }
    }else{
      dispatch({ type: "SET_RETAIL_PERMISSION", data:null, loading: false });
    }
  }).catch(error => {
    dispatch({ type: "SET_RETAIL_PERMISSION", data:null, loading: false });
  })
}

export default api_permission