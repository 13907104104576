import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ApiCustomer as api } from "../../service/api";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { decrypt } from "service";

const SelectPerangkat = (props) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const { onChange = () => null, value } = props;

  const getCompany = () => {
    let result = "";

    result = Cookies.get("company") ? decrypt(Cookies.get("company")) : {};

    console.log({ result });

    return result;
  };

  const getData = async () => {
    setLoading(true);
    await api
      .getCompany({
        company_name: search,
      })
      .then((res) => {
        if (res?.success) {
          const mapping = res?.data?.map((item) => ({
            value: item.company_id,
            label: item.company_name,
          }));
          setData(mapping);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    onChange({
      value: getCompany()?.company_id,
      label: getCompany()?.company?.company_name,
    });
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getData();
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [search]);

  const onSelectHandler = (e) => onChange(e);

  const handlerOnInputChange = (e) => {
    setSearch(e);
  };

  return (
    <Select
      options={data}
      onInputChange={handlerOnInputChange}
      onChange={onSelectHandler}
      value={value}
      placeholder="Pilih Perusahaan"
      isClearable
      isLoading={loading}
    />
  );
};

export default React.memo(SelectPerangkat);

SelectPerangkat.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};
