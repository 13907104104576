import styled from "styled-components";
import IcSearch from "../../../../assets/iconAndLogo/ic_search_our_value.png";

const OurValueItem = () => {
  return (
    <Container>
      <div className="container-img">
        <img src={IcSearch} alt={IcSearch} />
      </div>
      <div className="title-our-value-item">Lorem Ipsum Dolor</div>
      <div className="sub-title-our-value-item">
        Lorem ipsum dolor sit amet consectetur. Ipsum risus sapien condimentum
        nulla cursus nisi. Nulla cras purus aliquam nec urna.
      </div>
      <button className="btn btn-read-more">Read More &nbsp; ></button>
    </Container>
  );
};

export default OurValueItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 367px;
  height: auto;

  .container-img {
    width: 96px;
    height: 96px;

    /* Brand Colors/Primary/20

EDEDF1
*/
    background: #ededf1;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    img {
      width: 40px;
    }
  }
  .title-our-value-item {
    /* Heading/S */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    text-align: center;
    letter-spacing: -0.017em;

    /* Neutral/100 */

    color: #222833;
    margin-bottom: 24px;
  }

  .sub-title-our-value-item {
    /* Text L/Regular */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    letter-spacing: -0.011em;

    /* Neutral/80 */

    color: #6d7078;

    margin-bottom: 40px;
  }

  .btn-read-more {
    /* Text L/Bold */

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.011em;

    /* Brand Colors/Primary/100 */

    color: #3b3e98;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
