import Select from "react-select";

function WajibSelection({ value, onChange }) {
  const options = [
    {
      label: "Wajib",
      value: 1,
    },
    {
      label: "Optional",
      value: 0,
    },
  ];

  return <Select options={options} value={value} onChange={onChange} />;
}

export default WajibSelection;
