import React from "react";
import styled from "styled-components";
import aksenBuble from "landingPage/assets/iconAndLogo/Asset Aksen Bubble 1.png";
import aksenBuble2 from "landingPage/assets/iconAndLogo/Asset Aksen Bubble 2.png";
import Card from "./card";
import LineImg from "landingPage/assets/iconAndLogo/Group 37974.png";
import BusinessRegister from "landingPage/assets/iconAndLogo/businessRegister.png";
import businessGo from "landingPage/assets/iconAndLogo/businessGo.png";
import { Mobile_query_v2 } from "../../../util/Query";

const GrowingBusiness = () => {
  return (
    <Container>
      <div className="banyak-buble" />
      <div className="title">
        <div className="rounded-yellow" />
        How To Start Growing Your Business With Mediacartz
      </div>
      <div className="desc">
        You can start reaching, engaging and exposing your brand/product to your
        customers and managing your data personally by following these simple
        steps.
      </div>

      <div className="scroll-card">
        <div
          className="wrap-card d-flex justify-content-center align-items-center"
          style={{ gap: "45px", marginTop: "100px" }}
        >
          <div className="line-img" />
          <Card
            desc={
              <div>
                Register your business and complete your company information.
                After completing, you’ll receive a verification email.
              </div>
            }
            title={"Register"}
            imgLogo={BusinessRegister}
          />
          <Card active={true} />
          <Card
            desc={
              <div>
                Create your campaigns, and manage existing ones and the new ones
                from one easy-to-use dashboard.
              </div>
            }
            title={"Go!"}
            imgLogo={businessGo}
          />
        </div>
      </div>
      <div className="banyak-buble-2">
        <img src={aksenBuble2} alt={aksenBuble2} />
      </div>
    </Container>
  );
};

export default GrowingBusiness;

const Container = styled.div`
  ${Mobile_query_v2} {
    .line-img {
      display: none;
    }
    .banyak-buble-2 {
      display: none !important;
    }
    .banyak-buble {
      display: none !important;
    }
    .rounded-yellow {
      right: 0 !important;
      top: 0;
    }
    .desc {
      font-size: 14px !important;
    }
    .scroll-card {
      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .wrap-card {
      width: 1000px;

      /* overflow-x: scroll; */
    }
  }
  .wrap-card {
    position: relative;
    z-index: 2;
  }
  .wrap-card > .line-img {
    background-image: url(${LineImg});
    width: 767px;

    height: 384px;
    position: absolute;
    z-index: -1;
  }
  .banyak-buble {
    width: 100px;
    height: 125px;
    background-image: url(${aksenBuble});
  }
  .banyak-buble-2 {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: flex-end;
  }
  .title {
    position: relative;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    z-index: 2;
    /* identical to box height */

    text-align: center;

    /* Primary */

    color: #3b3e98;

    .rounded-yellow {
      width: 40px;
      height: 40px;
      background-color: #fcd45d;
      border-radius: 100px;
      position: absolute;
      right: 250px;
      bottom: 5px;
      z-index: -1;
    }
  }
  .desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    /* or 200% */

    text-align: center;
    margin-top: 30px;

    color: rgba(12, 27, 77, 0.6);
  }
`;
