import {
  Modal_Component as ModalComponent
} from "component";



const ViewHtml = ({ show, onHide, content }) => {

  const handleClose = () => {
    onHide()
  }
  return (
    <ModalComponent fullscreen show={show} onHide={handleClose}>
      {content}
    </ModalComponent>
  )
}


export default ViewHtml;