import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import imgBanner1 from "landingPage/assets/iconAndLogo/hotNewsBanner.png";
import imgBanner3 from "landingPage/assets/iconAndLogo/Asset Banner 3@3x.png";
import imgBanner0 from "landingPage/assets/iconAndLogo/Asset Banner 1@3x.svg";
import { Mobile_query_v2 } from "../../../util/Query";
import { Link } from "react-router-dom";

const content = [
  {
    image:
      "https://images.unsplash.com/photo-1605457867610-e990b192418e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1426&q=80",
    title: "title 1",
    description: "descriptions 1",
    button: "View",
  },
  {
    image:
      "https://images.unsplash.com/photo-1561424412-6c2125ecb1cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    title: "title 2",
    description: "descriptions 2",
    button: "View",
  },
  // {
  //   image:
  //     "https://images.unsplash.com/photo-1460176449511-ff5fc8e64c35?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80",
  //   title: "title 3",
  //   description: "descriptions 3",
  //   button: "View",
  // },
];

const ListSlide = ({ idx }) => {
  if (idx === 0) {
    return (
      <ContainerSlide0 className="d-flex">
        <div className="left">
          <img src={imgBanner1} alt="" />
        </div>
        <div className="right">
          <div className="title">Hot News</div>
          <div className="desc">
            Now!! Retail and POS Apps can use for your business. Read more this
            information and try it on your business.
          </div>
          <Link to="/home/company/press-and-medias/detail">
            <button tton className="btn btn-seeDetail">
              See Detail
            </button>
          </Link>
        </div>
      </ContainerSlide0>
    );
  } else if (idx === 1) {
    return (
      <ContainerSlide0 className="d-flex">
        <div className="left">
          <img src={imgBanner1} alt="" />
        </div>
        <div className="right">
          <div className="title">Hot News</div>
          <div className="desc">
            Now!! Retail and POS Apps can use for your business. Read more this
            information and try it on your business.
          </div>
          <Link to="/home/company/press-and-medias/detail">
            <button className="btn btn-seeDetail">See Detail</button>
          </Link>
        </div>
      </ContainerSlide0>
    );
  }
};

const SliderCarouselPressMedia = () => {
  return (
    <div style={{ marginBottom: "89px" }}>
      <Carousel>
        {content.map((item, idx) => (
          <Carousel.Item key={idx}>
            <ListSlide idx={idx} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default SliderCarouselPressMedia;

const ContainerSlide0 = styled.div`
  ${Mobile_query_v2} {
    flex-direction: column !important;
    height: auto !important;
    .right {
      .desc {
        font-size: 15px !important;
      }
      .btn-seeDetail {
        height: auto !important;
      }
    }
    .left {
      img {
        width: 100% !important;
        object-fit: contain !important;
      }
    }
  }
  align-items: center;
  min-height: 390px;

  .right {
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      /* identical to box height */

      /* Brand Color/Primary */

      color: #3b3e98;
      margin-bottom: 16px;
    }

    .desc {
      /* Body/Large - SemiBold */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* or 156% */

      /* Color/Neutral 80 */

      color: #6d7078;
      margin-bottom: 62px;
    }
    .btn-seeDetail {
      width: 192px;
      height: 65px;

      /* Brand Color/Primary */

      background: #3b3e98;
      box-shadow: 0px 0px 49px -2px rgba(0, 0, 0, 0.19);
      border-radius: 81px;

      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      text-align: center;

      /* Color/Neutral 10 */

      color: #ffffff;
      text-transform: uppercase;
    }
  }
  .left,
  .right {
    flex: 1;
  }
`;
