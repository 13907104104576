import React, { useEffect, useContext, useState } from "react";
import * as Components from "../../../component";
import * as Style from "../../../component/style/content/default";
import * as Services from "../../../service";
import * as Modal from "./modal";
import { Method } from "../../../service";
import { Title } from "../dashboard/style";
import moment from 'moment'
import { Form, Table } from "react-bootstrap";
import { Refresh } from "@styled-icons/boxicons-regular";
import api_permission from "service/api/permission";

export default function MonitoringStock() {
  const { selected_company, dispatch, retail_permission, data_user } = useContext(Services.Context);
  const [modal, setModal] = useState({
    company: false
  });
  const [store, setStore] = useState([]);
  const [storeSelected, setStoreSelected] = useState([]);
  const [data, setData] = useState(null);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(selected_company.value){
      api_permission(dispatch, selected_company.value, data_user.id)
    }
  }, [selected_company])

  const GetCompany = async () => {
    await Method.get(`company`)
      .then(async (res) => {
        if (res?.data?.success) {
          if (!selected_company.slug) {
            dispatch({
              type: "SELECTED_COMPANY",
              selected_company: {
                value: res?.data?.data[0]?.company_id,
                label: res?.data?.data[0]?.company_name,
                slug: res?.data?.data[0]?.company_slug
              },
            });
          }
        }
      })
      .catch((err) => {
      });
  };

  const GetStore = async () => {
    await Method.get(`company/${selected_company.value}/store?limit=*`)
      .then(async (res) => {
        if (res.data.success) {
          setStore(res.data.data);
          var storeSelectedTemp = [];
          for (var i = 0; i < res.data.data.length; i++) {
            storeSelectedTemp.push(res.data.data[i].store_id);
          }
          setStoreSelected(storeSelectedTemp);
        }
      })
      .catch((err) => {
      });
  };

  const GetMenu = async () => {
    setLoading(true);
    setDate(new Date());
    await Method.get(`company/slug/${selected_company.slug}/store/menu`)
      .then(async (res) => {
        if (res.data.success) {
          setData(res.data.data);
        } else {
          Components.AlertError({ title: "Error", text: res?.data?.error });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Components.AlertError({ title: "Error", text: 'Terjadi Kesalahan' });
      });
  };

  const checkStoreSelected = (item) => {
    const idx = storeSelected.findIndex(x => x === item.store_id);
    if (idx >= 0) {
      return true;
    } else {
      return false;
    }
  }

  const checkedStore = (item) => {
    const idx = storeSelected.findIndex(x => x === item.store_id);
    if (idx >= 0) {
      var storeSelectedNew = storeSelected.filter(x => x !== item.store_id)
    } else {
      var storeSelectedNew = [...storeSelected, item.store_id]
    }
    setStoreSelected(storeSelectedNew);
  }

  useEffect(() => {
    GetCompany();
  }, []);

  useEffect(() => {
    if (selected_company.value) {
      GetStore();
      GetMenu();
    }
  }, [selected_company])

  return (
    <Style.ContainerContent>
      {/* MODAL =========== */}
      <Modal.ModalSelectCompany
        show={modal.company}
        onHide={() => setModal((state) => ({ ...state, company: false }))}
      />

      <div className="d-flex">
        <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
          Monitoring Stock
        </Style.HeaderPrimary>
        <div className="wrap-select align-items-center ms-5">
          <button
            className="btn border"
            onClick={() => setModal((state) => ({ ...state, company: true }))}
          >
            {selected_company.label ? selected_company.label : 'Select Company'} <i className="demo-icon icon-chevron-down"></i>
          </button>
        </div>
      </div>

      {
        retail_permission.data?.type!=='staff' ? (
          <div>
            {
              selected_company &&
              <div className="mt-4">
                <div className="d-flex flex-row align-items-center">
                  <div className="me-4">
                    <div>Last Update:</div>
                    <Title style={{ fontSize: 20 }}>{moment(date).format('DD MMMM YYYY HH:mm:ss')}</Title>
                  </div>
                  <Style.ButtonPrimary onClick={() => GetMenu()} disabled={loading}>
                    {
                      loading ? (
                        <div>Data Loading...</div>
                      ) : (
                        <div>
                          <Refresh
                            width={24}
                          /> &nbsp; Refresh Data
                        </div>
                      )
                    }
                  </Style.ButtonPrimary>
                </div>
                <div className="mt-4" style={{ padding: 15, backgroundColor: '#669dcc', borderRadius: 10 }}>
                  {
                    store.length > 0 ? (
                      <div className="d-flex flex-row align-items-center mb-2">
                        <div className="text-white me-5" style={{ fontSize: 16 }}>Choose your store</div>
                        <Form.Check
                          inline
                          label="Store"
                          type={'checkbox'}
                          style={{ color: '#fff', marginTop: 5 }}
                          checked={storeSelected.length > 0}
                          onChange={(value) => {
                            if(storeSelected.length > 0){
                              setStoreSelected([])
                            }else{
                              var storeSelectedTemp = [];
                              for (var i = 0; i < store.length; i++) {
                                storeSelectedTemp.push(store[i].store_id);
                              }
                              setStoreSelected(storeSelectedTemp);
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="text-white" style={{ fontSize: 16 }}>Store Not Found</div>
                    )
                  }
                  <div className="row">
                    {
                      store.map((item, index) => {
                        return (
                          <div key={'store-' + index.toString()} className="col-md-2 col-sm-4">
                            <Form.Check
                              inline
                              label={item.store_name}
                              type={'checkbox'}
                              style={{ color: '#fff' }}
                              checked={checkStoreSelected(item)}
                              onChange={() => checkedStore(item)}
                            />
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {
                  data && store.length > 0 &&
                  <div className="mt-4">
                    <Table bordered hover>
                      <thead style={{ backgroundColor: '#669dcc' }}>
                        <tr>
                          <th colSpan={storeSelected.length + 1} className="align-middle text-center text-white">SUMMARY Stock Store</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {
                            storeSelected.map((item, index) => {
                              const store_selected = store.filter(x => x.store_id === item);
                              const store_name = store_selected[0]?.store_name;
                              return (
                                <th key={'storelist-' + index.toString()} className="align-middle text-center">{store_name}</th>
                              )
                            })
                          }
                          <th className="align-middle text-center">Total Stock</th>
                        </tr>
                        <tr>
                          {
                            storeSelected.map((itemStore, indexStore) => {
                              var a = 0;
                              var regex = new RegExp(`.*S${itemStore}$`, 'm');

                              Object.keys(data.stock).map((item) => {
                                if (item.match(regex)) {
                                  a += data.stock[item]
                                }
                              });

                              return (
                                <th key={"store-data-" + indexStore.toString()} className="text-center">
                                  <b style={{ color: '#669dcc' }}>{format(a)}</b>
                                </th>
                              )
                            })
                          }
                          <th className="text-center">
                            <b style={{ color: '#669dcc' }}>{format(TotalStock())}</b>
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                    <Table bordered hover className="mt-2">
                      <thead style={{ backgroundColor: '#669dcc' }}>
                        <tr>
                          <th rowSpan={2} className="align-middle text-center text-white">Item SKU</th>
                          <th rowSpan={2} className="align-middle text-center text-white">Item Name</th>
                          <th colSpan={storeSelected.length} className="text-center text-white">Store</th>
                          <th rowSpan={2} className="align-middle text-center text-white">Total Item</th>
                        </tr>
                        <tr>
                          {
                            storeSelected.map((item, index) => {
                              const store_selected = store.filter(x => x.store_id === item);
                              const store_name = store_selected[0]?.store_name;
                              return (
                                <td key={'storelist-' + index.toString()} className="align-middle text-center text-white">{store_name}</td>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.item.map((item, index) => {
                            return (
                              <tr key={"data-" + index.toString()}>
                                <td>{item.item_sku}</td>
                                <td>{item.item_name}</td>
                                {
                                  storeSelected.map((itemStore, indexStore) => {
                                    return (
                                      <td key={"store-data-" + indexStore.toString()} className="text-center">
                                        {data.stock[`I${item.item_id}S${itemStore}`] ? format(data.stock[`I${item.item_id}S${itemStore}`]) : "-"}
                                      </td>
                                    )
                                  })
                                }
                                <td className="text-center">
                                  <b style={{ color: '#669dcc' }}>{format(TotalItem(item))}</b>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                      <tbody>
                        <tr>
                          <th className="align-middle text-center" colSpan={2} rowSpan={2}>
                            <b style={{ color: '#669dcc' }}>Total Stock</b>
                          </th>
                          {
                            storeSelected.map((item, index) => {
                              const store_selected = store.filter(x => x.store_id === item);
                              const store_name = store_selected[0]?.store_name;
                              return (
                                <th key={'storelist-' + index.toString()} className="align-middle text-center">{store_name}</th>
                              )
                            })
                          }
                          <th className="align-middle text-center">Total Item</th>
                        </tr>
                        <tr>
                          {
                            storeSelected.map((itemStore, indexStore) => {
                              var a = 0;
                              var regex = new RegExp(`.*S${itemStore}$`, 'm');

                              Object.keys(data.stock).map((item) => {
                                if (item.match(regex)) {
                                  a += data.stock[item]
                                }
                              });

                              return (
                                <th key={"store-data-" + indexStore.toString()} className="text-center">
                                  <b style={{ color: '#669dcc' }}>{format(a)}</b>
                                </th>
                              )
                            })
                          }
                          <th className="text-center">
                            <b style={{ color: '#669dcc' }}>{format(TotalStock())}</b>
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                }
              </div>
            }
          </div>
        ):(
          <div class="alert alert-warning mt-lg-5" role="alert">
            <h5>Akses Ditolak</h5>
            Anda sebagai staff tidak mempunyai akses di perusahaan ini
          </div>
        )
      }

    </Style.ContainerContent>
  );

  function TotalItem(item) {
    var total = 0;
    storeSelected.map((store) => {
      if (data?.stock[`I${item.item_id}S${store}`]) {
        total += data?.stock[`I${item.item_id}S${store}`]
      }
    })

    return total;
  }

  function TotalStock() {
    var total = 0;
    storeSelected.map((store) => {
      var regex = new RegExp(`.*S${store}$`, 'm');
      Object.keys(data.stock).map((item) => {
        if (item.match(regex)) {
          total += data.stock[item]
        }
      });
    })

    return total;
  }

  function format(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}