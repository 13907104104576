import React, { useContext, useEffect, useState } from "react";
import * as Component from "../../../../component";
import * as Style from "../../../../component/style/content/default";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Method } from "../../../../service";
import * as Services from "../../../../service";
import { Form } from 'react-bootstrap';

const api = Services.api.ApiRetail.toko;

const ModalFormBlacklistPhone = (props) => {
  const { selected_company } = useContext(Services.Context);
  const { show, onHide, onSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [customerMsisdn, setCustomerMsisdn] = useState("");

  const BtnSubmit = () => {
    if (show === 'add') {
      saveProcess();
    } else {
      editProcess();
    }
  }

  const saveProcess = async () => {
    setLoading(true);
    try {
      const data_post = {
        company_id: selected_company.value,
        customer_msisdn: customerMsisdn
      }

      await Method.post(`loyalty/blacklist_phone`, data_post)
        .then(async (res) => {
          if (res?.data?.success) {
            Component.AlertSuccess({ title: "Success", text: res?.data?.success });
            onSubmit();
          } else {
            Component.AlertError({ title: "Error", text: res?.data?.error });
          }
        })
    } catch (err) {
      Component.AlertError({ title: "Error", text: err });
    } finally {
      setLoading(false);
    }
  }

  const editProcess = async () => {
    setLoading(true);
    try {
      const data_post = {
        loyalty_blacklist_phone_id: show.loyalty_blacklist_phone_id,
        company_id: selected_company.value,
        customer_msisdn: customerMsisdn
      }

      await Method.put(`loyalty/blacklist_phone`, data_post)
        .then(async (res) => {
          if (res?.data?.success) {
            Component.AlertSuccess({ title: "Success", text: res?.data?.success });
            onSubmit();
          } else {
            Component.AlertError({ title: "Error", text: res?.data?.error });
          }
        })
    } catch (err) {
      Component.AlertError({ title: "Error", text: err });
    } finally {
      setLoading(false);
    }
  }

  const clearForm = () => {
    setCustomerMsisdn("");
  }

  useEffect(() => {
    if (show) {
      if (show === 'add') {
        clearForm();
      } else {
        setCustomerMsisdn(show.customer_msisdn)
      }
    }
  }, [show])

  return (
    <Component.Modal_Component
      size="lg"
      title={(show=="add"?"Tambah":"Edit") + ' MSISDN'}
      btnSubmit
      btnName={loading ? <Component.LoadingIcon /> : "Simpan"}
      onClick={loading ? null : BtnSubmit}
      onHide={onHide}
      show={show}
    >
      <div className="container">
        <div>
          <Style.Label color={Style.COLOR_SECONDARY}>Nomer MSISDN</Style.Label>
          <Form.Control
            placeholder="MSISDN"
            value={customerMsisdn}
            onChange={(e) => setCustomerMsisdn(e.target.value)}
          />
        </div>
      </div>
    </Component.Modal_Component>
  );
};

export default ModalFormBlacklistPhone;