import Navbar from "landingPage/components/navbar_v2";
import Footer from "landingPage/components/footer_v2";
import { Carousel, Interest, Article } from "../../../components/version2";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import NavTabsInterest from "./interest/nav_tabs_interest";
import PlacementSection from "./interest/placement_ooh";
import ProductSection from "./interest/product";
import SelectedFilterItem from "./interest/selected_filter_item";
import qs from "qs"

const baseUrl = "https://stadscore.mediacartz.com/api/v1";

const HomePage = () => {
  const [data, setData] = useState({
    carousel: [],
    channel: [],
    inventory: [],
    product: [],
    placement: [],
    category: [],
    article: [],
  });

  const [selected, setSelected] = useState({
    channel: {},
    inventory: [],
    inventory_placement: [],
    placement: [],
  });

  const [selectedCat, setSelectedCat] = useState({
    province: "",
    city: "",
    district: "",
    subDistrict: "",
  });
  const [searchPlacement, setSearchPlacement] = useState("");

  const getDataCarousel = async () => {
    const result = await axios.get(`${baseUrl}/banner`).then((res) => res.data);
    setData((state) => ({
      ...state,
      carousel: result.data,
    }));
  };

  const getDataChannel = async () => {
    const result = await axios
      .get(`${baseUrl}/channel`)
      .then((res) => res.data);
    setData((state) => ({
      ...state,
      channel: result.data,
    }));
  };

  const getDataInventory = async (channel_id) => {
    const result = await axios
      .get(`${baseUrl}/inventory?channel_id=${channel_id}`)
      .then((res) => res.data);
    setData((state) => ({
      ...state,
      inventory: result.data,
    }));
  };

  const getDataCategory = async (parent_id) => {
    const result = await axios
      .get(`${baseUrl}/category/recursive/${parent_id || 1}`)
      .then((res) => res.data);
    setData((state) => ({
      ...state,
      category: result.data,
    }));
  };

  const getDataPlacement = async (
    category_id,
    inventory_id,
    placement_name
  ) => {
    const result = await axios
      .get(`${baseUrl}/placement`, {
        params: {
          channel_id: [selected.channel?.channel_id],
          inventory_id: [inventory_id],
          category_id: category_id,
          placement_name: placement_name,
        },
      })
      .then((res) => res.data);
    setData((state) => ({
      ...state,
      placement: result.data,
    }));
  };

  const getDataProduct = async () => {
    const newInventoryPlacement = selected.inventory_placement?.map((item) => ({
      ...item,
      inventory_id: selected.placement.find(
        (find) => find.channel_id === item.channel_id
      )?.inventory_id,
    }));

    const filter = selected.inventory
      .map((item) => ({
        channel_id: item.channel_id,
        inventory_id: item.inventory_id,
      }))
      .concat(
        newInventoryPlacement.map((item) => ({
          channel_id: item.channel_id,
          inventory_id: item.inventory_id,
          placement_id: item.placement_id,
        }))
      );
    const body = {
      params: {
        page: 1,
        rows: 10,
        filter,

        // inventory_id: selected.inventory?.map((item) => item?.inventory_id),
        // placement_id: selected.inventory_placement?.map(
        //   (item) => item.placement_id
        // ),
      },
    };

    const result = await axios
      .get(`${baseUrl}/product`, body)
      .then((res) => res.data);

    console.log({ result });
    setData((state) => ({
      ...state,
      product: result.data?.data,
    }));
  };

  const getDataArticle = async () => {
    const body = {
      params: {
        page: 1,
        rows: 10,
        order: [
          {
            field: "views",
            sort: "DESC",
          },
        ],
      },
    };
    try {
      const result = await axios.get(`${baseUrl}/article`, {
        params: body.params,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
      console.log({ result: result.data.data.data });
      if (result.data.data.data) {
        setData((state) => ({
          ...state,
          article: result.data.data.data,
        }));
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const btnSelectCategeory = (evt, type) => {
    const value = evt?.value;
    setSelectedCat((state) => ({
      ...state,
      [type]: value?.category_id,
    }));
    getDataPlacement(
      value?.category_id,
      selected.placement[0]?.inventory_id,
      searchPlacement
    );
  };

  useEffect(() => {
    getDataCarousel();
    getDataCategory(1);
    getDataChannel();
    getDataArticle();
  }, []);

  useEffect(() => {
    getDataProduct();
  }, [selected]);

  const btnHandleChannel = (item) => {
    console.log({ btnHandleChannel: item });
    setSelected((state) => ({
      ...state,
      channel: item,
    }));
    getDataInventory(item.channel_id);
  };

  const btnHandleInventory = (item) => {
    const shorten = selected.channel.shorten;
    console.log({ btnHandleInventory: item });

    if (shorten === "MSG") {
      if (
        selected.inventory.filter(
          (filter) => filter.inventory_name === item.inventory_name
        ).length == 0
      ) {
        setSelected((state) => ({
          ...state,
          inventory: [...selected.inventory, { ...item, shorten }],
        }));
      }
    } else {
      setSelected((state) => ({
        ...state,
        placement: [{ ...item, shorten }],
      }));
      getDataPlacement("", item?.inventory_id);
    }
  };

  const btnHandlePlacement = (item) => {
    const shorten = selected.channel.shorten;
    console.log({ item, selected, cek: selected.inventory_placement });

    if (
      selected.inventory_placement.filter(
        (filter) => filter.placement_id === item.placement_id
      ).length === 0
    ) {
      console.log("lagi kosong");
      setSelected((state) => ({
        ...state,
        inventory_placement: [
          ...selected.inventory_placement,
          {
            ...item,
            shorten,
            inventory_name: selected.placement[0]?.inventory_name,
          },
        ],
      }));
    }
  };

  const btnDeletedFilter = (item) => {
    console.log({ item });

    if (item.shorten !== "MSG") {
      setSelected((state) => ({
        ...state,
        inventory_placement: selected.inventory_placement.filter(
          (filter) => filter.placement_id !== item.placement_id
        ),
      }));
    } else {
      setSelected((state) => ({
        ...state,
        inventory: selected.inventory.filter(
          (filter) => filter.inventory_id !== item.inventory_id
        ),
      }));
    }
  };

  const btnSubmitSearchPlacement = (e) => {
    e.preventDefault();
    getDataPlacement(
      selectedCat.province ??
      selectedCat.city ??
      selectedCat.district ??
      selectedCat.subDistrict,
      selected.placement[0]?.inventory_id,
      searchPlacement
    );
  };

  const newInventory = selected.inventory.concat(selected.inventory_placement);

  // console.log({ newInventory });
  // console.log({ selected });
  return (
    <div>
      <Navbar />
      <div className="container-carousel">
        <Carousel arrImage={data.carousel} key={0} />
      </div>
      <Container>
        <div className="container-interest">
          <div className="title-product-section">
            Select Your Interests on Our Ad Channels
          </div>
          <NavTabsInterest
            key={0}
            data={data.channel}
            dataInventory={data.inventory}
            btnHandleChannel={btnHandleChannel}
            btnHandleInventory={btnHandleInventory}
            selected={selected}
          />

          {newInventory.length > 0 && newInventory[0].inventory_name ? (
            <div className="container-selected-filters mt-5">
              <div className="title-selected-filter">Selected Filters</div>
              <div className="list-selected-filter d-flex gap-2 flex-wrap">
                {newInventory.map((item, idx) => (
                  <SelectedFilterItem
                    key={idx}
                    title={`${item.shorten} - ${item.shorten !== "MSG" ? `${item.inventory_name} - ` : ""
                      }  ${item.shorten !== "MSG"
                        ? item.placement_name
                        : item.inventory_name
                      }`}
                    onClickProp={() => btnDeletedFilter(item)}
                  />
                ))}
              </div>
            </div>
          ) : null}

          {selected.channel?.has_placement === 1
            ? selected.placement.length > 0 && (
              <div className="mt-5">
                <PlacementSection
                  data={data.placement}
                  category={data.category}
                  btnSelectCategeory={btnSelectCategeory}
                  selected={selectedCat}
                  selected_placement={selected}
                  btnHandlePlacement={btnHandlePlacement}
                  onChangeSearchPlacement={(e) =>
                    setSearchPlacement(e.target.value)
                  }
                  btnSubmitSearchPlacement={btnSubmitSearchPlacement}
                />
              </div>
            )
            : null}

          <div className="mt-4">
            <ProductSection data={data.product} />
          </div>
        </div>
        <div className="container-article">
          <Article data={data.article} />
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default HomePage;

const Container = styled.div`
  .title-product-section {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    /* or 129% */
    text-align: center;
    letter-spacing: -0.021em;

    color: #161c2b;
    margin-bottom: 20px;
  }
  padding: 0 50px;

  .container-interest {
    margin-top: 80px;
  }

  .container-carousel {
    margin-top: 42px;
  }

  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding: 0 20px;
    overflow-x: hidden;
  }
`;
