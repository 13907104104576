import React from "react";
import Select from "react-select";
import InputSearch from "../../../../component/input/search";
import { ButtonPrimary, ButtonDanger } from "component/style/content/default";
import CardProduct from "component/card/beriklan/product";

const OOH = () => {
  return (
    <React.Fragment>
      <div className="section-header-information text-center">
        <h3>
          {/* nama channel */}
          OUT OF HOME
        </h3>
        <span>
          {/* nama inventory yang di ambil */}
          OUTDOOR DIGITAL
        </span>
      </div>

      <div className="section-form-filter container mt-lg-5">
        <div className="row">
          <div className="col-md-3 col-sm-12 p-1">
            <Select />
          </div>
          <div className="col-md-3 col-sm-12 p-1">
            <Select />
          </div>
          <div className="col-md-3 col-sm-12 p-1">
            <Select />
          </div>
          <div className="col-md-3 col-sm-12 p-1">
            <Select />
          </div>
          <div className="col-md-3 col-sm-12 p-1">
            <Select />
          </div>
          <div className="col-md-3 col-sm-12 p-1">
            <Select />
          </div>
          <div className="col-md-3 col-sm-12 p-1">
            <Select />
          </div>
          <div className="col-md-3 col-sm-12 p-1">
            <InputSearch />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 col-sm-12">
            <div className="d-flex align-items-center gap-2">
              <ButtonPrimary medium>Search Product</ButtonPrimary>
              <ButtonDanger medium>Reset</ButtonDanger>
            </div>
          </div>
        </div>
      </div>

      <div className="section-list-product container mt-5">
        <div className=" bg-white shadow rounded-2 p-5">
          <div className="row">
            <div className="col-md-4 col-sm-6 p-1">
              <CardProduct />
            </div>
            <div className="col-md-4 col-sm-6 p-1">
              <CardProduct />
            </div>
            <div className="col-md-4 col-sm-6 p-1">
              <CardProduct />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OOH;
