import React from "react"
import styled from "styled-components";
import { Search } from "@styled-icons/bootstrap"

const InputSearch = ({
  propsInput,
  onSubmit
}) => {
  return (
    <Container onSubmit={onSubmit} className="d-flex">
      <input {...propsInput} type="text" className="form-control" />
      <button type="submit">
        <Search className="icon-search" width={18} />
      </button>
    </Container>
  )
}

export default InputSearch;

const Container = styled.form`
position:relative;

input{
  border-radius:7px !important;
}

button{
  position:absolute;
  right:0;
  bottom:0;
  top:0;
  border:none;
  background-color: transparent;
  
  
  .icon-search{
    color:#000000;
    
  }
}
`

