import styled from "styled-components";
import ImgBanner from "../../../assets/iconAndLogo/img_banner_publisher.png";

const BannerPublisher = () => {
  return (
    <Container>
      <div className="big-text">
        Lorem ipsum dolor sit amet consectetur. Fermentum fusce aliquam.
      </div>
      <div className="sub-title-banner">
        Lorem ipsum dolor sit amet consectetur. A ac velit nulla neque gravida
        eu mauris integer.
      </div>
      <button className="btn btn-banner">Lorem Ipsum Dolor</button>
    </Container>
  );
};

export default BannerPublisher;

const Container = styled.div`
  width: 100%;
  background-image: url(${ImgBanner});
  background-position: center;
  background-repeat: no-repeat;
  height: 716px;
  overflow: hidden;
  padding: 0 150px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .container-interest {
    margin-top: 80px;
  }

  .container-carousel {
    margin-top: 42px;
  }

  @media only screen and (min-width: 468px) and (max-width: 1024px) {
    padding: 0 20px;
    overflow-x: hidden;
  }

  .btn-banner {
    width: 220px;
    height: 60px;

    /* Neutral/10

White
*/
    background: #ffffff;
    /* Brand Colors/Primary/100

3B3E98
*/
    border: 1px solid #3b3e98;
    border-radius: 8px;
    /* Text L/Semi Bold */

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.011em;

    /* Brand Colors/Primary/100 */

    color: #3b3e98;
  }
  .sub-title-banner {
    /* Heading/S */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */

    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    margin-bottom: 34px;
    color: #ffffff;
  }

  .big-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    /* or 114% */

    display: flex;
    align-items: center;

    color: #ffffff;
    max-width: 1000px;
    margin-bottom: 12px;
  }
`;
