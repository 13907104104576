import React, { useState, useEffect } from "react";
import {
  Form,
  AlertQuestion,
  AlertSuccess,
  TableData,
  AlertError,
} from "../../../../component";
import {
  ButtonPrimary,
  COLOR_PRIMARY,
  ContainerContent,
  HeaderPrimary,
  Tr,
  Td,
} from "../../../../component/style/content/default";
import { api_sender_id } from "../../../../service/api";
import {
  Modal_detail,
  Modal_edit_sender,
  Modal_tambah_sender,
  Modal_upload_dokumen,
} from "./modal";
import { Pagination, DropDown_More } from "../../../../component";
import { Numeric, displayStatus, useTitle } from "../../../../util";
import Select from "react-select";

const header = [
  "No",
  "Tipe",
  "Sender ID",
  "Surat Penunjukkan",
  "Form Pengumpulan Data",
  "Perusahaan",
  "Status Persetujuan",
  "More",
];

const data_more = [
  { name: "Lihat Detail" },
  { name: "Ubah" },
  { name: "Upload Dokumen" },
  { name: "Kirim Ulang Verifikasi" },
];

export const IdxSenderId = () => {
  useTitle("Beriklan | Sender ID");
  const [modal, setModal] = useState({
    tambah: false,
    detail: false,
    ubah: false,
    upload_dokumen: false,
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    page: 1,
    last_page: 1,
  });
  const [sender_id, setSender_id] = useState(null);
  const [options, setOptions] = useState({
    ms_channel: [],
    ms_inventory: [],
  });
  const [selected, setSelected] = useState({
    ms_channel: "",
    ms_inventory: "",
  });

  const getData = async () => {
    await api_sender_id
      .get_sender({
        page: 1,
        param: {
          ms_channel_id: selected["ms_channel"]?.value,
          ms_inventory_id: selected["ms_inventory"]?.value,
        },
      })
      .then(async (res) => {
        if (res?.success) {
          setData(res.data);
          setPage({ ...page, page: res.page, last_page: res.last_page });
        } else {
          await AlertError({ title: "ERROR", text: "Terjadi kesalahan" });
          await window.location.reload();
        }
      });
  };

  const BtnPagination = async (e) => {
    await api_sender_id
      .get_sender({
        page: e.selected + 1,
        param: {
          ms_channel_id: selected["ms_channel"]?.value,
          ms_inventory_id: selected["ms_inventory"]?.value,
        },
      })
      .then((res) => {
        if (res?.success) {
          setData(res.data);
          setPage({ ...page, page: res.page, last_page: res.last_page });
        }
      });
  };

  const getMSChannel = async () => {
    const result = await api_sender_id.get_ms_channel();

    if (result.success) {
      setOptions((state) => ({
        ...state,
        ms_channel: [
          {
            value: "",
            label: "Lihat semua",
          },
          ...result?.data?.map((item) => ({
            value: item.ms_channel_id,
            label: item.ms_channel_name,
          })),
        ],
      }));
    }
  };

  const getMsInventory = async () => {
    const result = await api_sender_id.get_ms_inventory();

    console.log({ result });

    if (result.success) {
      setOptions((state) => ({
        ...state,
        ms_inventory: [
          {
            value: "",
            label: "Lihat Semua",
          },
          ...result?.data?.map((item) => ({
            value: item.ms_inventory_id,
            label: item.ms_inventory_identifier,
          })),
        ],
      }));
    }
  };

  useEffect(() => {
    getData();
    getMSChannel();
    getMsInventory();
  }, []);

  const btnMore = ({ name, idx, sender_id }) => {
    setSender_id(sender_id);

    switch (name) {
      case "Kirim Ulang Verifikasi":
        AlertQuestion({ text: "Resend Verification Email ?" }).then(
          async (result) => {
            if (result.isConfirmed) {
              await api_sender_id
                .put_resend_verfikasi({ sender_id })
                .then(async (res) => {
                  await AlertSuccess({ title: "SUCCESS", text: res.success });
                  await getData();
                });
            }
          }
        );
        break;

      case "Lihat Detail":
        setModal({ ...modal, detail: true });
        break;
      case "Ubah":
        setModal({ ...modal, ubah: true });
        break;
      case "Upload Dokumen":
        setModal({ ...modal, upload_dokumen: true });
        break;
    }
  };

  const handleSelectMSChannel = async (e) => {
    setSelected((state) => ({
      ...state,
      ms_channel: e,
    }));
    await api_sender_id
      .get_sender({
        page: 1,
        param: {
          ms_channel_id: e.value,
        },
      })
      .then(async (res) => {
        if (res?.success) {
          setData(res.data);
          setPage({ ...page, page: 1, last_page: res.last_page });
        } else {
          await AlertError({ title: "ERROR", text: "Terjadi kesalahan" });
          await window.location.reload();
        }
      });
  };
  const handleSelectMSInventory = async (e) => {
    setSelected((state) => ({
      ...state,
      ms_inventory: e,
    }));
    await api_sender_id
      .get_sender({
        page: 1,
        param: {
          ms_inventory_id: e.value,
        },
      })
      .then(async (res) => {
        if (res?.success) {
          setData(res.data);
          setPage({ ...page, page: 1, last_page: res.last_page });
        } else {
          await AlertError({ title: "ERROR", text: "Terjadi kesalahan" });
          await window.location.reload();
        }
      });
  };

  return (
    <ContainerContent>
      <HeaderPrimary color={COLOR_PRIMARY}>Daftar Sender ID</HeaderPrimary>

      {/* MODAL ==================== */}

      <Modal_tambah_sender
        show={modal.tambah}
        onHide={() => setModal({ ...modal, tambah: false })}
        getData1={getData}
      />

      <Modal_detail
        show={modal.detail}
        onHide={() => setModal({ ...modal, detail: false })}
        sender_id={sender_id}
      />

      <Modal_edit_sender
        getData1={getData}
        show={modal.ubah}
        onHide={() => setModal({ ...modal, ubah: false })}
        sender_id={sender_id}
      />

      <Modal_upload_dokumen
        show={modal.upload_dokumen}
        onHide={() => setModal({ ...modal, upload_dokumen: false })}
        sender_id={sender_id}
        getData1={getData}
      />
      {/* MODAL ==================== */}

      <section>
        <Form>
          <section className=" mb-3 gap-2">
            <div className="row">
              <div className=" col-auto p-2">
                <ButtonPrimary
                  onClick={() => setModal({ ...modal, tambah: true })}
                >
                  Create Sender ID
                </ButtonPrimary>
              </div>
              <div className="col-md-3 col-sm-12 p-2">
                <Select
                  options={options.ms_channel}
                  placeholder="Select Channel"
                  onChange={handleSelectMSChannel}
                />
              </div>
              <div className="col-md-3 col-sm-12 p-2">
                <Select
                  options={options.ms_inventory}
                  placeholder="Select Inventory"
                  onChange={handleSelectMSInventory}
                />
              </div>
            </div>
          </section>

          <section className="mb-3 mb-md-3">
            <TableData header={header}>
              {data.map((item, idx) => (
                <Tr
                  key={idx}
                  // style={{ cursor: "pointer" }}
                  // onClick={() =>
                  //   btnMore({
                  //     name: "Lihat Detail",
                  //     sender_id: item.sender_id,
                  //     idx: idx,
                  //   })
                  // }
                >
                  <Td data-label="No">
                    {Numeric({ page: page.page, idx: idx })}
                  </Td>
                  <Td data-label="Tipe">
                    {item.ms_inventory &&
                      item.ms_inventory.ms_inventory_identifier}{" "}
                    {item.ms_channel && item.ms_channel.ms_channel_name}
                  </Td>
                  <Td data-label="Sender ID">{item.sender_name}</Td>
                  <Td data-label="Surat Penunjukkan">
                    {(item.ms_inventory.ms_inventory_identifier === "SMS" ||
                      item.ms_inventory.ms_inventory_identifier === "MMS") &&
                    item.sender_approve_status_name !== "approved"
                      ? item.sender_data_form_file
                        ? "✔"
                        : "menunggu"
                      : "N/A"}
                  </Td>
                  <Td data-label="Form Pengumpulan Data">
                    {(item.ms_inventory?.ms_inventory_identifier === "SMS" ||
                      item.ms_inventory?.ms_inventory_identifier === "MMS") &&
                    item.sender_approve_status_name !== "approved"
                      ? item.sender_data_form_file
                        ? "✔"
                        : "menunggu"
                      : "N/A"}
                  </Td>
                  <Td data-label="Perusahaan">
                    {item.company && item.company?.company_name}
                  </Td>
                  <Td data-label="Status Persetujuan">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: displayStatus(item.sender_approve_status_name),
                      }}
                    />
                  </Td>
                  <Td data-label="More">
                    {item.demo_sender_status === false ? (
                      <DropDown_More
                        title={
                          <button
                            class="button is-primary is-small"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                          >
                            <span class="icon is-small">•••</span>
                          </button>
                        }
                        id={item.sender_id}
                        data_more={data_more.filter((fil) =>
                          item.sender_approve_status_name === "pending" &&
                          item.ms_inventory_id == 1
                            ? fil.name !== "Upload Dokumen"
                            : item.ms_inventory.ms_inventory_identifier !==
                                "EMAIL" &&
                              item.ms_channel.ms_channel_name === "BROADCAST"
                            ? fil.name !== "Kirim Ulang Verifikasi"
                            : fil.name !== "Kirim Ulang Verifikasi" &&
                              fil.name !== "Upload Dokumen"
                        )}
                        onClick={({ name, id }) =>
                          btnMore({ name: name, sender_id: id, idx: idx })
                        }
                      />
                    ) : (
                      "Demo Access"
                    )}
                  </Td>
                </Tr>
              ))}
            </TableData>
          </section>

          <section>
            <Pagination
              totalPage={page.last_page}
              page={page.page}
              handleOnChange={BtnPagination}
            />
          </section>
        </Form>
      </section>
    </ContainerContent>
  );
};
