import styled from "styled-components";
import ImgBanner from "landingPage/assets/iconAndLogo/Group39711.png";

const BannerAboutUs = () => {
  return (
    <Container>
      <div className="left">
        <img src={ImgBanner} alt={ImgBanner} />
      </div>
      <div className="right">
        <div className="title">About Us</div>
        <div className="desc">
          Catch up on the latest news coverage, product updates, promotions, and
          more about Mediacartz
        </div>
      </div>
    </Container>
  );
};

export default BannerAboutUs;

const Container = styled.div`
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .desc {
      /* Body/Large - SemiBold */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */

      text-align: center;

      /* Color/Neutral 80 */

      color: #6d7078;
    }
    .title {
      display: flex;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      /* identical to box height */

      /* Brand Color/Primary */

      color: #3b3e98;
      text-align: center;
      position: relative;
      /* justify-content: center; */
      z-index: 1;
      :before {
        z-index: -1;
        position: absolute;
        content: "";
        width: 40px;
        height: 40px;
        background: #fcd45d;
        left: 0;
        top: -5px;
        border-radius: 100%;
        display: flex;
      }
    }
  }
  display: flex;
  align-items: center;
  .right,
  .left {
    flex: 1;
  }
`;
